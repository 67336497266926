import { FaroDialog, SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { Alert, Typography } from "@mui/material";
import WarningIcon from "@assets/icons/warning_32px.svg?react";
import { sphereColors } from "@styles/common-colors";

export interface DialogContentProps {
    /** The title of the dialog */
    title: string;
  
    /** The description below the title in the dialog */
    description: string;  
}
interface Props{
    /** The content of the dialog */
    dialogContent: DialogContentProps;

    /** Callback when the dialog closes */
    onClose: () => void;

    /** Callback when the confirm button clicked */
    onConfirm: () => void;

    /** Whether the dialog is in the process of deleting or not */
    isDeleting: boolean;

    /** Whether to show the warning message or not. Defaults to true */
    shouldShowWarning?: boolean;
}

/**
 *  A dialog prompting the user to confirm or cancel the deletion of a markup. 
 *  The dialog will also show a warning message that the operation cannot be undone.
*/
export function DeleteMarkupDialog({
    dialogContent,
    onClose,
    onConfirm,
    isDeleting,
    shouldShowWarning= true,
  }: Props): JSX.Element {
    return (
        <FaroDialog
            title= {dialogContent.title}
            confirmText="Delete"
            open={true}
            isConfirmLoading={isDeleting}
            onConfirm={onConfirm}
            onClose={onClose}
            confirmButtonColor="red"
            maxWidth="sm"
            fullWidth={true}
        >
            <Typography 
                sx={{ 
                fontSize: "14px",
                fontWeight: "400",
                alignSelf: "stretch",
                mb: SPACE_ELEMENTS_OF_MODAL,
                }}
            >
                {dialogContent.description}
            </Typography>
            {shouldShowWarning && (
            <Alert
                severity="warning"
                icon={<WarningIcon width="20px" height="20px"/>}
                sx={{
                    backgroundColor: sphereColors.yellow100,
                    alignItems: "center",
                    paddingY: "8px",
                    "& .MuiAlert-icon ": {
                    marginRight: "0px",
                    paddingRight: "16px",
                    paddingY: "0px",
                    },
                }}
            >
                <Typography
                    sx={{ 
                    fontSize: "14px",
                    fontWeight: "600",
                    color: sphereColors.gray800,
                    }}
                >
                The operation cannot be undone.
                </Typography>
            </Alert>
            )}
        </FaroDialog>
    );
}