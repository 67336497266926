import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { formatUserRoleType } from "@utils/data-display";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { FaroTextMenuButton } from "@components/common/faro-text-menu-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName, updateOne } from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import { useChangeRoleBulkDialogContent } from "@components/table/members/bulk-actions/bulk-change-role-helper";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import {
  BaseCompanyIdProps,
  BaseGroupIdProps,
} from "@custom-types/sdb-company-types";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import {
  AssignableUpdateGroupMemberRole,
  BaseMemberAndTeam,
} from "@custom-types/member-types";
import { updateMemberRoleInGroup } from "@store/groups/groups-slice";
import { currentUserSelector } from "@store/user/user-selector";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";

/** Button allowing to change role of members in group */
export function BulkChangeGroupRole({
  companyId,
  groupId,
}: BaseCompanyIdProps & BaseGroupIdProps): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [newRole, setNewRole] =
    useState<SphereDashboardAPITypes.GroupMemberCompanyRole>(
      CoreAPITypes.EUserCompanyRole.projectManager
    );
  const currentUser = useAppSelector(currentUserSelector);

  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));
  const selectedTeams = useAppSelector(selectedEntitiesSelector("teams"));

  // TODO: https://faro01.atlassian.net/browse/ST-2840 remove this variable after remove the user group on members endpoint
  const selectedEntitiesWithoutTeams = selectedEntities.filter(
    (entities) => entities.kind === "user"
  );

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const { bulkDialogContent, successDialogContent } =
    useChangeRoleBulkDialogContent({ subjectType: "group", newRole });

  // Closes the bulk dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onNewRoleClicked(event: React.MouseEvent<HTMLLIElement>): void {
    dispatch(setBulkActionName("changeRole"));

    // Cannot change the role of current logged user if the role is group manager as he might loose access to the group
    if (
      currentUser &&
      currentUser.role === CoreAPITypes.EUserCompanyRole.companyManager
    ) {
      dispatch(
        updateOne({
          id: currentUser.identity,
          changes: {
            status: "not-allowed",
            message: "Cannot change the role of your user as group manager",
          },
        })
      );
    }

    // TODO: Check whether it is possible to remove type-casting: https://faro01.atlassian.net/browse/ST-1348
    setNewRole(
      event.currentTarget.dataset
        .myValue as SphereDashboardAPITypes.GroupMemberCompanyRole
    );
    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextMenuButton
        buttonTitle="Change role"
        tooltipText="Change role in group"
        buttonSx={() => {
          return { fontWeight: 600 };
        }}
      >
        {Object.values(AssignableUpdateGroupMemberRole).map((role) => (
          <FaroMenuItem
            data-my-value={role}
            key={role}
            value={role}
            onClick={onNewRoleClicked}
          >
            {formatUserRoleType(role)}
          </FaroMenuItem>
        ))}
      </FaroTextMenuButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "changeRole" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "changeRole" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkChangeRole}
          bulkActionCallback={(member: BaseMemberAndTeam) =>
            dispatch(
              updateMemberRoleInGroup({
                coreApiClient,
                companyId,
                groupId,
                role: newRole,
                userIdentity: member.identity,
              })
            ).unwrap()
          }
          selectedEntities={[...selectedEntitiesWithoutTeams, ...selectedTeams]}
          uniqueIdKey="identity"
          tableDataType="members"
        />
      )}
    </>
  );
}
