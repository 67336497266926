import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";
import {
  BackgroundTaskInfoType,
  BackgroundTaskState,
  CaptureTreePointCloudType,
  CaptureTreeEntity,
  CaptureTreeEntityType,
  RevisionStatus,
} from "@faro-lotv/service-wires";
import { CaptureTreeEntityWithRevisionStatus } from "@custom-types/capture-tree/capture-tree-types";
import { GUID } from "@faro-lotv/ielement-types";
import { FileUploadTask } from "@custom-types/file-upload-types";
import { TableItem, TableItemState, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { getGlsUuid } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { RegisteredDataBase } from "@pages/project-details/project-data-management/registered-data/registered-data-types";
import { DeviceType, UploadedElsScan } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { ICompanyMemberBase } from "@stellar/api-logic/dist/api/core-api/sphere-dashboard-api-types";
import { isElsScanFileUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { getScanByFilename } from "@api/stagingarea-api/stagingarea-api";
import { SdbBackgroundTask } from "@custom-types/sdb-background-tasks-types";
import { isScanEntity, isScanEntityType } from "@utils/capture-tree/capture-tree-utils";

/** Specify base number to parse integers from */
const RADIX = 10;

/**
 * Constructs a URL for publishing a registration revision for a specified project.
 *
 * @param {APITypes.ProjectId} projectId - The ID of the project for which the registration is being published.
 * @param {string} revisionId - The ID of the registration revision to be published.
 * @returns {string} A URL object pointing to the endpoint for publishing the specified registration revision.
 */
export function getInspectAndPublishToolUrl(
  projectId: APITypes.ProjectId,
  revisionId: string
): string {
  const url = new URL(
    `/data-preparation/project/${projectId}`,
    runtimeConfig.urls.sphereViewerUrl
  );
  url.searchParams.append("revisionId", revisionId);
  return url.href;
}

/**
 * @returns The UUID of a raw ELS or FLS scan, as defined by the scanner. Undefined otherwise.
 *          For older ELS scans uploaded by Staging Area, the externalId may be a random GUID instead.
 */
export function getExternalScanId(scan: CaptureTreeEntity): string | undefined {
  if (isScanEntity(scan) && scan.pointClouds) {
    const { elsRaw, flsRaw } = CaptureTreePointCloudType;
    for (const pointCloud of scan.pointClouds) {
      if ((pointCloud.type === elsRaw || pointCloud.type === flsRaw) && pointCloud.externalId) {
        return pointCloud.externalId;
      }
    }
  }
  return undefined;
}

/**
 * Returns a map of the externalIds of the uploaded ELS scans.
 * @param uploadedEntities Array of uploaded entities returned by uploadedDataSelector.
 */
export function getUploadedIdsMap(uploadedEntities: UploadedElsScan[]): { [key: string]: boolean } {
  const idMap: { [key: string]: boolean } = {};
  for (const uploadedEntity of uploadedEntities) {
    const externalId = getExternalScanId(uploadedEntity);
    if (externalId) {
      idMap[externalId] = true;
    }
  }
  return idMap;
}

/**
 * Returns the upload tasks to remove since associated entities now exist, and those to keep.
 * @param uploadTasks Array of upload tasks for the current project.
 * @param uploadedIdsMap Result of the getUploadedIdsMap function.
 */
export function getUploadTasksToKeepOrRemove(
  uploadTasks: FileUploadTask[],
  uploadedIdsMap: { [key: APITypes.UUID]: boolean }
): {
  uploadTasksToKeep: FileUploadTask[],
  uploadTasksToRemove: FileUploadTask[]
} {
  const uploadTasksToKeep: FileUploadTask[] = [];
  const uploadTasksToRemove: FileUploadTask[] = [];

  for (const task of uploadTasks) {
    const lsDataV2 = isElsScanFileUploadTaskContext(task.context) ? task.context.lsDataV2 : null;
    const id = getGlsUuid(task.fileName, lsDataV2);
    if (id && uploadedIdsMap[id]) {
      uploadTasksToRemove.push(task);
    } else {
      uploadTasksToKeep.push(task);
    }
  }

  return { uploadTasksToKeep, uploadTasksToRemove };
}

/**
 * Creates table entries for the scan table shown in the Staging Area based on the provided upload tasks.
 * @param uploadTasks Array of upload tasks for the current project.
 * @param currentUser Current user returned by currentUserSelector.
 * @returns Object that also contains if at least one upload task is currently active and if there is at least one
 *          upload error.
 */
export function getTableItemsFromTasks(
  uploadTasks: FileUploadTask[],
  currentUser: ICompanyMemberBase | null
) : {
  tableItemsFromTasks: TableItem[],
  isUploadingFromTasks: boolean,
  hasUploadErrorFromTasks: boolean,
} {
  const tableItemsFromTasks: TableItem[] = [];
  let isUploadingFromTasks: boolean = false;
  let hasUploadErrorFromTasks: boolean = false;

  for (const task of uploadTasks) {
    const progress = getUploadProgressFromTask(task);

    let itemState: TableItemState;
    switch (task.status) {
      case BackgroundTaskState.created:
      case BackgroundTaskState.started:
        if (0 < progress) {
          isUploadingFromTasks = true;
          itemState = "uploading";
        } else {
          itemState = "start";
        }
        break;
      case BackgroundTaskState.succeeded:
        // It takes a while until a finished upload task results in an added entity to uploadedEntities in which case
        // the uploadedIdsMap check above for a skip resolves to true.
        // We have to handle this by marking the upload as still in progress for the overall state in this case.
        isUploadingFromTasks = true;
        itemState = "uploaded";
        break;
      case BackgroundTaskState.failed:
        itemState = "error";
        hasUploadErrorFromTasks = true;
        break;
      case BackgroundTaskState.aborted:
        // User-aborted tasks are not considered as upload errors so we don't set hasUploadErrorFromTasks to true here.
        // It doesn't seem necessary to show them in the table, since the user aborted them himself in the current tab.
        continue;
      case BackgroundTaskState.scheduled:
      default:
        itemState = "start";
        break;
    }

    const lsDataV2 = isElsScanFileUploadTaskContext(task.context) ? task.context.lsDataV2 : null;
    const scan = getScanByFilename(task.fileName, lsDataV2);

    const item: TableItem = {
      id: task.id,
      type: "Upload",
      name: scan?.name || task.fileName,
      additionalName: scan?.name ? task.fileName : undefined,
      createdAt: scan?.recordingTime ?? "1970-01-01T00:00:00.000Z",
      // Since it's only possible to see the uploads from the current user then one can correctly assume that all
      // local uploads are from the current user.
      createdBy: currentUser?.id ?? "",
      deviceType: CaptureTreeEntityType.elsScan,
      state: itemState,
      progress,
    };
    tableItemsFromTasks.push(item);
  }

  return {
    tableItemsFromTasks,
    isUploadingFromTasks,
    hasUploadErrorFromTasks,
  };
}

/**
 * Creates table entries for the scan table shown in the Staging Area based on the provided uploaded entities.
 * @param uploadedEntities Array of uploaded entities returned by uploadedDataSelector.
 * @param state State of the Staging Area workflow.
 */
export function getTableItemsFromEntities(
  state: WorkflowState,
  uploadedEntities: UploadedElsScan[]
): TableItem[] {
  const tableItemsFromUploads: TableItem[] = [];

  for (const upload of uploadedEntities) {
    let itemState: TableItemState;
    if (upload.isProcessing) {
      itemState = "processing";
    } else if (upload.hasTaskErrors) {
      itemState = "error";
    } else if (state === "published") {
      itemState = "published";
    } else {
      itemState = "unpublished";
    }

    const item: TableItem = {
      id: upload.id,
      type: "Scan",
      name: upload.name,
      createdAt: upload.createdAt,
      createdBy: upload.createdBy,
      deviceType: upload.type,
      state: itemState,
    };
    tableItemsFromUploads.push(item);
  }

  return tableItemsFromUploads;
}

/**
 * Returns the progress of the upload task.
 * @param uploadTask Upload task for the current project.
 */
function getUploadProgressFromTask(uploadTask: FileUploadTask): number {
  switch (uploadTask.status) {
    case BackgroundTaskState.succeeded:
    case BackgroundTaskState.aborted:
    case BackgroundTaskState.failed:
      // We treat errors as the task having finished for the sake of progress reporting.
      return 100;
    case BackgroundTaskState.created:
    case BackgroundTaskState.scheduled:
    case BackgroundTaskState.started:
    default:
      return uploadTask.progress ?? 0;
  }
}

/**
 * Returns the progress of the upload tasks by calculating the average progress value.
 * This wouldn't work well if the uploaded files had very different file sizes. But this shouldn't be much of a problem
 * for our use case in the Staging Area, so it should be a reasonably good heuristic.
 * @param uploadTasks Array of upload tasks for the current project.
 */
function getUploadProgressFromTasks(uploadTasks: FileUploadTask[]): number {
  if (!uploadTasks.length) {
    return 0;
  }

  let sumProgress = 0;
  for (const uploadTask of uploadTasks) {
    sumProgress += getUploadProgressFromTask(uploadTask);
  }
  return sumProgress / uploadTasks.length;
}

/**
 * Returns the upload progress.
 * @param state State of the Staging Area workflow.
 * @param uploadTasks Array of upload tasks for the current project.
 */
export function getUploadProgress(state: WorkflowState, uploadTasks: FileUploadTask[]): number {
  switch (state) {
    case "start":
      return 0;
    case "uploading":
    case "uploadError": {
      const progress = getUploadProgressFromTasks(uploadTasks);
      // There's unfortunately an additional waiting time of various length from the moment when all upload tasks report
      // 100% and the start of the processing step. Instead of showing 100%, we rather show 98% to indicate that there's
      // still work getting done.
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- See comment above
      return Math.min(progress, 98);
    }
    case "uploaded":
    case "processing":
    case "processError":
    case "processed":
    case "registering":
    case "registerError":
    case "registerBadResult":
    case "registered":
    case "publishing":
    case "publishError":
    case "published":
      return 100;
    default:
      return 0;
  }
}

/**
 * Returns the processing progress.
 * @param state State of the Staging Area workflow.
 * @param captureTreeOpenDraft Capture tree entities for the current draft revision of the selected project.
 * @param tasks Array of background tasks for the current project.
 */
export function getProcessProgress(
  state: WorkflowState,
  captureTreeOpenDraft: CaptureTreeEntityWithRevisionStatus[],
  tasks: SdbBackgroundTask[]
): number {
  switch (state) {
    case "start":
    case "uploading":
    case "uploadError":
    case "uploaded":
    case "processError":
      return 0;
    case "processing":
      return getProcessProgressFromTasks(captureTreeOpenDraft, tasks);
    case "processed":
    case "registering":
    case "registerError":
    case "registerBadResult":
    case "registered":
    case "publishing":
    case "publishError":
    case "published":
      return 100;
    default:
      return 0;
  }
}

/**
 * Returns the progress of the provided registration task.
 * @param registrationRevision Newest registration backend revision = registeredDataSelector()[0].
 */
function getRegisterProgressFromEntity(registrationRevision?: RegisteredDataBase): number {
  const task = registrationRevision?.task;
  if (!task) {
    return 0;
  }

  switch (task.state) {
    case "Succeeded":
    case "Aborted":
    case "Failed":
      // We treat errors as the task having finished for the sake of the progress bar.
      return 100;
    case "Pending":
    case "Created":
    case "Scheduled":
    case "Started":
    default: {
      // Cut off the % suffix added by generateSdbBackgroundTaskProgress.
      if (task.progress?.endsWith("%")) {
        const progressStr = task.progress.substring(0, task.progress.length - 1);
        return parseInt(progressStr, RADIX);
      } else {
        return 0;
      }
    }
  }
}

/**
 * Returns the registration progress.
 * @param state State of the Staging Area workflow.
 * @param registrationRevision Newest registration backend revision = registeredDataSelector()[0].
 */
export function getRegisterProgress(state: WorkflowState, registrationRevision?: RegisteredDataBase): number {
  switch (state) {
    case "start":
    case "uploading":
    case "uploadError":
    case "uploaded":
    case "processing":
    case "processError":
    case "registerError":
    case "registerBadResult":
    case "processed":
      return 0;
    case "registering":
      return getRegisterProgressFromEntity(registrationRevision);
    case "registered":
    case "publishing":
    case "publishError":
    case "published":
      return 100;
    default:
      return 0;
  }
}

/**
 * @returns The device type for a capture tree entity type
 *
 * @param captureTreeEntityType Capture tree entity type
 */
export function getDeviceType(
  captureTreeEntityType: CaptureTreeEntityType
): DeviceType {
  switch (captureTreeEntityType) {
    case CaptureTreeEntityType.elsScan:
      return DeviceType.blink;
    case CaptureTreeEntityType.focusScan:
      return DeviceType.focus;
    case CaptureTreeEntityType.orbisScan:
    case CaptureTreeEntityType.orbisFlashScan:
      return DeviceType.orbis;
    case CaptureTreeEntityType.siteScapeScan:
      return DeviceType.sitescape;
    case CaptureTreeEntityType.root:
    case CaptureTreeEntityType.cluster:
    case CaptureTreeEntityType.pCloudUploadScan:
    case CaptureTreeEntityType.structuredScan:
    case CaptureTreeEntityType.unstructuredScan:
    case CaptureTreeEntityType.photogrammetryScan:
      return DeviceType.unknown;
    default:
      captureTreeEntityType satisfies never;
      return DeviceType.unknown;
  }
}

/**
 * Map background tasks by element ID.
 * @param tasks Array of background tasks for the current project.
 */
function getTasksByElementId(tasks: SdbBackgroundTask[]): Record<string, SdbBackgroundTask> {
  const tasksByElementId: Record<string, SdbBackgroundTask> = {};
  for (const task of tasks) {
    if (task.context?.elementId) {
      tasksByElementId[task.context.elementId] = task;
    }
  }
  return tasksByElementId;
}

/**
 * Get all pointcloud IDs for the given pontcloud type from a capture tree.
 * @param captureTree Capture tree entities for the selected project.
 * @param pcType Pointcloud type to filter.
 */
function getPointCloudsByType(captureTree: CaptureTreeEntity[], pcType: CaptureTreePointCloudType): string[] {
  const pointClouds: string[] = [];
  for (const node of captureTree) {
    if (node.pointClouds) {
      for (const pointCloud of node.pointClouds) {
        if (pointCloud.type === pcType) {
          pointClouds.push(pointCloud.id);
        }
      }
    }
  }
  return pointClouds;
}

/**
 * Get the progress of all tasks that are running for the given pointcloud IDs.
 * @param tasksByElementId Background tasks for the current project.
 * @param pointCloudIds The IDs of the pointclouds belonging to a scans of the capture tree.
 * @returns The progress and total number of tasks.
 */
function getBackgroundTaskProgress(
  tasksByElementId: { [id: GUID]: SdbBackgroundTask },
  pointCloudIds: string[]
): { progress: number, total: number } {
  // Default value is correct for ProcessElsRawScan. It is used when a task is missing to have the correct total.
  let defaultTotal = 6;
  let progress = 0;
  let total = 0;

  // 1st pass: Find the default total value.
  for (const pointCloudId of pointCloudIds) {
    const task = tasksByElementId[pointCloudId];
    if (task && task.status?.type === BackgroundTaskInfoType.progress && task.status.progress) {
      // Update the default, in case it is different. "> 0" should be always true, but who knows.
      if (task.status.progress.total > 0) {
        defaultTotal = task.status.progress.total;
        break;
      }
    }
  }

  // 2nd pass: Calculate the progress and total values.
  for (const pointCloudId of pointCloudIds) {
    const task = tasksByElementId[pointCloudId];
    if (task) {
      if (task.status?.type === BackgroundTaskInfoType.state) {
        if (task.status.state === BackgroundTaskState.succeeded) {
          progress += defaultTotal;
          total += defaultTotal;
        } else if (task.status.state === BackgroundTaskState.failed) {
          // NOP: Error in a processing task. There may be another task that retries the processing.
          // -> Increment neither progress nor total.
        }
      } else if (task.status?.type === BackgroundTaskInfoType.progress && task.status.progress) {
        progress += task.status.progress.current;
        total += task.status.progress.total;
      }
    } else {
      total += defaultTotal;
    }
  }

  return { progress, total };
}

/**
 * Returns the progress percentage of processing tasks for the given scans in the capture tree.
 * @param captureTreeOpenDraft Capture tree entities for the current draft revision of the selected project.
 * @param tasks Array of background tasks for the current project.
 */
function getProcessProgressFromTasks(
  captureTreeOpenDraft: CaptureTreeEntityWithRevisionStatus[],
  tasks: SdbBackgroundTask[]
): number {
  const tasksByElementId = getTasksByElementId(tasks);
  // Filter out unchanged entities.
  const changedCaptureTreeEntities = captureTreeOpenDraft.filter((entity) => entity.status !== RevisionStatus.initialized);
  const elsPointClouds = getPointCloudsByType(changedCaptureTreeEntities, CaptureTreePointCloudType.elsRaw);
  const progressResult = getBackgroundTaskProgress(tasksByElementId, elsPointClouds);

  // Return the progress percentage, as a number between 0 and 100.
  if (progressResult.total === 0) {
    return 0;
  }
  return Math.floor(progressResult.progress / progressResult.total * 100);
}

/** Returns whether the provided table item can be removed. */
export function canTableItemBeRemoved(item: TableItem): boolean {
  return item.type === "Scan" && item.state !== "uploading" && item.state !== "processing";
}

/** Returns the capture tree entities of scans to be removed. */
export function getScanEntitiesToRemove(
  scansToRemove: TableItem[],
  treeElements: CaptureTreeEntity[]
): CaptureTreeEntity[] {
  // Find the corresponding capture tree entities for the selected table items.
  const treeMap = new Map<string, CaptureTreeEntity>();
  for (const treeElement of treeElements) {
    if (isScanEntityType(treeElement.type)) {
      treeMap.set(treeElement.id, treeElement);
    }
  }

  const treeEntitiesToRemove: CaptureTreeEntity[] = [];
  for (const scan of scansToRemove) {
    const treeElement = treeMap.get(scan.id);
    if (treeElement) {
      treeEntitiesToRemove.push(treeElement);
    }
  }
  return treeEntitiesToRemove;
}
