import { useState } from "react";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useRegistrationApiClient } from "@api/registration-api/use-registration-api-client";
import { FaroPopoverAction } from "@components/common/faro-popover-action";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { fetchCaptureTreeData } from "@hooks/data-management/use-data-management";
import { useToast } from "@hooks/use-toast";
import { Box, LinearProgress, Stack, Step, StepLabel } from "@mui/material";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { DataManagementProgressBar } from "@pages/project-details/project-data-management/data-management-stepper/data-management-progress-bar";
import { StepState, TableItem, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { getScanEntitiesToRemove } from "@pages/project-details/project-data-management/data-management-utils";
import { deleteCaptureTreeEntities } from "@pages/project-details/project-data-management/import-data/cancel-import-utils";
import { RegisterDataButton } from "@pages/project-details/project-data-management/register-data-button";
import { startRegistration } from "@pages/project-details/project-data-management/register-data-utils";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { captureTreeSelector } from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector, useAppDispatch } from "@store/store-helper";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";

interface Props extends BaseProjectIdProps {
  processStepState: StepState;
  state: WorkflowState;
  progress: number;
  /** Array of DataManagementTableItem entities. */
  items: TableItem[];
}

export function ProcessStep({
  processStepState,
  state,
  projectId,
  progress,
  items,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const projectApiClient = useProjectApiClient({ projectId });
  const progressApiClient = useProgressApiClient({ projectId });
  const registrationApiClient = useRegistrationApiClient({ projectId });
  const { showToast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleErrorWithToast } = useErrorContext();
  const dispatch = useAppDispatch();
  const treeElements = useAppSelector(captureTreeSelector);

  async function continueWithSuccessfulScans(): Promise<void> {
    const scans = items.filter((item) => item.type === "Scan");
    const scansToRemove = scans.filter((item) => item.state === "error");

    const treeEntitiesToRemove = getScanEntitiesToRemove(scansToRemove, treeElements);
    if (treeEntitiesToRemove.length) {
      trackEvent({
        name: DataManagementEvents.removeScans,
        props: {
          numberOfEntities: treeEntitiesToRemove.length,
          numberOfUploads: 0,
          source: "continueWithSuccessfulScans",
        },
      });

      await deleteCaptureTreeEntities(projectApiClient, treeEntitiesToRemove);
      // Fetch the new state immediately instead of waiting until the next usePolling() happens.
      await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient)
        // Not important. The UI will update a bit later.
        .catch(() => undefined);
    }

    // Don't start the registration if there are no scans remaining.
    const hasRemainingScans = scansToRemove.length < scans.length;
    if (hasRemainingScans) {
      await startRegistration({
        progressApiClient,
        projectApiClient,
        registrationApiClient,
        trackEvent,
        setIsLoading,
        showToast,
        dispatch,
        handleErrorWithToast,
      });
    } else {
      showToast({
        message: "There were no usable scans. Please retry the upload.",
        type: "info",
        shouldAutoHide: false,
      });
    }
  }

  return (
    <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <Step
          active={processStepState === "active"}
          completed={processStepState === "done"}
        >
          <FaroPopoverAction
            title="Processing failed"
            // eslint-disable-next-line max-len
            description="Some scans could not be processed. These scans won't be published to the project dataset. You can continue with the successfully processed scans."
            actionTitle="Continue with Successful Scans"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            action={continueWithSuccessfulScans}
            isLoading={isLoading}
            isEnabled={processStepState === "error"}
          >
            <StepLabel StepIconComponent={DataManagementStepIcon} error={processStepState === "error"}>
              Process
            </StepLabel>
          </FaroPopoverAction>
        </Step>
        {
          // Better show infinite progress bar until there's actual progress reported.
          (processStepState === "active" && progress === 0) ?
            <LinearProgress
              variant="indeterminate"
              color="primary"
              sx={{
                "&.MuiLinearProgress-root": {
                  flex: "1 1 auto",
                  marginLeft: "10px",
                  marginRight: "10px",
                },
              }}
            /> :
            <DataManagementProgressBar progress={progress} />
        }
      </Stack>
      { (state === "processed" || state === "registerError" || isDevModeEnabled) &&
        <Box sx={{
          width: "fit-content",
          marginLeft: "28px",
        }}>
          <RegisterDataButton size="small" isDevMode={state !== "processed" && state !== "registerError"} />
        </Box>
      }
    </Stack>
  );
}
