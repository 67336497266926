import { formatUserRoleType } from "@utils/data-display";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useToast } from "@hooks/use-toast";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  AssignableUpdateCompanyMemberRole,
  MemberIdentityRoleProps,
} from "@custom-types/member-types";
import { currentUserSelector } from "@store/user/user-selector";
import { RoleChangeSuccessDescription } from "@components/table/members/members-column/role-column/role-change-success-description";
import { updateMemberRoleInWorkspace } from "@store/members/members-slice-thunk";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { SelectCompanyRole } from "@components/role/select-company-role";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { RolePartiallyChangedGmPm } from "@components/table/members/members-column/role-column/role-partially-changed-gm-pm";

const selectableRoles = Object.values(AssignableUpdateCompanyMemberRole);

type Props = BaseCompanyIdProps & MemberIdentityRoleProps;

/**
 * Render and adjust member roles in company context.
 * This dropdown is showed to change the role for a single member inside a table.
 */
export function CompanyMemberRole({
  companyId,
  memberIdentityRole,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);

  const isCurrentUser = currentUser?.identity === memberIdentityRole.identity;
  const { canEditMember } = useHasUserValidRoleCompanyLevel();

  // For Project and Group Manager roles don't show the "No role" option since
  // changing to "No role" will have no effect.
  let allowedRoles: CoreAPITypes.EUserCompanyRole[] = selectableRoles;
  if (
    memberIdentityRole.role === CoreAPITypes.EUserCompanyRole.companyManager ||
    memberIdentityRole.role === CoreAPITypes.EUserCompanyRole.projectManager
  ) {
    allowedRoles = selectableRoles.filter(
      (role) => role !== CoreAPITypes.EUserCompanyRole.member
    );

    allowedRoles.push(memberIdentityRole.role);
  }

  /**
   * Return non-editable member role if:
   * - Member is current logged user
   * - User has no permission to edit member
   */
  if (isCurrentUser || !canEditMember) {
    return (
      <span style={{ fontSize: DEFAULT_TEXT_FONT_SIZE }}>
        {formatUserRoleType(memberIdentityRole.role)}
      </span>
    );
  }

  /**
   * Shows a message to the user about the role change.
   * It could be that everything was changed successfully, or that the role was partially changed,
   * because the user was a manager or a group.
   */
  function notifySuccessRoleChange({
    newRole,
    intendedRole,
  }: {
    newRole: CoreAPITypes.EUserCompanyRole;
    intendedRole: CoreAPITypes.EUserCompanyRole;
  }): void {
    // If the new role is different than the role the user intended to change,
    // show a warning message because it changed to a different role.
    if (newRole !== intendedRole) {
      if (
        newRole === CoreAPITypes.EUserCompanyRole.companyManager ||
        newRole === CoreAPITypes.EUserCompanyRole.projectManager
      ) {
        const newRoleName = formatUserRoleType(newRole);
        showToast({
          message: <>Role Changed to <strong>{newRoleName}</strong></>,
          description: (
            <RolePartiallyChangedGmPm
              intendedRole={formatUserRoleType(intendedRole)}
              newRole={newRoleName}
            />
          ),
          type: "warning",
        });
      }
    } else {
      // Otherwise it means that the role was successfully changed to the intended one.
      showToast({
        message: "Role Changed",
        description: (
          <RoleChangeSuccessDescription role={formatUserRoleType(newRole)} />
        ),
        type: "success",
      });
    }
  }

  /** Trigger updating member role and show success message */
  async function onRoleChange(
    role: SphereDashboardAPITypes.IAssignmentCompanyRole
  ): Promise<void> {
    try {
      const result = await dispatch(
        updateMemberRoleInWorkspace({
          coreApiClient,
          companyId,
          role: role as SphereDashboardAPITypes.UpdateCompanyMemberRole,
          identity: memberIdentityRole.identity,
        })
      ).unwrap();
      notifySuccessRoleChange({
        newRole: result.role,
        intendedRole: role,
      });
    } catch (error) {
      // Errors are already handled by the slice, only notify about a successful result
      return;
    }
  }

  return (
    <SelectCompanyRole
      selectedRole={
        memberIdentityRole.role as SphereDashboardAPITypes.IAssignmentCompanyRole
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onChange={onRoleChange}
      isTableCell={true}
    />
  );
}
