import { TeamsTable } from "@components/table/teams/teams-table";
import { TeamsDetails } from "@pages/members/teams/teams-details";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import {
  fetchingTeamsFlagsSelector,
  teamsSelector,
} from "@store/teams/teams-selector";
import { useEffect, useMemo } from "react";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { TeamsPageBulkActions } from "@pages/members/teams/teams-page-bulk-actions";
import { useCoreApiClient } from "@api/use-core-api-client";
import { fetchTeams } from "@store/teams/teams-slice-thunk";
import { TeamsEmptyPage } from "@pages/members/teams/teams-empty-page";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { searchSelector } from "@store/ui/ui-selector";

/**
 * The component that will render the kpi details and the table for the teams
 */
export function Teams({ companyId }: BaseCompanyIdProps): JSX.Element {
  const teams = useAppSelector(teamsSelector);
  const { status, hasFetchedTeams } = useAppSelector(fetchingTeamsFlagsSelector);
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { debouncedSearchText, isDebouncingSearch } = useAppSelector(searchSelector);

  // Constructing the empty page content if should show
  const shouldShowEmptyPage = useMemo(() => {
    return (
      (teams.length === 0 && status === FetchingStatus.succeeded) ||
      status === FetchingStatus.rejected
    );
  }, [status, teams.length]);

  // Fetches the teams if not yet fetched for the first time
  useEffect(() => {
    async function getTeams(): Promise<void> {
      await dispatch(fetchTeams({ coreApiClient}));
    }

    if (!hasFetchedTeams) {
      void getTeams();
    }
  }, [coreApiClient, dispatch, hasFetchedTeams]);

  // Fetches searched teams
  useEffect(() => {
    async function searchTeams(): Promise<void> {
      await dispatch(fetchTeams({
        coreApiClient,
        searchText: debouncedSearchText,
      }));
    }

    if (debouncedSearchText.length) {
      void searchTeams();
    }
  }, [coreApiClient, debouncedSearchText, dispatch]);

  const buttonComponents = TeamsPageBulkActions({
    companyId,
    teams,
  });

  // If fetching is done and teams are empty, then either no team is present or the search text is not matching any team
  if (shouldShowEmptyPage) {
    return <TeamsEmptyPage status={status} />;
  }

  return (
    <>
      <TeamsDetails />

      <TeamsTable
        companyId={companyId}
        teams={teams}
        isLoading={status === FetchingStatus.pending || isDebouncingSearch}
        requiredColumns={[
          TeamsHeaders.name,
          TeamsHeaders.users,
          TeamsHeaders.creationDate,
          TeamsHeaders.workspaceRole,
          TeamsHeaders.options,
        ]}
        bulkActionButtons={buttonComponents}
      />
    </>
  );
}
