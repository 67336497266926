import { Alert, Grid, Stack } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { APITypes, CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { LabelWithHelp } from "@components/common/label-with-help";
import { useAppParams } from "@router/router-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import {
  MemberAutocomplete,
  SelectedMember,
} from "@components/common/member-autocomplete/member-autocomplete";
import { STACK_ERROR_SX } from "@styles/common-styles";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { isObject } from "lodash";
import { sphereColors } from "@styles/common-colors";
import {
  getProjectByIdSelector,
  isProjectArchiveSelector,
} from "@store/projects/projects-selector";
import InfoIcon from "@assets/icons/new/info-circle-fill.svg?react";
import { isProjectDemo } from "@utils/project-utils";
import { ReactSetStateFunction } from "@custom-types/types";
import { isAllowedToChangeManagement } from "@pages/project-details/project-overview/change-management/change-management-utils";
import {
  GroupAutoComplete,
} from "@components/common/group-autocomplete/group-autocomplete";

interface Props extends PropsWithChildren {
  /**
   * Callback function to update the parent component's state
   * to allow or restrict changes to the project group.
   */
  setIsAllowedToChangeProjectGroup: ReactSetStateFunction<boolean>;
}

/** A dialog to change the project manager and group of the current project */
export function ChangeManagementDialog({
  children,
  setIsAllowedToChangeProjectGroup,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { companyId, projectId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();
  const selectedProject = useAppSelector(getProjectByIdSelector(projectId));
  const isProjectArchived = useAppSelector(
    isProjectArchiveSelector(projectId ?? "")
  );
  const isDemoProject = !!selectedProject && isProjectDemo(selectedProject);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [groupMembers, setGroupMembers] = useState<
    SphereDashboardAPITypes.IGroupMemberDetails[]
  >([]);
  const [selectedProjectManager, setSelectedProjectManager] =
    useState<SelectedMember>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<APITypes.GroupId | undefined>(undefined);

  /** Determines if the current user is allowed to change project group */
  const isAllowedToChangeProjectGroup = isAllowedToChangeManagement(
    isProjectArchived,
    isDemoProject,
    selectedProject
  );

  useEffect(() => {
    setIsAllowedToChangeProjectGroup(isAllowedToChangeProjectGroup);
  }, [isAllowedToChangeProjectGroup, setIsAllowedToChangeProjectGroup]);

  // Triggered every time a group is selected.
  // It fetches the members that belong to that group and stores them
  // in the members variable.
  useEffect(() => {
    async function onGroupSelected(groupId: string | number | undefined): Promise<void> {
      if (!companyId || !groupId) {
        return undefined;
      }

      // As soon as you select a different group, empty the members array.
      // TODO: This should be moved to the group slice as createAsyncThunk: https://faro01.atlassian.net/browse/ST-698
      setGroupMembers([]);
      try {
        const { members } = await coreApiClient.V3.SDB.getGroupById(
          companyId,
          groupId
        );

        const membersResult = members ?? [];
        setGroupMembers(membersResult);
      } catch (error) {
        handleErrorWithToast({
          id: `getGroupById-${Date.now().toString()}`,
          title: "Cannot get the group details",
          error,
        });
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    onGroupSelected(selectedGroupId);
  }, [companyId, coreApiClient, handleErrorWithToast, selectedGroupId]);

  // Prefill the project manager with the current project manager, if the current group of the project is selected
  // Empty it for other groups
  useEffect(() => {
    if (selectedProject && companyId && selectedProject.group) {
      if (selectedProject.group.id.toString() !== selectedGroupId) {
        setSelectedProjectManager(null);
        return;
      }

      const projectManager =
        groupMembers.find(
          (member) =>
            member.identity ===
            selectedProject.managers?.projectManager?.identity
        ) ?? null;
      setSelectedProjectManager(projectManager);
    }
  }, [companyId, groupMembers, selectedProject, selectedGroupId]);

  function onOpenInviteDialog(): void {
    if (!isAllowedToChangeProjectGroup) {
      return;
    }

    setIsDialogOpen(true);

    if (selectedProject && companyId && selectedProject.group) {
      setSelectedGroupId(selectedProject.group.id.toString());
    } else {
      setSelectedGroupId("");
    }
  }

  /**
   * Sends a request to the backend to change the project manager and group of the project
   */
  async function changeProjectManagerAndGroup(): Promise<void> {
    if (!companyId) {
      throw new Error("Company id is required!");
    }

    // Theoretically the user should not be able to reach this state
    // if the project manager was not provided but do this for type safety
    // and as an edge case if our members selection didn't work properly.
    if (!selectedProjectManager) {
      throw new Error("Project manager is required!");
    }

    if (!isObject(selectedProjectManager)) {
      const payload: SphereDashboardAPITypes.IBulkMemberInvitePayload<"company"> =
        {
          assignments: [
            {
              identities: [selectedProjectManager],
              role: CoreAPITypes.EUserCompanyRole.projectManager,
            },
          ],
        };

      await coreApiClient.V3.SDB.addMembersToCompany(companyId, payload);
    }

    const payload: SphereDashboardAPITypes.IUpdateProjectPayload = {
      projectManagerIdentity: isObject(selectedProjectManager)
        ? selectedProjectManager.identity
        : selectedProjectManager,
      groupId: String(selectedGroupId),
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    dispatch(updateProjectDetails({ coreApiClient, payload }));

    setIsDialogOpen(false);
  }

  return (
    <>
      <div
        style={
          isAllowedToChangeProjectGroup ? { cursor: "pointer" } : undefined
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onClick={onOpenInviteDialog}
      >
        {children}
      </div>
      <FaroDialog
        title="Change Management"
        confirmText="Change"
        open={isDialogOpen}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={changeProjectManagerAndGroup}
        isConfirmDisabled={!selectedProjectManager}
        onClose={() => setIsDialogOpen(false)}
      >
        <Grid maxWidth="100%" width="70vw">
          <Alert
            icon={<InfoIcon color={sphereColors.blue500} />}
            severity="info"
            sx={{
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
              backgroundColor: sphereColors.blue100,
              color: sphereColors.black,
            }}
          >
            Be aware that changing a group can ultimately impact the project
            manager.
          </Alert>
          <Stack sx={STACK_ERROR_SX}>
            <LabelWithHelp title="Group" />
            <GroupAutoComplete
              callbackOnGroupSelected={(groupId) => 
                setSelectedGroupId(groupId)
              }
              selectedGroupId={selectedGroupId}
            />
          </Stack>
          <Stack>
            <LabelWithHelp title="Manager" isDisabled={!selectedGroupId} />
            <MemberAutocomplete
              members={groupMembers}
              selectedMember={selectedProjectManager}
              onOptionChange={setSelectedProjectManager}
              isDisabled={!selectedGroupId}
              placeholder="Choose who can manage this project..."
            />
          </Stack>
        </Grid>
      </FaroDialog>
    </>
  );
}
