import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { AssignableGroupMemberRole } from "@custom-types/group-types";
import { SelectRoleSubjectProps } from "@components/role/select-role-utils";
import { SelectRole } from "@components/role/select-role";

/** Renders a dropdown to select group role */
export function SelectGroupRole<
  T extends SphereDashboardAPITypes.IAssignmentGroupRole
>({
  selectedRole,
  onChange,
  isTableCell,
  disabledRoles = [],
}: SelectRoleSubjectProps<T>): JSX.Element {
  function onSelectRoleChange(
    role: SphereDashboardAPITypes.IAssignmentGroupRole
  ): void {
    // TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
    onChange(role as T);
  }
  return (
    <SelectRole
      label="Role"
      roles={Object.values(AssignableGroupMemberRole)}
      disabledRoles={disabledRoles}
      isTableCell={isTableCell}
      selectedRole={selectedRole}
      onChange={onSelectRoleChange}
      link="workspaceRolesHelp"
      dataTestId="group-role-selector"
    />
  );
}
