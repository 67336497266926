import { UserProfile } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useState } from "react";
import {
  AccountSettingsDropdown,
  IItemProps,
} from "@pages/account-settings/account-settings-dropdown";

/** Existing unit types in Dashboard */
type UnitType = "Metric" | "Imperial";

/** All the unit types mapped to their description */
const units: IItemProps<UnitType>[] = [
  {
    value: "Metric",
    label: "Metric",
    description: "Millimeter (mm) / Centimeter (cm) / Meter (m)",
  },
  {
    value: "Imperial",
    label: "Imperial",
    description: "Inch (in) / Feet (ft) / Yard (yd)",
  },
];

/** A simple unit dropdown. When opened the user can chose between the available units */
export function AccountUnitSelector(): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const [selectedUnit, setSelectedUnit] = useState<IItemProps<UnitType>>(
    units[0]
  );

  function onUnitChange(newUnit: string): void {
    trackEvent({
      name: UserProfile.changeUnit,
      props: { unit: newUnit },
    });
    const newSelectedUnit = units.find((unit) => unit.value === newUnit);
    if (newSelectedUnit) {
      setSelectedUnit(newSelectedUnit);
    }
  }

  return (
    <AccountSettingsDropdown
      shouldShowDescriptionInTitle={true}
      items={units}
      selectedItem={{
        label: selectedUnit.label,
        value: selectedUnit.value,
        description: selectedUnit.description,
      }}
      onClickHandler={onUnitChange}
    />
  );
}
