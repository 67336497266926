import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCallback, useEffect } from "react";
import { markupsSelector } from "@store/markups/markups-selector";
import { Markup } from "@custom-types/project-markups-types";
import { selectIsProjectLoading } from "@store/i-elements/i-elements-selectors";
import { hasFetchedMarkupsIElementsSelector } from "@store/sdb-i-elements/sdb-i-elements-selectors";
import { setHasFetchedMarkupsIElements } from "@store/sdb-i-elements/sdb-i-elements-slice";
import { IElement, IElementType, IElementTypeHint } from "@faro-lotv/ielement-types";

interface UseProjectMarkupsReturn {
  /** The array of fetched markup entities */
  markups: Markup[];

  /** Whether the project markups are being fetched from the backend */
  isFetchingMarkups: boolean;
}

/**
 * Custom hook that takes care of fetching all markup elements and other associated elements
 *
 * Since a project can have thousands of elements, fetching all of them is inefficient.
 * We will fetch only the elements we need to keep the store as lightweight as possible.
 */
export function useProjectMarkups({
  projectId,
}: BaseProjectIdProps): UseProjectMarkupsReturn {
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const markups = useAppSelector(markupsSelector);
  const isFetchingMarkups = useAppSelector(selectIsProjectLoading);
  const hasFetchedMarkupsIElements = useAppSelector(
    hasFetchedMarkupsIElementsSelector
  );

  const fetchMarkupTemplates = useCallback(async (): Promise<IElement[]> => {
    const templatesContainer = await projectApiClient.getAllIElements({
      type: IElementType.group,
      typeHints: [IElementTypeHint.markup],
    });

    const ancestorIds = templatesContainer.map((element) => element.id);
    const templates = await projectApiClient.getAllIElements({
      ancestorIds,
    });
  
    return templates;
  }, [projectApiClient]);

  // Fetches the ielements required to display the annotations table. The required ielements are:
  // - Markup ielements
  // - Direct children ielements of each markup ielement:
  //    - Assignee ielement
  //    - Due date ielement
  //    - Status ielement
  //    - Thumbnail ielement
  //    - Attachments group ielement. This ielement is the parent of the annotation attachments and it
  //      can be used to count the amount of attachments.
  // - Markup templates ielements. These are needed to edit the annotations.
  // - Root ielement. It is always required to have it in the redux store when handling ielements.
  useEffect(() => {
    if (hasFetchedMarkupsIElements) {
      return;
    }

    void dispatch(
      fetchProjectIElements({
        fetcher: async () => {
          const markups = await projectApiClient.getAllIElements({
            type: IElementType.markup,
          });

          if (!markups.length) {
            dispatch(setHasFetchedMarkupsIElements(true));
            return [];
          }

          const markupIds = markups.map((markup) => markup.id);

          const [children, templates, root] = await Promise.all([
            projectApiClient.getAllIElements({
              ancestorIds: markupIds,
              typeHints: [
                IElementTypeHint.markupAssigneeId,
                IElementTypeHint.markupIssueDueDate,
                IElementTypeHint.markupIssueStatus,
                IElementTypeHint.markupIssueImage,
                IElementTypeHint.attachments,
              ],
            }),
            fetchMarkupTemplates(),
            projectApiClient.getRootIElement(),
          ]);

          dispatch(setHasFetchedMarkupsIElements(true));
          return [...markups, ...children, ...templates, root];
        },
      })
    );
  }, [dispatch, fetchMarkupTemplates, hasFetchedMarkupsIElements, projectApiClient]);

  return {
    markups,
    isFetchingMarkups,
  };
}
