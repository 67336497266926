import { TokenProvider } from "@faro-lotv/service-wires";
import { APITypes } from "@stellar/api-logic";
import {
  ActivityApiConstructorParams,
  FetchCreditTransactionProps,
} from "@api/activity-api/activity-api-types";
import { PaginatedCreditTransactionResponse } from "@store/credit-transactions/credit-transactions-slice-types";

/**
 * An api client to handle request to activity tracking api
 * https://faro01.atlassian.net/wiki/spaces/HB/pages/3873013913/Activity+Tracking+API+aka+Analytics+Backend
 */
export class ActivityApiClient {
  #companyId?: APITypes.CompanyId;
  #projectId?: APITypes.ProjectId;
  #tokenProvider: TokenProvider;
  #activityApiBaseUrl: string;
  #clientId?: string;

  /** Create a new activity api client */
  constructor(params: ActivityApiConstructorParams) {
    this.#companyId = params.companyId;
    this.#projectId = params.projectId;
    this.#activityApiBaseUrl = params.activityApiBaseUrl;
    this.#tokenProvider = params.tokenProvider;
    this.#clientId = params.clientId;
  }

  /**
   * @returns Paginated list of credit transactions
   */
  async fetchCreditTransaction({
    companyId,
    projectId,
  }: FetchCreditTransactionProps = {}): Promise<PaginatedCreditTransactionResponse> {
    const searchParams = new URLSearchParams();

    // Adding companyId if already provided in constructor
    const companyIdToSearch = this.#companyId ?? companyId;
    if (companyIdToSearch) {
      searchParams.append("companyId", companyIdToSearch.toString());
    }

    // Adding projectId if already provided in constructor
    const projectIdToSearch = this.#projectId ?? projectId;
    if (projectIdToSearch) {
      searchParams.append("projectId", projectIdToSearch.toString());
    }
    const url = new URL(
      `${this.#activityApiBaseUrl}/v1/activities?${searchParams.toString()}`
    );

    const req = await fetch(url.toString(), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await this.#tokenProvider()}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ...(this.#clientId && { "X-Holobuilder-Component": this.#clientId }),
      },
    });
    if (!req.ok) {
      throw new Error(`Unable to talk to ActivityAPI: ${req.statusText}`);
    }

    const response = await req.json();

    // TODO: Validate the error response with the error type guard for activity api responses
    // https://faro01.atlassian.net/browse/ST-2947

    return response;
  }
}
