import { ProjectArchivingState } from "@custom-types/project-types";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { AuthenticatedRoute } from "@router/authenticated-route";
import { ProjectsOverview } from "@pages/projects/projects-overview";

interface Props extends BaseCompanyIdProps {
  projectArchivingState: ProjectArchivingState;
  shouldForceLoading: boolean;
}

/**
 * @returns The authenticated projects overview
 */
export function AuthenticatedProjectsOverview ({
  companyId,
  projectArchivingState,
  shouldForceLoading,
}: Props): JSX.Element {
  return (
    <AuthenticatedRoute key={`${projectArchivingState}-projects`}>
      <ProjectsOverview
        companyId={companyId}
        projectArchivingState={projectArchivingState}
        shouldForceLoading={shouldForceLoading}
      />
    </AuthenticatedRoute>
  );
}
