import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import {
  FileUploadTask,
  InvalidUploadToStore,
  MultiFileUploadResponse,
  MultiUploadedFileResponse,
  UploadFileParams,
  UploadMultipleFilesParams,
  ValidateFile,
  UploadedFile,
} from "@custom-types/file-upload-types";
import { useToast } from "@hooks/use-toast";
import { doUpload, isValidFile } from "@hooks/file-upload-utils";
import { useAppDispatch } from "@store/store-helper";
import { setOne } from "@store/upload-tasks/upload-tasks-slice";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { uniqueId } from "lodash";
import { GUID } from "@faro-lotv/foundation";
import {
  isCanceledUploadFile,
  isUploadedFile,
  isUploadFailedFile,
} from "@custom-types/file-upload-type-guards";
import { checkMagicFileHeader } from "@utils/file-utils";

export interface UseFileUpload {
  /**
   * Uploads a single file
   *
   * @returns boolean Returns either true or false, depending on whether the file uploaded successfully or not.
   */
  uploadSingleFile: (params: UploadFileParams) => Promise<boolean>;

  /**
   * Uploads a big file with chunks
   *
   * @returns boolean Returns either true or false, depending on whether the file uploaded successfully or not.
   */
  uploadFileWithChunks: (params: UploadFileParams) => Promise<boolean>;

  /**
   * Upload multiple files
   *
   * @returns boolean Returns either true when all promises finished
   */
  uploadMultipleFiles: (params: UploadMultipleFilesParams) => Promise<boolean>;

  /**
   * Validates a file and adds error to the upload task
   *
   * @returns boolean True if the file is valid to upload
   */
  validateAndAddFailedTask: (params: ValidateFile) => boolean;
}

/** get corresponding bucket so we can chart easely the Upload speed distribution (speed buckets e.g. for current month) */
function getUploadSpeedBucket(uploadSpeedMBps: number): MultiUploadedFileResponse["uploadSpeedBucketMBps"]  {
  if (uploadSpeedMBps <= 2) {
    return "0-2";
  }
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (uploadSpeedMBps <= 5) {
    return "2-5";
  }
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (uploadSpeedMBps <= 10) {
    return "5-10";
  }
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (uploadSpeedMBps <= 20) {
    return "10-20";
  }
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (uploadSpeedMBps <= 50) {
    return "20-50";
  }
  return ">50";
}

/** A hook to upload a file */
export function useFileUpload(): UseFileUpload {
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();
  const { uploadManager } = useFileUploadContext();
  const { showToast } = useToast();
  const dispatch = useAppDispatch();

  async function uploadSingleFile({
    file,
    onUploadStart,
    onUploadProgress,
    onUploadComplete,
    context,
  }: UploadFileParams): Promise<boolean> {
    if (!file.type) {
      // file.type is used to determine the the content-type header of the upload request
      // and must be set for some storage providers.
      showToast({
        message: "File type Missing",
        description: "The selected file is not valid type to upload",
        type: "error",
      });
      return false;
    }

    try {
      await checkMagicFileHeader(file);

      onUploadStart();

      // Fetch upload request details
      const uploadRequest =
        await coreApiClient.V3.SDB.generateSignedUploadUrlWithHeaders(
          encodeURIComponent(file.type),
          context.projectId
        );

      // Upload the file
      await coreApiClient.V3.SDB.uploadAsset({
        method: uploadRequest.method,
        uploadUrl: uploadRequest.uploadUrl,
        file,
        type: file.type,
        headers: uploadRequest.headers,
        onUploadProgress,
      });

      const downloadUrl = uploadRequest.uploadUrl.split("?")[0];

      // Callback with the new file url and file
      onUploadComplete(downloadUrl, context);

      return true;
    } catch (error) {
      handleErrorWithToast({
        id: `uploadSingleFile-${Date.now().toString()}`,
        title: "Error uploading file",
        error,
      });
      return false;
    }
  }

  async function uploadFileWithChunks({
    file,
    onUploadStart,
    onUploadProgress,
    onUploadComplete,
    context,
  }: UploadFileParams): Promise<boolean> {
    if (!file.type) {
      // file.type is used to determine the the content-type header of the upload request
      // and must be set for some storage providers.
      showToast({
        message: "File type Missing",
        description: "The selected file is not valid type to upload",
        type: "error",
      });
      return false;
    }

    try {
      await checkMagicFileHeader(file);

      onUploadStart();

      const uploadResult = await doUpload({
        file,
        // The CoreAPI endpoint to upload files always requires the project ID field.
        // If the project ID is not defined then just pass an empty string.
        // NOTE: CoreAPI doesn't seem to allow an empty string any more; see SphereDropzone.
        projectId: context.projectId ?? "",
        coreApiClient,
        onUploadProgress,
      });

      onUploadComplete(uploadResult.downloadUrl, context);

      return true;
    } catch (error) {
      handleErrorWithToast({
        id: `uploadFileWithChunks-${Date.now().toString()}`,
        title: "Error uploading file",
        error,
      });
      return false;
    }
  }

  async function uploadMultipleFiles({
    files,
    finalizer,
    onUploadStart,
    onUploadProgress,
    onUploadComplete,
    context,
  }: UploadMultipleFilesParams): Promise<boolean> {
    onUploadStart();

    // Adjust the number of concurrent file and chunk uploads to best utilize the bandwidth.
    uploadManager.setMaxConcurrentUploadsAuto(files);
    const uploadT0 = performance.now();

    const responses: MultiFileUploadResponse[] = await Promise.all(
      files.map(
        (file) =>
          // checkMagicFileHeader() is called in CoreFileUploader.doUpload().
          new Promise<MultiFileUploadResponse>((resolve) => {
            uploadManager.startFileUpload({
              file,
              context,
              coreApiClient,
              finalizer,
              onUploadCompleted: (
                id: GUID,
                fileName: string,
                fileSize: number,
                fileType: string,
                downloadUrl: string,
                md5: string
              ) => {
                resolve({
                  id,
                  fileName,
                  fileSize,
                  fileType,
                  downloadUrl,
                  md5,
                });
              },
              onUploadFailed: (id: GUID, fileName: string, error: Error) => {
                resolve({
                  id,
                  fileName,
                  errorMessage: error.message,
                });
              },
              onUploadCanceled: (id: GUID, fileName: string) => {
                resolve({
                  id,
                  fileName,
                });
              },
              onUploadUpdated: (_, progress) => {
                onUploadProgress(progress);
              },
            });
          })
      )
    );

    const uploadT1 = performance.now();
    const oneSecondInMilliseconds = 1000;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const bytesInMB = 1024 * 1024;

    const uploadsTimeSecs = (uploadT1 - uploadT0) / oneSecondInMilliseconds;
    const successUploads = responses.filter(isUploadedFile);
    const successUploadsSize = successUploads.reduce((sum: number, file: UploadedFile) => sum + file.fileSize, 0);
    const successUploadSpeedMBps = (successUploadsSize / bytesInMB) / uploadsTimeSecs;

    const uploadedResponse: MultiUploadedFileResponse = {
      successful: successUploads,
      failed: responses.filter(isUploadFailedFile),
      canceled: responses.filter(isCanceledUploadFile),
      uploadSizeMB: Number((successUploadsSize / bytesInMB).toFixed(2)),
      uploadTimeSecs: Number(uploadsTimeSecs.toFixed(2)),
      uploadSpeedMBps: Number(successUploadSpeedMBps.toFixed(2)),
      uploadSpeedBucketMBps: getUploadSpeedBucket(successUploadSpeedMBps),
    };

    onUploadComplete(uploadedResponse, context);
    return true;
  }

  /** Used to add failed task in store */
  function addFailedUploadTaskInStore({
    fileName,
    errorMessage,
    context,
  }: InvalidUploadToStore): void {
    const isoDate = new Date().toISOString();

    const fileUploadTask: FileUploadTask = {
      id: uniqueId(),
      fileName,
      createdAt: isoDate,
      status: BackgroundTaskState.failed,
      progress: 0,
      context,
      errorMessage,
    };
    dispatch(setOne(fileUploadTask));
  }

  function validateAndAddFailedTask({
    file,
    allowedExtensions,
    maxFileSize,
    context,
  }: ValidateFile): boolean {
    const validatedFile = isValidFile({ file, allowedExtensions, maxFileSize });
    if (!validatedFile.isValid) {
      showToast({
        message: validatedFile.message,
        description: validatedFile.description,
        type: "warning",
      });

      addFailedUploadTaskInStore({
        fileName: file.name,
        context,
        errorMessage: validatedFile.message ?? "Failed to import file",
      });
      return false;
    }

    return true;
  }

  return {
    uploadSingleFile,
    uploadFileWithChunks,
    uploadMultipleFiles,
    validateAndAddFailedTask,
  };
}
