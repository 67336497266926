import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

import { BackgroundTask } from "@api/progress-api/progress-api-types";
import {
  BackgroundTaskCategory,
  SdbBackgroundTask,
} from "@custom-types/sdb-background-tasks-types";
import { SPHERE_LEGACY_MIGRATED_TAG } from "@utils/sdb-company-utils";
import { SdbProject } from "@custom-types/project-types";
import { SdbCompany } from "@custom-types/sdb-company-types";
import {
  AccountSettingsTabs,
  AddOnsTabs,
  AnalyticsTabs,
  GroupTabs,
  MemberTabs,
  ProjectTabs,
  RouteParams,
  SettingsTabs,
  TeamTabs,
  UsersTabs,
} from "@router/route-params";
import { ProjectArchivingState } from "@custom-types/project-types";
import {
  BackgroundTaskState,
  CreateScanEntitiesParams,
} from "@faro-lotv/service-wires";
import {
  CanceledUploadFile,
  FileUploadTask,
  UploadedFile,
  UploadElementType,
  UploadFailedFile,
} from "@custom-types/file-upload-types";
import { CaptureTreeEntity } from "@faro-lotv/service-wires";
import {
  MOCK_CAPTURE_TREE_FOR_MAIN_REVISION,
  MOCK_COMPANY_MEMBERS,
  MOCK_CAPTURE_TREE_REVISIONS,
  MOCK_PROJECT_MEMBERS,
  MOCK_MARKUPS,
  MOCK_IELEMENTS,
  MOCK_CAPTURE_TREE_FOR_REVISION,
  MOCK_UPLOADED_ELS_SCAN_FILES,
  MOCK_UPLOADED_ELS_SCAN_FILES_PARAMS,
  MOCK_FAILED_UPLOADED_ELS_SCAN_FILES,
  MOCK_CANCELED_UPLOADED_ELS_SCAN_FILES,
  MOCK_TEAMS,
  MOCK_REGISTERED_DATA,
  MOCK_UPLOADED_DATA,
  MOCK_PUBLISHED_DATA,
  MOCK_SUBSCRIPTION_FEATURES,
  MOCK_TEAMS_WITH_ROLES,
  MOCK_LEGACY_PROCORE_DATA,
  MOCK_PROCORE_DATA,
  MOCK_UNSET_PROCORE_DATA,
  MOCK_LEGACY_AUTODESK_DATA,
  MOCK_AUTODESK_DATA,
  MOCK_UNSET_AUTODESK_DATA,
  MOCK_GET_AUTODESK_PROJECT_RESPONSE,
  MOCK_CREDIT_TRANSACTIONS,
  MOCK_MARKUP_ATTACHMENTS,
} from "@test/mock-data/index";
import { UploadedData } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { MemberTypes } from "@custom-types/member-types";
import { Markup } from "@custom-types/project-markups-types";
import { IElement, IElementAttachment } from "@faro-lotv/ielement-types";
import { RegisteredData } from "@pages/project-details/project-data-management/registered-data/registered-data-types";
import { PublishedData } from "@pages/project-details/project-data-management/published-data/published-data-types";
import { SdbTeam } from "@custom-types/teams-types";
import {
  AutodeskLegacyMap,
  ProcoreLegacyMap,
  ProjectIntegrationsMap,
} from "@services/integrations-service/integrations-service-types";
import {
  AutodeskProject,
  AutodeskResponse,
} from "@services/integrations-service/autodesk/autodesk-types";
import { CreditTransaction } from "@custom-types/credit-types";
import { CaptureTreeRevision, CaptureTreeEntityWithRevisionStatus } from "@custom-types/capture-tree/capture-tree-types";

interface MockedDataReturn {
  mockCompanies: SphereDashboardAPITypes.ICompanyWithRoleDetails[];
  mockWorkspaces: SphereDashboardAPITypes.IWorkspace[];
  mockBackgroundTasks: BackgroundTask[];
  mockSdbBackgroundTasks: SdbBackgroundTask[];
  mockCompanyGroups: SphereDashboardAPITypes.ICompanyGroup[];
  mockProjectGroups: SphereDashboardAPITypes.ICompanyProject["group"][];
  mockProjectBase: SphereDashboardAPITypes.IProjectBase;
  mockCaptureTree: {
    mockCaptureTreeForMainRevision: CaptureTreeEntity[];
    mockCaptureTreeForRevision: CaptureTreeEntityWithRevisionStatus[];
    mockCaptureTreeRevisions: CaptureTreeRevision[];
  };
  mockCompanyMembers: MemberTypes[];
  mockProjectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
  mockMarkups: Markup[];
  mockMarkupAttachments: IElementAttachment[];
  mockIElements: IElement[];
  mockUploadedElsScanFiles: UploadedFile[];
  mockUploadedElsScanFilesParams: CreateScanEntitiesParams["requestBody"];
  mockFailedUploadedElsScanFiles: UploadFailedFile[];
  mockCanceledUploadedElsScanFiles: CanceledUploadFile[];
  mockTeams: SphereDashboardAPITypes.ITeam[];
  mockTeamsWithRole: SdbTeam[];
  mockUploadedData: UploadedData[];
  mockRegisteredData: RegisteredData[];
  mockPublishedData: PublishedData[];
  mockSubscriptionFeatures: Record<string, SphereDashboardAPITypes.IFeature>;
  mockLegacyProcoreData: ProcoreLegacyMap[];
  mockProcoreData: ProjectIntegrationsMap[];
  mockUnsetProcoreData: ProjectIntegrationsMap[];
  mockLegacyAutodeskData: AutodeskLegacyMap[];
  mockAutodeskData: ProjectIntegrationsMap[];
  mockUnsetAutodeskData: ProjectIntegrationsMap[];
  mockGetAutodeskProjectResponse: AutodeskResponse<AutodeskProject>;
  mockCreditTransactions: CreditTransaction[];
}

export const MockedData: MockedDataReturn = {
  mockWorkspaces: [
    {
      type: CoreAPITypes.EWorkspaceType.admin,
      name: "Admin Panel",
      apiUrl: "https://core.api.staging.holobuilder.com",
      url: "https://admin.staging.holobuilder.com",
      role: SphereDashboardAPITypes.EWorkspaceRole.administrator,
    },
    {
      type: CoreAPITypes.EWorkspaceType.admin,
      name: "Admin Panel (Legacy)",
      apiUrl: "https://core.api.staging.holobuilder.com",
      url: "https://core.api.staging.holobuilder.com/admin",
      role: SphereDashboardAPITypes.EWorkspaceRole.administrator,
    },
    {
      type: CoreAPITypes.EWorkspaceType.company,
      name: "Mohammad Test Company",
      apiUrl: "https://core.api.staging.holobuilder.com",
      url: "https://dashboard.staging.holobuilder.com/5402573851852888",
      role: SphereDashboardAPITypes.EWorkspaceRole.enterpriseAdmin,
      icon: "https://uploads.staging.holobuilder.com/uploads/misc/4KTg8mdOIiJEA9sdaRoAOzT0SCLIuXdyfzLT",
    },
    {
      type: CoreAPITypes.EWorkspaceType.company,
      name: "Rajiv Test Company",
      apiUrl: "https://core.api.staging.holobuilder.com",
      url: "https://dashboard.staging.holobuilder.com/5350890253844480",
      role: SphereDashboardAPITypes.EWorkspaceRole.companyMember,
      icon: "",
    },
    {
      type: CoreAPITypes.EWorkspaceType.company,
      name: "QATest2023",
      apiUrl: "https://core.api.staging.holobuilder.com",
      url: "https://dashboard.staging.holobuilder.com/e53e4f75-9034-47a5-a58f-996d78107f2c",
      role: SphereDashboardAPITypes.EWorkspaceRole.enterpriseViewer,
    },
  ],
  mockCompanies: [
    {
      id: "5402573851852888",
      name: "Mohammad Test Company",
      role: CoreAPITypes.EUserCompanyRole.companyExecutive,
      logoUrl:
        "https://uploads.staging.holobuilder.com/uploads/misc/4KTg8mdOIiJEA9sdaRoAOzT0SCLIuXdyfzLT",
      tags: [],
    },
    {
      id: "5350890253844480",
      name: "Rajiv Test Company",
      role: CoreAPITypes.EUserCompanyRole.member,
      logoUrl: "",
      tags: ["random-tag"],
    },
    {
      id: "e53e4f75-9034-47a5-a58f-996d78107f2c",
      name: "QATest2023",
      role: CoreAPITypes.EUserCompanyRole.companyViewer,
      tags: [SPHERE_LEGACY_MIGRATED_TAG, "other-tag"],
    },
  ],
  mockBackgroundTasks: [
    {
      id: "cee26f24-2985-422a-90d0-6b7ft0090c2c",
      createdAt: "2023-07-26T17:49:02.131Z",
      taskType: "PointCloudLazToPotree",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4860-b4d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8f5b-c5c305017ff7",
        correlationId: "01f5042d-0d7c-4be0-f180-08db8e00b2a3",
        jobId: "point-cloud-container-processor-1690395742093",
        elementId: null,
      },
      status: {
        state: "Succeeded",
        id: "2dbd8d45-6r45-4458-8db5-eff053331839",
        type: "State",
        changedAt: "2023-07-26T17:50:13.51Z",
        devMessage: "Point Cloud task Succeeded",
        errorCode: null,
        result: null,
      },
      tags: ["pointcloud_processor", "point_cloud_generation"],
    },
    {
      id: "54af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-07-26T17:54:52.123Z",
      taskType: "PointCloudExport",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "point-cloud-export-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Succeeded",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-26T17:55:32.075Z",
        devMessage: "Point Cloud task Succeeded",
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
      },
      tags: null,
    },
    {
      id: "99af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-07-28T17:54:52.123Z",
      taskType: "PointCloudExport",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "point-cloud-export-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        progress: {
          current: 7,
          total: 25,
        },
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "Progress",
        changedAt: "2023-07-26T17:55:32.075Z",
        devMessage: "Task running",
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
      },
      tags: null,
    },
    {
      id: "84af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-06-26T17:54:52.123Z",
      taskType: "VideoMode",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "video-mode-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Failed",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-25T17:55:32.075Z",
        devMessage: "Unknown error",
        errorCode: null,
        result: null,
      },
      tags: ["video-processing"],
    },
    {
      id: "84af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-06-26T17:54:52.123Z",
      taskType: "BimModelImport",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "video-mode-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Failed",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-25T17:55:32.075Z",
        devMessage: "BIM model task failed",
        errorCode: null,
        result: null,
      },
      tags: ["video-processing"],
    },
    {
      id: "84af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-06-26T17:54:52.123Z",
      taskType: null,
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "video-mode-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Failed",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-25T17:55:32.075Z",
        devMessage: "Video mode error",
        errorCode: null,
        result: null,
      },
      tags: ["video-processing"],
    },
    {
      id: "24ffb835-f131-4771-b0fd-17c9b15f97a5",
      createdAt: "2024-07-23T11:38:34.551Z",
      taskType: "CloudRegistration",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "multi-cloud-reg-17c9b15f97a5",
        elementId: "dea429f6-764f-4e81-668a-08dcaa383283",
      },
      status: {
        progress: {
          current: 50,
          total: 100,
        },
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "Progress",
        changedAt: "2024-07-23T11:38:34.551Z",
        devMessage: null,
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
      },
      tags: null,
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6390",
      createdAt: "2024-10-29T09:07:51.249Z",
      taskType: "ProcessElsRawScan",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a5",
        elementId: "9c83c896-c960-43d4-a3c7-5f0a61897a8f",
      },
      status: {
        state: "Started",
        id: "f6dc5c44-3e02-4faa-b247-ad8a1231a745",
        type: "State",
        changedAt: "2024-10-29T09:22:45.185Z",
        devMessage: null,
        errorCode: null,
        result: null,
      },
      tags: null,
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6391",
      createdAt: "2024-10-29T10:07:51.249Z",
      taskType: "ProcessElsRawScan",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a6",
        elementId: "9c83c896-c960-43d4-a3c7-5f0a61897a8f",
      },
      status: {
        state: "Failed",
        id: "f6dc5c44-3e02-4faa-b247-ad8a1231a746",
        type: "State",
        changedAt: "2024-10-29T10:22:45.185Z",
        devMessage: null,
        errorCode: null,
        result: null,
      },
      tags: null,
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6392",
      createdAt: "2024-07-23T11:38:34.551Z",
      taskType: "ProcessElsRawScan",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a7",
        elementId: "8df92222-53e5-463d-a236-4d81ff49f2d2",
      },
      status: {
        progress: {
          current: 21,
          total: 50,
        },
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923567",
        type: "Progress",
        changedAt: "2024-07-23T11:40:34.551Z",
        devMessage: null,
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
      },
      tags: null,
    },
    {
      id: "a93d8b2f-7e02-4170-bbe2-3e7bd8fd7a7e",
      createdAt: "2024-10-29T11:07:51.249Z",
      taskType: "ProcessPointCloudFlsRaw",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a5",
        elementId: "70be314d-c1fc-4e46-b639-a6097d5b7e86",
      },
      status: {
        state: "Started",
        id: "f6dc5c44-3e02-4faa-b247-ad8a1231a745",
        type: "State",
        changedAt: "2024-10-29T11:22:45.185Z",
        devMessage: null,
        errorCode: null,
        result: null,
      },
      tags: null,
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6393",
      createdAt: "2024-07-23T11:38:34.552Z",
      taskType: "ProcessElsRawScan",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a8",
        elementId: "16ab52e2-e554-469b-91d7-08dcbaf989b4",
      },
      status: {
        progress: {
          current: 21,
          total: 50,
        },
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923568",
        type: "Progress",
        changedAt: "2024-07-23T11:40:34.552Z",
        devMessage: null,
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/scan.e57",
        },
      },
      tags: null,
    },
  ],
  mockSdbBackgroundTasks: [
    {
      id: "cee26f24-2985-422a-90d0-6b7ft0090c2c",
      createdAt: "2023-07-26T17:49:02.131Z",
      taskType: "PointCloudLazToPotree",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Succeeded",
      progress: "100%",
      updatedAt: "2023-07-26T17:50:13.51Z",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4860-b4d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8f5b-c5c305017ff7",
        correlationId: "01f5042d-0d7c-4be0-f180-08db8e00b2a3",
        jobId: "point-cloud-container-processor-1690395742093",
        elementId: null,
      },
      status: {
        state: "Succeeded",
        id: "2dbd8d45-6r45-4458-8db5-eff053331839",
        type: "State",
        changedAt: "2023-07-26T17:50:13.51Z",
        devMessage: "Point Cloud task Succeeded",
        errorCode: null,
        result: null,
      },
    },
    {
      id: "54af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-07-26T17:54:52.123Z",
      taskType: "PointCloudExport",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Succeeded",
      progress: "100%",
      updatedAt: "2023-07-26T17:55:32.075Z",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "point-cloud-export-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Succeeded",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-26T17:55:32.075Z",
        devMessage: "Point Cloud task Succeeded",
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
      },
    },
    {
      id: "99af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-07-28T17:54:52.123Z",
      taskType: "PointCloudExport",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Started",
      progress: "28%",
      updatedAt: "2023-07-26T17:55:32.075Z",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "point-cloud-export-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        progress: {
          current: 7,
          total: 25,
        },
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "Progress",
        changedAt: "2023-07-26T17:55:32.075Z",
        devMessage: "Task running",
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
      },
    },
    {
      id: "84af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-06-26T17:54:52.123Z",
      taskType: "VideoMode",
      taskCategory: BackgroundTaskCategory.videoMode,
      state: "Failed",
      progress: "",
      updatedAt: "2023-07-25T17:55:32.075Z",
      message: "Unknown error",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "video-mode-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Failed",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-25T17:55:32.075Z",
        devMessage: "Unknown error",
        errorCode: null,
        result: null,
      },
    },
    {
      id: "84af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-06-26T17:54:52.123Z",
      taskType: "BimModelImport",
      taskCategory: BackgroundTaskCategory.cad,
      state: "Failed",
      progress: "",
      updatedAt: "2023-07-25T17:55:32.075Z",
      message: "BIM model task failed",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "video-mode-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Failed",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-25T17:55:32.075Z",
        devMessage: "BIM model task failed",
        errorCode: null,
        result: null,
      },
    },
    {
      id: "84af4bff-88a7-4415-94h2-3d42a06fe2cf",
      createdAt: "2023-06-26T17:54:52.123Z",
      taskType: null,
      taskCategory: null,
      state: "Failed",
      progress: "",
      updatedAt: "2023-07-25T17:55:32.075Z",
      message: "Video mode error",
      context: {
        companyId: null,
        projectId: "b7699795-3fa2-4800-m7d1-d0c656ca167b",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "video-mode-230726-175452105-20230720-5-dbw37b7a",
        elementId: "3d899838-9ldf-4326-60b0-08db8e009b5b",
      },
      status: {
        state: "Failed",
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "State",
        changedAt: "2023-07-25T17:55:32.075Z",
        devMessage: "Video mode error",
        errorCode: null,
        result: null,
      },
    },
    {
      id: "24ffb835-f131-4771-b0fd-17c9b15f97a5",
      createdAt: "2024-07-23T11:38:34.551Z",
      taskType: "CloudRegistration",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Started",
      progress: "50%",
      updatedAt: "2024-07-23T11:38:34.551Z",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "multi-cloud-reg-17c9b15f97a5",
        elementId: "dea429f6-764f-4e81-668a-08dcaa383283",
      },
      status: {
        progress: {
          current: 50,
          total: 100,
        },
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "Progress",
        changedAt: "2024-07-23T11:38:34.551Z",
        devMessage: null,
        errorCode: null,
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
      },
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6390",
      createdAt: "2024-10-29T09:07:51.249Z",
      taskType: "ProcessElsRawScan",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Started",
      message: undefined,
      progress: "Calculating...",
      updatedAt: "2024-10-29T09:22:45.185Z",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a5",
        elementId: "9c83c896-c960-43d4-a3c7-5f0a61897a8f",
      },
      status: {
        state: "Started",
        id: "f6dc5c44-3e02-4faa-b247-ad8a1231a745",
        type: "State",
        changedAt: "2024-10-29T09:22:45.185Z",
        devMessage: null,
        errorCode: null,
        result: null,
      },
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6391",
      createdAt: "2024-10-29T10:07:51.249Z",
      taskType: "ProcessElsRawScan",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Failed",
      progress: "",
      message: "Something went wrong",
      updatedAt: "2024-10-29T10:22:45.185Z",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a6",
        elementId: "9c83c896-c960-43d4-a3c7-5f0a61897a8f",
      },
      status: {
        state: "Failed",
        id: "f6dc5c44-3e02-4faa-b247-ad8a1231a746",
        type: "State",
        changedAt: "2024-10-29T10:22:45.185Z",
        devMessage: null,
        errorCode: null,
        result: null,
      },
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6392",
      createdAt: "2024-07-23T11:38:34.551Z",
      progress: "42%",
      state: "Started",
      taskCategory: BackgroundTaskCategory.pointCloud,
      taskType: "ProcessElsRawScan",
      updatedAt: "2024-07-23T11:40:34.551Z",
      context: {
        companyId: null,
        correlationId: null,
        elementId: "8df92222-53e5-463d-a236-4d81ff49f2d2",
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a7",
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
      },
      status: {
        changedAt: "2024-07-23T11:40:34.551Z",
        devMessage: null,
        errorCode: null,
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923567",
        progress: {
          current: 21,
          total: 50,
        },
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
        type: "Progress",
      },
    },
    {
      id: "a93d8b2f-7e02-4170-bbe2-3e7bd8fd7a7e",
      createdAt: "2024-10-29T11:07:51.249Z",
      taskType: "ProcessPointCloudFlsRaw",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Started",
      message: undefined,
      progress: "Calculating...",
      updatedAt: "2024-10-29T11:22:45.185Z",
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a5",
        elementId: "70be314d-c1fc-4e46-b639-a6097d5b7e86",
      },
      status: {
        state: "Started",
        id: "f6dc5c44-3e02-4faa-b247-ad8a1231a745",
        type: "State",
        changedAt: "2024-10-29T11:22:45.185Z",
        devMessage: null,
        errorCode: null,
        result: null,
      },
    },
    {
      id: "9a3548a7-c708-4e42-84e0-3d9a528d6393",
      createdAt: "2024-07-23T11:38:34.552Z",
      progress: "42%",
      state: "Started",
      taskCategory: BackgroundTaskCategory.pointCloud,
      taskType: "ProcessElsRawScan",
      updatedAt: "2024-07-23T11:40:34.552Z",
      context: {
        companyId: null,
        correlationId: null,
        elementId: "16ab52e2-e554-469b-91d7-08dcbaf989b4",
        jobId: "els-proc-24102909075122602f7-241008-4-ccfd86a8",
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
      },
      status: {
        changedAt: "2024-07-23T11:40:34.552Z",
        devMessage: null,
        errorCode: null,
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923568",
        progress: {
          current: 21,
          total: 50,
        },
        result: {
          downloadUrl:
            "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/scan.e57",
        },
        type: "Progress",
      },
    },
  ],
  mockCompanyGroups: [
    {
      createdAt: 1689174188011,
      id: "484b581a-bf67-4b59-b4f4-f0859a4bed8c",
      name: "Demo",
      numManagers: 9,
      numProjects: 13,
      role: CoreAPITypes.EUserCompanyRole.companyManager,
      sqftAssigned: -1,
      sqftUsed: 1078974,
    },
    {
      createdAt: 1692349829514,
      id: "484b581e-bf47-4b59-b4f4-f0859a4bed8c",
      name: "Group 1",
      numManagers: 3,
      numProjects: 5,
      role: undefined,
      sqftAssigned: -1,
      sqftUsed: 125,
    },
  ],
  mockProjectGroups: [
    {
      id: "e0500b8e-23d3-4340-85b7-3171aa2a6069",
      createdAt: 1683798930833,
      name: "Test2",
      numManagers: 2,
      role: "RESOURCE_OWNER" as CoreAPITypes.EUserProjectRole,
      numProjects: 0,
      sqftAssigned: 0,
      sqftUsed: 0,
    },
  ],
  mockProjectBase: {
    accessLevel:
      "private" as SphereDashboardAPITypes.ICompanyProject["accessLevel"],
    address: "",
    createdAt: 1689176867427,
    id: "d9f704b0-5ba6-4d1f-8ee5-732155d15ee0",
    modifiedAt: 1690983017256,
    name: "Test Project",
    tags: [],
  },
  mockCaptureTree: {
    mockCaptureTreeForMainRevision: MOCK_CAPTURE_TREE_FOR_MAIN_REVISION,
    mockCaptureTreeForRevision: MOCK_CAPTURE_TREE_FOR_REVISION,
    mockCaptureTreeRevisions: MOCK_CAPTURE_TREE_REVISIONS,
  },
  mockCompanyMembers: MOCK_COMPANY_MEMBERS,
  mockProjectMembers: MOCK_PROJECT_MEMBERS,
  mockMarkups: MOCK_MARKUPS,
  mockMarkupAttachments: MOCK_MARKUP_ATTACHMENTS,
  mockIElements: MOCK_IELEMENTS,
  mockUploadedElsScanFiles: MOCK_UPLOADED_ELS_SCAN_FILES,
  mockUploadedElsScanFilesParams: MOCK_UPLOADED_ELS_SCAN_FILES_PARAMS,
  mockFailedUploadedElsScanFiles: MOCK_FAILED_UPLOADED_ELS_SCAN_FILES,
  mockCanceledUploadedElsScanFiles: MOCK_CANCELED_UPLOADED_ELS_SCAN_FILES,
  mockTeams: MOCK_TEAMS,
  mockTeamsWithRole: MOCK_TEAMS_WITH_ROLES,
  mockUploadedData: MOCK_UPLOADED_DATA,
  mockRegisteredData: MOCK_REGISTERED_DATA,
  mockPublishedData: MOCK_PUBLISHED_DATA,
  mockSubscriptionFeatures: MOCK_SUBSCRIPTION_FEATURES,
  mockLegacyProcoreData: MOCK_LEGACY_PROCORE_DATA,
  mockProcoreData: MOCK_PROCORE_DATA,
  mockUnsetProcoreData: MOCK_UNSET_PROCORE_DATA,
  mockLegacyAutodeskData: MOCK_LEGACY_AUTODESK_DATA,
  mockAutodeskData: MOCK_AUTODESK_DATA,
  mockUnsetAutodeskData: MOCK_UNSET_AUTODESK_DATA,
  mockGetAutodeskProjectResponse: MOCK_GET_AUTODESK_PROJECT_RESPONSE,
  mockCreditTransactions: MOCK_CREDIT_TRANSACTIONS,
};

/**
 * Mocks a simple archived company project returned from the backend.
 * !: Do not remove casts like  as SphereDashboardAPITypes.ICompanyProject["archivingState"]
 * We use casts to avoid using the enums to really test how the responses from the backend
 * look alike, only change the values if the API definition changes.
 */
export const archivedCompanyProject: SphereDashboardAPITypes.ICompanyProject = {
  ...MockedData.mockProjectBase,
  group: MockedData.mockProjectGroups[0],
  sqftUsed: 100,
  archivingState:
    "ARCHIVED" as SphereDashboardAPITypes.ICompanyProject["archivingState"],
  clientName: "",
  permissions: [],
  features: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "dashboard-groups": { enabled: true },
  } as SphereDashboardAPITypes.ICompanyProject["features"],
  evaluation: {
    evaluations: [],
    average: 0,
  },
  role: "RESOURCE_OWNER" as SphereDashboardAPITypes.ICompanyProject["role"],
};

/**
 * Mocks a simple archived company project returned from the backend.
 * !: Do not remove casts like  as SphereDashboardAPITypes.ICompanyProject["archivingState"]
 * We use casts to avoid using the enums to really test how the responses from the backend
 * look alike, only change the values if the API definition changes.
 */
export const unarchivedCompanyProject: SphereDashboardAPITypes.ICompanyProject =
  {
    ...archivedCompanyProject,
    archivingState:
      "UNARCHIVED" as SphereDashboardAPITypes.ICompanyProject["archivingState"],
  };

/**
 * Mocks a simple archived and ready to download company project returned from the backend.
 * !: Do not remove casts like  as SphereDashboardAPITypes.ICompanyProject["archivingState"]
 * We use casts to avoid using the enums to really test how the responses from the backend
 * look alike, only change the values if the API definition changes.
 */
export const archivedReadyToDownloadCompanyProject: SphereDashboardAPITypes.ICompanyProject =
  {
    ...archivedCompanyProject,
    archivingState:
      "ARCHIVED_READY_TO_DOWNLOAD" as SphereDashboardAPITypes.ICompanyProject["archivingState"],
  };

/**
 * Mocks a simple archived downloaded company project returned from the backend.
 * !: Do not remove casts like  as SphereDashboardAPITypes.ICompanyProject["archivingState"]
 * We use casts to avoid using the enums to really test how the responses from the backend
 * look alike, only change the values if the API definition changes.
 */
export const archivedDownloadedCompanyProject: SphereDashboardAPITypes.ICompanyProject =
  {
    ...archivedCompanyProject,
    archivingState:
      "ARCHIVED_DOWNLOADED" as SphereDashboardAPITypes.ICompanyProject["archivingState"],
  };

export const MockedSdbProject: SdbProject = {
  accessLevel:
    "private" as SphereDashboardAPITypes.ICompanyProject["accessLevel"],
  address: "",
  archivingState:
    "ARCHIVED" as SphereDashboardAPITypes.ICompanyProject["archivingState"],
  area: {
    amount: 100,
    unit: "sqft" as SdbProject["area"]["unit"],
  },
  clientName: null,
  createdAt: 1689176867427,
  description: null,
  evaluation: {
    evaluations: [],
    average: 0,
  },
  features: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "dashboard-groups": { enabled: true },
  } as SphereDashboardAPITypes.ICompanyProject["features"],
  featuresAvailable: null,
  fileUrl: null,
  group: {
    id: MockedData.mockProjectGroups[0].id,
    name: MockedData.mockProjectGroups[0].name,
  },
  id: "d9f704b0-5ba6-4d1f-8ee5-732155d15ee0",
  lastCapturedAt: null,
  lastDownloadedAt: null,
  managers: {
    projectManager: null,
    companyManager: null,
  },
  members: [],
  modifiedAt: 1690983017256,
  name: "Test Project",
  permissions: [],
  role: "RESOURCE_OWNER" as CoreAPITypes.EUserProjectRole,
  standalone: null,
  tags: [],
  thumbnailUrl: null,
};

export const mockCurrentUser: SphereDashboardAPITypes.ICompanyMemberBase = {
  role: CoreAPITypes.EUserCompanyRole.companyExecutive,
  id: "a11d7dcc-0637-4211-82b2-ab110afb17e0",
  identity: "mockIdentity",
  email: "mock.email@faro.com",
};

export const mockSDBCompany: SdbCompany = {
  apiUrl: "mockApiUrl",
  tags: [],
  id: "mockId",
  url: "mockUrl",
  name: "mockName",
  type: CoreAPITypes.EWorkspaceType.company,
  isSphereLegacyMigrated: true,
  role: {
    workspace: SphereDashboardAPITypes.EWorkspaceRole.administrator,
  },
};

export const mockRouterParams: RouteParams = {
  companyId: "mockCompany",
  projectStatus: ProjectArchivingState.active,
  projectId: "mockId",
  groupId: "mockGroupId",
  memberId: "mockMemberId",
  teamId: "mockTeamId",
  groupTabs: GroupTabs.managers,
  settingsTab: SettingsTabs.general,
  addOnsTab: AddOnsTabs.extensions,
  memberTabs: MemberTabs.overview,
  analyticsTabs: AnalyticsTabs.workspace,
  projectTabs: ProjectTabs.members,
  usersTab: UsersTabs.members,
  teamTabs: TeamTabs.members,
  accountSettingsTab: AccountSettingsTabs.security,
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const inFifteenMinutes = Date.now() + 15 * 60 * 1000;
export const mockUploadTasks: FileUploadTask[] = [
  {
    id: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fa",
    fileName: "test.png",
    createdAt: "2024-02-14T16:28:54.604Z",
    status: BackgroundTaskState.failed,
    progress: 0,
    context: {
      uploadElementType: UploadElementType.annotation,
      projectId: MockedSdbProject.id,
      iElementId: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fa",
    },
    expectedEnd: inFifteenMinutes,
  },
  {
    id: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fc",
    fileName: "file.ext",
    createdAt: "2024-02-14T16:28:54.604Z",
    progress: 25,
    status: BackgroundTaskState.failed,
    context: {
      uploadElementType: UploadElementType.annotation,
      projectId: MockedSdbProject.id,
      iElementId: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fc",
    },
    expectedEnd: inFifteenMinutes,
  },
  {
    id: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fs",
    fileName: "file2.ext",
    createdAt: "2024-02-14T16:28:54.604Z",
    progress: 0,
    status: BackgroundTaskState.failed,
    context: {
      uploadElementType: UploadElementType.annotation,
      projectId: MockedSdbProject.id,
      iElementId: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fs",
    },
    expectedEnd: inFifteenMinutes,
  },
  {
    id: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fb",
    fileName: "test.png",
    createdAt: "2024-02-14T16:28:54.604Z",
    status: BackgroundTaskState.started,
    progress: 0,
    context: {
      uploadElementType: UploadElementType.annotation,
      projectId: MockedSdbProject.id,
      iElementId: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fb",
    },
    expectedEnd: inFifteenMinutes,
  },
  {
    id: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fe",
    fileName: "file.ext",
    createdAt: "2024-02-14T16:28:54.604Z",
    progress: 25,
    status: BackgroundTaskState.started,
    context: {
      uploadElementType: UploadElementType.annotation,
      projectId: MockedSdbProject.id,
      iElementId: "6f3d9f42-491d-467b-af8d-7e56f2b8f6fe",
    },
    expectedEnd: inFifteenMinutes,
  },
  {
    id: "6f3d9f42-491d-467b-af8d-7e56f2b8f6ff",
    fileName: "file2.ext",
    createdAt: "2024-02-14T16:28:54.604Z",
    progress: 25,
    status: BackgroundTaskState.started,
    context: {
      uploadElementType: UploadElementType.annotation,
      projectId: MockedSdbProject.id,
      iElementId: "6f3d9f42-491d-467b-af8d-7e56f2b8f6ff",
    },
    expectedEnd: inFifteenMinutes,
  },
];
