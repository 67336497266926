import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { SdbProject } from "@custom-types/project-types";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";
import { hasUserValidPermissionProjectLevel } from "@utils/permission-control/project-permission-control";
import { useCallback, useMemo } from "react";

interface Props {
  /** The internal name used in the UI to determine certain permission */
  permissionName: RequiredPermissionProjectLevelName;

  /** The project to get the user's permission level. */
  project?: SdbProject | null;
}

export type ReturnProps = {
  [key in RequiredPermissionProjectLevelName]: boolean;
} & {
  /** Wrapper to check if user has a permission to do given action (by permission name) or not */
  hasUserPermissionProjectLevel: ({
    permissionName,
    project,
  }: Props) => boolean;
};

/** Checks whether a user has permission for an action on project level or not */
export function useHasUserValidPermissionProjectLevel({
  project = null,
}: Pick<Props, "project"> = {}): ReturnProps {
  const selectedProjectInStore = useAppSelector(selectedProjectSelector);

  /** If user provided a project, then use that. Otherwise, use the selectedProject */
  const selectedProject = useMemo(
    () => project || selectedProjectInStore,
    [project, selectedProjectInStore]
  );

  const canChangeRole = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName: RequiredPermissionProjectLevelName.canChangeRole,
        selectedProject,
      }),
    [selectedProject]
  );

  const canChangeProjectGroup = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canChangeProjectGroup,
        selectedProject,
      }),
    [selectedProject]
  );

  const canChangeProjectAccessLevel = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canChangeProjectAccessLevel,
        selectedProject,
      }),
    [selectedProject]
  );

  const canEditProjectDetails = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canEditProjectDetails,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewProjectCloudActivity = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canViewProjectCloudActivity,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewAllProjectMembers = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canViewAllProjectMembers,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewDataManagement = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canViewDataManagement,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewProjectSettings = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canViewProjectSettings,
        selectedProject,
      }),
    [selectedProject]
  );

  const canInviteUsersToPrivateProject = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canInviteUsersToPrivateProject,
        selectedProject,
      }),
    [selectedProject]
  );

  const canEditProjectSettings = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canEditProjectSettings,
        selectedProject,
      }),
    [selectedProject]
  );

  const canDeleteMemberFromProject = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canDeleteMemberFromProject,
        selectedProject,
      }),
    [selectedProject]
  );

  const canDeleteProject = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName: RequiredPermissionProjectLevelName.canDeleteProject,
        selectedProject,
      }),
    [selectedProject]
  );

  const canDeleteSnapshots = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName: RequiredPermissionProjectLevelName.canDeleteSnapshots,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewProjectMarkups = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canViewProjectMarkups,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewProjectMarkupsAllowViewers = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canViewProjectMarkupsAllowViewers,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewProjectsStats = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName: RequiredPermissionProjectLevelName.canViewProjectsStats,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewProjectData = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName: RequiredPermissionProjectLevelName.canViewProjectData,
        selectedProject,
      }),
    [selectedProject]
  );

  const canEditProjectMarkups = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canEditProjectMarkups,
        selectedProject,
      }),
    [selectedProject]
  );

  const canViewProjectIntegrations = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName:
          RequiredPermissionProjectLevelName.canViewProjectIntegrations,
        selectedProject,
      }),
    [selectedProject]
  );

  const hasUserPermissionProjectLevel = useCallback(
    ({ permissionName, project = null }: Props) =>
      hasUserValidPermissionProjectLevel({
        permissionName,
        selectedProject: project,
      }),
    []
  );

  return {
    canChangeRole,
    canChangeProjectGroup,
    canChangeProjectAccessLevel,
    canEditProjectDetails,
    canViewProjectCloudActivity,
    canViewAllProjectMembers,
    canViewDataManagement,
    canViewProjectSettings,
    canInviteUsersToPrivateProject,
    canEditProjectSettings,
    canDeleteMemberFromProject,
    canDeleteProject,
    canDeleteSnapshots,
    canViewProjectMarkups,
    canViewProjectMarkupsAllowViewers,
    canViewProjectsStats,
    canViewProjectData,
    canEditProjectMarkups,
    canViewProjectIntegrations,
    hasUserPermissionProjectLevel,
  };
}
