import { FaroTableFooterContainer } from "@components/common/faro-table/faro-table-footer-container";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { Box } from "@mui/material";
import { DASH } from "@utils/ui-utils";
import ArrowLeftIcon from "@assets/icons/new/arrow-left_18px.svg?react";
import ArrowRightIcon from "@assets/icons/new/arrow-right_18px.svg?react";

declare module "@mui/x-data-grid-pro" {
  // We need to extend the original MUI DataGridPro footer interface to allow custom props
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface FooterPropsOverrides extends ProjectsTableFooterProps {}
}

interface ProjectsTableFooterProps {
  /** Index of the first item in the page */
  from: number;
  /** Index of the last item in the page */
  to: number;
  /** Total row count. If it's still unknown it defaults to -1 */
  rowCount: number;
  /** Current page */
  page: number;
  /** The last page. If it's still unknown it defaults to -1 */
  lastPage: number;
  /** Whether the projects table is in loading state */
  isLoading: boolean;
  /** Callback to handle a change of page */
  handlePageChange: (newPage: number) => void;
}

/**
 * @returns the custom footer component of the projects table.
 */
export function ProjectsTableFooter({
  from,
  to,
  rowCount,
  page,
  lastPage,
  isLoading,
  handlePageChange,
}: ProjectsTableFooterProps): JSX.Element {
  return (
    <FaroTableFooterContainer>
      <Box component="strong">{`${from} ${DASH} ${to} `}</Box>
      <Box component="span" sx={{ marginX: "4px" }}>
        of {rowCount !== -1 ? rowCount : `more than ${to}`}
      </Box>
      <FaroIconButton
        component={ArrowLeftIcon}
        isDisabled={page === 0 || isLoading}
        onClick={() => handlePageChange(page - 1)}
        buttonSize="28px"
        iconSize="20px"
      />
      <Box component="span" sx={{ marginRight: "4px" }} />
      <FaroIconButton
        component={ArrowRightIcon}
        isDisabled={page === lastPage || isLoading}
        onClick={() => handlePageChange(page + 1)}
        buttonSize="28px"
        iconSize="20px"
      />
    </FaroTableFooterContainer>
  );
}
