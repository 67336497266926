import { SphereTooltip } from "@components/common/sphere-tooltip";
import {
  generateProjectDetailsRoute,
  useAppParams,
} from "@router/router-helper";
import { APITypes } from "@stellar/api-logic";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { withEllipsis } from "@styles/common-styles";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { Link } from "react-router-dom";
import styles from "@components/table/projects/projects-table.module.css";
import { DASH } from "@utils/ui-utils";
import { fetchingTransactionsFlagsSelector } from "@store/credit-transactions/credit-transactions-selector";
import { Skeleton } from "@mui/material";
import { FetchingStatus } from "@store/store-types";

interface Props {
  /** The of the project */
  projectId?: APITypes.ProjectId;
}

/** Renders the user column fields in transactions table */
export function TransactionProject({ projectId }: Props): JSX.Element {
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();
  const project = useAppSelector(getProjectByIdSelector(projectId));
  const { status } = useAppSelector(fetchingTransactionsFlagsSelector);

  // Show dash "-" if project id is not provided
  if (!projectId) {
    return <var>{DASH}</var>;
  }

  // Show skeleton loader if project not available in store and not fetched yet
  if (!project && status === FetchingStatus.succeeded) {
    return (
      <Skeleton
        sx={{
          width: "100%",
          height: "auto",
        }}
      />
    );
  }

  // Show project id with tooltip if project not available
  if (!project) {
    return (
      <SphereTooltip
        title="Project could not be found in the workspace."
        boxProps={{
          sx: {
            ...withEllipsis,
            fontWeight: "bold",
          },
        }}
      >
        <var>{projectId}</var>
      </SphereTooltip>
    );
  }

  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    trackEvent({
      name: ProjectEvents.openProjectDetails,
      props: {
        eventLocation: "transactions-table",
      },
    });

    // to + event replicate onRowClick as in transaction table
    e.stopPropagation();
  }

  return (
    <Link
      to={generateProjectDetailsRoute(companyId ?? "", projectId)}
      onClick={handleClick}
      className={styles["removed-underline"]}
    >
      <SphereTooltip
        title={<var>{project.name}</var>}
        boxProps={{
          sx: {
            ...withEllipsis,
            fontWeight: "bold",
          },
        }}
      >
        <var>{project.name}</var>
      </SphereTooltip>
    </Link>
  );
}
