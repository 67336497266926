import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/** A single description for roles on the project level. */
interface RequiredFeatureProjectLevel {
  /** List of subscription roles to check in the available features of a project */
  projectSubscriptionRoles: APITypes.EUserSubscriptionRole[];
}

export interface HasFeatureProjectLevelProps {
  /** The available features for the project */
  featuresAvailable: SphereDashboardAPITypes.IFeature[] | null;

  /** The required subscription roles for the project */
  requiredProjectFeatures: APITypes.EUserSubscriptionRole[];
}

/**
 * Object that determines all different features for users to get
 * access on the project level.
 */
export type RequiredFeaturesProjectLevel<RoleNameT extends string> = {
  [key in RoleNameT]: RequiredFeatureProjectLevel;
};

/**
 * Signature for the function hasUserValidFeatureProjectLevel.
 */
export interface HasUserValidFeatureProjectLevelProps<
  RoleNameT extends string
> {
  /**
   * The internal name used in the UI to determine certain feature permissions,
   * e.g. "canArchive" is used to determine whether the user can archive a project.
   */
  roleName: RoleNameT;

  /**
   * The available features for the project.
   */
  featuresAvailable?: SphereDashboardAPITypes.IFeature[];

  /**
   * The default required features for the project level.
   * Only used for testing purposes.
   */
  defaultRequiredFeaturesProjectLevel?: RequiredFeaturesProjectLevel<RoleNameT>;
}

/** Names for all features within the project level. */
export enum RequiredFeatureProjectLevelName {
  /** Whether the user can archive projects. */
  canArchiveProject = "canArchiveProject",

  /** Whether the user can unarchive projects. */
  canUnarchiveProject = "canUnarchiveProject",

  /** Whether the user can view 2d image feature. */
  canView2dImagesFeature = "canView2dImagesFeature",

  /** Whether the user can view video mode feature. */
  canViewVideoModeFeature = "canViewVideoModeFeature",

  /** Whether the user can view face blurring feature. */
  canViewFaceBlurringFeature = "canViewFaceBlurringFeature",

  /** Whether the user can view face blurring feature. */
  canViewFaceBlurringV1Feature = "canViewFaceBlurringV1Feature",

  /** Whether the user can view snapshot feature. */
  canViewSnapshot = "canViewSnapshot",

  /** Whether the user can create snapshot. */
  canCreateSnapshot = "canCreateSnapshot",

  /** Whether the user can download projects. */
  canDownloadProject = "canDownloadProject",

  /** Whether the user can manage project integrations. */
  canManageProjectIntegrations = "canManageProjectIntegrations",
    
  /** Whether the user can view photogrammetry feature. */
  canViewPhotogrammetryFeature = "canViewPhotogrammetryFeature",
}
