/* eslint-disable @typescript-eslint/naming-convention */
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/** Mocked company features associated to Sphere XG subscriptions */
export const MOCK_SUBSCRIPTION_FEATURES: Record<
  string,
  SphereDashboardAPITypes.IFeature
> = {
  sphereXGStandard: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGStandard,
    enabled: true,
    permitted: true,
    expiresAt: "2025-10-07T23:59:59.999Z",
  },
  sphereXGPro: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGPro,
    enabled: true,
    permitted: true,
    expiresAt: "2026-10-07T23:59:59.999Z",
  },
  sphereXGEnterprise: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGEnterprise,
    enabled: true,
    permitted: true,
    expiresAt: "2027-10-07T23:59:59.999Z",
  },
  sphereXGAddon360: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGAddon360,
    enabled: true,
    permitted: true,
  },
  sphereXGAddonPointCloud: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGAddonPointCloud,
    enabled: true,
    permitted: true,
  },
  userRoleFmTrial: {
    identifier: APITypes.EUserSubscriptionRole.user_role_fm_trial,
    enabled: true,
    permitted: true,
  },
  sphereXGStandardDisabled: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGStandard,
    enabled: false,
    permitted: true,
    expiresAt: "2025-10-07T23:59:59.999Z",
  },
  sphereXGProDisabled: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGPro,
    enabled: false,
    permitted: true,
    expiresAt: "2026-10-07T23:59:59.999Z",
  },
  sphereXGEnterpriseDisabled: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGEnterprise,
    enabled: false,
    permitted: true,
    expiresAt: "2027-10-07T23:59:59.999Z",
  },
  sphereXGAddon360Disabled: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGAddon360,
    enabled: false,
    permitted: true,
  },
  sphereXGAddonPointCloudDisabled: {
    identifier: APITypes.EUserSubscriptionRole.sphereXGAddonPointCloud,
    enabled: false,
    permitted: true,
  },
  userRoleFmTrialDisabled: {
    identifier: APITypes.EUserSubscriptionRole.user_role_fm_trial,
    enabled: false,
    permitted: true,
  },
};
