import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FetchingStatus } from "@store/store-types";
import {
  addMembersToTeam,
  createTeam,
  deleteTeam,
  fetchTeamAsMember,
  fetchTeamDetails,
  fetchTeamMembers,
  fetchTeams,
  removeMembersFromTeam,
  updateTeamDetails,
} from "@store/teams/teams-slice-thunk";
import { TeamState } from "@store/teams/teams-slice-types";
import { removeLocalMemberFromTeam } from "@store/teams/teams-slice-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getMemberId } from "@utils/member-utils";

export const teamAdapter = createEntityAdapter<
  SphereDashboardAPITypes.ITeam,
  EntityId
>({
  selectId: (team) => team.id,
});

export const initialState: TeamState = {
  ...teamAdapter.getInitialState(),
  selectedTeam: { id: null, groups: null, teamAsMember: null, members: {} },
  fetching: {
    status: FetchingStatus.uninitialized,
    isRemovingTeam: false,
    isRemovingTeamMember: false,
    isFetchingTeamMembers: FetchingStatus.uninitialized,
    hasFetchedTeams: false,
  },
  nextTeamMember: null,
  isDetailOpened: false,
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    resetTeamState: () => initialState,
    openDetailPanel: (state) => {
      state.isDetailOpened = true;
    },
    closeDetailPanel: (state) => {
      state.isDetailOpened = false;
    },
    removeMembersByIds: (state, action: PayloadAction<EntityId[]>) => {
      const memberIdsToRemove = action.payload;

      memberIdsToRemove.forEach((memberId) => {
        delete state.selectedTeam.members[memberId];
      });
    },
    removeMembersByEmails: (state, action: PayloadAction<string[]>) => {
      const emailsToRemove = new Set(action.payload);

      Object.values(state.entities).forEach((team) => {
        team.sampleMembers = team.sampleMembers.filter(
          (member) => !emailsToRemove.has(member.userResponse.email)
        );
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.fetching.status = FetchingStatus.pending;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.fetching.status = FetchingStatus.succeeded;
        teamAdapter.upsertMany(state, action.payload.data);
        state.nextTeamMember = action.payload.next;
        // Set the flag to know if the teams have been fetched for the first time.
        if (!state.fetching.hasFetchedTeams) {
          state.fetching.hasFetchedTeams = true;
        }
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.fetching.status = FetchingStatus.rejected;
      })

      .addCase(createTeam.fulfilled, (state, action) => {
        teamAdapter.setOne(state, action.payload);
      })

      .addCase(addMembersToTeam.pending, (state) => {
        state.fetching.status = FetchingStatus.pending;
      })
      .addCase(addMembersToTeam.fulfilled, (state, action) => {
        state.fetching.status = FetchingStatus.succeeded;

        const selectedId = state.selectedTeam.id;
        const teamId = action.payload.teamId;
        const team = state.entities[teamId];
        team.memberCount += action.payload.data.length;

        for (const member of action.payload.data) {
          if (selectedId) {
            const id = getMemberId(member.userResponse);
            state.selectedTeam.members[id] = member;
          } else {
            team.sampleMembers.push(member);
          }
        }
      })
      .addCase(addMembersToTeam.rejected, (state, action) => {
        state.fetching.status = FetchingStatus.rejected;
      })

      .addCase(fetchTeamDetails.pending, (state) => {
        state.fetching.status = FetchingStatus.pending;
      })
      .addCase(fetchTeamDetails.fulfilled, (state, action) => {
        state.selectedTeam.id = action.payload.id;
        state.fetching.status = FetchingStatus.succeeded;
        teamAdapter.setOne(state, action.payload);
      })
      .addCase(fetchTeamDetails.rejected, (state) => {
        state.fetching.status = FetchingStatus.rejected;
      })

      .addCase(fetchTeamAsMember.pending, (state) => {
        state.fetching.status = FetchingStatus.pending;
      })
      .addCase(fetchTeamAsMember.fulfilled, (state, action) => {
        state.selectedTeam.teamAsMember = action.payload;
        state.fetching.status = FetchingStatus.succeeded;
      })
      .addCase(fetchTeamAsMember.rejected, (state) => {
        state.fetching.status = FetchingStatus.rejected;
      })

      .addCase(updateTeamDetails.pending, (state) => {
        state.fetching.status = FetchingStatus.pending;
      })
      .addCase(updateTeamDetails.fulfilled, (state, action) => {
        state.selectedTeam.id = action.payload.id;
        state.fetching.status = FetchingStatus.succeeded;
        teamAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateTeamDetails.rejected, (state) => {
        state.fetching.status = FetchingStatus.rejected;
      })

      .addCase(removeMembersFromTeam.pending, (state) => {
        state.fetching.isRemovingTeamMember = true;
      })
      .addCase(removeMembersFromTeam.fulfilled, (state, action) => {
        removeLocalMemberFromTeam(state, action.payload);
        state.fetching.isRemovingTeamMember = false;
      })
      .addCase(removeMembersFromTeam.rejected, (state) => {
        state.fetching.isRemovingTeamMember = false;
      })

      .addCase(deleteTeam.pending, (state) => {
        state.fetching.isRemovingTeam = true;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        teamAdapter.removeOne(state, action.payload);
        state.fetching.isRemovingTeam = false;
      })
      .addCase(deleteTeam.rejected, (state) => {
        state.fetching.isRemovingTeam = false;
      })

      .addCase(fetchTeamMembers.pending, (state) => {
        state.fetching.isFetchingTeamMembers = FetchingStatus.pending;
      })
      .addCase(fetchTeamMembers.fulfilled, (state, action) => {
        state.nextTeamMember = action.payload.next ?? null;
        for (const member of action.payload.members) {
          const id = getMemberId(member.userResponse);
          state.selectedTeam.members[id] = member;
        }
        state.fetching.isFetchingTeamMembers = FetchingStatus.succeeded;
      })
      .addCase(fetchTeamMembers.rejected, (state) => {
        state.fetching.isFetchingTeamMembers = FetchingStatus.rejected;
      });
  },
});

export const {
  resetTeamState,
  openDetailPanel,
  closeDetailPanel,
  removeMembersByIds,
  removeMembersByEmails,
} = teamSlice.actions;

export const teamReducer = teamSlice.reducer;
