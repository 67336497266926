import { UploadFailedFile } from "@custom-types/file-upload-types";
import { Box } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";

interface Props {
  /** List of failed uploaded files */
  failedUploads: UploadFailedFile[];
}

/** Content of the warning toast shown when some files failed to upload */
export function FailedUploadsToastContent({ failedUploads }: Props): JSX.Element | undefined {
  if (!failedUploads.length) {
    return;
  }
  // For simplicity, we show only the first error message.
  const errorDetails = failedUploads[0].errorMessage;

  return (
    <Box
      data-testid="failed-uploads-toast-content"
      sx={{
        mt: "12px",
        fontSize: "12px",
      }}
    >
      <Box>These files failed to upload:</Box>
      <Box
        sx={{
          maxHeight: "100px",
          overflowY: "auto",
          scrollbarWidth: "thin",
          mb: "12px",
        }}
      >
        {failedUploads.map((failedUpload, index) => (
          <Box
            key={index}
            component={"var"}
            sx={{
              display: "block",
              ...withEllipsis,
            }}
          >
            {"• "}{failedUpload.fileName}
          </Box>
        ))}
      </Box>
      <Box>
        {/* Keep message in sync with useHandleAllUploadsFailed. */}
        You can try to upload the Blink folder again. Only the failed files will be uploaded again in this case.
      </Box>
      {!!errorDetails && (
        <Box sx={{ mt: "16px" }}>{errorDetails}</Box>
      )}
    </Box>
  );
}
