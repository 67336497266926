import { useGetBulkDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-helper";
import { BulkDialogs } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { CoreAPITypes } from "@stellar/api-logic";
import {
  bulkActionResultsSelector,
  numberOfUpdatingItemsSelector,
} from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { formatUserRoleType, nounPluralize } from "@utils/data-display";

/** List of entity type which can use change role feature */
export enum ChangeRoleEntityType {
  member = "member",
  team = "team",
}
interface GenericProps<T extends MemberTableSubject, K> {
  /** The type of subject that members are changing role from */
  subjectType: T;

  /** The new assigned role */
  newRole: K;

  /** The type of the entity to change role. i.e: member, team */
  entityType?: ChangeRoleEntityType;
}

/** Changing role in company */
type CompanyChangeRole = GenericProps<
  "workspace",
  CoreAPITypes.EUserCompanyRole
>;

/** Changing role in project */
type ProjectChangeRole = GenericProps<"project", CoreAPITypes.EUserProjectRole>;

/** Changing role in group */
type GroupChangeRole = GenericProps<"group", CoreAPITypes.EUserCompanyRole>;

type Props = CompanyChangeRole | ProjectChangeRole | GroupChangeRole;

/** Determines the content of bulk dialog and bulk success dialog for changing role */
export function useChangeRoleBulkDialogContent({
  subjectType,
  newRole,
  entityType = ChangeRoleEntityType.member,
}: Props): BulkDialogs {
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { numberOfSuccess, numberOfErrors } = useAppSelector(
    bulkActionResultsSelector
  );

  const bulkDialogContent = useGetBulkDialogContent({
    initial: {
      title: `Change role for ${nounPluralize({
        counter: numberOfUpdatingItems,
        word: "this",
        shouldShowCounter: false,
      })} 
      ${nounPluralize({
        counter: numberOfUpdatingItems,
        word: entityType,
        shouldShowCounter: false,
      })}?`,
      description: `When you confirm this action, the following ${nounPluralize(
        {
          counter: numberOfUpdatingItems,
          word: entityType,
          shouldShowCounter: false,
        }
      )} 
      will change their current roles to the ${formatUserRoleType(
        newRole
      )} role.`,
    },

    failed: {
      title: `Couldn't Change the role for ${numberOfErrors} of ${numberOfUpdatingItems} ${nounPluralize(
        {
          counter: numberOfUpdatingItems,
          word: entityType,
        }
      )}`,
      description: `The role of 
      ${nounPluralize({
        counter: numberOfUpdatingItems,
        word: "this",
      })} 
      ${nounPluralize({
        counter: numberOfUpdatingItems,
        word: entityType,
      })} couldn't be changed to ${formatUserRoleType(
        newRole
      )} in this ${subjectType}, check the details and try again.`,
    },
    confirmButtonText: "change",
    entityType,
  });

  const successDialogContent = {
    title: `${nounPluralize({
      counter: numberOfSuccess,
      word: "role",
    })} changed`,
    description: `All selected ${nounPluralize({
      counter: numberOfUpdatingItems,
      word: entityType,
      shouldShowCounter: false,
    })} have changed their roles to ${formatUserRoleType(newRole)}.`,
  };

  return { bulkDialogContent, successDialogContent };
}
