import {
  TeamBulkButtonComponents,
  TeamTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { useMemo } from "react";
import { ExportTeamsAsCSV } from "@components/table/teams/bulk-actions/export-teams-as-csv";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { BulkDeleteTeams } from "@components/table/teams/bulk-actions/bulk-delete-teams";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { SdbTeam } from "@custom-types/teams-types";
import { BulkChangeCompanyRole } from "@components/table/members/bulk-actions/bulk-change-company-role";

interface Props extends BaseCompanyIdProps {
  /** List of teams to process in bulk actions */
  teams: SdbTeam[];
}

/** List all the bulk action components for teams table */
export function TeamsPageBulkActions({
  companyId,
  teams,
}: Props): TeamBulkButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("teams"));

  const isAnyRowSelected = selectedEntities.length !== 0;
  const { canDeleteTeams, canEditMember } = useHasUserValidRoleCompanyLevel();

  const isAnyIsManaged = selectedEntities.some((team) => team.isManaged);

  const allowedButtonsBasedOnRowSelection: TeamTableBulkName[] = useMemo(() => {
    const allowedButtons: TeamTableBulkName[] = ["exportCsv"];

    if (isAnyRowSelected) {
      if (canEditMember) {
        allowedButtons.push("changeRole");
      }

      if (canDeleteTeams && !isAnyIsManaged) {
        allowedButtons.push("removeTeam");
      }
    }

    return allowedButtons;
  }, [canDeleteTeams, canEditMember, isAnyIsManaged, isAnyRowSelected]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  return {
    changeRole: shouldShowBulkButtons("changeRole") ? (
      <BulkChangeCompanyRole companyId={companyId} entityType="teams" />
    ) : null,

    removeTeam: shouldShowBulkButtons("removeTeam") ? (
      <BulkDeleteTeams companyId={companyId} />
    ) : null,

    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <ExportTeamsAsCSV
        selectedTeams={isAnyRowSelected ? selectedEntities : teams}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
