import {
  ConsumptionReason,
  CreditTransaction,
  TransactionActions,
  TransactionSources,
} from "@custom-types/credit-types";

export const MOCK_CREDIT_TRANSACTIONS: CreditTransaction[] = [
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c38",
    timestamp: "2024-12-17T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 200,
        consumptionReason: ConsumptionReason.floorplanGeneration,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "f70f8b2d-af50-4d02-b6c8-9f4e366a6031",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "0402c110-d27f-4a61-b42c-e53b7d941c38",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c39",
    timestamp: "2024-12-17T12:30:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Purchase credits",
      category: TransactionActions.creditPurchase,
      data: {
        creditAmount: 500,
        consumptionReason: ConsumptionReason.floorplanGeneration,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      workflowId: "",
    },
    result: {
      type: "CreditPurchased",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c40",
    timestamp: "2024-12-12T12:35:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 600,
        consumptionReason: ConsumptionReason.floorplanGeneration,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "2b6a75f0-ba94-4527-83a2-55392060495f",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "61ad191f-4492-4390-8f66-19f2c2fd44a6",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941d45",
    timestamp: "2024-12-05T01:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Purchase credits",
      category: TransactionActions.creditPurchase,
      data: {
        creditAmount: 500,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "903f6b38-3473-439c-94eb-dbf32281f75f",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c43",
    timestamp: "2024-11-25T12:30:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 150,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "1252c15f-921e-415a-8c95-59e626a54cab",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "1cacfe41-aa30-4835-aa52-01600c0b2b83",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c44",
    timestamp: "2024-12-08T12:45:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 300,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "77082f04-a876-472b-8130-6f84d3c30a26",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "0402c110-d27f-4a61-b42c-e53b7d941c38",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c46",
    timestamp: "2024-12-08T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 200,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "8468f903-af14-4fba-ba5c-0dd96861f8e3",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "18c4ff50-c520-4df4-8c01-786ab54d9f6f",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c41",
    timestamp: "2024-12-17T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 200,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "8468f903-af14-4fba-ba5c-0dd96861f8e3",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "18c4ff50-c520-4df4-8c01-786ab54d9f6e",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c77",
    timestamp: "2024-12-17T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Purchase credits",
      category: TransactionActions.creditPurchase,
      data: {
        creditAmount: 1000,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "9760b108-456b-4c60-97ab-dc595da7c748",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      workflowId: "",
    },
    result: {
      type: "CreditPurchased",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d741c43",
    timestamp: "2024-12-03T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 250,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "f70f8b2d-af50-4d02-b6c8-9f4e366a6031",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "61ad191f-4492-4390-8f66-19f2c2fd44a6",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c48",
    timestamp: "2024-12-01T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Purchase credits",
      category: TransactionActions.creditPurchase,
      data: {
        creditAmount: 500,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      workflowId: "",
    },
    result: {
      type: "CreditPurchased",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c49",
    timestamp: "2024-12-25T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 300,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "2b6a75f0-ba94-4527-83a2-55392060495f",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "7d2001d5-9087-4a0e-8b83-73b20569b33f",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c50",
    timestamp: "2024-11-28T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Purchase credits",
      category: TransactionActions.creditPurchase,
      data: {
        creditAmount: 500,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "9760b108-456b-4c60-97ab-dc595da7c748",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      workflowId: "",
    },
    result: {
      type: "CreditPurchased",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c51",
    timestamp: "2024-12-21T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 250,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "1252c15f-921e-415a-8c95-59e626a54cab",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "0402c110-d27f-4a61-b42c-e53b7d941c38",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c52",
    timestamp: "2024-12-09T02:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 150,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "77082f04-a876-472b-8130-6f84d3c30a26",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "61ad191f-4492-4390-8f66-19f2c2fd44a6",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b85s-e53b7d941c51",
    timestamp: "2024-12-17T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Purchase credits",
      category: TransactionActions.creditPurchase,
      data: {
        creditAmount: 500,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "9760b108-456b-4c60-97ab-dc595da7c748",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      workflowId: "",
    },
    result: {
      type: "CreditPurchased",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c53",
    timestamp: "2024-12-17T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Floor plan Generation",
      category: TransactionActions.creditConsumption,
      data: {
        creditAmount: 350,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "8468f903-af14-4fba-ba5c-0dd96861f8e3",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      projectId: "7d2001d5-9087-4a0e-8b83-73b20569b33f",
      workflowId: "",
    },
    result: {
      type: "CreditConsumed",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
  {
    id: "0402c110-d27f-6w66-b42c-e53b7d941c54",
    timestamp: "2024-12-17T12:00:00Z",
    source: {
      type: TransactionSources.webEditor,
      version: "1.4.3",
    },
    action: {
      type: "Purchase credits",
      category: TransactionActions.creditPurchase,
      data: {
        creditAmount: 500,
      },
      tags: [],
    },
    visibility: "External",
    context: {
      userId: "9760b108-456b-4c60-97ab-dc595da7c748",
      userEmail: "",
      companyId: "5abf777e-2694-41d2-a715-77ff1561f8b6",
      workflowId: "",
    },
    result: {
      type: "CreditPurchased",
      data: {
        subscriptionIds: ["1d235127-c5d2-4d9c-8753-639b71bd756c"],
      },
    },
  },
];
