import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { formatUserRoleType } from "@utils/data-display";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { FaroTextMenuButton } from "@components/common/faro-text-menu-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  setBulkActionName,
  updateMany,
  updateOne,
} from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import {
  ChangeRoleEntityType,
  useChangeRoleBulkDialogContent,
} from "@components/table/members/bulk-actions/bulk-change-role-helper";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import {
  AssignableUpdateCompanyMemberRole,
  MemberTypes,
} from "@custom-types/member-types";
import { currentUserSelector } from "@store/user/user-selector";
import { updateMemberRoleInWorkspace } from "@store/members/members-slice-thunk";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";
import { EntityId, Update } from "@reduxjs/toolkit";
import { FetchingItem } from "@store/table/table-slice-helper";

interface Props extends BaseCompanyIdProps {
  /**
   * Specifies the type of the entity: either an individual user or a team.
   */
  entityType: "members" | "teams";
}

/** Button allowing to change role of members or teams in company */
export function BulkChangeCompanyRole({
  companyId,
  entityType,
}: Props): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [newRole, setNewRole] =
    useState<SphereDashboardAPITypes.UpdateCompanyMemberRole>(
      CoreAPITypes.EUserCompanyRole.member
    );

  const currentUser = useAppSelector(currentUserSelector);
  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector(entityType));

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const { bulkDialogContent, successDialogContent } =
    useChangeRoleBulkDialogContent({
      subjectType: "workspace",
      newRole,
      entityType:
        entityType === "members"
          ? ChangeRoleEntityType.member
          : ChangeRoleEntityType.team,
    });

  // Allowing no workspace role for teams
  const assignableRoles =
    entityType === "teams"
      ? Object.values(AssignableUpdateCompanyMemberRole)
      : Object.values(AssignableUpdateCompanyMemberRole).filter(
          (role) => role !== CoreAPITypes.EUserCompanyRole.member
        );

  // Closes the bulk dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onNewRoleClicked(event: React.MouseEvent<HTMLLIElement>): void {
    dispatch(setBulkActionName("changeRole"));

    const notAllowedChanges: Update<FetchingItem, EntityId>[] = [];
    for (const entity of selectedEntities) {
      // Marking entities as not allowed if project and group managers are selected for changing role to member
      if (
        event.currentTarget.dataset.myValue ===
          CoreAPITypes.EUserCompanyRole.member &&
        (entity.role === CoreAPITypes.EUserCompanyRole.companyManager ||
          entity.role === CoreAPITypes.EUserCompanyRole.projectManager)
      ) {
        notAllowedChanges.push({
          id: entity.identity,
          changes: {
            status: "not-allowed",
            message: `You cannot change from ${formatUserRoleType(
              entity.role
            )} role to ${formatUserRoleType(
              CoreAPITypes.EUserCompanyRole.member
            )}.`,
          },
        });
      }
    }

    if (notAllowedChanges.length) {
      dispatch(updateMany(notAllowedChanges));
    }

    if (currentUser) {
      dispatch(
        updateOne({
          id: currentUser.identity,
          changes: {
            status: "not-allowed",
            message: "You cannot change the role of yourself.",
          },
        })
      );
    }

    // TODO: Check whether it is possible to remove type-casting: https://faro01.atlassian.net/browse/ST-1348
    setNewRole(
      event.currentTarget.dataset
        .myValue as SphereDashboardAPITypes.UpdateCompanyMemberRole
    );
    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextMenuButton
        buttonTitle="Change role"
        tooltipText="Change role in workspace"
        buttonSx={() => {
          return { fontWeight: 600 };
        }}
      >
        {assignableRoles.map((role) => (
          <FaroMenuItem
            data-my-value={role}
            key={role}
            value={role}
            onClick={onNewRoleClicked}
          >
            {formatUserRoleType(role)}
          </FaroMenuItem>
        ))}
      </FaroTextMenuButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "changeRole" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "changeRole" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkChangeRole}
          bulkActionCallback={(member: MemberTypes) =>
            dispatch(
              updateMemberRoleInWorkspace({
                coreApiClient,
                companyId,
                role: newRole,
                identity: member.identity,
              })
            ).unwrap()
          }
          selectedEntities={selectedEntities}
          uniqueIdKey="identity"
          tableDataType={entityType}
        />
      )}
    </>
  );
}
