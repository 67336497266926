import { useMediaQueryList } from "@hooks/use-media-query";
import { Box, Grid, Skeleton } from "@mui/material";
import {
  CommonStyles,
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE,
} from "@styles/common-styles";
import { CSSProperties } from "react";

/**
 * Defines a single section item that has some content and a label,
 * i.e. label = "First Name" and content = <TextField>
 */
export interface PageSectionItemData {
  /** The label for the item, i.e. "First Name" */
  label?: string;

  /** Optional flag to indicate if the label text should be bold */
  shouldLabelBold?: boolean;

  /**
   * Optional flag to indicate if this section item should be hidden.
   * If true, the section item will be hidden and it won't visible.
   */
  isHidden?: boolean;

  /** The content of the item, i.e. <TextField> */
  content: JSX.Element;

  /** Optional custom skeleton to be shown when the content is loading */
  customSkeleton?: JSX.Element;

  /** Optional flag to add right padding to the content. Used when displaying buttons */
  shouldAddRightPadding?: boolean;

  /**
   * Optional flag to indicate if the bottom border should hide,
   */
  shouldHideBottomBorder?: boolean;

  /**
   * Optional flag to indicate if the top border should hide,
   */
  shouldHideTopBorder?: boolean;

  /**
   * Optional flag to indicate if the item content should take the full width,
   */
  shouldContentTakeFullWidth?: boolean;

  /** Optional flag to indicate if the item content should be aligned to the end of items or not. */
  shouldAlignToEnd?: boolean;
}

interface Props {
  /** The item to be rendered */
  item: PageSectionItemData;

  /**
   * Flag when the content is loading,
   * if true, the content in all items will be replaced with a skeleton.
   */
  isLoading: boolean;

  /**
   * Flag to indicate that the top border should be shown.
   */
  shouldShowTopBorder?: boolean;

  /**
   * Optional margin bottom for the item.
   */
  marginBottom?: CSSProperties["marginBottom"];
}

/**
 * Default skeleton for the input fields in the page section.
 */
const DEFAULT_INPUT_SKELETON: JSX.Element = (
  <Skeleton variant="text" sx={{ width: "100%", height: "30px" }} />
);

/**
 * Defines a single section item that has some content and a label,
 * i.e. label = "First Name" and content = <TextField>
 */
export function PageSectionItem({
  item,
  shouldShowTopBorder,
  isLoading,
  marginBottom = "0px",
}: Props): JSX.Element {
  const { isScreenMdAndSmaller } = useMediaQueryList();

  return (
    // Wrapper for each item in the section
    <Box component="div" marginBottom={marginBottom}>
      {/* Grid to define two columns in each item */}
      <Grid
        container
        sx={{
          width: "100%",
          borderTop:
            shouldShowTopBorder && !item.shouldHideTopBorder
              ? CommonStyles.Borders.gray200Divider
              : "none",
          borderBottom: item.shouldHideBottomBorder
            ? "none"
            : CommonStyles.Borders.gray200Divider,
        }}
      >
        {/* Left column containing the label */}
        {item.label && (
          <Grid
            item
            xs={12}
            md={6}
            sm={4}
            lg={3}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: DEFAULT_INPUT_FONT_SIZE,
              fontWeight: item.shouldLabelBold ? "bold" : "normal",
            }}
          >
            {item.label}
          </Grid>
        )}

        {/* Right column containing the content */}
        <Grid
          item
          xs={12}
          /* eslint-disable @typescript-eslint/no-magic-numbers -- these numbers are requited for grid size */
          md={item.shouldContentTakeFullWidth ? 12 : 6}
          sm={item.shouldContentTakeFullWidth ? 12 : 8}
          lg={item.shouldContentTakeFullWidth ? 12 : 9}
          /* eslint-enable @typescript-eslint/no-magic-numbers */
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: item.shouldAlignToEnd ? "flex-end" : "flex-start",
            fontSize: DEFAULT_TITLE_FONT_SIZE,
            paddingRight:
              item.shouldAddRightPadding && !isScreenMdAndSmaller
                ? "27px"
                : "0px",
            height: "70px",
          }}
        >
          {isLoading ? (
            item.customSkeleton ?? DEFAULT_INPUT_SKELETON
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment -- needed for JSX
            <>{item.content}</>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
