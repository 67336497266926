// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { sphereColors } from "@styles/common-colors";
import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { BannersHeaderLayout } from "@components/page-layout/banners-header-layout";
import { BodyLayout } from "@components/page-layout/body-layout";
import { AccountSettingsBodyLayout } from "@pages/account-settings/account-settings-layout/account-settings-body-layout";
import { AppHeader } from "@components/page-layout/header/app-header";

export interface MainPageLayoutProps extends PropsWithChildren {
  /** Flag whether the Sphere XG logo should be shown on the left side of the screen */
  shouldShowLogo?: boolean;

  /** Flag whether the open button should be hidden regardless of the screen size*/
  shouldHideOpenButton?: boolean;

  /** Flag whether the user menu should be shown. Default true */
  shouldShowUserMenu?: boolean;

  /** Flag whether the sidebar should be shown. Default true */
  shouldShowSidebar?: boolean;

  /** Flag whether the progress overview menu should be shown. Default true */
  shouldShowProgressOverviewMenu?: boolean;

  /** Optional component to display in the center */
  centerComponent?: ReactNode;

  /** The color of topbar background if need to use different one */
  topbarBackgroundColor?: string;

  /** Indicates if the topbar should have a bottom border */
  shouldShowTopbarBorder?: boolean;

  /** Color of the content background */
  contentBackgroundColor?: CSSProperties["color"];

  /** Logics related to the button on the left side of header */
  topLeftHeaderButton?: JSX.Element;

  /** If the layout is for account settings */
  isAccountSettings?: boolean;
}

/**
 * Describes the layout for the Sphere Dashboard website.
 * Includes the header bar, the breadcrumbs and the "Outlet", in which the
 * router will inject every page content.
 *
 * To keep margins consistent across pages, every page should not have neither padding
 * nor margins for the top component, instead we define those margins and paddings here.
 *
 * @returns React main page layout component.
 */
export function MainPageLayout({
  children,
  shouldHideOpenButton = false,
  shouldShowLogo = false,
  shouldShowUserMenu = true,
  shouldShowSidebar = true,
  shouldShowProgressOverviewMenu = true,
  topLeftHeaderButton,
  centerComponent,
  topbarBackgroundColor = sphereColors.pureWhite,
  shouldShowTopbarBorder,
  contentBackgroundColor,
  isAccountSettings = false,
}: MainPageLayoutProps): JSX.Element {
  return (
    <Grid sx={{ width: "100%", overflow: "auto" }}>
      <BannersHeaderLayout />

      {/* Contains the header bar */}
      <AppHeader
        shouldShowLogo={shouldShowLogo}
        topLeftHeaderButton={topLeftHeaderButton}
        topbarBackgroundColor={topbarBackgroundColor}
        shouldShowTopbarBorder={shouldShowTopbarBorder}
        shouldHideOpenButton={shouldHideOpenButton}
        shouldShowSidebar={shouldShowSidebar}
        centerComponent={centerComponent}
        shouldShowProgressOverviewMenu={shouldShowProgressOverviewMenu}
        shouldShowUserMenu={shouldShowUserMenu}
      />

      {/* Contains the body of the layout */}
      {isAccountSettings ? (
        <AccountSettingsBodyLayout>{children}</AccountSettingsBodyLayout>
      ) : (
        <BodyLayout contentBackgroundColor={contentBackgroundColor}>
          {children}
        </BodyLayout>
      )}
    </Grid>
  );
}
