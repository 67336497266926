import { SvgIcon, Typography, Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import CheckMarkIcon from "@assets/icons/new/checkmark-blue-circle-fill_24px.svg?react";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";


interface CreditFeatureItemProps {
  /** The text to display as a feature description in the credit package item. */
  featureText: string;
}

/**
 * A component that displays a feature item with a checkmark icon and descriptive text.
 * Used within credit package displays to list included features or benefits.
 */
export function CreditFeatureItem({
  featureText,
}: CreditFeatureItemProps): JSX.Element {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <SvgIcon
        component={CheckMarkIcon}
        sx={{
          width: "24px",
          height: "24px",
          color: sphereColors.blue100,
        }}
      />
      <Typography
        sx={{
          lineHeight: "36px",
          fontSize: DEFAULT_INPUT_FONT_SIZE,
        }}
      >
        {featureText}
      </Typography>
    </Stack>
  );
}
