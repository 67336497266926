import { FaroTextButton } from "@components/common/faro-text-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName, updateOne } from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { deleteProject } from "@store/projects/projects-slice-thunk";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { useDeleteProjectBulkDialogContent } from "@components/table/projects/bulk-actions/bulk-delete-project-helper";
import { SdbProject } from "@custom-types/project-types";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";

/** Show delete projects button and dispatch action where the bulk dialog will act accordingly */
export function BulkDeleteProjects({
  companyId,
}: BaseCompanyIdProps): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);

  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("projects"));
  const { hasUserPermissionProjectLevel } =
    useHasUserValidPermissionProjectLevel();

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const { bulkDialogContent, successDialogContent } =
    useDeleteProjectBulkDialogContent();

  // Closes the dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onDeleteProjectClick(): void {
    dispatch(setBulkActionName("removeProject"));

    for (const entity of selectedEntities) {
      const hasUserValidRole = hasUserPermissionProjectLevel({
        permissionName: RequiredPermissionProjectLevelName.canDeleteProject,
        project: entity,
      });

      if (!hasUserValidRole) {
        dispatch(
          updateOne({
            id: entity.id,
            changes: {
              status: "not-allowed",
              message:
                "You are not allowed to delete this project from workspace",
            },
          })
        );
      }
    }

    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextButton
        onClick={onDeleteProjectClick}
        tooltipText="Delete project from workspace"
        sx={{ fontWeight: 600 }}
      >
        Delete
      </FaroTextButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "removeProject" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "removeProject" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkDeleteProject}
          bulkActionCallback={(project: SdbProject) =>
            dispatch(
              deleteProject({
                coreApiClient,
                companyId,
                projectId: project.id,
              })
            ).unwrap()
          }
          selectedEntities={selectedEntities}
          uniqueIdKey="id"
          tableDataType="projects"
        />
      )}
    </>
  );
}
