import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { CoreAPITypes } from "@stellar/api-logic";
import { formatUserRoleType } from "@utils/data-display";

interface Props {
  /** Company role of the member */
  role: CoreAPITypes.EUserCompanyRole;
}

/**
 * Component that shows the info bar of a single member.
 * Currently shows the workspace role and the amount of projects.
 */
export function MemberProfileInfoBar({
  role,
}: Props): JSX.Element {
  return (
    <PageInfoBar
      items={[
        {
          content: (
            <FaroTextField
              label="Workspace Role"
              initialValue={formatUserRoleType(role)}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
    />
  );
}
