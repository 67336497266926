import { MemberTypes } from "@custom-types/member-types";
import { CoreAPITypes } from "@stellar/api-logic";

export const MOCK_COMPANY_MEMBERS: MemberTypes[] = [
  {
    identity: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    id: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    name: "Some Member",
    email: "some.member@foo.com",
    role: CoreAPITypes.EUserCompanyRole.companyExecutive,
  },
];
