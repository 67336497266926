import { ISOTimeString, UserId } from "@faro-lotv/ielement-types";
import { CaptureTreeEntityType } from "@faro-lotv/service-wires";

/** If there's no error, "uploaded" and "processed" are usually jumped over for the next state. */
export type WorkflowState = "start" |
  "uploading"   | "uploaded" | "uploadError" |
  "processing"  | "processed" | "processError" |
  "registering" | "registered" | "registerError" | "registerBadResult" |
  "publishing"  | "published" | "publishError";

export type StepState = "todo" | "active" | "done" | "error";

export type TableItemState = "start" | "uploading" | "uploaded" | "processing" | "unpublished" | "published" | "error";

/** Indices for the Staging Area stepper. */
export enum StepperIndices {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  upload = 0,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  process = 1,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  register = 2,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  publish = 3,
}

/** Headers for the Staging Area table. */
export enum TableHeaders {
  /** File name or other user-friendly name. */
  name = "Name",
  /** User who created the entity. */
  createdBy = "Created by",
  /** The date time when the entity was created. */
  createdAt = "Recorded at",
  /** Type of device used to generate the entity. */
  deviceType = "Device",
  /** Status icon such as the processing spinner. */
  status = "Status",
  /** Action buttons. */
  actions = "Actions",
}

/** Interface for the scan entities shown in the Staging Area table. */
export interface TableItem {
  /** Entity ID. */
  id: string;
  /** Type of the entity. */
  type: "Upload" | "Scan";
  /** Scan name or other user-friendly name. */
  name: string;
  /** Additional name, e.g. the file name, for the tooltip. */
  additionalName?: string;
  /** User who created the entity. */
  createdBy: UserId;
  /** The date time when the entity was created. */
  createdAt: ISOTimeString;
  /** Type of device used to generate the entity. */
  deviceType: CaptureTreeEntityType;
  /** State of the entity. */
  state: TableItemState;
  /** Progress from 0 to 100. Currently not displayed. */
  progress?: number;
};
