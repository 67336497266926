import {
  EntityAdapter,
  EntityId,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { CoreAPITypes } from "@stellar/api-logic";
import { BaseEntityState } from "@store/store-types";

export const slideNodesAdapter: EntityAdapter<CoreAPITypes.IArSlideJson, EntityId> =
  createEntityAdapter({
    selectId: (slideNode): EntityId => slideNode.sId,
  });

const initialState: BaseEntityState<CoreAPITypes.IArSlideJson> = slideNodesAdapter.getInitialState();

const slideNodesSlice = createSlice({
  name: "slideNodes",
  initialState,
  reducers: {
    setAll: slideNodesAdapter.setAll,
    resetSlideNodesState: () => initialState,
  },
});

export const { setAll, resetSlideNodesState } = slideNodesSlice.actions;

export const slideNodesReducer = slideNodesSlice.reducer;
