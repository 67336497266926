import InfoIcon from "@assets/icons/new/info_20px.svg?react";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { SphereTooltipIcon } from "@components/common/sphere-tooltip-icon";
import { addTransparency } from "@utils/ui-utils";

/** Size for the help icon that activates the tooltip */
const INFO_ICON_SIZE = "16px";

interface Props {
  /** Component that will show when icon is hovered */
  text: React.ReactNode;
}

/** Renders an info icon that shows a tooltip text when hovered */
export function PageSectionInfoIcon({ text }: Props): JSX.Element {
  return (
    <SphereTooltipIcon
      text={text}
      icon={InfoIcon}
      size={INFO_ICON_SIZE}
      tooltipProps={{
        slotProps: {
          tooltip: {
            sx: {
              backgroundColor: sphereColors.pureWhite,
              border: `1px solid ${sphereColors.gray200}`,
              borderRadius: "4px",
              boxShadow: `0px 3px 3px 0px ${addTransparency({
                color: sphereColors.gray500,
                alpha: EDecimalToHex.fiftyOne,
              })}`,
              padding: "16px",
            },
          },
        },
      }}
    />
  );
}
