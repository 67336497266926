import InfoSvg from "@assets/icons/new/info-circle_24px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Stack, Typography } from "@mui/material";
import { UnpublishedChangesChip } from "@pages/project-details/project-data-management/unpublished-changes-chip";

interface Props {
  /** Flag if an empty page is currently shown. */
  isEmpty: boolean,
  /** Indicates if there are any changes in the draft. */
  hasAnyDraftChanges: boolean,
  /** Flag if the publish button is disabled. */
  isPublishDisabled: boolean,
}

/**
 * Title that's showed above the table/actions section
 */
export function DataManagementTitle({ isEmpty, hasAnyDraftChanges, isPublishDisabled }: Props): JSX.Element {
  return (
    <Stack direction="row">
      <div style={{ fontSize: "24px" }}>
        Data
      </div>

      <SphereTooltip
        dataTestId="sa-title-tooltip"
        enterDelay={0}
        title={
          <div>
            <div style={{ fontWeight: "bold", fontSize: "14px" }}>
              Blink Data
            </div>
            Currently, only Blink data is supported.
          </div>
        }
      >
        {/* No click handler, we only want the hover effect. */}
        <FaroIconButton
          component={InfoSvg}
          iconButtonProps={{ sx: { marginLeft: "2px", top: "1px" } }}
        />
      </SphereTooltip>

      <div style={{
        fontSize: "16px",
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
      }}>

        { isEmpty &&
          <Typography
            sx={{
              fontSize: "16px",
              marginLeft: "20px",
              display: "flex",
              alignItems: "center",
            }}>
            Let’s get started by adding some data!
          </Typography>
        }

        { hasAnyDraftChanges &&
          <UnpublishedChangesChip isPublishDisabled={isPublishDisabled} />
        }

      </div>
    </Stack>
  );
}
