import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { FaroTable } from "@components/common/faro-table/faro-table";
import { CreditPurchasesHistoryHeaders } from "@components/table/transactions/transactions-table-utils";
import { Box } from "@mui/material";
import { getCreditPurchasesColumns } from "@components/table/transactions/credit-purchases-columns";
import { useDateTime } from "@hooks/use-date-time";
import { useMemo } from "react";
import { useAppSelector } from "@store/store-helper";
import { allCreditSubscriptionsSelector } from "@store/sdb-company/sdb-company-selector";

/** List of required columns in the credits purchases history table */
const requiredColumns = [
  CreditPurchasesHistoryHeaders.package,
  CreditPurchasesHistoryHeaders.purchasedDate,
  CreditPurchasesHistoryHeaders.expirationDate,
  CreditPurchasesHistoryHeaders.status,
  CreditPurchasesHistoryHeaders.creditsRemaining,
];

/** Renders the table for credits history */
export function CreditPurchasesTable(): JSX.Element {
  const { formatDate } = useDateTime();
  const subscriptions = useAppSelector(allCreditSubscriptionsSelector);
  const tableColumns = useMemo(
    () =>
      requiredColumns.map(
        (requiredColumn) =>
          getCreditPurchasesColumns({ formatDate })[requiredColumn]
      ),
    [formatDate]
  );

  return (
    <Box
      sx={{
        marginTop: SPACE_ELEMENTS_OF_MODAL,
        overflow: "auto",
      }}
    >
      <FaroTable
        entities={subscriptions}
        uniqueIdKey="uiTemporaryId"
        isLoading={false}
        shouldHideCheckbox={true}
        columns={tableColumns}
        rowHeight={70}
      />
    </Box>
  );
}
