import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { ProjectIntegrationId, ProjectIntegrationsMap } from "@services/integrations-service/integrations-service-types";

export const MOCK_PROCORE_DATA: ProjectIntegrationsMap[] = [
  {
    procore: {
      providerId: SphereDashboardAPITypes.IntegrationId.procore,
      projectName: "1234 - Sandbox Test Project",
      projectId: "122287",
      companyId: "4266512",
    },
    [ProjectIntegrationId.procoreObservations]: {
      bcfProjectId: "122287~4266512",
    },
    [ProjectIntegrationId.procoreRfis]: {
      bcfProjectId: "122287~4266512",
    },
  },
  {
    procore: {
      providerId: SphereDashboardAPITypes.IntegrationId.procore,
      projectName: "1234 - Sandbox Test Project",
      projectId: "122287",
      companyId: "4266512",
    },
    [ProjectIntegrationId.procoreObservations]: {
      bcfProjectId: "122287~4266512",
    },
    [ProjectIntegrationId.procoreRfis]: {
      bcfProjectId: "122287~4266512",
    },
    autodesk: {
      providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
      hubId: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
      projectName: "Test Project for BIM360",
      projectId: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
    },
    [ProjectIntegrationId.autodeskBim360Issues]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    [ProjectIntegrationId.autodeskBim360Rfis]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
  },
];

export const MOCK_UNSET_PROCORE_DATA: ProjectIntegrationsMap[] = [
  {
    procore: null,
    [ProjectIntegrationId.procoreObservations]: null,
    [ProjectIntegrationId.procoreRfis]: null,
  },
  {
    procore: null,
    [ProjectIntegrationId.procoreObservations]: null,
    [ProjectIntegrationId.procoreRfis]: null,
    autodesk: {
      providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
      hubId: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
      projectName: "Test Project for BIM360",
      projectId: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
    },
  },
];

export const MOCK_AUTODESK_DATA: ProjectIntegrationsMap[] = [
  {
    autodesk: {
      providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
      hubId: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
      projectName: "Test Project for BIM360",
      projectId: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
    },
    [ProjectIntegrationId.autodeskBim360Issues]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    [ProjectIntegrationId.autodeskBim360Rfis]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
  },
  {
    autodesk: {
      providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
      hubId: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
      projectName: "Test Project for BIM360",
      projectId: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
    },
    [ProjectIntegrationId.autodeskBim360Issues]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    [ProjectIntegrationId.autodeskBim360Rfis]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    [ProjectIntegrationId.autodeskAccIssues]: null,
    [ProjectIntegrationId.autodeskAccRfis]: null,
  },
  {
    autodesk: {
      providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
      hubId: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
      projectName: "Test Project for BIM360",
      projectId: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
    },
    [ProjectIntegrationId.autodeskBim360Issues]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    [ProjectIntegrationId.autodeskBim360Rfis]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    procore: {
      providerId: SphereDashboardAPITypes.IntegrationId.procore,
      projectName: "1234 - Sandbox Test Project",
      projectId: "122287",
      companyId: "4266512",
    },
    [ProjectIntegrationId.procoreObservations]: {
      bcfProjectId: "122287~4266512",
    },
    [ProjectIntegrationId.procoreRfis]: {
      bcfProjectId: "122287~4266512",
    },
  },
  {
    autodesk: {
      providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
      hubId: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
      projectName: "Test Project for BIM360",
      projectId: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
    },
    [ProjectIntegrationId.autodeskBim360Issues]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    [ProjectIntegrationId.autodeskBim360Rfis]: {
      bcfProjectId:
        "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    },
    [ProjectIntegrationId.autodeskAccIssues]: null,
    [ProjectIntegrationId.autodeskAccRfis]: null,
    procore: {
      providerId: SphereDashboardAPITypes.IntegrationId.procore,
      projectName: "1234 - Sandbox Test Project",
      projectId: "122287",
      companyId: "4266512",
    },
    [ProjectIntegrationId.procoreObservations]: {
      bcfProjectId: "122287~4266512",
    },
    [ProjectIntegrationId.procoreRfis]: {
      bcfProjectId: "122287~4266512",
    },
  },
];

export const MOCK_UNSET_AUTODESK_DATA: ProjectIntegrationsMap[] = [
  {
    autodesk: null,
    [ProjectIntegrationId.autodeskAccIssues]: null,
    [ProjectIntegrationId.autodeskAccRfis]: null,
    [ProjectIntegrationId.autodeskBim360Issues]: null,
    [ProjectIntegrationId.autodeskBim360Rfis]: null,
  },
  {
    autodesk: null,
    [ProjectIntegrationId.autodeskAccIssues]: null,
    [ProjectIntegrationId.autodeskAccRfis]: null,
    [ProjectIntegrationId.autodeskBim360Issues]: null,
    [ProjectIntegrationId.autodeskBim360Rfis]: null,
    procore: {
      providerId: SphereDashboardAPITypes.IntegrationId.procore,
      projectName: "1234 - Sandbox Test Project",
      projectId: "122287",
      companyId: "4266512",
    },
  },
];

export const MOCK_PROCORE_AND_AUTODESK_DATA: ProjectIntegrationsMap = {
  procore: {
    providerId: SphereDashboardAPITypes.IntegrationId.procore,
    projectName: "1234 - Sandbox Test Project",
    projectId: "122287",
    companyId: "4266512",
  },
  [ProjectIntegrationId.procoreObservations]: {
    bcfProjectId: "122287~4266512",
  },
  [ProjectIntegrationId.procoreRfis]: {
    bcfProjectId: "122287~4266512",
  },
  autodesk: {
    providerId: SphereDashboardAPITypes.IntegrationId.autodesk,
    hubId: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
    projectName: "Test Project for BIM360",
    projectId: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
  },
  [ProjectIntegrationId.autodeskBim360Issues]: {
    bcfProjectId:
      "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
  },
  [ProjectIntegrationId.autodeskBim360Rfis]: {
    bcfProjectId:
      "2602f5f3-55b8-4339-a247-0263b9d0af62~b.2602f5f3-55b8-4339-a247-0263b9d0af62~b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
  },
};
