import { Box } from "@mui/material";
import { DEFAULT_FONT_FAMILY_ITALIC, DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";

interface Props {
  /** The role that the member was changed to, e.g. Project Manager */
  newRole: string;

  /** The role that the member was tried to changed to, e.g. Member */
  intendedRole: string;
}

/**
 * Format the warning message after editing the member role, and it only partially worked
 * because the member is a group manager or project manager.
 */
export function RolePartiallyChangedGmPm({
  intendedRole,
  newRole,
}: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        fontSize: DEFAULT_INPUT_FONT_SIZE,
      }}
    >
      The role could not be fully changed because the member is the {newRole?.toLocaleLowerCase()} of a group.

      <br />

      To change the role to
      <Box
        component="span"
        sx={{
          fontFamily: DEFAULT_FONT_FAMILY_ITALIC,
        }}
      >
        {" "}{intendedRole}{" "}
      </Box>
      please remove the member from the group first.
    </Box>
  );
}
