import { EmptyPage } from "@src/components/common/empty-page/empty-page";
import ArchiveDuoColorIcon from "@assets/icons/archived-duo-color.svg?react";
import BrowseDuoColorIcon from "@assets/icons/browse-duo-color.svg?react";
import ProjectsDuoColorIcon from "@assets/icons/projects-duo-color.svg?react";
import { useAppSelector } from "@store/store-helper";
import { searchSelector } from "@store/ui/ui-selector";
import { ProjectArchivingState } from "@custom-types/project-types";

interface Props {
  /** The archiving-state of the projects that will be shown */
  projectArchivingState: ProjectArchivingState;
}

/** Empty page content for projects overview */
export function ProjectsOverviewEmptyPage({
  projectArchivingState,
}: Props): JSX.Element {
  const { debouncedSearchText } = useAppSelector(searchSelector);

  if (debouncedSearchText.length) {
    return (
      <EmptyPage
        title="No results found"
        subtitle="There are no projects that match this search."
        icon={BrowseDuoColorIcon}
      />
    );
  }
  if (projectArchivingState === ProjectArchivingState.archived) {
    return (
      <EmptyPage
        title={`No ${projectArchivingState} projects`}
        subtitle={
          // eslint-disable-next-line max-len -- needed per design
          "When you archive a project it will be stored here and remain in your workspace.\nHere you can also unarchive projects."
        }
        icon={ArchiveDuoColorIcon}
      />
    );
  }
  return (
    <EmptyPage
      title={`No ${projectArchivingState} projects`}
      subtitle={
        // eslint-disable-next-line max-len -- needed per design
        "You don't have any active projects in your workspace. Create a new project or unarchive one of the projects in the archived tab."
      }
      icon={ProjectsDuoColorIcon}
    />
  );
}
