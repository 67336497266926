import {
  IElement,
  IElementImg2d,
  IElementType,
  IElementTypeHint,
  isIElementAreaSection,
  isIElementGenericDataset,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  IElementAreaSectionWithUrl,
  IElementAttachmentsGroup,
  IElementElsDataSet,
  IElementMarkupWithTypeHint,
} from "@custom-types/i-elements-types";
import { isValidUrl } from "@utils/url-utils";

/**
 * @returns True iff the IElement is a markup image
 * @param element IElement
 */
export function isIElementMarkupImage(
  element: IElement
): element is IElementImg2d {
  return isIElementWithTypeAndHint(
    element,
    IElementType.img2d,
    IElementTypeHint.markupIssueImage
  );
}

/**
 * @returns True iff the IElement is a Area Section with a valid slideNodesUrl property
 * @param element IElement
 */
export function isIElementAreaSectionWithUrl(
  element: IElement
): element is IElementAreaSectionWithUrl {
  if (!isIElementAreaSection(element)) {
    return false;
  }

  const slideNodesUrl = element.metaDataMap?.slideNodesUrl;
  return isValidUrl(slideNodesUrl);
}

/**
 * @returns True if the iElement is a IElementMarkup with TypeHint Markup
 * @param iElement The element to check
 */
export function isIElementMarkupWithTypeHint(
  iElement: IElement
): iElement is IElementMarkupWithTypeHint {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.markup,
    IElementTypeHint.markup
  );
}

/**
 * @returns True if the IElement is an ELS dataset
 * @param element The IElement to check
 */
export function isIElementElsDataSet(
  element: IElement
): element is IElementElsDataSet {
  return (
    isIElementGenericDataset(element) &&
    element.typeHint === IElementTypeHint.dataSetEls
  );
}

/**
 * @returns whether the IElement if of type IElementGroup with type hint Attachments
 * @param element IElement
 */
export function isIElementAttachmentsGroup(
  element: IElement
): element is IElementAttachmentsGroup {
  return isIElementWithTypeAndHint(
    element,
    IElementType.group,
    IElementTypeHint.attachments
  );
}
