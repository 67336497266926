import { SphereTooltip } from "@components/common/sphere-tooltip";
import { UserAvatarWithMenu } from "@components/user-more-info-menu/user-avatar-with-menu";
import { MemberTypes } from "@custom-types/member-types";
import { withEllipsis } from "@styles/common-styles";
import { DASH } from "@utils/ui-utils";

interface Props {
  /** Optional property that contains details of a user */
  member?: MemberTypes;
}

/** Renders the user column fields in transactions table */
export function TransactionUser({ member }: Props): JSX.Element {
  if (!member) {
    return <var>{DASH}</var>;
  }

  return (
    <>
      <UserAvatarWithMenu member={member} />

      <SphereTooltip
        title={<var>{member.name}</var>}
        boxProps={{
          sx: {
            ...withEllipsis,
            fontWeight: "bold",
            cursor: "default",
            marginLeft: "6px",
          },
        }}
        component={"var"}
      >
        <var>{member.name}</var>
      </SphereTooltip>
    </>
  );
}
