import { BaseGroupProps } from "@custom-types/group-types";
import { isGroupProcessingSelector } from "@store/groups/groups-selector";
import { useAppSelector } from "@store/store-helper";
import { GroupCardTop } from "@components/group-card/group-card-top";
import { GroupCardMiddle } from "@components/group-card/group-card-middle";
import { GroupCardBottom } from "@components/group-card/group-card-bottom";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { useAppParams } from "@router/router-helper";
import { FaroCard } from "@components/common/faro-card/faro-card";

/**
 * A card that summarizes the group info
 */
export function GroupCard({ group }: BaseGroupProps): JSX.Element {
  const { companyId } = useAppParams();
  const { navigateToGroupDetails } = useAppNavigation();

  const isGroupProcessing = useAppSelector(isGroupProcessingSelector(group.id));

  return (
    <FaroCard
      isLoading={isGroupProcessing}
      onClick={() =>
        companyId &&
        navigateToGroupDetails({
          companyId: companyId,
          groupId: group.id,
        })
      }
    >
      {/* Card top section: Shows the group thumbnail image */}
      <GroupCardTop group={group} />

      {/* Card middle section: Shows the group name and creation date */}
      <GroupCardMiddle group={group} />

      {/* Card bottom section: Shows the number of group managers and projects */}
      <GroupCardBottom group={group} />
    </FaroCard>
  );
}
