import { AutodeskProject, AutodeskResponse } from "@services/integrations-service/autodesk/autodesk-types";

export const MOCK_AUTODESK_PROJECT: AutodeskProject = {
  type: "projects",
  id: "b.2602f5f3-55b8-4339-a247-0263b9d0af62",
  attributes: {
    name: "Test Project for BIM360",
    extension: {
      data: {
        projectType: "BIM360",
      },
    },
  },
  relationships: {
    hub: {
      data: {
        type: "hubs",
        id: "b.534ff3bb-7532-4834-9c4b-2f0f518a1555",
      },
    },
    issues: {
      data: {
          type: "issueContainerId",
          id: "2602f5f3-55b8-4339-a247-0263b9d0af62",
      },
    },
    rfis: {
      data: {
        type: "rfisContainerId",
        id: "2602f5f3-55b8-4339-a247-0263b9d0af62",
      },
    },
  },
};

export const MOCK_GET_AUTODESK_PROJECT_RESPONSE: AutodeskResponse<AutodeskProject> = {
  data: MOCK_AUTODESK_PROJECT,
};
