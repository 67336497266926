import { useAppSelector } from "@store/store-helper";
import {
  fetchedMembersFlagsSelector,
  fetchingMembersFlagsSelector,
  filteredCompanyMembersSelector,
} from "@store/members/members-selector";
import { useCompanyMembers } from "@hooks/use-company-members";
import { MainMemberDetails } from "@pages/members/main-member-details";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { MembersEmptyPage } from "@components/common/empty-page/members-empty-page";
import { useMemo } from "react";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { MembersTable } from "@components/table/members/members-table";
import { CompanyMemberBulkActions } from "@src/pages/members/company-member-bulk-actions";
import { currentUserSelector } from "@store/user/user-selector";
import { InviteMemberToCompany } from "@pages/members/invite-member-to-company";
import { useAppParams } from "@router/router-helper";
import { searchSelector } from "@store/ui/ui-selector";

/** Listing all the members of a company in a table */
export function MembersPage(): JSX.Element {
  useCompanyMembers();
  const filteredCompanyMembers = useAppSelector(filteredCompanyMembersSelector);
  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const { hasFetchedWorkspaceMembers } = useAppSelector(
    fetchedMembersFlagsSelector
  );
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const { companyId } = useAppParams();
  const currentUser = useAppSelector(currentUserSelector);
  const { debouncedSearchText } = useAppSelector(searchSelector);

  /**
   * Decides whether to show the empty page or not.
   * The empty page component handles the two different cases:
   * - No members at all (only the current user is in the workspace)
   * - No members that match the search
   */
  const shouldShowEmptyPage = useMemo(() => {
    if (isFetchingCompanyMembers || !hasFetchedWorkspaceMembers) {
      return false;
    }

    // If there are no members, show the empty page
    if (filteredCompanyMembers.length === 0) {
      return true;
    }

    if (debouncedSearchText.length) {
      // If there are no members that match the search, show the empty page
      // Here if the only member is the current user we don't show the empty page,
      // only if there are no members at all
      return !filteredCompanyMembers.length;
    }

    if (filteredCompanyMembers.length === 1) {
      // If the only member is the current user, show the empty page
      return filteredCompanyMembers[0].identity === currentUser?.identity;
    }

    return false;
  }, [
    filteredCompanyMembers,
    isFetchingCompanyMembers,
    hasFetchedWorkspaceMembers,
    currentUser,
    debouncedSearchText,
  ]);

  const buttonComponents = CompanyMemberBulkActions({
    members: filteredCompanyMembers,
    company: selectedSdbCompany,
  });

  if (shouldShowEmptyPage) {
    return (
      <MembersEmptyPage
        subtitle="All of your workspace members will be found here. You can add members to your workspace by inviting them."
        button={<InviteMemberToCompany buttonVariant="filled" />}
      />
    );
  }

  return (
    <>
      <MainMemberDetails />
      <MembersTable
        companyId={companyId}
        members={filteredCompanyMembers}
        tableSubject="workspace"
        isLoading={isFetchingCompanyMembers}
        requiredColumns={[
          MemberHeaders.avatar,
          MemberHeaders.user,
          MemberHeaders.email,
          MemberHeaders.status,
          MemberHeaders.role,
          MemberHeaders.options,
        ]}
        bulkActionButtons={buttonComponents}
      />
    </>
  );
}
