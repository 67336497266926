import { APITypes } from "@stellar/api-logic";
import { PageSectionProps } from "@components/page-section/page-section";
import { Feature2DPhotos } from "@pages/project-details/project-settings/feature-2d-photos";
import { FeatureVideoMode } from "@pages/project-details/project-settings/feature-video-mode";
import { FeatureFaceBlurring } from "@pages/project-details/project-settings/feature-face-blurring";
import { useAppSelector } from "@store/store-helper";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";
import { useMemo } from "react";
import { SettingDisplayForViewers } from "@src/pages/project-details/project-settings/setting-display-for-viewers";
import { useHasValidFeatureProjectLevel } from "@hooks/feature-control/use-has-user-valid-feature-project-level";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";
import { BaseSettingsPage } from "@pages/settings/base-settings-page";

interface Props {
  /** The ID of the project to display in the background tasks tab */
  projectId?: APITypes.ProjectId;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/** Renders the settings tab in project details page */
export function ProjectSettings({ projectId, isLoading }: Props): JSX.Element {
  const { isFetchingProjectContext, isFetchingProjectSettings } =
    useAppSelector(fetchingProjectsFlagsSelector);

  const {
    canView2dImagesFeature,
    canViewVideoModeFeature,
    canViewFaceBlurringFeature,
  } = useHasValidFeatureProjectLevel();

  const { canViewProjectSettings } = useHasUserValidPermissionProjectLevel();

  const isLoadingFeatures = useMemo(() => {
    return isLoading || isFetchingProjectContext;
  }, [isFetchingProjectContext, isLoading]);

  const isLoadingSettings = useMemo(() => {
    return isLoading || isFetchingProjectSettings;
  }, [isFetchingProjectSettings, isLoading]);

  const pageSectionsProps: PageSectionProps[] = [
    {
      title: "Project Features",
      subtitle: `These are the available features for your project. 
      Adjust them here to fit your needs by using the toggles.`,
      isLoading: isLoadingFeatures,
      items: [
        {
          shouldContentTakeFullWidth: true,
          shouldLabelBold: true,
          shouldAddRightPadding: true,
          isHidden: !canView2dImagesFeature,
          content: <Feature2DPhotos projectId={projectId ?? ""} />,
        },
        {
          shouldContentTakeFullWidth: true,
          shouldAddRightPadding: true,
          shouldLabelBold: true,
          isHidden: !canViewVideoModeFeature,
          content: <FeatureVideoMode projectId={projectId ?? ""} />,
        },
        {
          shouldContentTakeFullWidth: true,
          shouldLabelBold: true,
          shouldAddRightPadding: true,
          isHidden: !canViewFaceBlurringFeature,
          content: <FeatureFaceBlurring projectId={projectId ?? ""} />,
        },
      ],
    },
    {
      title: "Annotations visibility",
      subtitle:
        // eslint-disable-next-line quotes -- prettier rule conflicts with eslint rule
        'By selecting this option, permission to view annotations will be granted to all "Viewers" of this project.',
      isLoading: isLoadingSettings,
      items: [
        {
          shouldContentTakeFullWidth: true,
          shouldLabelBold: true,
          shouldAddRightPadding: true,
          isHidden: !canViewProjectSettings,
          content: <SettingDisplayForViewers />,
        },
      ],
    },
  ];

  return (
    <BaseSettingsPage
      pageSectionProps={pageSectionsProps}
      isLoading={isLoading}
      dataTestId="WORKSPACE_SETTING_CONTAINER"
      customSx={{ mt: "45px" }}
    />
  );
}
