import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

export type StateCreateNewTeam = {
  isLoading: boolean;
  selectedGroupId?: APITypes.GroupId;
  teamName: string;
  description: string;
  message?: AutoCompleteMessage;
  selectedRole?: SphereDashboardAPITypes.IAssignmentCompanyRole;
  isMemberInputValid: boolean;
  selectedMembersIds: string[];
};

export type ActionCreateNewTeam =
  | { type: "SET_IS_LOADING"; isLoading: boolean }
  | { type: "SET_SELECTED_GROUP_ID"; selectedGroupId: APITypes.GroupId | undefined }
  | { type: "SET_TEAM_NAME"; teamName: string }
  | { type: "SET_DESCRIPTION"; description: string }
  | { type: "SET_MESSAGE"; message: AutoCompleteMessage | undefined }
  | {
      type: "SET_SELECTED_ROLE";
      selectedRole: SphereDashboardAPITypes.IAssignmentCompanyRole | undefined;
    }
  | { type: "SET_IS_MEMBER_INPUT_VALID"; isMemberInputValid: boolean }
  | { type: "SET_SELECTED_MEMBERS_IDS"; selectedMembersIds: string[] };

export function reducerTeams(
  state: StateCreateNewTeam,
  action: ActionCreateNewTeam
): StateCreateNewTeam {
  switch (action.type) {
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.isLoading };
    case "SET_SELECTED_GROUP_ID":
      return { ...state, selectedGroupId: action.selectedGroupId };
    case "SET_TEAM_NAME":
      return { ...state, teamName: action.teamName };
    case "SET_DESCRIPTION":
      return { ...state, description: action.description };
    case "SET_MESSAGE":
      return { ...state, message: action.message };
    case "SET_SELECTED_ROLE":
      return { ...state, selectedRole: action.selectedRole };
    case "SET_IS_MEMBER_INPUT_VALID":
      return { ...state, isMemberInputValid: action.isMemberInputValid };
    case "SET_SELECTED_MEMBERS_IDS":
      return { ...state, selectedMembersIds: action.selectedMembersIds };
    default:
      return state;
  }
}
