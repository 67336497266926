import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { APITypes } from "@stellar/api-logic";
import { SdbProject } from "@custom-types/project-types";
import {
  ProjectsListType,
  ProjectsState,
  RemoveMemberResult,
  UpdateMemberRoleInProject,
} from "@store/projects/projects-slice-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import {
  deleteProject,
  editProjectFeature,
  fetchProjectContext,
  fetchProjectDetails,
  fetchProjects,
  fetchProjectSettings,
  getProjectFeatures,
  removeMemberFromProject,
  updateMemberRoleInProjectSingle,
  updateMemberRoleInProjectBulk,
  updateProjectDetails,
  updateProjectSettings,
  fetchProjectsByIds,
} from "@store/projects/projects-slice-thunk";
import {
  convertProjectTypesToSdbProject,
  PROJECTS_LIST_INITIAL_STATE,
} from "@store/projects/projects-slice-utils";

/** Creates an entity adapter to store a map with all the projects that the user has access to. */
export const projectsAdapter: EntityAdapter<SdbProject, EntityId> =
  createEntityAdapter({
    selectId: (project) => project.id,
  });

const initialState: ProjectsState = {
  ...projectsAdapter.getInitialState(),

  lists: {
    active: PROJECTS_LIST_INITIAL_STATE,
    archived: PROJECTS_LIST_INITIAL_STATE,
    searched: PROJECTS_LIST_INITIAL_STATE,
    group: PROJECTS_LIST_INITIAL_STATE,
  },

  selectedProject: {
    id: null,
    context: null,
    settings: null,
  },

  fetching: {
    isFetchingProjects: false,
    isUpdatingProjects: false,
    isRemovingProjectMember: false,
    isFetchingProjectContext: false,
    isFetchingProjectSettings: false,
    isUpdatingProjectSettings: false,
    processingProjects: {},
  },
};

/**
 * Removes locally a member from a project, by removing it from members array
 * attribute of the project.
 */
function removeLocalMemberFromProject(
  state: ProjectsState,
  result: RemoveMemberResult
): void {
  const project = projectsAdapter
    .getSelectors()
    .selectById(state, result.projectId);
  if (project) {
    // If the projects is in the store update the members attribute
    // by removing the member that was removed from it.
    projectsAdapter.updateOne(state, {
      id: project.id,
      changes: {
        members: project.members.filter(
          (member) => member.identity !== result.member.identity
        ),
      },
    });
  }
  state.fetching.isRemovingProjectMember = false;
}

/**
 * Update a member role in project store by updating it from members array attribute of the project.
 */
function updateMemberRoleInProjectStore(
  state: ProjectsState,
  result: UpdateMemberRoleInProject
): void {
  const project = projectsAdapter
    .getSelectors()
    .selectById(state, result.projectId);

  // If the projects is in the store update the member role
  if (project) {
    projectsAdapter.updateOne(state, {
      id: project.id,
      changes: {
        members: project.members.map((member) => {
          if (member.identity === result.identity) {
            return { ...member, role: result.role };
          }
          return member;
        }),
      },
    });
  }
}

/** Slice to access state of loaded projects */
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    // Check the Redux Toolkit documentation to understand the purpose of each reducer function:
    // https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

    /** Accepts a single project entity and adds or replaces it. */
    setOneProjects: projectsAdapter.setOne,

    /** Accepts an array of project entities, and adds or replaces them. */
    setManyProjects: projectsAdapter.setMany,

    /** Sets all projects in the state with the passed array of projects */
    setAllProjects: projectsAdapter.setAll,

    /** Removes all project entities from the store. */
    removeAllProjects: projectsAdapter.removeAll,

    /** Accepts an array of project IDs, and removes each project entity with those IDs if they exist. */
    removeManyProjects: projectsAdapter.removeMany,

    /** Accepts a single project IDs, and removes the project entity with that ID if it exists. */
    removeOneProjects: projectsAdapter.removeOne,

    addProcessingProjects(state, action: PayloadAction<APITypes.ProjectId[]>) {
      action.payload.forEach(
        (projectId) => (state.fetching.processingProjects[projectId] = true)
      );
    },

    /** Stores the project id of the selected project. */
    setSelectedProjectId(state, action: PayloadAction<string | null>) {
      state.selectedProject.id = action.payload;
    },

    /** Resets the selected project state */
    resetSelectedProject(state) {
      state.selectedProject.id = null;
      state.selectedProject.context = null;
      state.selectedProject.settings = null;
    },

    removeProcessingProjects(
      state,
      action: PayloadAction<APITypes.ProjectId[]>
    ) {
      action.payload.forEach(
        (projectId) => delete state.fetching.processingProjects[projectId]
      );
    },

    /** Set the fetching of updating projects */
    setFetchingUpdatingProjects(state, action: PayloadAction<boolean>) {
      state.fetching.isUpdatingProjects = action.payload;
    },

    /** Resets the state of a list of projects */
    resetProjectsList(state, action: PayloadAction<ProjectsListType>) {
      state.lists[action.payload] = PROJECTS_LIST_INITIAL_STATE;
    },

    resetProjectsState: () => initialState,
  },

  extraReducers(builder) {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.fetching.isFetchingProjects = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        // Generate project entities. Linear operation O(n)
        const sdbProjects = action.payload.projects.map(convertProjectTypesToSdbProject);
        // Set entities in redux store
        projectsAdapter.setMany(state, sdbProjects);
        // Update projects list state
        const list = state.lists[action.payload.listType];
        list.nextPageCursor = action.payload.nextPageCursor;
        if (!list.hasFetchedFirstPage) {
          list.hasFetchedFirstPage = true;
        }

        state.fetching.isFetchingProjects = false;
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.fetching.isFetchingProjects = false;
      })

      .addCase(fetchProjectDetails.pending, (state) => {
        state.fetching.isFetchingProjects = true;
      })
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        state.fetching.isFetchingProjects = false;
        state.selectedProject.id = action.payload.id;
        projectsAdapter.setOne(
          state,
          convertProjectTypesToSdbProject(action.payload)
        );
      })
      .addCase(fetchProjectDetails.rejected, (state) => {
        state.fetching.isFetchingProjects = false;
      })

      .addCase(updateProjectDetails.pending, (state) => {
        state.fetching.isUpdatingProjects = true;
      })
      .addCase(updateProjectDetails.fulfilled, (state, action) => {
        state.fetching.isUpdatingProjects = false;
        projectsAdapter.setOne(
          state,
          convertProjectTypesToSdbProject(action.payload)
        );
      })
      .addCase(updateProjectDetails.rejected, (state, action) => {
        state.fetching.isUpdatingProjects = false;
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      .addCase(removeMemberFromProject.pending, (state) => {
        state.fetching.isRemovingProjectMember = true;
      })
      .addCase(removeMemberFromProject.fulfilled, (state, action) => {
        removeLocalMemberFromProject(state, action.payload);
      })
      .addCase(removeMemberFromProject.rejected, (state) => {
        state.fetching.isRemovingProjectMember = false;
      })

      .addCase(updateMemberRoleInProjectSingle.pending, (state) => {
        state.fetching.isUpdatingProjects = true;
      })
      .addCase(updateMemberRoleInProjectSingle.fulfilled, (state, action) => {
        updateMemberRoleInProjectStore(state, action.payload);
        state.fetching.isUpdatingProjects = false;
      })
      .addCase(updateMemberRoleInProjectSingle.rejected, (state) => {
        state.fetching.isUpdatingProjects = false;
      })

      .addCase(updateMemberRoleInProjectBulk.pending, (state) => {
        state.fetching.isUpdatingProjects = true;
      })
      .addCase(updateMemberRoleInProjectBulk.fulfilled, (state, action) => {
        updateMemberRoleInProjectStore(state, action.payload);
        state.fetching.isUpdatingProjects = false;
      })
      .addCase(updateMemberRoleInProjectBulk.rejected, (state) => {
        state.fetching.isUpdatingProjects = false;
      })

      .addCase(fetchProjectContext.pending, (state) => {
        state.fetching.isFetchingProjectContext = true;
      })
      .addCase(fetchProjectContext.fulfilled, (state, action) => {
        state.selectedProject.context = action.payload;
        state.fetching.isFetchingProjectContext = false;
      })
      .addCase(fetchProjectContext.rejected, (state) => {
        state.fetching.isFetchingProjectContext = false;
      })

      .addCase(getProjectFeatures.pending, (state) => {
        state.fetching.isFetchingProjectContext = true;
      })
      .addCase(getProjectFeatures.fulfilled, (state, action) => {
        projectsAdapter.updateOne(state, {
          id: action.payload.projectId,
          changes: {
            features: { ...action.payload.features },
          },
        });
        state.fetching.isFetchingProjectContext = false;
      })
      .addCase(getProjectFeatures.rejected, (state) => {
        state.fetching.isFetchingProjectContext = false;
      })

      .addCase(editProjectFeature.fulfilled, (state, action) => {
        projectsAdapter.updateOne(state, {
          id: action.payload.selectedProject.id,
          changes: {
            features: {
              ...action.payload.selectedProject.features,
              ...action.payload.newFeatures,
            },
          },
        });
      })
      .addCase(editProjectFeature.rejected, (state, action) => {
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      .addCase(deleteProject.pending, (state, action) => {
        state.fetching.processingProjects[action.meta.arg.projectId] = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        projectsAdapter.removeOne(state, action.payload);
        delete state.fetching.processingProjects[action.payload];
      })
      .addCase(deleteProject.rejected, (state, action) => {
        delete state.fetching.processingProjects[action.meta.arg.projectId];
      })

      .addCase(fetchProjectSettings.pending, (state) => {
        state.fetching.isFetchingProjectSettings = true;
      })
      .addCase(fetchProjectSettings.fulfilled, (state, action) => {
        state.selectedProject.settings = action.payload;
        state.fetching.isFetchingProjectSettings = false;
      })
      .addCase(fetchProjectSettings.rejected, (state) => {
        state.fetching.isFetchingProjectSettings = false;
      })

      .addCase(updateProjectSettings.pending, (state) => {
        state.fetching.isUpdatingProjectSettings = true;
      })
      .addCase(updateProjectSettings.fulfilled, (state, action) => {
        state.selectedProject.settings = action.payload;
        state.fetching.isUpdatingProjectSettings = false;
      })
      .addCase(updateProjectSettings.rejected, (state) => {
        state.fetching.isUpdatingProjectSettings = false;
      })

      .addCase(fetchProjectsByIds.pending, (state) => {
        state.fetching.isFetchingProjects = true;
      })
      .addCase(fetchProjectsByIds.fulfilled, (state, action) => {
        const sdbProjects = action.payload.map(convertProjectTypesToSdbProject);
        projectsAdapter.upsertMany(state, sdbProjects);
        state.fetching.isFetchingProjects = false;
      })
      .addCase(fetchProjectsByIds.rejected, (state) => {
        state.fetching.isFetchingProjects = false;
      });
  },
});

export const {
  setOneProjects,
  setManyProjects,
  setAllProjects,
  removeAllProjects,
  removeManyProjects,
  removeOneProjects,
  addProcessingProjects,
  setSelectedProjectId,
  resetSelectedProject,
  removeProcessingProjects,
  resetProjectsState,
  setFetchingUpdatingProjects,
  resetProjectsList,
} = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
