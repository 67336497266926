import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  IntegrationCompany,
  IntegrationProject,
  ProcoreLegacyMap,
  ProjectIntegrationId,
  ProjectIntegrationsMap,
} from "@services/integrations-service/integrations-service-types";
import { ProcoreEntity } from "@services/integrations-service/procore/procore-types";
import { BaseService } from "@services/integrations-service/base-service";
import { StatusCodes } from "http-status-codes";
import {
  isProcoreLegacyProjectIntegration,
  isResponseError,
} from "@services/integrations-service/integrations-type-guards";

/** Service that provides methods to manage the Procore 3rd party integration */
export class ProcoreService extends BaseService {
  protected integrationId = SphereDashboardAPITypes.IntegrationId.procore;

  /**
   * @returns the IntegrationCompany entities of the user
   * @throws {Error} if it fails to get the entities
   */
  public async getIntegrationCompanies(): Promise<IntegrationCompany[]> {
    const procoreCompanies = await this.getProcoreCompanies();

    return procoreCompanies.map((procoreCompany) => ({
      integrationId: SphereDashboardAPITypes.IntegrationId.procore,
      id: procoreCompany.id.toString(),
      name: procoreCompany.name,
    }));
  }

  /**
   * @returns the IntegrationProject entities for a given integration company
   * @throws {Error} if it fails to get the entities
   * @param companyId ID of the company
   */
  public async getIntegrationProjects(
    companyId: string
  ): Promise<IntegrationProject[]> {
    const procoreProjects = await this.getProcoreProjects(companyId);

    const integrationProjects: IntegrationProject[] = procoreProjects.map((procoreProject) => {
      const bcfProjectId = `${procoreProject.id}~${companyId}`;
      return {
        integrationId: SphereDashboardAPITypes.IntegrationId.procore,
        id: procoreProject.id.toString(),
        name: procoreProject.name,
        companyId,
        rfisBcfProjectId: bcfProjectId,
        issuesOrObservationsBcfProjectId: bcfProjectId,
        type: "none",
      };
    });

    return integrationProjects;
  }

  /**
   * @returns the companies of the user
   * @throws {Error} if it fails to fetch the companies
   */
  private async getProcoreCompanies(): Promise<ProcoreEntity[]> {
    return this.request({
      url: "companies",
      verb: "GET",
    });
  }

  /**
   * @returns the projects for a given company
   * @throws {Error} if it fails to fetch the projects
   * @param companyId ID of the company
   */
  private async getProcoreProjects(companyId: string): Promise<ProcoreEntity[]> {
    return this.request({
      url: `companies/${companyId}/projects`,
      verb: "GET",
    });
  }

  /**
   * Handles errors originating from the CoreAPI proxy backend for requests issued to the Procore backend.
   * - If the error status is UNAUTHORIZED it attempts to update the token.
   * - If the error status is FORBIDDEN and for some specific error codes then it also attempts
   * to update the token.
   * @param error Error to handle
   * @throws {CoreAPITypes.IBaseResponse} if it can't handle the error.
   */
  protected async handleIntegrationError(error: CoreAPITypes.IBaseResponse): Promise<void> {
    switch (error.status) {
      case StatusCodes.UNAUTHORIZED:
        await this.handleTokenUpdate();
        break;
      case StatusCodes.FORBIDDEN:
        if (
          isResponseError(error) &&
          (
            error.error_v2 === "authentication_error" ||
            error.error_v2 === "no_authorization_token"
          )
        ) {
          await this.handleTokenUpdate();
        } else {
          throw error;
        }
        break;
      default:
        throw error;
    }
  }

  /**
   * Updates the project connection data to the Sphere XG format.
   * @returns The project integrations map with the updated data format
   * @param data Project integrations map
   */
  public updateDataFormat(data: ProcoreLegacyMap): Promise<ProjectIntegrationsMap> {
    const bcfProjectId = `${data.procore.projectId}~${data.procore.companyId}`;

    return Promise.resolve({
      ...data,
      [ProjectIntegrationId.procoreObservations]: {
        bcfProjectId,
      },
      [ProjectIntegrationId.procoreRfis]: {
        bcfProjectId,
      },
    });
  }

  /**
   * @returns Whether the project connection data format is of type Legacy (HB).
   * The legacy Procore data format can be identified from the project integrations map when
   * the "procore" property is defined but the other 2 new Sphere XG Procore fields are undefined or null.
   * @param data Project integrations map
   */
  public isLegacyDataFormat(data: ProjectIntegrationsMap): data is ProcoreLegacyMap {
    return (
      isProcoreLegacyProjectIntegration(data.procore) &&
      !data["procore.observations"] &&
      !data["procore.rfis"]
    );
  }
}
