import { useMemo, useState } from "react";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { fetchCaptureTreeData } from "@hooks/data-management/use-data-management";
import { useToast } from "@hooks/use-toast";
import { Box } from "@mui/material";
import { deleteCaptureTreeEntities } from "@pages/project-details/project-data-management/import-data/cancel-import-utils";
import { TableItem, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { canTableItemBeRemoved, getScanEntitiesToRemove } from "@pages/project-details/project-data-management/data-management-utils";
import { captureTreeSelector } from "@store/capture-tree/capture-tree-selectors";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props extends BaseProjectIdProps {
  /** All table items. */
  allItems: TableItem[];
  /**
   * The table items selected for removal.
   * Always one element if the removal button for a single table item was used.
   * This may include table items currently not eligible for removal if the multi-selection tool was used,
   * in particular table items for file upload tasks. It's currently not possible to hide the checkboxes for
   * such items in advance.
   */
  items: TableItem[];
  /** Workflow state. */
  state: WorkflowState;
  /** Whether this dialog is open. */
  isOpen: boolean;
  /** Callback to set whether this dialog is open. */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/** Confirmation dialog for the removal of the selected scans in the Staging Area. */
export function RemoveScansDialog({ projectId, allItems, items, state, isOpen, setIsOpen }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();
  const projectApiClient = useProjectApiClient({ projectId });
  const progressApiClient = useProgressApiClient({ projectId });
  // For showing a loading spinner while the operation is in progress.
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const treeElements = useAppSelector(captureTreeSelector);

  const itemsAllowed = useMemo(() => {
    return items.filter((item) => canTableItemBeRemoved(item));
  }, [items]);

  const allowedIds = useMemo(() => {
    return new Set(itemsAllowed.map((item) => item.id));
  }, [itemsAllowed]);

  const itemsDisallowed = useMemo(() => {
    return items.filter((item) => !allowedIds.has(item.id));
  }, [items, allowedIds]);

  // List elements for scans that can be removed.
  const elementsAllowed = useMemo(() => {
    const namesAllowed = itemsAllowed.map((item) => item.name);
    namesAllowed.sort();
    return namesAllowed.map((name, i) =>
      <li key={i}><var>{name}</var></li>
    );
  }, [itemsAllowed]);

  // List elements for scans that currently can't be removed.
  const elementsDisallowed = useMemo(() => {
    const namesDisallowed = itemsDisallowed.map((item) => item.name);
    namesDisallowed.sort();
    return namesDisallowed.map((name, i) =>
      <li key={i} style={{ color: sphereColors.gray500 }}><var>{name}</var></li>
    );
  }, [itemsDisallowed]);

  async function removeScans(): Promise<void> {
    try {
      setIsRemoving(true);

      const scansToRemove = itemsAllowed.filter((item) => canTableItemBeRemoved(item) && item.type === "Scan");
      const treeEntitiesToRemove = getScanEntitiesToRemove(scansToRemove, treeElements);
      if (treeEntitiesToRemove.length === 0) {
        showToast({
          message: "No scans could be removed.",
          type: "info",
        });
        return;
      }

      await deleteCaptureTreeEntities(projectApiClient, treeEntitiesToRemove);

      trackEvent({
        name: DataManagementEvents.removeScans,
        props: {
          numberOfScans: scansToRemove.length,
          source: "removeScansDialog",
        },
      });

      // Fetch the new state immediately instead of waiting until the next usePolling() happens.
      // Don't await since that may alter the values of input variables and thus the text displayed by the still open
      // dialog.
      void fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient)
        // Not important. The UI will update a bit later.
        .catch(() => undefined);

      const scans = allItems.filter((item) => item.type === "Scan");
      const hasRemainingScans = scansToRemove.length < scans.length;
      const hasRemainingItems = scansToRemove.length < allItems.length;

      if (hasRemainingScans &&
        (state === "published" || state === "registered" || state === "registerBadResult")
      ) {
        showToast({
          message: "Click on the \"Publish\" button to make all changes visible to other users.",
          type: "info",
          shouldAutoHide: false,
        });
      } else if (!hasRemainingItems) {
        showToast({
          message: "The draft version of your project contains no data anymore. " +
            "Please add some data first before publishing the new state.",
          type: "info",
          shouldAutoHide: false,
        });
      }
    } finally {
      setIsRemoving(false);
      setIsOpen(false);
    }
  }

  return (
    <FaroDialog
      title={ elementsAllowed.length === 1 ? "Remove Scan" : "Remove Scans" }
      confirmText={ elementsAllowed.length ? "Remove" : "Close" }
      open={isOpen}
      onConfirm={() => void removeScans()}
      onClose={() => setIsOpen(false)}
      isConfirmLoading={isRemoving}
      sx={{ fontSize: "14px" }}
    >
      { !!elementsAllowed.length &&
        <Box>
          Do you want to remove the following { elementsAllowed.length === 1 ? "scan" : `${elementsAllowed.length} scans` }?
          <ul>{ elementsAllowed }</ul>
        </Box>
      }

      { !!elementsDisallowed.length &&
        <Box sx={{
          marginTop: "5px",
          color: sphereColors.gray500,
        }}>
          The following { elementsDisallowed.length === 1 ? "scan " : `${elementsDisallowed.length} scans ` }
          can't be removed due to { elementsDisallowed.length === 1 ? "its" : "their" } current state.<br/>
          Please try again later.
          <ul>{ elementsDisallowed }</ul>
        </Box>
      }
    </FaroDialog>
  );
}
