import { CoreAPITypes } from "@stellar/api-logic";
import { formatUserRoleType } from "@utils/data-display";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { FaroTextMenuButton } from "@components/common/faro-text-menu-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName, updateOne } from "@store/table/table-slice";
import { selectedProjectManagerSelector } from "@store/projects/projects-selector";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import { useChangeRoleBulkDialogContent } from "@components/table/members/bulk-actions/bulk-change-role-helper";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import {
  BaseCompanyIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { updateMemberRoleInProjectBulk } from "@store/projects/projects-slice-thunk";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BaseMemberAndTeam } from "@custom-types/member-types";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";

/** Button allowing to change role of members in project */
export function BulkChangeProjectRole({
  companyId,
  projectId,
}: BaseCompanyIdProps & BaseProjectIdProps): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<CoreAPITypes.EUserProjectRole>(
    CoreAPITypes.EUserProjectRole.viewer
  );

  const { user: selectedProjectManager } = useAppSelector(
    selectedProjectManagerSelector
  );
  const bulkActionName = useAppSelector(bulkActionNameSelector);

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));
  const selectedTeams = useAppSelector(selectedEntitiesSelector("teams"));

  // TODO: https://faro01.atlassian.net/browse/ST-2840 remove this variable after remove the user group on members endpoint
  const selectedEntitiesWithoutTeams = selectedEntities.filter(
    (entities) => entities.kind === "user"
  );

  const { bulkDialogContent, successDialogContent } =
    useChangeRoleBulkDialogContent({ subjectType: "project", newRole });

  // Closes the bulk dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onNewRoleClicked(event: React.MouseEvent<HTMLLIElement>): void {
    dispatch(setBulkActionName("changeRole"));

    if (selectedProjectManager) {
      dispatch(
        updateOne({
          id: selectedProjectManager.identity,
          changes: {
            status: "not-allowed",
            message: "Cannot change the role of project owner",
          },
        })
      );
    }

    // TODO: Check whether it is possible to remove type-casting: https://faro01.atlassian.net/browse/ST-1348
    setNewRole(
      event.currentTarget.dataset.myValue as CoreAPITypes.EUserProjectRole
    );
    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextMenuButton
        buttonTitle="Change role"
        tooltipText="Change role in project"
        buttonSx={() => {
          return { fontWeight: 600 };
        }}
      >
        {Object.values(CoreAPITypes.EUserProjectRole).map(
          (role) =>
            role !== CoreAPITypes.EUserProjectRole.owner && (
              <FaroMenuItem
                data-my-value={role}
                key={role}
                value={role}
                onClick={onNewRoleClicked}
              >
                {formatUserRoleType(role)}
              </FaroMenuItem>
            )
        )}
      </FaroTextMenuButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "changeRole" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "changeRole" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkChangeRole}
          bulkActionCallback={(member: BaseMemberAndTeam) =>
            dispatch(
              updateMemberRoleInProjectBulk({
                coreApiClient,
                companyId,
                projectId,
                identity: member.identity,
                role: newRole,
              })
            ).unwrap()
          }
          selectedEntities={[...selectedTeams, ...selectedEntitiesWithoutTeams]}
          uniqueIdKey="identity"
          tableDataType="members"
        />
      )}
    </>
  );
}
