import { useState } from "react";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { TableItem } from "@pages/project-details/project-data-management/data-management-types";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { captureTreeSelector } from "@store/capture-tree/capture-tree-selectors";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { ScanEntity } from "@custom-types/capture-tree/capture-tree-types";
import { createRevisionToRenameScans, isScanEntity } from "@utils/capture-tree/capture-tree-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { EventProps } from "@faro-lotv/foreign-observers";
import { fetchRevisionsAndDraftEntities } from "@hooks/data-management/use-data-management";

interface Props {
  entity: TableItem,
  /** True if the scan name should be editable */
  isEditable: boolean;
}

/** Renders and updates the scan name */
export function EditableScanName({ entity, isEditable }: Props): JSX.Element {
  const { projectId } = useDataManagementContext();
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();
  const projectApiClient = useProjectApiClient({ projectId });
  const dispatch = useAppDispatch();

  const entities = useAppSelector(captureTreeSelector);

  const [isUpdating, setIsUpdating] = useState(false);

  /**
   * Updates the scan name in the backend and returns the new value if successful.
   * Otherwise, returns the old value.
   *
   * @param value The new scan name.
   */
  async function onUpdateName(value: string): Promise<string> {
    value = value.trim();
    const scan = entities.find((e) => e.id === entity.id);

    const propsToSend: EventProps = {
      newScanNameLength: value.length,
      type: scan?.type ?? "N/A",
    };
    trackEvent({
      name: DataManagementEvents.renameScan,
      props: propsToSend,
    });

    if (scan && scan.id && isScanEntity(scan)) {
      setIsUpdating(true);
      
      // we have to do this since it's readonly value, we can't do scan.name = ...
      const scanClone: ScanEntity = {
        ...scan,
        name: value,
      };

      try {
        await createRevisionToRenameScans(projectApiClient, entities, [scanClone]);

        // Non-essential: Make sure that "Publish" button is shown immediately after renaming.
        await fetchRevisionsAndDraftEntities(dispatch, projectApiClient)
          .catch(() => undefined);
      } catch (error) {
        // Revert the edit field to the old name.
        value = scan.name;

        handleErrorWithToast({
          id: `changeScanName-${Date.now().toString()}`,
          title: "Failed to rename scan",
          error,
        });
      } finally {
        setIsUpdating(false);
      }
    }

    return value;
  }

  return (
    <FaroTextField
      initialValue={entity.name}
      faroVariant="row"
      fontSize="12px"
      isReadOnly={!isEditable}
      isLoading={isUpdating}
      isDisabled={isUpdating}
      minInputLength={1}
      onConfirmed={onUpdateName}
      shouldShowTooltip={true}
    />
  );
}
