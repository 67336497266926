import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { UpdateProfilePictureDialog } from "@components/user-info/update-profile-picture-dialog";
import { Grid } from "@mui/material";
import {
  DEFAULT_BOLD_TEXT_FONT_WEIGHT,
  DEFAULT_INPUT_FONT_SIZE,
} from "@styles/common-styles";
import { getUserInitials } from "@utils/user-utils";
import { useState } from "react";
import { useUserContext } from "@pages/account-settings/user-provider";

/** Renders profile picture section item in member account page */
export function AccountProfilePicture(): JSX.Element {
  const { user } = useUserContext();
  const [isProfilePictureDialogOpen, setIsProfilePictureDialogOpen] =
    useState<boolean>(false);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
    >
      <SphereAvatar
        src={user.profileImageUrl}
        initials={getUserInitials(user)}
        icon={undefined}
        shouldUseSimplifiedAvatar={true}
        shouldHideWhiteRim={true}
        size="large"
      />

      {/* Button to show the update profile picture dialog */}
      <FaroTextButton
        onClick={() => setIsProfilePictureDialogOpen(true)}
        sx={{
          fontSize: DEFAULT_INPUT_FONT_SIZE,
          fontWeight: DEFAULT_BOLD_TEXT_FONT_WEIGHT,
        }}
      >
        {user.profileImageUrl ? "Change picture" : "Add"}
      </FaroTextButton>

      <UpdateProfilePictureDialog
        user={user}
        isDialogOpen={isProfilePictureDialogOpen}
        setIsDialogOpen={setIsProfilePictureDialogOpen}
      />
    </Grid>
  );
}
