import { QueryParams } from "@router/route-params";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";

export interface BuildForbiddenRouteQueryStringProps {
  /**
   * The current logged in user.
   */
  userId?: string;

  /**
   * Optional array filled with the required company roles for the route.
   */
  requiredCompanyRoles?: CoreAPITypes.EUserCompanyRole[];

  /**
   * Optional array filled with the required permissions for the route.
   */
  requiredPermissions?: SphereDashboardAPITypes.EProjectPermission[];

  /**
   * Optional array filled with the required company subscriptions for the route
   */
  requiredCompanySubscriptions?: APITypes.EUserSubscriptionRole[];
}

/**
 * Encodes an array of strings into a single encoded string that can
 * be sent over the URL. It uses "+" to separate elements, so make sure
 * that the array does not contain elements with "+" because it could
 * produce errors when decoding.
 *
 * @param strings An array filled with strings to be combined and encoded.
 * @returns A string that can be sent over the url.
 */
export function encodeURIStrings(strings: string[]): string {
  // Note that "+" will be transformed in "%252B" by encodeURIComponent
  return encodeURIComponent(strings?.join("+"));
}

/**
 * Builds a query string to be appended to the redirection route
 * to the forbidden page
 *
 * @param props Objects to get the route requirements. E.g.
 * { requiredCompanyRoles: ["COMPANY_EXECUTIVE"], requiredProjectRoles: ["RESOURCE_OWNER", "RESOURCE_ADMIN"] }
 * @returns The string version of the query, e.g.
 * "?workspace_roles=COMPANY_EXECUTIVE&project_roles=RESOURCE_OWNER%252BRESOURCE_ADMIN"
 */
export function buildForbiddenRouteQueryString({
  requiredCompanyRoles,
  requiredPermissions,
  requiredCompanySubscriptions,
  userId,
}: BuildForbiddenRouteQueryStringProps): string {
  const params = new URLSearchParams();

  if (requiredCompanyRoles && requiredCompanyRoles.length) {
    params.append(
      QueryParams.workspaceRoles,
      // Converts an array with roles to an string that can be sent over the url
      encodeURIStrings(requiredCompanyRoles)
    );
  }
  if (requiredPermissions && requiredPermissions.length) {
    params.append(
      QueryParams.projectPermissions,
      // Converts an array with roles to an string that can be sent over the url
      encodeURIStrings(requiredPermissions)
    );
  }
  if (requiredCompanySubscriptions && requiredCompanySubscriptions.length) {
    params.append(
      QueryParams.companySubscriptions,
      // Converts an array with roles to an string that can be sent over the url
      encodeURIStrings(requiredCompanySubscriptions)
    );
  }
  if (userId) {
    params.append(QueryParams.user, encodeURI(userId));
  }
  params.append(QueryParams.origin, encodeURI(window.location.href));

  const stringParams = params.toString();
  // Only include "?" if there are params to include in the search params
  return stringParams ? `?${stringParams}` : "";
}
