import { useCallback } from "react";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { getLsDataV2Package } from "@api/stagingarea-api/stagingarea-api";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SdbProject } from "@custom-types/project-types";
import { useReadLsDataV2 } from "@hooks/data-management/use-read-ls-data-v2";
import { ReturnFunctionOnSelectFiles } from "@hooks/data-management/use-on-select-any-files";
import { useUploadErrorToast, UploadErrorToastType } from "@hooks/data-management/use-upload-error-toast";
import { useUploadMultipleScans } from "@hooks/data-management/use-upload-multiple-scans";
import { isValidFile } from "@hooks/file-upload-utils";
import { createRevisionForElsScans } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import {
  ALLOWED_EXTENSIONS_ALL,
  filesInfoForTracking,
  getScansAlreadyUploaded,
  isGLS,
  isValidGlsFile,
  MAX_FILE_SIZE_IN_MB,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { APITypes } from "@stellar/api-logic";
import { cleanupUploadTasks } from "@pages/project-details/project-data-management/import-data/cancel-import-utils";
import { useAppSelector } from "@store/store-helper";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { getFilesWithDuplicateNames, sortFiles } from "@utils/file-utils";
import { DataManagementEvents } from "@utils/track-event/track-event-list";

/**
 * Set the files for upload, replacing any previous selected ones. We allow to upload a single ELS folder at once.
 * Returns true on success, false if some kind of error happened. In that case, an error toast is displayed
 * automatically.
 * @param project The project to upload the files to.
 */
export function useOnSelectBlinkFiles(project: SdbProject): ReturnFunctionOnSelectFiles {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const uploadErrorToast = useUploadErrorToast();
  const uploadTasks = useAppSelector(uploadTasksSelector);
  const { uploadManager } = useFileUploadContext();
  const projectApiClient = useProjectApiClient({ projectId: project.id });

  const uploadMultipleScans = useUploadMultipleScans(project);
  const readLsDataV2 = useReadLsDataV2(project.id);

  const allowedExtensions = ALLOWED_EXTENSIONS_ALL;

  return useCallback(
    async (
      selectedFiles: FileList | File[],
      uploadedIdsMap: { [key: APITypes.UUID]: boolean }
    ): Promise<boolean> => {
      // We want to track the action of selecting the files, so it's better to track before any validation.
      // If/when the upload actually starts, we track another event: DataManagementEvents.startUpload.
      trackEvent({
        name: DataManagementEvents.selectFiles,
        props: filesInfoForTracking(selectedFiles.length ? [...selectedFiles] : []),
      });

      // Validate files before uploading.
      const allowedFiles: File[] = [];
      for (const file of selectedFiles) {
        const result = isValidFile({ file, allowedExtensions, maxFileSize: MAX_FILE_SIZE_IN_MB });
        if (result.isValid) {
          allowedFiles.push(file);
        }
      }

      const glsFiles = allowedFiles.filter((file) => isGLS(file.name));
      if (glsFiles.length === 0) {
        uploadErrorToast(UploadErrorToastType.noFiles);
        return false;
      }

      const lsDataV2Files = getLsDataV2Package(allowedFiles);
      if (!lsDataV2Files?.isValid) {
        uploadErrorToast(UploadErrorToastType.invalidMetadata);
        return false;
      }

      const lsDataV2 = await readLsDataV2(lsDataV2Files);
      if (!lsDataV2) {
        uploadErrorToast(UploadErrorToastType.invalidMetadata);
        return false;
      }

      const hasInvalidFile = glsFiles.some((file) => !isValidGlsFile(file, lsDataV2));
      if (hasInvalidFile) {
        uploadErrorToast(UploadErrorToastType.invalidFormat);
        return false;
      }

      const filesDuplicate = getFilesWithDuplicateNames(glsFiles);
      if (0 < filesDuplicate.size) {
        uploadErrorToast(UploadErrorToastType.duplicateFiles);
        return false;
      }

      const scansAlreadyUploaded = getScansAlreadyUploaded(glsFiles, lsDataV2, uploadedIdsMap);
      // If all scans were already uploaded before, we don't have anything to do.
      const hasOnlyExistingScans = glsFiles.every((file) => scansAlreadyUploaded.has(file));
      if (hasOnlyExistingScans) {
        uploadErrorToast(UploadErrorToastType.noNewFiles);
        return false;
      }

      const glsFilesSorted = sortFiles(glsFiles);
      const glsFilesToUpload = glsFilesSorted.filter((file) => !scansAlreadyUploaded.has(file));

      try {
        const {
          captureTreeRevisionId, captureTreeRootAndClustersByUuid, captureTreeMaps,
        } = await createRevisionForElsScans(
          projectApiClient, lsDataV2, glsFilesToUpload
        );

        // Cleaning up old upload tasks should be done after createRevisionForElsScans to minimize the time during
        // which the "old" state is active again before the state will be set to uploading.
        try {
          cleanupUploadTasks(uploadTasks, uploadManager);
        } catch (e) {
          // Nothing. Cleaning up the upload tasks isn't essential.
        }

        // Checking for existing revisions takes some time, so we "await" here to make sure that the spinner is shown.
        // The actual upload of the scans is done in the background.
        await uploadMultipleScans(captureTreeRevisionId, captureTreeRootAndClustersByUuid,
          captureTreeMaps.scanIdByUuid, glsFilesSorted, lsDataV2, scansAlreadyUploaded);
      } catch (error) {
        handleErrorWithToast({
          id: `createRevisionForElsScans-${Date.now().toString()}`,
          title: "Failed to prepare the data import. Please try again.",
          error,
        });
      }
      return true;
    },
    [
      readLsDataV2,
      trackEvent,
      allowedExtensions,
      uploadMultipleScans,
      handleErrorWithToast,
      uploadErrorToast,
      uploadManager,
      uploadTasks,
      projectApiClient,
    ]
  );
}
