import { ProjectApi } from "@api/project-api/project-api";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import {
  fetchAllCaptureTreeRevisions,
  fetchCaptureTree,
  fetchCaptureTreeForOpenDraftRevision,
} from "@store/capture-tree/capture-tree-thunks";
import { setFetchCaptureTreeDataError } from "@store/capture-tree/capture-tree-slice";
import { AppDispatch, useAppDispatch } from "@store/store-helper";
import { useCallback, useEffect } from "react";
import { usePolling } from "@hooks/use-polling";
import { ProgressApiClient } from "@api/progress-api/progress-api-client";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { updateBackgroundTasks, UpdateBackgroundTasksResult } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { resetSdbBackgroundTasksState } from "@store/sdb-background-tasks/sdb-background-tasks-slice";
import { isOpenDraftRevision } from "@utils/capture-tree/capture-tree-utils";
import { sentryCaptureError } from "@src/utils/sentry-utils";
import { CaptureTreeEntity } from "@faro-lotv/service-wires";
import { CaptureTreeRevision, CaptureTreeEntityWithRevisionStatus } from "@custom-types/capture-tree/capture-tree-types";

/**
 * Custom hook that polls data required for the Data Management page
 */
export function useDataManagement({ projectId }: BaseProjectIdProps): void {
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({ projectId });
  const progressApiClient = useProgressApiClient({ projectId });

  /**
   * Fetches:
   * - The capture tree entities for the current main/draft revision of the current project
   * - The revisions of the current project
   * - The ProgressAPI tasks of the current project
   * Not currently done anymore (see comment):
   *   - The published ELS point cloud ielements, which is used to generate the PublishedData entities
   *   - The scan ielements and all their children ielements (PointCloudElsRaw, PointCloudE57, PointCloudStream, etc.)
   * The presence of the PointCloudStream as children of the scan indicates that the scan has been processed.
   *
   * @throws {error} if the requests to fetch capture tree data fail.
   */
  const fetchCaptureTreeDataCallback = useCallback(async (): Promise<void> => {
    await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient);
  }, [dispatch, progressApiClient, projectApiClient]);

  /**
   * Error handler callback, if the 1st try fails.
   * We show an error page in this case, since we don't know the current state of the project.
   */
  const errorHandlerFirstLoad = useCallback((error: unknown): void => {
    dispatch(setFetchCaptureTreeDataError(true));
    sentryCaptureError({
      title: "CaptureTreeError: Failed to fetch capture tree data (on first load)",
      error,
    });
  }, [dispatch]);

  usePolling({
    callback: fetchCaptureTreeDataCallback,
    errorHandlerFirstLoad,
    errorHandler: undefined,
    maxAttemptsMsg: "Failed to fetch the uploaded data for this project! Please reload the page and try again.",
  });

  /**
   * Resets the background tasks store on component unmount.
   * This is done to avoid any potential problem with the Project Activity tab, that tab shows all background tasks
   * and since we already fetched some tasks it might interfere with how pagination works in Activity tab.
   */
  useEffect(() => {
    // To avoid problems in the other direction (Activity -> Data Management), also reset the store on mount.
    dispatch(resetSdbBackgroundTasksState());

    return () => {
      dispatch(resetSdbBackgroundTasksState());
    };
  }, [dispatch]);
}

/** Result of fetchMainEntitiesAndTasks(). */
interface FetchMainEntitiesAndTasksResult {
  mainEntities: CaptureTreeEntity[];
  tasks: UpdateBackgroundTasksResult;
}

/** Result of fetchRevisionsAndDraftEntities(). */
interface FetchRevisionsAndDraftEntitiesResult {
  revisions: CaptureTreeRevision[];
  openDraftRevision: CaptureTreeRevision | undefined;
  openDraftEntities: CaptureTreeEntityWithRevisionStatus[];
}

/** Result of fetchCaptureTreeData(). */
interface FetchCaptureTreeDataResult extends FetchMainEntitiesAndTasksResult, FetchRevisionsAndDraftEntitiesResult {}

/** Slimmer version of fetchCaptureTreeData(), for main capture tree + tasks. */
export async function fetchMainEntitiesAndTasks(
  dispatch: AppDispatch,
  projectApiClient: ProjectApi,
  progressApiClient: ProgressApiClient
): Promise<FetchMainEntitiesAndTasksResult> {
  const mainEntitiesPromise = dispatch(
    fetchCaptureTree({ projectApiClient })
  ).unwrap();

  // Fetch the ITEMS_PER_PAGE latest background tasks = the most recently updated (or created?) ones.
  const tasksPromise = dispatch(
    updateBackgroundTasks({
      progressApiClient,
      projectApiClient,
      beforePage: null,
      // We could set this, but it seems like we're already getting the last page, even when coming from the Activity tab.
      // See https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/commit/5b7610fdbfa29a861021fb4628431472b2b83e4e
      //     https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/pullrequest/26538
      // shouldOverrideBeforePage: true,
      shouldFetchIElements: false,
    })
  ).unwrap();

  const [mainEntities, tasks] = await Promise.all([mainEntitiesPromise, tasksPromise]);
  return { mainEntities, tasks };
}

/** Slimmer version of fetchCaptureTreeData(), for revisions + draft entities. */
export async function fetchRevisionsAndDraftEntities(
  dispatch: AppDispatch,
  projectApiClient: ProjectApi
): Promise<FetchRevisionsAndDraftEntitiesResult> {
  const revisions = await dispatch(
    fetchAllCaptureTreeRevisions({ projectApiClient })
  ).unwrap();

  const openDraftRevision = revisions.find(isOpenDraftRevision);
  const openDraftEntities = await dispatch(
    fetchCaptureTreeForOpenDraftRevision({
      projectApiClient,
      openDraftRevisionId: openDraftRevision?.id ?? null,
    })
  ).unwrap();

  return { revisions, openDraftRevision, openDraftEntities };
}

/**
 * Fetches the capture tree data required for the Data Management page.
 *
 * @param dispatch - The Redux dispatch function
 * @param projectApiClient - The project API client
 * @param progressApiClient - The progress API client
 */
export async function fetchCaptureTreeData(
  dispatch: AppDispatch,
  projectApiClient: ProjectApi,
  progressApiClient: ProgressApiClient
): Promise<FetchCaptureTreeDataResult> {
  const [mainEntitiesTasks, revisionsDraft]  = await Promise.all([
    fetchMainEntitiesAndTasks(dispatch, projectApiClient, progressApiClient),
    fetchRevisionsAndDraftEntities(dispatch, projectApiClient),
  ]);

  const { mainEntities, tasks } = mainEntitiesTasks;
  const { revisions, openDraftRevision, openDraftEntities } = revisionsDraft;

  return {
    revisions,
    openDraftRevision,
    openDraftEntities,
    mainEntities,
    tasks,
  };
}
