import { GroupHeaders } from "@components/table/groups/groups-table-utils";
import { GroupTypes } from "@custom-types/group-types";
import { useMediaQueryList } from "@hooks/use-media-query";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { useAppDispatch } from "@store/store-helper";
import { FaroTable } from "@components/common/faro-table/faro-table";
import { useEffect } from "react";
import { resetTableState, setTableType } from "@store/table/table-slice";
import { TableType } from "@utils/track-event/track-event-list";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { getGroupsColumns } from "@components/table/groups/groups-columns";
import { GroupBulkButtonComponents } from "@components/common/faro-table/faro-table-types";
import { useDateTime } from "@hooks/use-date-time";

interface Props extends BaseCompanyIdProps {
  /** The groups that is shown in the table */
  groups: GroupTypes[];

  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: GroupHeaders[];

  /** List of all the bulk actions and callbacks */
  bulkActionButtons?: GroupBulkButtonComponents;
}

/** Listing all the groups of a company */
export function GroupsTable({
  companyId,
  groups,
  isLoading,
  requiredColumns,
  bulkActionButtons,
}: Props): JSX.Element {
  const { navigateToGroupDetails } = useAppNavigation();
  const dispatch = useAppDispatch();
  const { isMedium, isScreenLgAndLarger } = useMediaQueryList();
  const { formatDate } = useDateTime();

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    dispatch(setTableType(TableType.groups));

    return () => {
      dispatch(resetTableState());
    };
  }, [dispatch]);

  return (
    <FaroTable
      entities={groups}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      columns={requiredColumns.map(
        (requiredColumn) =>
          getGroupsColumns({
            options: {
              isScreenLgOrLarger: isScreenLgAndLarger,
              isMedium,
            },
            formatDate,
          })[requiredColumn]
      )}
      sx={{
        "& .MuiDataGrid-row": {
          cursor: "pointer",
        },
      }}
      onRowClick={(id) =>
        navigateToGroupDetails({
          companyId,
          groupId: id,
        })
      }
      bulkActionButtons={bulkActionButtons}
      rowHeight={70}
    />
  );
}
