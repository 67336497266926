import { FaroButtonContained } from "@components/common/faro-button-contained";
import { useAppParams } from "@router/router-helper";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { TeamEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useState } from "react";
import { FaroButton } from "@components/common/faro-button";
import { CreateTeamDialog } from "@pages/members/teams/create-team-dialog";

interface Props {
  /** Should show the contained button or not */
  shouldUseContainedButton?: boolean;
}

/** Renders the create button and dialog to create team */
export function CreateTeam({
  shouldUseContainedButton = true,
}: Props): JSX.Element {
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  /** Open the dialog. */
  function onOpenDialog(): void {
    trackEvent({ name: TeamEvents.startCreateNewTeam });
    setIsDialogOpen(true);
  }

  return (
    <>
      {shouldUseContainedButton ? (
        <FaroButtonContained onClick={onOpenDialog}>
          New {TEAM_DISPLAY_NAME}
        </FaroButtonContained>
      ) : (
        <FaroButton onClick={onOpenDialog}> New {TEAM_DISPLAY_NAME}</FaroButton>
      )}

      {/* Create project dialog */}
      {companyId && (
        <CreateTeamDialog
          companyId={companyId}
          isDialogOpen={isDialogOpen}
          onCloseDialog={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
}
