import { RootState } from "@store/store-helper";
import { membersAdapter } from "@store/members/members-slice";
import { MemberTypes } from "@custom-types/member-types";
import { createSelector } from "@reduxjs/toolkit";
import { getPrettyName } from "@utils/user-utils";
import { APITypes, CoreAPITypes } from "@stellar/api-logic";
import { MembersState } from "@src/store/members/members-slice-helper";

/**
 * Returns all the stored members. It filter out the teams and only return the members
 */
export const companyMembersSelector: (state: RootState) => MemberTypes[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return membersAdapter
        .getSelectors()
        .selectAll(state.members)
        .filter((member) => member.kind === "user");
    }
  );

/**
 * Returns all the stored teams as member type. It filter out the members and only return the teams
 */
export const companyTeamsAsMemberSelector: (state: RootState) => MemberTypes[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return membersAdapter
        .getSelectors()
        .selectAll(state.members)
        .filter((member) => member.kind === "user-group");
    }
  );

/**
 * Returns all the stored members filtered by search text.
 */
export const filteredCompanyMembersSelector: (
  state: RootState
) => MemberTypes[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const searchText = state.ui.search.debouncedSearchText.toLowerCase();
    return companyMembersSelector(state).filter((member) => {
      return (
        member.email.toLowerCase().includes(searchText) ||
        // Use pretty name, in case the user types the full name in the search bar.
        getPrettyName(member).toLowerCase().includes(searchText)
      );
    });
  }
);

/**
 * Returns the fetching properties of the members slice.
 */
export const fetchingMembersFlagsSelector: (
  state: RootState
) => MembersState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.members.fetching;
  }
);

/**
 * Returns the fetched properties of the members slice.
 */
export const fetchedMembersFlagsSelector: (
  state: RootState
) => MembersState["fetched"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.members.fetched;
  }
);

/**
 * Get company member with the provided role
 */
export function companyMembersByRoleSelector(
  userCompanyRole: CoreAPITypes.EUserCompanyRole
): (state: RootState) => MemberTypes[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedMembers = companyMembersSelector(state);

      return selectedMembers.filter(({ role }) => role === userCompanyRole);
    }
  );
}

/**
 * Get single member with the provided member id.
 * The id is not the actual member id, but the id used to store the member in the store,
 * which is either identity or email.
 *
 * @see getMemberId
 */
export function companyMemberSelector(
  memberId: APITypes.UserId | undefined
): (state: RootState) => MemberTypes | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!memberId) {
        return null;
      }
      return (
        membersAdapter.getSelectors().selectById(state.members, memberId) ??
        null
      );
    }
  );
}
