import { CoreAPITypes } from "@stellar/api-logic";

/**
 * Gets the disabled roles based on the selected role.
 * For example an enterprise executive can't be downgraded to a project manager.
 *
 * @param selectedRole The current selected role to check
 * @returns Array filled with the roles that should be disabled
 * @see https://faro01.atlassian.net/wiki/spaces/HBWEBDEV/pages/4473782411/Workspace+Role+Hierarchy
 */
export function getDisabledRoles(
  selectedRole: CoreAPITypes.EUserCompanyRole
): CoreAPITypes.EUserCompanyRole[] {
  switch (selectedRole) {
    case CoreAPITypes.EUserCompanyRole.companyExecutive:
      return [
        CoreAPITypes.EUserCompanyRole.companyExecutive,
        CoreAPITypes.EUserCompanyRole.companyManager,
        CoreAPITypes.EUserCompanyRole.projectManager,
      ];
    case CoreAPITypes.EUserCompanyRole.companyViewer:
      return [
        CoreAPITypes.EUserCompanyRole.companyViewer,
        CoreAPITypes.EUserCompanyRole.companyManager,
        CoreAPITypes.EUserCompanyRole.projectManager,
      ];
    case CoreAPITypes.EUserCompanyRole.companyManager:
      return [
        CoreAPITypes.EUserCompanyRole.companyManager,
        CoreAPITypes.EUserCompanyRole.projectManager,
        CoreAPITypes.EUserCompanyRole.member,
      ];
    case CoreAPITypes.EUserCompanyRole.projectManager:
      return [
        CoreAPITypes.EUserCompanyRole.companyManager,
        CoreAPITypes.EUserCompanyRole.projectManager,
        CoreAPITypes.EUserCompanyRole.member,
      ];
    case CoreAPITypes.EUserCompanyRole.member:
      return [
        CoreAPITypes.EUserCompanyRole.companyManager,
        CoreAPITypes.EUserCompanyRole.projectManager,
        CoreAPITypes.EUserCompanyRole.member,
      ];
    default:
      return [];
  }
}
