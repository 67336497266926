import {
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMatches, Link as RouterLink, useLocation } from "react-router-dom";

import { sphereColors } from "@styles/common-colors";
import { SdbProject } from "@custom-types/project-types";
import { useAppSelector } from "@store/store-helper";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { RouteParams } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { BreadcrumbHandle } from "@utils/faro-breadcrumb-utils";
import { shortenText } from "@utils/string-utils";
import { sidebarSelector } from "@store/ui/ui-selector";
import { getGroupByIdSelector } from "@store/groups/groups-selector";
import { GroupTypes } from "@custom-types/group-types";
import { MemberTypes } from "@custom-types/member-types";
import { companyMemberSelector } from "@store/members/members-selector";
import { currentUserSelector } from "@store/user/user-selector";
import { BreadcrumbEvents } from "@utils/track-event/track-event-list";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { getTeamByIdSelector } from "@store/teams/teams-selector";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";

/** Extended definition when using useMatches to provide types to the attributes that we know. */
interface MatchObject<HandleT> extends ReturnType<typeof useMatches> {
  /** id of the route, supplied by react-router */
  id: string;
  /** the path of the matched route, defined by us in the routes */
  pathname: string;
  /** route params, defined in the routes */
  params: Partial<RouteParams>;
  /** the data object, which contains data returned by the loader of the route */
  data: unknown;
  /** a handle that includes the breadcrumb for the route  as the defined by the type BreadcrumbHandle */
  handle: HandleT;
}

/** Defines the data included to the breadcrumb handle. */
export interface BreadcrumbHandleData {
  /**
   * Includes the selected project object if applicable to the route, null otherwise.
   */
  selectedProject: SdbProject | null;

  /**
   * Includes the selected group object if applicable to the route.
   */
  selectedGroup?: GroupTypes;

  /**
   * Includes the selected company member if applicable to the route
   */
  selectedMember?: MemberTypes | null;

  /**
   * Includes the selected team if applicable to the route
   */
  selectedTeam?: SphereDashboardAPITypes.ITeam | null;

  /**
   * Includes the current user.
   */
  currentUser?: SphereDashboardAPITypes.ICompanyMemberBase | null;

  /**
   * Includes the data coming in the route from useLoaderData
   *
   * @see https://reactrouter.com/en/main/hooks/use-loader-data
   */
  loaderData?: unknown;

  /** Get the query params for the breadcrumbs */
  queryParams?: string;
}

export type Breadcrumb = {
  /** if this is set, the breadcrumb would be a clickable router-link */
  link?: string;

  /** the text of the breadcrumb */
  text: string;

  /** If this property is present the text should not be translated with localize */
  shouldNotTranslate?: true;

  /** Optional property to disable the breadcrumb */
  isDisabled?: boolean;
};

/** A dynamic breadcrumbs component that renders a collapsed breadcrumbs using the handles provided in the routes */
export function FaroBreadcrumbs(): JSX.Element {
  const matches = useMatches() as Array<MatchObject<BreadcrumbHandle>>;
  const location = useLocation();
  const queryParams = location.search;

  const { trackEvent } = useTrackEvent();
  const { projectId, groupId, memberId, teamId } = useAppParams();

  const selectedProject = useAppSelector(getProjectByIdSelector(projectId));
  const selectedGroup = useAppSelector(getGroupByIdSelector(groupId));
  const selectedMember = useAppSelector(companyMemberSelector(memberId));
  const selectedTeam = useAppSelector(getTeamByIdSelector(teamId));
  const currentUser = useAppSelector(currentUserSelector);
  const sidebar = useAppSelector(sidebarSelector);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isScreenMedium = useMediaQuery(theme.breakpoints.only("md"));
  const isScreenLarge = useMediaQuery(theme.breakpoints.only("lg"));
  const isScreenExtraLarge = useMediaQuery(theme.breakpoints.only("xl"));

  /** Size in pixels for the breadcrumb wrapper element. */
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const breadcrumbsMaxSize = sidebar.isFullSize ? 378 : 220;

  /** Holds how many characters should be allowed per breadcrumb item */
  let maxBreadcrumbChars: number;

  if (isScreenSmall) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxBreadcrumbChars = sidebar.isFullSize ? 11 : 20;
  } else if (isScreenMedium) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxBreadcrumbChars = sidebar.isFullSize ? 28 : 36;
  } else if (isScreenLarge) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxBreadcrumbChars = sidebar.isFullSize ? 44 : 53;
  } else if (isScreenExtraLarge) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxBreadcrumbChars = sidebar.isFullSize ? 63 : 71;
  } else {
    // in XS sizes we don't show them
    maxBreadcrumbChars = sidebar.isFullSize ? 0 : 0;
  }

  const breadcrumbs = matches
    // get rid of any matches that don't have handle and breadcrumb
    .filter((match) => Boolean(match.handle?.breadcrumb))
    // get the breadcrumbs
    .map((match) =>
      typeof match.handle.breadcrumb === "function"
        ? match.handle.breadcrumb(match.params, {
            selectedProject,
            selectedGroup,
            selectedMember,
            selectedTeam,
            currentUser,
            loaderData: match.data,
            queryParams,
          })
        : match.handle.breadcrumb
    )
    // flatten the array because the breadcrumb can be an array of breadcrumbs
    .flat()
    // get rid of any null or undefined breadcrumbs or breadcrumb.text
    .filter((match) => Boolean(match?.text)) as Breadcrumb[];

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<Typography sx={{ marginX: 2 }}>/</Typography>}
      sx={{
        // Do not allow the width to overtake other components.
        maxWidth: `calc(100vw - ${breadcrumbsMaxSize}px)`,
      }}
    >
      {breadcrumbs.map((item, i) => {
        const itemText = shortenText({
          value: (item.text ?? "").toLocaleUpperCase(),
          maxChars: maxBreadcrumbChars,
        });
        /* If the item contains a link and it is not the last one, make it clickable */
        if (item.link && i < breadcrumbs.length - 1) {
          return (
            <Link
              key={i}
              component={RouterLink}
              underline="none"
              color={sphereColors.gray600}
              to={item.link}
              sx={{
                pointerEvents: item.isDisabled ? "none" : "auto",
                opacity: item.isDisabled ? "0.5" : "1",
                fontSize: "10px",
                letterSpacing: "0.5px",
                wordBreak: "break-all",
                fontWeight: 800,
                "&:hover": {
                  color: sphereColors.gray800,
                },
              }}
              onClick={() =>
                trackEvent({
                  name: BreadcrumbEvents.navigateWithBreadcrumb,
                })
              }
            >
              {item.shouldNotTranslate ? (
                // The <div> is necessary to prevent a displacement of the text towards the bottom.
                <div><var>{itemText}</var></div>
              ) : (
                <div>{itemText}</div>
              )}
            </Link>
          );
        } else {
          return (
            <Typography
              key={i}
              color={
                i === breadcrumbs.length - 1
                  ? sphereColors.gray800
                  : sphereColors.gray600
              }
              sx={{
                fontSize: "10px",
                letterSpacing: "0.5px",
              }}
            >
              <strong
                className={
                  item.shouldNotTranslate ? NO_TRANSLATE_CLASS : undefined
                }
                style={{
                  fontWeight: 800,
                  wordBreak: "break-all",
                }}
              >
                {itemText}
              </strong>
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}
