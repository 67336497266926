import { useCallback } from "react";
import { ProjectApi } from "@api/project-api/project-api";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import {
  ElsScanFileUploadTaskContext,
  UploadedFile,
  UploadFailedFile,
} from "@custom-types/file-upload-types";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useToast } from "@hooks/use-toast";
import { BackgroundTaskState, RegistrationState } from "@faro-lotv/service-wires";
import { FailedUploadsToastContent } from "@pages/project-details/project-data-management/import-data/failed-uploads-toast-content";
import { addScansToRevisionAndMergeHelper } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { UploadErrorToastType, useUploadErrorToast } from "@hooks/data-management/use-upload-error-toast";
import { sentryCaptureError } from "@src/utils/sentry-utils";
import { useAppDispatch } from "@store/store-helper";
import { updateOne as updateOneUploadTask } from "@store/upload-tasks/upload-tasks-slice";

type ReturnFunction = (
  successfulUploads: UploadedFile[],
  failedUploads: UploadFailedFile[],
  projectApiClient: ProjectApi,
  context: ElsScanFileUploadTaskContext
) => Promise<boolean>;

/**
 * Handles the logic after the upload of ELS scan succeeded:
 * - Checks if the revision was already canceled, e.g. via "Cancel Import" button, and then returns silently.
 * - Adds (creates) successful uploads to the specified cluster of the project revision.
 * - Updates revision to "registered" status and merges it to the main revision.
 * - If it fails it will then set the revision as canceled.
 *
 * Returns false if the import was already canceled.
 * Returns true otherwise (for both success or failure).
 */
export function useAddScansToRevisionAndMerge(): ReturnFunction {
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const cancelRevision = useCancelRevision();
  const uploadErrorToast = useUploadErrorToast();
  const dispatch = useAppDispatch();

  const failSuccessfulUploads = useCallback(
    (successfulUploads: UploadedFile[]) => {
      const errorMessage = "Failed to add completed uploads to project.";
      for (const upload of successfulUploads) {
        dispatch(updateOneUploadTask({
          id: upload.id,
          changes: {
            status: BackgroundTaskState.failed,
            errorMessage,
          },
        }));
      }
    },
    [dispatch]
  );

  return useCallback(
    async (
      successfulUploads: UploadedFile[],
      failedUploads: UploadFailedFile[],
      projectApiClient: ProjectApi,
      context: ElsScanFileUploadTaskContext
    ): Promise<boolean> => {
      // If revision was already canceled, e.g. via "Cancel Import" button, return silently.
      try {
        const revision = await projectApiClient.getRegistrationRevision(context.captureTreeRevisionId);
        if (revision.state === RegistrationState.canceled) {
          return false;
        }
      } catch (error) {
        // The check is not essential, so we ignore the error and try to proceed with merging.
        sentryCaptureError({
          title: "RevisionError: Failed to get revision details. We assume the revision is not canceled.",
          error,
        });
      }

      try {
        await addScansToRevisionAndMergeHelper(projectApiClient, context);

        // Show a warning toast with the list of files that failed to upload
        if (failedUploads.length) {
          showToast({
            message: "Data import was partially successful",
            type: "warning",
            shouldAutoHide: false,
            description: (
              <FailedUploadsToastContent failedUploads={failedUploads}/>
            ),
          });
        }
      } catch (error: unknown) {
        if ((error as Error)?.message === UploadErrorToastType.noNewFiles) {
          uploadErrorToast(UploadErrorToastType.noNewFiles);
        } else {
          handleErrorWithToast({
            id: `addScansToRevisionAndMerge-${Date.now().toString()}`,
            title: "Failed to add imported data to the project. Please try to import the data again.",
            error,
          });
        }

        // Attempt to set the revision as canceled.
        // Does not throw, handles the error internally.
        await cancelRevision(projectApiClient, context.captureTreeRevisionId);

        // Make sure that the UI is not stuck in "uploading" state, so the user can retry the import.
        failSuccessfulUploads(successfulUploads);
      }
      return true;
    },
    [cancelRevision, failSuccessfulUploads, handleErrorWithToast, showToast, uploadErrorToast]
  );
}
