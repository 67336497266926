import { TabsWithRoutes } from "@components/common/tabs/tabs-with-routes";
import { ProjectArchivingState } from "@custom-types/project-types";
import {
  fetchingProjectsFlagsSelector,
  projectsSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { isProjectDemo } from "@utils/project-utils";
import { ProjectsOverviewDemoEmptyPage } from "@components/common/empty-page/projects-overview-demo-empty-page";
import { useCallback, useEffect, useMemo } from "react";
import { fetchProjects } from "@store/projects/projects-slice-thunk";
import { useCoreApiClient } from "@api/use-core-api-client";
import { APITypes } from "@stellar/api-logic";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { AuthenticatedProjectsOverview } from "@pages/projects/authenticated-projects-overview";
import { ProjectsTabActionButtons } from "@pages/projects/projects-tab-action-buttons";
import { setAllProjects } from "@store/projects/projects-slice";
import { convertProjectTypesToSdbProject } from "@store/projects/projects-slice-utils";

interface Props extends BaseCompanyIdProps {
  /** Archiving status of the current projects section */
  projectStatus: ProjectArchivingState;
}

/**
 * Content in the projects page
 */
export function ProjectsPage({
  companyId,
  projectStatus,
}: Props): JSX.Element {
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { isFetchingProjects } = useAppSelector(fetchingProjectsFlagsSelector);
  const projects = useAppSelector(projectsSelector);
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();

  const shouldShowDemoEmptyPage = useMemo(() => {
    if (!isFetchingProjects && projects.length === 1) {
      const project = projects[0];

      return (
        // Only show the "Demo empty page" screen if the user is in the active tab and the demo project is active
        projectStatus === ProjectArchivingState.active &&
        isProjectDemo(project) &&
        project.archivingState === APITypes.ArchivingState.UNARCHIVED
      );
    }

    return false;
  }, [isFetchingProjects, projectStatus, projects]);

  // Fetches the first page of both active and archived projects
  // Sets the projects in the redux store with setAll in order to also reset the store
  const fetchFirstPage = useCallback(async (): Promise<void> => {
    const activePromise = dispatch(fetchProjects({
      coreApiClient,
      companyId,
      projectArchivingState: ProjectArchivingState.active,
    })).unwrap();

    const archivedPromise = dispatch(fetchProjects({
      coreApiClient,
      companyId,
      projectArchivingState: ProjectArchivingState.archived,
    })).unwrap();

    try {
      const [active, archived] = await Promise.all([activePromise, archivedPromise]);
      const projects = [...active.projects, ...archived.projects].map(convertProjectTypesToSdbProject);
      dispatch(setAllProjects(projects));
    } catch (_) {
      // The error slice will take care of logging and displaying the error to the user
    }
  }, [companyId, coreApiClient, dispatch]);

  // Fetches the 1st page of both active and archived projects
  useEffect(() => {
    void fetchFirstPage();
  }, [fetchFirstPage]);

  if (shouldShowDemoEmptyPage) {
    return (
      <ProjectsOverviewDemoEmptyPage
        demoProject={projects[0]}
        companyId={companyId}
      />
    );
  }

  return (
    <TabsWithRoutes
      selectedTab={projectStatus}
      dataTestId="PROJECTS_CONTAINER"
      selectedProject={selectedProject}
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openProjectsTab}
      tabs={[
        {
          label: "Active",
          route: ProjectArchivingState.active,
          content: () => AuthenticatedProjectsOverview({
            companyId,
            projectArchivingState: ProjectArchivingState.active,
            shouldForceLoading: false,
          }),
          loadingContent: AuthenticatedProjectsOverview({
            companyId,
            projectArchivingState: ProjectArchivingState.active,
            shouldForceLoading: true,
          }),
          actionButtons: <ProjectsTabActionButtons />,
        },
        {
          label: "Archived",
          route: ProjectArchivingState.archived,
          content: () => AuthenticatedProjectsOverview({
            companyId,
            projectArchivingState: ProjectArchivingState.archived,
            shouldForceLoading: false,
          }),
          loadingContent: AuthenticatedProjectsOverview({
            companyId,
            projectArchivingState: ProjectArchivingState.archived,
            shouldForceLoading: true,
          }),
          actionButtons: <ProjectsTabActionButtons />,
        },
      ]}
    />
  );
}
