import { useCallback } from "react";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import {
  MultiUploadedFileResponse,
  PhotogrammetryUploadTaskContext,
  UploadElementType,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { ReturnFunctionOnSelectFiles } from "@hooks/data-management/use-on-select-any-files";
import { UploadErrorToastType, useUploadErrorToast } from "@hooks/data-management/use-upload-error-toast";
import { isValidFile } from "@hooks/file-upload-utils";
import { useFileUpload } from "@hooks/use-file-upload";
import { filesInfoForTracking } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { useSendMutation } from "@pages/project-details/project-data-management/photogrammetry-data/photogrammetry-data-mutation";
import { APITypes } from "@stellar/api-logic";
import { FILE_SIZE_MULTIPLIER } from "@utils/file-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { assert } from "@faro-lotv/foundation";
import { isPhotogrammetryUploadTaskContext } from "@custom-types/file-upload-type-guards";

interface UploadResult {
  uploadedResponse: MultiUploadedFileResponse;
  /** The context of the file upload task. */
  context: PhotogrammetryUploadTaskContext;
}

// Move allowedExtensions outside the component
const ALLOWED_EXTENSIONS = ["jpg", "jpeg"];

const MAX_FILE_SIZE_IN_MB = 20;
const MAX_FILE_SIZE_IN_BYTE = MAX_FILE_SIZE_IN_MB * FILE_SIZE_MULTIPLIER;

/**
 * Handles file selection, validates and uploads image files for photogrammetry processing,
 * and triggers a photogrammetry task after a successful upload.
 * 
 * @param project The project to upload files to.
 */

export function useOnSelectPhotogrammetryFiles(project: SdbProject): ReturnFunctionOnSelectFiles {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const uploadErrorToast = useUploadErrorToast();

  const { uploadMultipleFiles, validateAndAddFailedTask } = useFileUpload();

  const sendMutation = useSendMutation(project);

  return useCallback(
    async (
      selectedFiles: FileList | File[],
      // We don't (yet) have the "upload each file only once" feature.
      uploadedIdsMap: { [key: APITypes.UUID]: boolean }
    ): Promise<boolean> => {

      // Validate files before uploading.
      const context: PhotogrammetryUploadTaskContext = {
        uploadElementType: UploadElementType.photogrammetry,
        projectId: project.id,
      };
      const allowedFiles: File[] = [];
      for (const file of selectedFiles) {
        const result = isValidFile({ file, allowedExtensions: ALLOWED_EXTENSIONS, maxFileSize: MAX_FILE_SIZE_IN_MB });
        if (result.isValid) {
          allowedFiles.push(file);
        }
      }

      trackEvent({
        name: DataManagementEvents.selectFiles,
        props: filesInfoForTracking(allowedFiles.length ? [...allowedFiles] : []),
      });

      const validFiles = allowedFiles.filter((file) =>
        validateAndAddFailedTask({
          file,
          allowedExtensions: ALLOWED_EXTENSIONS,
          maxFileSize: MAX_FILE_SIZE_IN_BYTE,
          context,
        })
      );

      if (validFiles.length === 0) {
        uploadErrorToast(UploadErrorToastType.noFiles);
        return false;
      }

      async function uploadFiles(): Promise<UploadResult> {
        return new Promise<UploadResult>((resolve, reject) => {
          const uploadParams: UploadMultipleFilesParams = {
            files: validFiles,
            onUploadStart: () => undefined,
            onUploadProgress: () => undefined,
            onUploadComplete: (uploadedResponse, context) => {
              assert(isPhotogrammetryUploadTaskContext(context));
              resolve({ uploadedResponse, context });
            },
            context,
          };

          uploadMultipleFiles(uploadParams).catch((error) => {
            reject(error);
          });
        });
      }

      try {
        trackEvent({
          name: DataManagementEvents.startUpload,
          props: {
            type: UploadElementType.photogrammetry,
            ...filesInfoForTracking(validFiles),
          },
        });

        const result = await uploadFiles();
        const successfulUploads = result.uploadedResponse.successful;
        const failedUploads = result.uploadedResponse.failed;
        const canceledUploads = result.uploadedResponse.canceled;

        trackEvent({
          name: DataManagementEvents.finishUpload,
          props: {
            type: UploadElementType.photogrammetry,
            successfulUploads: successfulUploads.length,
            failedUploads: failedUploads.length,
            canceledUploads: canceledUploads.length,
          },
        });

        await sendMutation({
          selectedFiles: validFiles,
          selectedSheet: null,
          uploadedResponse: result.uploadedResponse,
        });

        // Success result will trigger the photogrammetry service.
        return true;
      } catch (error) {
        handleErrorWithToast({
          id: `uploadFileWithChunks-${Date.now().toString()}`,
          title: "Error uploading file",
          error,
        });
        return false;
      }
    },
    [
      project.id,
      validateAndAddFailedTask,
      uploadErrorToast,
      trackEvent,
      sendMutation,
      uploadMultipleFiles,
      handleErrorWithToast,
    ]
  );
}
