import { useAppSelector } from "@store/store-helper";
import { isProjectProcessingSelector } from "@store/projects/projects-selector";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { BaseProjectProps } from "@custom-types/project-types";
import { ProjectCardTop } from "@components/common/project-card/project-card-top";
import { CardMiddle } from "@components/common/project-card/project-card-middle";
import { ProjectCardBottom } from "@components/common/project-card/project-card-bottom";
import { FaroCard } from "@components/common/faro-card/faro-card";
import { generateProjectDetailsRoute } from "@router/router-helper";
import styles from "@components/table/projects/projects-table.module.css";
import { Link } from "react-router-dom";

interface Props extends BaseCompanyIdProps, BaseProjectProps {
  /** Callback when the card is clicked */
  onClick?: () => void;
}

/**
 * A card that summarizes the project info
 */
export function ProjectCard({
  companyId,
  project,
  onClick,
}: Props): JSX.Element {
  const isProjectProcessing = useAppSelector(
    isProjectProcessingSelector(project.id)
  );

  return (
    <Link
      to={ !isProjectProcessing ? generateProjectDetailsRoute(companyId, project.id) : "#" }
      onClick={() => !isProjectProcessing && onClick?.()}
      className={styles["removed-underline-tile"]}
    >
      <FaroCard
        isLoading={isProjectProcessing}
        // eslint-disable-next-line @typescript-eslint/no-empty-function -- Handled by the parent (Link) instead.
        onClick={() => {}}
      >
        {/* Top section of the card: Shows the project thumbnail image */}
        <ProjectCardTop project={project} />

        {/* Middle section of the card: Shows the project details like name and last update */}
        <CardMiddle project={project} />

        {/* Bottom section of the card: Shows the access level and the project actions */}
        <ProjectCardBottom project={project} />
      </FaroCard>
    </Link>
  );
}
