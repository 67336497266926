import { Markup, MarkupsKPIs } from "@custom-types/project-markups-types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import {
  getMarkup,
  getMarkups,
  getMarkupsKPIs,
} from "@store/markups/markups-selector-utils";
import {
  AdvancedMarkupTemplateIds,
  selectAdvancedMarkupTemplateIds,
  selectIElement,
} from "@faro-lotv/project-source";
import { GUID } from "@faro-lotv/foundation";
import { getUniqueMarkupProperties } from "@utils/markups-utils";
import { MemberTypes } from "@custom-types/member-types";
import { getMarkupAssigneeMember } from "@pages/project-details/project-markups/markup-assignee-utils";
import { useMembersUtils } from "@hooks/use-member-utils";
import { isIElementMarkupWithTypeHint } from "@custom-types/i-elements-type-guards";

/**
 * @returns the list of all markup entities of the selected project
 *
 * It filters out markups that have integrations with an external service like Bim360 or Procore
 */
export const markupsSelector: (state: RootState) => Markup[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return getMarkups(state);
  }
);

/**
 * @returns The markups KPIs
 */
export const markupsKPIsSelector: (state: RootState) => MarkupsKPIs =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const markups = markupsSelector(state);
      return getMarkupsKPIs(markups);
    }
  );

/**
 * @returns The template ids for specific project
 */
export const markupsTemplateIdsSelector: (
  state: RootState
) => AdvancedMarkupTemplateIds | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return selectAdvancedMarkupTemplateIds(state);
  }
);

/** Gets a markup entity by providing the markup ID */
export function getMarkupByIdSelector(
  id?: GUID
): (state: RootState) => Markup | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const markup = selectIElement(id)(state);
      if (markup && isIElementMarkupWithTypeHint(markup)) {
        return getMarkup(state, markup);
      }
    }
  );
}

/** Returns the unique values of the provided markup key */
export function markupsUniqueKeysSelector<V extends keyof Markup>(
  uniqueIdKey: V
): (state: RootState) => NonNullable<Markup[V]>[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const markups = markupsSelector(state);

      return getUniqueMarkupProperties({
        markups,
        uniqueIdKey,
      });
    }
  );
}

/** Returns the unique assignees of the existing markups in the type of MemberTypes */
export function markupsUniqueAssigneeSelector(): (
  state: RootState
) => MemberTypes[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const markups = markupsSelector(state);
      const { companyMembers, projectMembers } = useMembersUtils();

      const markupsAssignees = getUniqueMarkupProperties({
        markups,
        uniqueIdKey: "assignee",
      });

      // Markup assignees each have its own id. They might point to the same member by the id stored in "value" key.
      // So we need to check for the unique ones and get the memberTypes
      return markupsAssignees.reduce<MemberTypes[]>((uniqueMembers, member) => {
        const assigneeMember = getMarkupAssigneeMember({
          assignee: member,
          companyMembers,
          projectMembers,
        });
        const isMemberExist = uniqueMembers.some(
          (uniqueMember) => uniqueMember.id === assigneeMember?.id
        );

        if (!isMemberExist && assigneeMember) {
          uniqueMembers.push(assigneeMember);
        }

        return uniqueMembers;
      }, []);
    }
  );
}
