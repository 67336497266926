import { TokenProvider } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";
import { CLIENT_ID } from "@api/client-id";
import {
  BaseCompanyIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports -- One of the central places to import CoreApiTokenManager.
import { CoreApiTokenManager } from "@api/core-api-token-manager";

/** Map from stringified combination of company ID and project ID to cached token provider. */
const tokenProviderMap: Record<string, TokenProvider> = {};

/**
 * Returns a token provider for the given project ID and/or company ID. The instance is cached, so that the /auth/token endpoint
 * will be called only once per company and/or company and project as long as the token is valid.
 * @param companyId Optional company ID
 * @param projectId Optional project ID
 * @returns Function that returns a Promise for a token.
 */
export function getCachedCompanyTokenProvider(
  companyId?: APITypes.CompanyId,
  projectId?: APITypes.ProjectId
): TokenProvider {
  /** Using a key "-withOrWithout-" if companyId and projectId both not provided */
  const key = `${companyId || ""}-withOrWithout-${projectId || ""}`;
  if (!tokenProviderMap[key]) {
    const coreApiUrl = new URL(runtimeConfig.urls.apiBaseUrl);
    const tokenManager = new CoreApiTokenManager({
      coreApiUrl,
      companyId: companyId?.toString(),
      projectId: projectId?.toString(),
      clientId: CLIENT_ID,
      // Scopes to use in order to provide a user access to a project and company
      scopes: ["user:project", "company:insights"],
    });
    tokenProviderMap[key] = tokenManager.getToken.bind(tokenManager);
  }
  return tokenProviderMap[key];
}

/**
 * Returns a token provider for the given optionally company ID and/or project ID.
 * The instance is cached, so that the /auth/token endpoint
 * will be called only once per company and/or company and project as long as the token is valid.
 * @param companyId Optional company ID
 * @param projectId Optional project ID
 * @returns Function that returns a Promise for a token.
 */
export function useCachedCompanyTokenProvider({
  companyId,
  projectId,
}: Partial<BaseCompanyIdProps> & Partial<BaseProjectIdProps>): TokenProvider {
  // Make sure the client is only created once, unless companyId or projectId changes.
  return useMemo(
    () => getCachedCompanyTokenProvider(companyId, projectId),
    [companyId, projectId]
  );
}
