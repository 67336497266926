import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  BaseCoreApiClientProps,
  BaseEntityState,
  CoreApiWithCompanyIdProps,
} from "@store/store-types";
import {
  AddGroupProps,
  FetchGroupDetailsProps,
} from "@store/groups/groups-slice-helper";
import { GroupTypes } from "@custom-types/group-types";
import { BaseMemberAndTeamProps } from "@custom-types/member-types";
import { isGroupDetails } from "@custom-types/type-guards";
import {
  BaseCompanyIdProps,
  BaseGroupIdProps,
} from "@custom-types/sdb-company-types";
import { RootState } from "@store/store-helper";

/**
 * State of groups
 */
export interface GroupsState extends BaseEntityState<GroupTypes> {
  /** The selected group in which user is viewing */
  selectedGroupId: APITypes.GroupId | null;

  /** Collects all the fetching properties for this slice */
  fetching: {
    /** Indicates if groups are currently being fetched from the backend */
    isFetchingGroups: boolean;

    /**
     * Map of groups IDs where a process is currently ongoing for them.
     * The value is always true as we remove the entity if the group is not being processed anymore
     */
    processingGroups: { [key: EntityId]: true };

    /** Indicates if a group member is currently being removed from the backend */
    isRemovingGroupMember: boolean;

    /** Indicates if a group is currently being updated to the backend */
    isUpdatingGroups: boolean;
  };
}

/** Creates an entity adapter to store a map with all the groups that belong to the company. */
export const groupsAdapter: EntityAdapter<GroupTypes, EntityId> = createEntityAdapter();

const initialState: GroupsState = {
  ...groupsAdapter.getInitialState(),
  selectedGroupId: null,
  fetching: {
    isFetchingGroups: false,
    processingGroups: {},
    isRemovingGroupMember: false,
    isUpdatingGroups: false,
  },
};

/** Fetches groups from the backend so they can be put into the store */
export const fetchGroups = createAsyncThunk<
  SphereDashboardAPITypes.ICompanyGroup[],
  CoreApiWithCompanyIdProps
>("groups/fetchGroups", async ({ coreApiClient, companyId }) => {
  if (!companyId) {
    throw new Error("No companyId was given to fetchGroups");
  }

  try {
    const data = await coreApiClient.V3.SDB.getGroups(companyId);
    return data;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/** Fetches the details of a group */
export const fetchGroupDetails = createAsyncThunk<
  SphereDashboardAPITypes.IGroupDetails,
  FetchGroupDetailsProps
>("groups/fetchGroupDetails", async ({ coreApiClient, companyId, groupId }) => {
  try {
    const fetchedGroupDetails = await coreApiClient.V3.SDB.getGroupById(
      companyId,
      groupId
    );

    return fetchedGroupDetails;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/** Creates a group in the backend and adds it to the store */
export const addGroup = createAsyncThunk<
  SphereDashboardAPITypes.ICompanyGroup,
  AddGroupProps
>(
  "groups/addGroup",
  async ({ coreApiClient, companyId, groupName, selectedGroupManagerIds }) => {
    if (!companyId) {
      throw new Error("No companyId was given to addGroup");
    }

    try {
      const group = await coreApiClient.V3.SDB.addGroup(companyId, {
        name: groupName,
        assignments: [
          {
            identities: selectedGroupManagerIds,
            role: CoreAPITypes.EUserCompanyRole.companyManager,
          },
        ],
      });
      return group;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface UpdateGroupDetailsProps
  extends BaseCoreApiClientProps,
    Partial<BaseGroupIdProps> {
  /** The payload for updating group details */
  payload: SphereDashboardAPITypes.IUpdateGroupPayload;
}

/** Update group details */
export const updateGroupDetails = createAsyncThunk<
  SphereDashboardAPITypes.IGroupDetails,
  UpdateGroupDetailsProps,
  {
    state: RootState;
  }
>(
  "groups/updateGroupDetails",
  async ({ coreApiClient, groupId, payload }, { getState }) => {
    const {
      groups: { selectedGroupId },
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    /**
     * The ID of the group that is going to be updated.
     * If groupId is not provided, the selectedGroupId from store is selected
     */
    const updatingGroupId = groupId || selectedGroupId;

    if (!selectedSdbCompanyId || !updatingGroupId) {
      throw new Error(
        "No companyId or groupId exist to was given to updateGroupDetails"
      );
    }

    try {
      const updatedGroup = await coreApiClient.V3.SDB.updateGroupDetails({
        companyId: selectedSdbCompanyId,
        groupId: updatingGroupId,
        payload,
      });

      return updatedGroup;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

interface OnGroupRemovedCallback {
  /** Callback to be triggered to navigate to the groups page */
  onGroupRemoved: () => void;
}

type RemoveMemberResult = OnGroupRemovedCallback &
  BaseCompanyIdProps &
  BaseMemberAndTeamProps &
  BaseGroupIdProps;

type RemoveMemberProps = CoreApiWithCompanyIdProps &
  RemoveMemberResult &
  OnGroupRemovedCallback;

/**
 * Remove a member from the group using the backend,
 * and then removes it from group in the store as well
 */
export const removeMemberFromGroup = createAsyncThunk<
  RemoveMemberResult,
  RemoveMemberProps
>(
  "groups/removeMemberFromGroup",
  async ({ coreApiClient, companyId, groupId, member, onGroupRemoved }) => {
    try {
      await coreApiClient.V3.SDB.removeGroupMember({
        companyId,
        groupId,
        userId: member.identity,
      });
      return {
        groupId,
        companyId,
        member,
        onGroupRemoved,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Removes locally a member from a group, by removing it from members array
 * attribute of the group if members are available.
 */
function removeLocalMemberFromGroup(
  state: GroupsState,
  result: RemoveMemberResult
): void {
  const group = groupsAdapter.getSelectors().selectById(state, result.groupId);
  if (group && isGroupDetails(group)) {
    const newMembers = group.members?.filter(
      (member) => member.identity !== result.member.identity
    );

    groupsAdapter.updateOne(state, {
      id: group.id,
      changes: {
        members: newMembers,
      },
    });

    if (newMembers.length === 0) {
      groupsAdapter.removeOne(state, result.groupId);
      result.onGroupRemoved();
    }
  }
}

interface UpdateMemberRoleProps extends CoreApiWithCompanyIdProps {
  /** The group id where member role will be adjusted */
  groupId: APITypes.GroupId;

  /** New role to be adjusted */
  role: SphereDashboardAPITypes.GroupMemberCompanyRole;

  /**
   * Equal to email if user is invited to company, but not registered
   * Equal to id if user is registered
   */
  userIdentity: APITypes.UserIdentity;
}

interface UpdateMemberRoleResult {
  /** The group id where member role will be adjusted */
  groupId: APITypes.GroupId;

  /** New role to be adjusted */
  role: SphereDashboardAPITypes.GroupMemberCompanyRole;

  /**
   * Equal to email if user is invited to company, but not registered
   * Equal to id if user is registered
   */
  userIdentity: APITypes.UserIdentity;
}

/**
 * Update a member role in the group using the backend,
 * and then update it in group in the store as well
 */
export const updateMemberRoleInGroup = createAsyncThunk<
  UpdateMemberRoleResult,
  UpdateMemberRoleProps
>(
  "groups/updateMemberRoleInGroup",
  async ({ coreApiClient, companyId, groupId, role, userIdentity }) => {
    try {
      await coreApiClient.V3.SDB.updateGroupMemberRole({
        companyId,
        groupId,
        userIdentity,
        payload: { role },
      });

      return {
        groupId,
        role,
        userIdentity,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Update a member role in group store by updating it from members array attribute of the group.
 */
function updateMemberRoleInGroupStore(
  state: GroupsState,
  result: UpdateMemberRoleResult
): void {
  const group = groupsAdapter.getSelectors().selectById(state, result.groupId);

  // If the groups is in the store update the member role
  if (group && isGroupDetails(group)) {
    groupsAdapter.updateOne(state, {
      id: group.id,
      changes: {
        members: group.members?.map((member) => {
          if (member.identity === result.userIdentity) {
            return { ...member, role: result.role };
          }
          return member;
        }),
      },
    });
  }
}

/**
 * Slice to access state of loaded groups
 */
const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    /**
     * Accepts a single group entity and adds or replaces it.
     *
     * @param state store state
     * @param entity group to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setOne: groupsAdapter.setOne,
    /**
     * Accepts an array of group entities, and adds or replaces them.
     *
     * @param state store state
     * @param entity groups to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setMany: groupsAdapter.setMany,
    /**
     * Removes all group entities from the store.
     *
     * @param state store state
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeAll: groupsAdapter.removeAll,
    /**
     * Accepts an array of group IDs, and removes each group entity with those IDs if they exist.
     *
     * @param state store state
     * @param entity Group Ids to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeMany: groupsAdapter.removeMany,
    /**
     * Accepts a single group IDs, and removes the group entity with that ID if it exists.
     *
     * @param state store state
     * @param entity Group Id to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeOne: groupsAdapter.removeOne,

    /** Adds group IDs to the processing groups map */
    addProcessingGroups(state, action: PayloadAction<APITypes.GroupId[]>) {
      action.payload.forEach(
        (groupId) => (state.fetching.processingGroups[groupId] = true)
      );
    },

    /** Removes group IDs from the processing groups map */
    removeProcessingGroups(state, action: PayloadAction<APITypes.GroupId[]>) {
      action.payload.forEach(
        (groupId) => delete state.fetching.processingGroups[groupId]
      );
    },

    /**
     * Stores the group ID of the selected group.
     */
    setSelectedGroupId(state, action: PayloadAction<string | null>) {
      state.selectedGroupId = action.payload;
    },

    /** Resets the group store to its initial state. */
    resetGroupsState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGroups.pending, (state, action) => {
        state.fetching.isFetchingGroups = true;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.fetching.isFetchingGroups = false;
        // Using upsertMany instead of setAll as there might be some groups in shape of group details already in store
        // and setMany will unnecessary remove and replace them
        groupsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.fetching.isFetchingGroups = false;
      })

      .addCase(fetchGroupDetails.pending, (state, action) => {
        state.fetching.isFetchingGroups = true;
      })
      .addCase(fetchGroupDetails.fulfilled, (state, action) => {
        state.selectedGroupId = action.payload.id;
        groupsAdapter.setOne(state, action.payload);
        state.fetching.isFetchingGroups = false;
      })
      .addCase(fetchGroupDetails.rejected, (state, action) => {
        state.fetching.isFetchingGroups = false;
      })

      .addCase(addGroup.fulfilled, (state, action) => {
        groupsAdapter.setOne(state, action.payload);
      })
      .addCase(addGroup.rejected, (state, action) => {
        throw new Error(getErrorDisplayMarkup(action.error));
      })

      .addCase(removeMemberFromGroup.pending, (state, action) => {
        state.fetching.isRemovingGroupMember = true;
      })
      .addCase(removeMemberFromGroup.fulfilled, (state, action) => {
        removeLocalMemberFromGroup(state, action.payload);
        state.fetching.isRemovingGroupMember = false;
      })
      .addCase(removeMemberFromGroup.rejected, (state, action) => {
        state.fetching.isRemovingGroupMember = false;
      })

      .addCase(updateMemberRoleInGroup.pending, (state, action) => {
        state.fetching.isUpdatingGroups = true;
      })
      .addCase(updateMemberRoleInGroup.fulfilled, (state, action) => {
        updateMemberRoleInGroupStore(state, action.payload);
        state.fetching.isUpdatingGroups = false;
      })
      .addCase(updateMemberRoleInGroup.rejected, (state, action) => {
        state.fetching.isUpdatingGroups = false;
      })

      .addCase(updateGroupDetails.pending, (state, action) => {
        state.fetching.isUpdatingGroups = true;
      })
      .addCase(updateGroupDetails.fulfilled, (state, action) => {
        state.fetching.isUpdatingGroups = false;
        groupsAdapter.setOne(state, action.payload);
      })
      .addCase(updateGroupDetails.rejected, (state, action) => {
        state.fetching.isUpdatingGroups = false;
        throw new Error(getErrorDisplayMarkup(action.error));
      });
  },
});

export const {
  setOne,
  setMany,
  removeAll,
  removeMany,
  removeOne,
  addProcessingGroups,
  removeProcessingGroups,
  setSelectedGroupId,
  resetGroupsState,
} = groupsSlice.actions;

export const groupsReducer = groupsSlice.reducer;
