import {
  BaseCompanyIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { runtimeConfig } from "@src/runtime-config";
import { CLIENT_ID } from "@api/client-id";
import { useMemo } from "react";
import { ActivityApiClient } from "@api/activity-api/activity-api-client";
import { useCachedCompanyTokenProvider } from "@api/use-cached-company-token-provider";

type Props = Partial<BaseCompanyIdProps> & Partial<BaseProjectIdProps>;

/** Custom hook that initializes the activity API client and returns an instance of it */
export function useActivityApiClient({
  companyId,
  projectId,
}: Props = {}): ActivityApiClient {
  const tokenProvider = useCachedCompanyTokenProvider({
    companyId: companyId,
    projectId: projectId,
  });

  // Make sure the client is only created once, unless projectId or token provider change
  return useMemo(
    () =>
      new ActivityApiClient({
        activityApiBaseUrl: runtimeConfig.urls.activityApiUrl,
        companyId: companyId?.toString(),
        projectId: projectId?.toString(),
        tokenProvider,
        clientId: CLIENT_ID,
      }),
    [companyId, projectId, tokenProvider]
  );
}
