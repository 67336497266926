import { Box, Button, Stack, Typography } from "@mui/material";
import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack";
import { FaroIconButton } from "@components/common/faro-icon-button";
import GenericClose from "@assets/icons/generic-close_l.svg?react";
import { forwardRef, useCallback } from "react";
import { getToastProperties } from "@context-providers/toast/faro-toast-helper";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { FaroToastProps } from "@context-providers/toast/faro-toast-types";
import { addTransparency } from "@utils/ui-utils";

/**
 * A toast component that can be used to show messages to the user.
 */
export const FaroToast = forwardRef<
  HTMLDivElement,
  FaroToastProps & Pick<CustomContentProps, "id" | "message">
>((props, ref) => {
  const { id, message, description, errorCode, onClose, type, actionButton } =
    props;

  const { closeSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    closeSnackbar(id);
    onClose?.();
  }, [id, closeSnackbar, onClose]);

  const { backgroundColor, ToastIcon } = getToastProperties(type);

  return (
    <SnackbarContent
      ref={ref}
      style={{
        maxWidth: 480,
        backgroundColor,
        flexDirection: "column",
        borderRadius: "4px",
        border: `1px solid ${addTransparency({
          color: sphereColors.black,
          alpha: EDecimalToHex.twentySix,
        })}`,
        padding: "16px",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
          <ToastIcon style={{ width: "20px", height: "20px" }} />

          <Typography
            sx={{
              marginLeft: "16px",
              alignItem: "flex-start",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            {message}
          </Typography>
        </Box>

        <FaroIconButton
          component={GenericClose}
          buttonSize="20px"
          iconSize="20px"
          onClick={handleClose}
        />
      </Stack>

      <Stack sx={{ pl: "36px", width: "100%" }}>
        {description && (
          <Box
            component="div"
            sx={{
              fontSize: "14px",
            }}
          >
            {description}
          </Box>
        )}

        {errorCode && (
          <Box
            component="div"
            sx={{
              fontSize: "10px",
              mt: "16px",
            }}
          >
            Error Code: <b>{errorCode}</b>
          </Box>
        )}
      </Stack>

      {actionButton && (
        <Button
          onClick={actionButton.onClick}
          sx={{
            textTransform: "capitalize",
            alignSelf: "flex-end",
            color: sphereColors.blue500,
          }}
        >
          {actionButton.title}
        </Button>
      )}
    </SnackbarContent>
  );
});
