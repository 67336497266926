import { Box } from "@mui/material";
// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { withEllipsis } from "@styles/common-styles";
import { MainPageLayoutProps } from "@components/page-layout/main-page-layout";

type Props = Pick<MainPageLayoutProps, "centerComponent">;

/** Content for the center of layout header */
export function HeaderLayoutCenter({
  centerComponent,
}: Props): JSX.Element | null {
  if (!centerComponent) {
    return null;
  }

  return (
    <Grid
      alignItems="center"
      sx={{
        ...withEllipsis,
      }}
    >
      <Box
        component="div"
        sx={{
          px: "10px",
          ...withEllipsis,
        }}
      >
        {centerComponent}
      </Box>
    </Grid>
  );
}
