import { Box } from "@mui/material";
import { DEFAULT_FONT_FAMILY_ITALIC, DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";

interface Props {
  /** New role of member */
  role: string;
}

/** Format the success message after editing the member role */
export function RoleChangeSuccessDescription({ role }: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        fontSize: DEFAULT_INPUT_FONT_SIZE,
      }}
    >
      New Role:{" "}
      <Box
        component="span"
        sx={{
          fontFamily: DEFAULT_FONT_FAMILY_ITALIC,
        }}
      >
        "{role}"
      </Box>
    </Box>
  );
}
