import { sphereColors } from "@styles/common-colors";
import { MainPageLayout } from "@components/page-layout/main-page-layout";
import { Grid } from "@mui/material";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

/** Renders the layout of the project data management page. */
export function DataManagementLayout({
  children,
}: PropsWithChildren): JSX.Element {
  return (
    <Grid container sx={{ backgroundColor: sphereColors.gray50 }}>
      <MainPageLayout
        shouldShowLogo={true}
        shouldHideOpenButton={true}
        shouldShowSidebar={false}
        shouldShowTopbarBorder={true}
        contentBackgroundColor={sphereColors.gray50}
      >
        <Grid container sx={{ marginTop: "40px" }}>
          {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
          {children ? children : <Outlet />}
        </Grid>
      </MainPageLayout>
    </Grid>
  );
}
