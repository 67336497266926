import { TrialExpirationBanner } from "@components/banners/trial-expiration-banner";
import { WorkspaceSpecificBanner } from "@components/banners/workspace-specific-banner/workspace-specific-banner";
import { ResendVerification } from "@components/resend-verification";
import { WORKSPACE_SPECIFIC_BANNERS } from "@components/banners/workspace-specific-banner/workspace-specific-banner-constants";

/** List of all the banners to show to customer in the header */
export function BannersHeaderLayout(): JSX.Element {
  return (
    <>
      {/* Banners that show for specific workspaces */}
      {WORKSPACE_SPECIFIC_BANNERS.map((banner, index) => (
        <WorkspaceSpecificBanner key={index} {...banner} />
      ))}

      {/* Banner to remind users to verify email address */}
      <ResendVerification />

      {/* Trial expiration banner */}
      <TrialExpirationBanner />
    </>
  );
}
