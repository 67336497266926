import {
  MemberRoleTypes,
  MemberTypes,
  UserRoleEnum,
} from "@custom-types/member-types";
import { EntityId } from "@reduxjs/toolkit";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { getPrettyName } from "@utils/user-utils";

/** Gets the ID for a member to be used in the entities dictionary to identify each member  */
export function getMemberId(member: Partial<MemberTypes>): EntityId {
  return member.id ?? member.identity ?? member.email ?? "";
}

/**
 * Returns true if provided string is a valid email
 *
 * @param email Email as string to validate
 * @returns boolean True if email is valid
 */
export function isValidEmail(email: string): boolean {
  // TODO: This logic should move to the web-core repo or to email-utils.ts
  const emailValidationRegEx =
    /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return emailValidationRegEx.test(email);
}

/**
 * Check the input text and return an array of valid emails if available
 */
export function parseTextToEmails(inputText: string): string[] {
  const emails: string[] = [];
  if (inputText.includes(",") || inputText.includes(";")) {
    const possibleEmails = inputText.trim().replaceAll(",", ";").split(";");

    possibleEmails.forEach((possibleEmail) => {
      const email = possibleEmail.trim();
      if (isValidEmail(email)) {
        emails.push(email);
      }
    });
  }
  return emails;
}

interface GetMemberByIdProps {
  /** ID of the member */
  memberId?: string;

  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/** Returns the member entity by the member ID if it's available */
export function getMemberById({
  memberId,
  companyMembers,
  projectMembers,
}: GetMemberByIdProps):
  | MemberTypes
  | SphereDashboardAPITypes.IProjectMemberBase
  | undefined {
  const companyMember = companyMembers.find(
    (member) => member.identity === memberId
  );

  const projectMember = projectMembers.find(
    (member) => member.identity === memberId
  );

  // Give priority to the company level member entity
  return companyMember || projectMember;
}

/** Returns the get the formatted member name by the member ID if it's available */
export function getMemberNameById({
  memberId,
  companyMembers,
  projectMembers,
}: GetMemberByIdProps): string | undefined {
  const member = getMemberById({
    memberId,
    companyMembers,
    projectMembers,
  });

  const name = getPrettyName(member);

  return name !== "" ? name : undefined;
}

/** Returns the email by the member ID if it's available */
export function getMemberEmailById({
  memberId,
  companyMembers,
  projectMembers,
}: GetMemberByIdProps): string | undefined {
  const member = getMemberById({
    memberId,
    companyMembers,
    projectMembers,
  });

  return member?.email;
}

/** Returns a map of members accessible by id */
export function getMembersMapById(
  companyMembers: MemberTypes[]
): Map<APITypes.UserIdentity, MemberTypes> {
  return new Map(companyMembers.map((member) => [member.identity, member]));
}

/** Returns the higher role between two role provided */
export function getHigherRole(
  roleA: MemberRoleTypes,
  roleB: MemberRoleTypes
): MemberRoleTypes {
  return UserRoleEnum[roleA] >= UserRoleEnum[roleB] ? roleA : roleB;
}
