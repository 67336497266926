import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { sphereColors } from "@styles/common-colors";
import { MARKUP_ATTACHMENT_EXTENSIONS_WHITE_LIST } from "@pages/project-details/project-markups/markup-attachment-utils";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import FileSvg from "@assets/icons/new/file_50px.svg?react";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { useAppSelector } from "@store/store-helper";
import { markupAttachmentGroupSelector } from "@store/i-elements/i-elements-selectors";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { cloneDeep, findIndex } from "lodash";
import { IElementAttachment } from "@faro-lotv/ielement-types";
import { currentUserSelector } from "@store/user/user-selector";
import {
  MultiUploadedFileResponse,
  UploadElementType,
} from "@custom-types/file-upload-types";

/** Maximum file size of attachment can be uploaded for annotations */
const MAX_ATTACHMENT_SIZE_IN_MB = 1024;

/** Renders dropzone to upload markup attachment  */
export function MarkupUploadAttachment({
  markup,
}: BaseMarkupProps): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const {
    projectId,
    setIsMarkupUpdating,
    markups,
    isAttachmentUploading,
    setIsAttachmentUploading,
    updateMarkups,
    attachments,
    updateAttachments,
  } = useMarkupContext();
  const { addAttachmentsToMarkup } = useProjectMarkupUpdate({ projectId });
  const attachmentGroup = useAppSelector(markupAttachmentGroupSelector(markup));
  const currentUser = useAppSelector(currentUserSelector);

  function setIsLoading(isLoading: boolean): void {
    setIsAttachmentUploading(isLoading);
    setIsMarkupUpdating(isLoading);
  }

  /** Triggers when attachment uploading is complete for adding the attachment to the markup */
  async function addAttachment(
    uploadedResponse: MultiUploadedFileResponse
  ): Promise<void> {
    const uploadedFiles = uploadedResponse.successful;

    // Early return if there are no successful uploads because they all failed and/or got canceled
    if (!uploadedFiles.length) {
      return;
    }

    trackEvent({
      name: AnnotationEvents.addNewAttachment,
      props: {
        numberOfFiles: uploadedFiles.length,
        iElementType: markup.type,
        // Adding typeHint if available
        ...(markup.typeHint && { iElementTypeHint: markup.typeHint }),
      },
    });

    setIsLoading(true);

    const mutations = await addAttachmentsToMarkup({
      uploadedFiles,
      markup,
      attachmentGroup,
    });

    if (!mutations || !currentUser) {
      return setIsLoading(false);
    }

    // Update attachments of the annotation
    const updatedAttachments = cloneDeep(attachments);
    mutations.forEach((mutation) => {
      if ("newElement" in mutation) {
        const newElement = mutation.newElement;

        const newItem: IElementAttachment = {
          ...newElement,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          root_Id: newElement.rootId,
          createdBy: currentUser.identity,
          createdAt: mutation.createdAt,
          modifiedBy: currentUser.identity,
          modifiedAt: mutation.createdAt,
        };

        updatedAttachments.unshift(newItem);
      }
    });
    updateAttachments(updatedAttachments);

    // Also update the value of the attachmentsCount property of the annotation, which is used in the annotations table
    const newMarkups = cloneDeep(markups);
    const markupIndex = findIndex(newMarkups, { id: markup.id });
    newMarkups[markupIndex].attachmentsCount = updatedAttachments.length;
    updateMarkups(newMarkups);

    setIsLoading(false);
  }

  return (
    <SphereDropzone
      titleDragAndDrop={"New Attachment"}
      instruction="Drag & drop here"
      maxFileSize={MAX_ATTACHMENT_SIZE_IN_MB}
      hasUploadFileButton={true}
      shouldShowSupportedFormats={false}
      shouldAllowMultiUpload={true}
      avatar={
        <SphereAvatar
          icon={<FileSvg />}
          size="x-large"
          shouldHideWhiteRim
          iconColor={sphereColors.gray600}
          backgroundColor={sphereColors.gray100}
        />
      }
      allowedExtensions={MARKUP_ATTACHMENT_EXTENSIONS_WHITE_LIST}
      isLoading={isAttachmentUploading}
      setIsLoading={setIsAttachmentUploading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onUploadComplete={addAttachment}
      context={{
        uploadElementType: UploadElementType.annotation,
        projectId,
        iElementId: markup.id,
      }}
    />
  );
}
