import { SidePanel } from "@components/sidepanel/sidepanel";
import { sphereColors } from "@styles/common-colors";
import {
  MarkupsSidePanelHeader,
  MarkupsSidePanelInfo,
  MarkupsSidePanelAttachments,
} from "@pages/project-details/project-markups/sidepanel";
import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { StickySidePanelContainer } from "@components/sidepanel/sticky-sidepanel-container";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useMarkupSidePanel } from "@pages/project-details/project-markups/sidepanel/use-markup-sidepanel";

/**
 * Component representing a side panel for displaying details of a selected markup.
 * It allows users to view information about a specific markup within a project.
 *
 * @returns {JSX.Element} A JSX element representing the markup side panel
 */
export function MarkupSidePanel(): JSX.Element {
  const theme = useTheme();
  const isScreenXs = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    isOpenMarkupSidePanel,
    selectedMarkup,
    isMarkupUpdateInProgress,
    activeTab,
  } = useMarkupContext();

  const { isLoading, closeSidePanel } = useMarkupSidePanel();

  return (
    <SidePanel
      isOpen={isOpenMarkupSidePanel}
      anchor={"right"}
      closeSidePanel={closeSidePanel}
      sx={{
        "& .MuiDrawer-paper": {
          boxShadow: "none",
          borderLeft: `1px solid ${sphereColors.gray200}`,
          width: isScreenXs ? "100%" : "430px",
          paddingX: "20px",
          paddingBottom: "16px",
        },
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
      }}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "16px",
          }}
        />
      ) : (
        <StickySidePanelContainer
          header={
            selectedMarkup && (
              <MarkupsSidePanelHeader
                markup={selectedMarkup}
                closeSidePanel={closeSidePanel}
              />
            )
          }
          selectedTab={activeTab}
          tabs={[
            {
              label: "Info",
              isDisabled: isMarkupUpdateInProgress(),
              content: () =>
                selectedMarkup && (
                  <MarkupsSidePanelInfo markup={selectedMarkup} />
                ),
            },
            {
              label: "Attachments",
              isDisabled: isMarkupUpdateInProgress(),
              content: () =>
                selectedMarkup && (
                  <MarkupsSidePanelAttachments markup={selectedMarkup} />
                ),
            },
          ]}
        />
      )}
    </SidePanel>
  );
}
