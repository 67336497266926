import { Box, Grid, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import {
  DEFAULT_BOLD_TEXT_FONT_WEIGHT,
  DEFAULT_TEXT_FONT_SIZE,
  DEFAULT_TEXT_FONT_WEIGHT,
  DEFAULT_TITLE_FONT_SIZE,
} from "@styles/common-styles";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { BasicTabProps } from "@components/common/tabs/tabs-type";
import { AccountSettingsTabs } from "@router/route-params";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { OpenTabEvents } from "@utils/track-event/track-event-list";

interface Props {
  /** The tabs for account settings */
  tabs: Record<AccountSettingsTabs, BasicTabProps<AccountSettingsTabs>>;

  /** The selected tab */
  selectedTabId: AccountSettingsTabs;
}

/** The mobile layout for account settings tab contents */
export function AccountSettingsMobileTabContents({
  tabs,
  selectedTabId,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const selectedTab = tabs[selectedTabId];

  const filteredTabContents = selectedTab.content.filter(
    (selectedTabContent) => !selectedTabContent.isHidden
  );

  return (
    <>
      {/* Header for the tab content */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ px: "15px", mt: "45px" }}
      >
        <Typography
          sx={{
            color: sphereColors.gray800,
            fontSize: "1.5rem",
          }}
        >
          {selectedTab.label}
        </Typography>
        <FaroIconButton
          component={CloseIcon}
          href={".."}
          onClick={() => {
            trackEvent({
              name: OpenTabEvents.openAccountSettingsTab,
              props: {
                tab: "Tab selection list",
              },
            });
          }}
          iconButtonProps={{
            // Adjust alignment of the close icon in small screen
            sx: {
              width: "2rem",
              height: "2rem",
            },
          }}
        />
      </Grid>

      {/* Body for the tab content */}
      <Box sx={{ mt: "20px", mx: "15px" }}>
        {filteredTabContents.map((section, index) => (
          <Box key={index} sx={{ mt: index === 0 ? "20px" : "80px" }}>
            <Typography
              sx={{
                fontSize: DEFAULT_TITLE_FONT_SIZE,
                fontWeight: DEFAULT_BOLD_TEXT_FONT_WEIGHT,
                color: sphereColors.gray800,
                mb: "8px",
              }}
            >
              {section.title}
            </Typography>
            <Typography
              sx={{
                fontSize: DEFAULT_TEXT_FONT_SIZE,
                fontWeight: DEFAULT_TEXT_FONT_WEIGHT,
                lineHeight: "18px",
                color: sphereColors.gray800,
                mb: "24px",
              }}
            >
              {section.subtitle}
            </Typography>
            {section.items.map((item, index) => (
              <Box key={index}>{item.content}</Box>
            ))}
          </Box>
        ))}
      </Box>
    </>
  );
}
