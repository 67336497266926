import { MemberRoleTypes, MemberTypes } from "@custom-types/member-types";
import { SdbTeamMemberBase } from "@custom-types/teams-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getHigherRole } from "@utils/member-utils";
import { getPrettyName } from "@utils/user-utils";

/**
 * Maps team members to sdb members by calculating the higher role of the member
 * based on the team role and company role if companyMembersMap is provided
 */
export function mapTeamMembersToSdbMembers(
  members: SphereDashboardAPITypes.ITeamMember[],
  teamRole: MemberRoleTypes,
  companyMembers?: Map<string, MemberTypes>
): SdbTeamMemberBase[] {
  return members.map((member) => {
    const companyRole = companyMembers?.get(member.userResponse.identity)?.role;
    return {
      ...member.userResponse,
      name: getPrettyName(member.userResponse),
      role: companyRole ? getHigherRole(teamRole, companyRole) : teamRole,
    };
  });
}
