import { FaroButton, FaroButtonSizes } from "@faro-lotv/flat-ui";
import { sphereColors } from "@styles/common-colors";
import { SxProps, Theme } from "@mui/material";


interface ToggleButtonProps {
  /** Whether the button is in selected state */
  isSelected: boolean;

  /** Click handler for the toggle button */
  onClick: () => void;

  /** Content to render inside the button */
  children: React.ReactNode;

  /** Size of the button - accepted values: "s", "m", "l" */
  size?: FaroButtonSizes;

  /** Custom styles to override default styling */
  customSx?: SxProps<Theme>;

  /** Test ID for testing */
  testId?: string;

  /** Custom colors for different states */
  colors?: {
    default?: string;
    selected?: string;
    hover?: string;
    text?: string;
    selectedText?: string;
  };
}

/** Renders a custom toggle button component */
export function FaroToggleButton({
  isSelected,
  onClick,
  children,
  size = FaroButtonSizes.s,
  testId = "faro-toggle-button",
  customSx,
  colors = {
    default: "transparent",
    selected: sphereColors.pureWhite,
    text: sphereColors.gray800,
    selectedText: sphereColors.blue500,
  },
}: ToggleButtonProps): JSX.Element {

  return (
    <FaroButton
      data-testid={testId}
      sx={{
        padding: "5px 14px",
        gap: "6px",
        margin: "2px",
        borderRadius: "2px",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 600,
        border: "none",
        color: colors.text,
        backgroundColor: colors.default,
        cursor: "pointer",
        textTransform: "none",
        "&:hover": {
          // Override default hover styles
          backgroundColor: isSelected ? colors.selected : colors.default,
          border: "none",
          boxShadow: "none",
        },
        ...(isSelected && {
          backgroundColor: colors.selected,
          color: colors.selectedText,
          "&.MuiButton-root": {
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)",
          },
        }),
        ...customSx,
      }}
      onClick={onClick}
      size={size}
    >
      {children}
    </FaroButton>
  );
}
