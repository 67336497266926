import { useCallback } from "react";
import { ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import {
  ElsScanFileUploadTaskContext,
  UploadedFile,
  UploadElementType,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { useOnUploadComplete } from "@hooks/data-management/use-on-upload-complete";
import { useFileUpload } from "@hooks/use-file-upload";
import {
  ExtraUploadContext,
  extraUploadContexts,
  getCreateScanEntityParams,
  lsDataV2InfoForTracking,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { CaptureTreeRootAndClustersByUuid } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import { UUID } from "@stellar/api-logic/dist/api/core-api/api-types";
import { exponentialBackOff, GUID, retry } from "@faro-lotv/foundation";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { ProjectApi } from "@api/project-api/project-api";
import pLimit, { LimitFunction } from "p-limit";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { CreateScanEntityParams } from "@custom-types/capture-tree/capture-tree-types";
import { loggedInUserSelector } from "@store/user/user-selector";
import { assertValue } from "@utils/assert-utils";
import { uploadedFileHash } from "@utils/file-utils";
import {
  cancelOldOpenRevisions, getMapOfScanEntitiesForReuse, reuseScanEntity, shouldReuseScanEntities,
} from "@pages/project-details/project-data-management/import-data/import-data-reuse-utils";
import { getBrowserClockBehindCoreApi } from "@api/use-cached-token-provider";
import { isNumber } from "lodash";

type ReturnFunction = (
  captureTreeRevisionId: string,
  captureTreeRootAndClustersByUuid: CaptureTreeRootAndClustersByUuid,
  captureTreeScanIdByUuid: Record<UUID, GUID>,
  files: File[],
  lsDataV2: ReadLsDataV2Response | null,
  scansAlreadyUploaded: Set<File>,
) => Promise<void>;

/** Argument type for finalizer. */
type FinalizerArgs = {
  projectApiClient: ProjectApi,
  context: ElsScanFileUploadTaskContext
  /** File that has been uploaded. */
  uploadedFile: UploadedFile,
  /** Extra mutable context to store the ScanEntity parameters. */
  extraContext: ExtraUploadContext,
  /** SMETA-1627: ProjectAPI does not allow parallel requests modifying the same revision. */
  limiter: LimitFunction
}

/**
 * After each file has been uploaded, this function is called to create the ScanEntity.
 * This makes the processing start immediately for each scan, and not later when all scans were uploaded.
 */
async function finalizer(args: FinalizerArgs): Promise<void> {
  const { projectApiClient, context, uploadedFile, extraContext, limiter } = args;
  try {
    // We could check again for existing scans, in case that another client uploads at the same time.
    // But doing so would require a lot of additional API calls. So let's first try without that,
    // and instead handle errors when adding the scan.
    //
    // const entitiesMain = await projectApiClient.getCaptureTree();
    // const existingExternalScanIdsArray = entitiesMain
    //   .map((entity) => getExternalScanId(entity))
    //   .filter((id) => id !== undefined);

    let scanEntityParams: CreateScanEntityParams;

    const existingFileToReuse = context.existingFilesToReuse?.[
      uploadedFileHash(uploadedFile.fileName, uploadedFile.fileSize)
    ];
    if (
      shouldReuseScanEntities() &&
      existingFileToReuse &&
      existingFileToReuse.downloadUrl === uploadedFile.downloadUrl &&
      existingFileToReuse.md5 === uploadedFile.md5
    ) {
      scanEntityParams = reuseScanEntity(uploadedFile.fileName, existingFileToReuse.data, context);
    } else {
      scanEntityParams = getCreateScanEntityParams({
        uploadedScan: uploadedFile,
        captureTreeRootAndClustersByUuid: context.captureTreeRootAndClustersByUuid,
        lsDataV2: context.lsDataV2,
      });
    }

    // After this API call, the ELS processing starts in the background (.gls -> .e57 -> PoTree).
    // So we get the advantage that processing starts immediately once a scan has been uploaded.
    // ProjectAPI often has surprises for us like SMETA-1627, so better retry:
    // Max. 3 tries in total, with 1st retry after ~1 second, and 2nd retry after another ~2 seconds.
    await limiter(() => {
      return retry(
        () => {
          return projectApiClient.createOrUpdateScanEntitiesForRegistrationRevision({
            registrationRevisionId: context.captureTreeRevisionId,
            requestBody: [scanEntityParams],
          });
        },
        { max: 3, delay: exponentialBackOff }
      );
    });
    // If the API call succeeded, save the ScanEntity parameters for later, when we add the edges.
    // If the scan failed to be added, we cannot add any edges to it.
    extraContext.scanEntitiesParams.push(scanEntityParams);
  } catch(error) {
    // eslint-disable-next-line no-console -- Helpful for devs to view the error.
    console.log("Error in useUploadMultipleScans finalizer", args.uploadedFile.fileName, error);
    throw error;
  }
}

/**
 * Carries out the upload of multiple *.gls files.
 * The caller probably doesn't want to await this async function, but let's it run in the background.
 */
export function useUploadMultipleScans(project: SdbProject): ReturnFunction {
  const { uploadMultipleFiles } = useFileUpload();
  const { trackEvent } = useTrackEvent();
  const onUploadComplete = useOnUploadComplete(project);
  const { uploadManager } = useFileUploadContext();
  const projectApiClient = useProjectApiClient({
    projectId: project.id,
  });
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(loggedInUserSelector);

  return useCallback(
    /**
     * Initiates the upload of multiple *.gls files.
     * @param captureTreeRevisionId ID of our Capture Tree revision.
     * @param captureTreeRootAndClustersByUuid Map from UUID from the scanner raw data to Capture Tree root/cluster.
     * @param files The selected *.gls files to upload.
     * @param lsDataV2 The parsed scan metadata, if available.
     * @param scansAlreadyUploaded Subset of files to skip, because they were already uploaded.
     * @returns Resolved promise when all files were uploaded or canceled.
     */
    async (
      captureTreeRevisionId: string,
      captureTreeRootAndClustersByUuid: CaptureTreeRootAndClustersByUuid,
      captureTreeScanIdByUuid: Record<UUID, GUID>,
      files: File[],
      lsDataV2: ReadLsDataV2Response | null,
      scansAlreadyUploaded: Set<File>
    ): Promise<void> => {
      // Return if there is no uploadable file (unexpected).
      if (!files.length) {
        return;
      }

      const { existingFilesToReuse, unmergedRevisions } = await getMapOfScanEntitiesForReuse(
        dispatch,
        projectApiClient,
        captureTreeRevisionId,
        files,
        assertValue(loggedInUser?.userId)
      );

      const secondsBehind = getBrowserClockBehindCoreApi(project.id);
      trackEvent({
        name: DataManagementEvents.startUpload,
        props: {
          filesGLS: files.length,
          filesGLSToUpload: files.length,
          filesGLSToUploadSize: files.reduce((sum, file) => sum + file.size, 0),
          filesGLSAlreadyUploaded: scansAlreadyUploaded.size,
          ...lsDataV2InfoForTracking(lsDataV2),
          existingFilesToReuse: existingFilesToReuse ? Object.keys(existingFilesToReuse).length : 0,
          unmergedRevisions: unmergedRevisions.length,
          isSharedWorker: uploadManager.isSharedWorker(),
          // Round to make it easier to create buckets for each value in Amplitude.
          browserClockBehindCoreApi: isNumber(secondsBehind) ? Math.round(secondsBehind) : false,
        },
      });

      // If we're re-using previous uploads, cancel old revisions of the same user & client & draft.
      // This stops the processing workers from adding more data to the scans of these old revisions,
      // since canceling a revision will also delete the related iElements.
      if (existingFilesToReuse) {
        await cancelOldOpenRevisions(projectApiClient, unmergedRevisions, true);
      }

      const context: ElsScanFileUploadTaskContext = {
        uploadElementType: UploadElementType.elsScan,
        projectId: project.id,
        captureTreeRevisionId,
        captureTreeRootAndClustersByUuid,
        captureTreeScanIdByUuid,
        lsDataV2,
        existingFilesToReuse,
      };

      // Create mutable storage space for ScanEntity parameters, since we'll need them later to create the edges.
      const extraContext = { scanEntitiesParams: [] };
      extraUploadContexts.set(context, extraContext);
      // SMETA-1627: ProjectAPI does not allow parallel requests modifying the same revision.
      const limiter = pLimit(1);

      const uploadParams: UploadMultipleFilesParams = {
        files,
        finalizer: (uploadedFile: UploadedFile) => {
          const finalizerArgs: FinalizerArgs = {
            projectApiClient,
            context,
            uploadedFile,
            extraContext,
            limiter,
          };
          return finalizer(finalizerArgs);
        },
        onUploadStart: () => undefined,
        onUploadProgress: () => undefined,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onUploadComplete,
        context,
      };

      // Start uploading in background, so the spinner disappears.
      void uploadMultipleFiles(uploadParams);
    },
    [
      dispatch,
      loggedInUser?.userId,
      onUploadComplete,
      project,
      projectApiClient,
      trackEvent,
      uploadManager,
      uploadMultipleFiles,
    ]
  );
}
