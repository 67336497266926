import { useCallback } from "react";
import { SdbProject } from "@custom-types/project-types";
import { useHasValidFeatureProjectLevel } from "@hooks/feature-control/use-has-user-valid-feature-project-level";
import { useOnSelectBlinkFiles } from "@hooks/data-management/use-on-select-files-blink";
import { useOnSelectPhotogrammetryFiles } from "@hooks/data-management/use-on-select-files-photogrammetry";
import { useUploadErrorToast, UploadErrorToastType } from "@hooks/data-management/use-upload-error-toast";
import {
  useDataManagementInputType,
  DataManagementInputType,
} from "@pages/project-details/project-data-management/use-data-management-input-type";
import { APITypes } from "@stellar/api-logic";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { useAppSelector } from "@store/store-helper";

export type ReturnFunctionOnSelectFiles = (
  selectedFiles: FileList | File[],
  /**
   * A map of the externalIds of the uploaded ELS scans, as returned by getUploadedIdsMap.
   * Used by the useOnSelectBlinkFiles selector.
   */
  uploadedIdsMap: { [key: APITypes.UUID]: boolean },
) => Promise<boolean>;

/**
 * Combined hook for handling file selection and upload for both Blink and Photogrammetry files.
 * Returns true on success, false if some kind of error happened.
 * @param project The project to upload the files to.
 */
export function useOnSelectFiles(project: SdbProject): ReturnFunctionOnSelectFiles {
  const determineInputType = useDataManagementInputType();
  const onSelectBlinkFiles = useOnSelectBlinkFiles(project);
  const onSelectPhotogrammetryFiles = useOnSelectPhotogrammetryFiles(project);
  const uploadErrorToast = useUploadErrorToast();
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const { canViewPhotogrammetryFeature } = useHasValidFeatureProjectLevel();

  return useCallback(
    async (
      selectedFiles: FileList | File[],
      uploadedIdsMap: { [key: APITypes.UUID]: boolean }
    ): Promise<boolean> => {
      const inputType = determineInputType(selectedFiles);

      switch (inputType) {
        case DataManagementInputType.gls:
          return onSelectBlinkFiles(selectedFiles, uploadedIdsMap);

        case DataManagementInputType.photogrammetry:
          if (!canViewPhotogrammetryFeature && !isDevModeEnabled) {
            uploadErrorToast(UploadErrorToastType.noPhotogrammetryAccess);
            return false;
          }
          return onSelectPhotogrammetryFiles(selectedFiles, uploadedIdsMap);

        case DataManagementInputType.unknown:
        default:
          uploadErrorToast(UploadErrorToastType.noFiles);
          return false;
      }
    },
    [
      canViewPhotogrammetryFeature,
      determineInputType,
      onSelectBlinkFiles,
      onSelectPhotogrammetryFiles,
      uploadErrorToast,
      isDevModeEnabled,
    ]
  );
}
