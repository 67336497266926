import { MemberTypes } from "@custom-types/member-types";
import { Box, SxProps } from "@mui/material";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { runtimeConfig } from "@src/runtime-config";
import { ContactSupportLocations } from "@custom-types/types";
import { UseTrackEvent } from "@utils/track-event/use-track-event";

interface GetFeedbackMessageProps {
  /** The selected rating */
  selectedRating?: number | null;

  /** The feedback text */
  feedbackMessage?: string;

  /** The current default launch project target for the user's workspace */
  defaultOpenProjectTarget?: string;
}

/** The maximum rating that can be given */
export const MAX_RATING = 5;

/** Gets a the email message to be sent when we get some feedback. */
export function getFeedbackMessage({
  selectedRating,
  feedbackMessage,
  defaultOpenProjectTarget,
}: GetFeedbackMessageProps): string {
  let message = "";
  if (feedbackMessage) {
    message += `Feedback: ${feedbackMessage}\n`;
  }
  if (selectedRating) {
    message += `Rating: ${selectedRating}/${MAX_RATING}\n`;
  }
  if (defaultOpenProjectTarget) {
    message += `Default project launch target: ${defaultOpenProjectTarget}\n`;
  }
  message += `App version: ${runtimeConfig.appVersion}\nEnvironment: ${runtimeConfig.appEnv}\n`;
  return message;
}

interface GetMailHrefProps {
  /** The members to send the email to */
  members?: Pick<MemberTypes, "email">[];

  /** Any additional emails to add to the list */
  additionalEmails?: string[];

  /** The subject of the email */
  subject?: string;

  /** The body of the email */
  body?: string;
}

/**
 * Gets a mail to link with the correct subject and body.
 */
export function getMailHref({
  members,
  additionalEmails,
  subject = "",
  body = "",
}: GetMailHrefProps): string {
  // If we have no members we just return an empty string.
  let membersMailAddresses =
    members?.map((member) => member.email).join(";") ?? "";

  // Additional properties to append to the mailto link.
  const appendProps: string[] = [];

  if (subject) {
    appendProps.push(`subject=${subject}`);
  }
  if (body) {
    appendProps.push(`body=${body}`);
  }

  if (membersMailAddresses.length && additionalEmails?.length) {
    // Add a semicolon to separate the emails.
    membersMailAddresses += ";";
  }

  let url = `mailto:${membersMailAddresses}${
    additionalEmails?.join(";") ?? ""
  }`;
  if (appendProps.length) {
    url += `?${appendProps.join("&")}`;
  }
  return url;
}

/**
 * Returns a link element for a shortcut to email someone.
 *
 * @param email The email to be displayed.
 * @param className Optional class name to include in the "a" element.
 * @returns An "a" element with the correct props included.
 */
export function getMailToLink(email: string, style: SxProps): JSX.Element {
  return (
    <Box
      component="a"
      href={getMailHref({
        members: [{ email }],
      })}
      sx={{
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
        ...style,
      }}
    >
      {email}
    </Box>
  );
}

type ContactSupportProps = Pick<UseTrackEvent, "trackEvent"> & {
  /** The location where the user clicked on the contact support link */
  location: ContactSupportLocations;
};

/**
 * Function to be called when the user clicks on the contact support link.
 */
export function contactSupport({
  location,
  trackEvent,
}: ContactSupportProps): void {
  // Track event of click on contact support button
  trackEvent({
    name: WorkspaceEvents.contactSupport,
    props: { location },
  });

  window.location.href = `mailto:${runtimeConfig.mailSupportAddress}`;
}
