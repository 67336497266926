import { useMemo } from "react";
import { DataManagementBulkButtonComponents, DataManagementTableBulkName } from "@components/common/faro-table/faro-table-types";
import { TableItem } from "@pages/project-details/project-data-management/data-management-types";
import { RemoveScansButton } from "@pages/project-details/project-data-management/remove-scans-button";
import { useAppSelector } from "@store/store-helper";
import { selectedIdsSelector } from "@store/table/table-selector";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";

interface Props {
  /** Array of DataManagementTableItem entities. */
  items: TableItem[],
  /** Callback to set the scans the user selected for removal. */
  setScansToRemove: React.Dispatch<React.SetStateAction<TableItem[]>>;
  /** Callback to set whether the confirmation dialog for removing scans is open. */
  setIsRemoveScansDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/** All bulk action buttons for the scan table in the Staging Area. */
export function DataManagementBulkActions({
  items,
  setScansToRemove,
  setIsRemoveScansDialogOpen,
}: Props): DataManagementBulkButtonComponents {
  const selectedIds = useAppSelector(selectedIdsSelector("scans"));

  const selectedItems = useMemo(() => {
    const ids = new Set(selectedIds);
    return items.filter((item) => ids.has(item.id));
  }, [selectedIds, items]);

  const allowedButtonsBasedOnRowSelection: DataManagementTableBulkName[] = useMemo(() => {
    const allowedBulkActions: DataManagementTableBulkName[] = [];
    if (selectedItems.length) {
      allowedBulkActions.push("removeScan");
    }
    return allowedBulkActions;
  }, [selectedItems.length]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({ allowedButtonsBasedOnRowSelection });

  return {
    removeScan: shouldShowBulkButtons("removeScan") ? (
      <RemoveScansButton
        selectedItems={selectedItems}
        setScansToRemove={setScansToRemove}
        setIsRemoveScansDialogOpen={setIsRemoveScansDialogOpen}
      />
    ) : null,
  };
}
