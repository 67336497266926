import { UserProfile } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { isSupportedLanguageObject, LanguageObject } from "@faro-lotv/flat-ui";
import { useLocalize } from "@faro-lotv/foreign-observers";

interface ReturnProps {
  /** All the available languages */
  languages: LanguageObject[];

  /** Callback when the language is changed */
  onLanguageChange: (languageCode: string) => void;

  /** The current selected language */
  currentLanguage: LanguageObject;
}

/** A hook that handles all the utility functions for language selector */
export function useLanguage(): ReturnProps {
  const { availableLanguages, setLanguage, currentLanguage } = useLocalize();
  const { trackEvent } = useTrackEvent();

  const languages = availableLanguages.filter(isSupportedLanguageObject);

  function onLanguageChange(languageCode: string): void {
    const languageObject = availableLanguages.find(
      ({ code }) => code === languageCode
    );

    if (languageObject) {
      trackEvent({
        name: UserProfile.changeLanguage,
        props: { language: languageObject.code },
      });
      setLanguage(languageObject.code);
    }
  }

  return {
    languages,
    onLanguageChange,
    currentLanguage: currentLanguage as LanguageObject,
  };
}
