import { useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import FaroSphereLogo from "@assets/icons/faro-sphere-xg-logo-color.svg?react";
import { sphereColors } from "@styles/common-colors";
import { FaroIconButton } from "@components/common/faro-icon-button";
import MenuIcon from "@assets/icons/generic-menu_l.svg?react";
// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { updateSidebar } from "@store/ui/ui-slice";
import { FaroBreadcrumbs } from "@components/header/faro-breadcrumbs";
import { sidebarSelector } from "@store/ui/ui-selector";
import { DEFAULT_TITLE_FONT_SIZE } from "@styles/common-styles";
import { MainPageLayoutProps } from "@components/page-layout/main-page-layout";
import { generateProjectsRoute, useAppParams } from "@router/router-helper";
import { SidebarEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

type Props = Pick<
  MainPageLayoutProps,
  | "shouldShowLogo"
  | "shouldHideOpenButton"
  | "shouldShowSidebar"
  | "topLeftHeaderButton"
>;

/** Content for the left side of layout header */
export function HeaderLayoutLeft({
  shouldShowLogo,
  shouldHideOpenButton,
  topLeftHeaderButton,
  shouldShowSidebar,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const sidebar = useAppSelector(sidebarSelector);
  const theme = useTheme();
  const isSmallAndLarger = useMediaQuery(theme.breakpoints.up("sm"));
  const { companyId } = useAppParams();

  const to = companyId ? generateProjectsRoute(companyId) : "/";

  const { logoHeight, logoML } = useMemo(() => {
    return isSmallAndLarger ? {
        logoHeight: "3.25rem",
        logoML: "-8px",
      } : {
        logoHeight: "3rem",
        logoML: "-16px",
      };
  }, [isSmallAndLarger]);

  useEffect(() => {
    dispatch(updateSidebar({ ...sidebar, isVisible: shouldShowSidebar }));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Putting sidebar on dependency will create a loop
  }, [dispatch, shouldShowSidebar]);

  return (
    <Grid container alignItems="center" flexGrow={1} flexBasis={0}>
      {/* Large button to show on the left side of header */}
      {topLeftHeaderButton}

      {shouldShowLogo && (
        <NavLink style={{ display: "contents" }} to={to}>
          <FaroSphereLogo style={{
            width: "138px",
            minWidth: "138px",
            height: logoHeight,
            minHeight: logoHeight,
            marginLeft: logoML,
            marginRight: "10px",
          }}
          onClick={() => {
            trackEvent({ name: SidebarEvents.goHome });
          }} />
        </NavLink>
      )}

      {/* Button to open drawer */}
      {!isSmallAndLarger && !shouldHideOpenButton && (
        <Grid alignItems="center">
          <Box
            component="div"
            sx={{
              color: sphereColors.gray800,
              fontWeight: 600,
              fontSize: DEFAULT_TITLE_FONT_SIZE,
              lineHeight: "28px",
              letterSpacing: "0.16px",
            }}
          >
            <FaroIconButton
              aria-label="open drawer"
              iconButtonProps={{
                edge: "start",
                sx: {
                  mr: 2,
                  display: { sm: "none" },
                },
              }}
              component={MenuIcon}
              iconSize="20px"
              onClick={() => {
                dispatch(updateSidebar({ isOpenOnMobile: true }));
              }}
            />
          </Box>
        </Grid>
      )}

      {isSmallAndLarger && (
        <Grid alignItems="center">
          <FaroBreadcrumbs />
        </Grid>
      )}
    </Grid>
  );
}
