import { BaseUserProps } from "@custom-types/member-types";
import { QueryParams } from "@router/route-params";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

interface UserContextData {
  /** User object */
  user: SphereDashboardAPITypes.IUserDetails;

  /** The origin URL from where the account settings page was opened */
  originUrl: string | null;
}

const UserContext = createContext<UserContextData | undefined>(undefined);

/**
 * @returns A provider component from which children can get the logged in user object.
 */
export function UserProvider({
  children,
  user,
}: PropsWithChildren<BaseUserProps>): JSX.Element | null {
  const [originUrl, setOriginUrl] = useState<string | null>(null);

  // Reads the URL param with the origin URL.
  // If defined it stores the origin URL and removes the query param from the URL.
  // We can't use getQueryParam and setUrlParam methods here because they push a new window.history entry
  // and we need to instead replace the last history entry when removing the query param after reading it.
  useEffect(() => {
    const url = new URL(window.location.href);
    const originUrl = url.searchParams.get(QueryParams.settingsOriginUrl);
    if (originUrl) {
      setOriginUrl(originUrl);
      url.searchParams.delete(QueryParams.settingsOriginUrl);
      // Replaces the last history entry instead of pushing a new one
      window.history.replaceState({}, "", url.toString());
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        originUrl,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

/** Hook that returns the user context */
export function useUserContext(): UserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw Error("useUserContext must be used inside the UserProvider");
  }

  return context;
}
