import { Button, ButtonProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { PropsWithChildren, useMemo } from "react";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";

interface Props {
  /** Flag whether the button should show as loading */
  isLoading?: boolean;

  /** True when the button should be disabled */
  isDisabled?: boolean;

  /** Optional sx properties to override the button style. */
  sx?: ButtonProps["sx"];

  /** Callback when the button is clicked */
  onClick: () => void | Promise<void>;
}

/** Class name for the loading state */
const LOADING_CLASS_NAME = "loading";

/**
 * Exports a button that has a white background and just a blue border.
 * The button can be used with a loading property that will make the button
 * disabled and will show a spinner next to the text.
 */
export function FaroButtonContained({
  isLoading = false,
  isDisabled,
  sx = {},
  children,
  onClick,
}: PropsWithChildren<Props>): JSX.Element {
  /**
   * Flag whether the button should be shown as disabled, either because it is
   * disabled or because it is loading.
   */
  const shouldShowDisabled = useMemo(() => {
    return isLoading || isDisabled;
  }, [isDisabled, isLoading]);

  return (
    <Button
      variant="contained"
      className={isLoading ? LOADING_CLASS_NAME : ""}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onClick={onClick}
      disabled={shouldShowDisabled}
      disableFocusRipple={true}
      disableRipple={true}
      disableElevation={true}
      disableTouchRipple={true}
      sx={{
        fontSize: "14px",
        fontWeight: "600",
        backgroundColor: sphereColors.pureWhite,
        color: sphereColors.blue500,
        borderRadius: "4px",
        border: `1px solid ${sphereColors.blue500}`,
        textTransform: "none",
        [`&.Mui-disabled.${LOADING_CLASS_NAME}`]: {
          color: sphereColors.blue600,
          backgroundColor: sphereColors.gray200,
        },
        "&:hover": {
          backgroundColor: sphereColors.gray100,
          borderColor: sphereColors.blue600,
          color: sphereColors.blue600,
        },
        "&:active": {
          backgroundColor: sphereColors.gray200,
          borderColor: sphereColors.blue600,
          color: sphereColors.blue600,
        },
        ...sx,
      }}
    >
      {children}
      <FaroButtonSpinner
        shouldHide={!isLoading}
        loadingIndicatorColor={sphereColors.blue600}
        loadingTrackColor={sphereColors.gray100}
      />
    </Button>
  );
}
