import {
  BackgroundTaskCategory,
  CloudRegistrationTask,
  ProcessElsRawScanTask,
  ProcessPointCloudFlsRawTask,
  SdbBackgroundTask,
} from "@custom-types/sdb-background-tasks-types";

/**
 * @returns whether an SdbBackgroundTask entity is of type CloudRegistrationTask
 *
 * @param task SdbBackgroundTask entity
 */
export function isCloudRegistrationTask(
  task: SdbBackgroundTask
): task is CloudRegistrationTask {
  return (
    task.taskType === "CloudRegistration" &&
    task.taskCategory === BackgroundTaskCategory.pointCloud
  );
}

/**
 * @returns whether an SdbBackgroundTask entity is for processing a scan added to the project.
 * @param task SdbBackgroundTask entity
 */
export function isProcessScanTask(
  task: SdbBackgroundTask
): task is ProcessElsRawScanTask | ProcessPointCloudFlsRawTask {
  return (
    (task.taskType === "ProcessElsRawScan" || task.taskType === "ProcessPointCloudFlsRaw") &&
    task.taskCategory === BackgroundTaskCategory.pointCloud
  );
}
