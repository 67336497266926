import { VerticalTabs } from "@components/common/tabs/vertical-tabs";
import { CloseAccountSettingsButton } from "@pages/account-settings/close-account-settings-button";
import { MainPageLayout } from "@components/page-layout/main-page-layout";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Box } from "@mui/material";
import { AccountSettingsMobileLayout } from "@pages/account-settings/account-settings-layout/mobile-layout/account-settings-mobile-layout";
import { fetchingUserFlagsSelector } from "@store/user/user-selector";
import { useAppSelector } from "@store/store-helper";
import { useAccountSettingsTabs } from "@pages/account-settings/account-settings-utils";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { AccountSettingsTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { useEffect } from "react";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";

/** The layout of account settings */
export function AccountSettingsLayout(): JSX.Element {
  const { isScreenSmOrSmaller } = useMediaQueryList();
  const { isFetchingLoggedInUser } = useAppSelector(fetchingUserFlagsSelector);
  const { accountSettingsTabs } = useAccountSettingsTabs();
  const { accountSettingsTab } = useAppParams();
  const { navigateToAccountSettingsPage } = useAppNavigation();

  // If accountSettingsTab is not available and screen is not mobile, navigate to the default tab
  useEffect(() => {
    if (accountSettingsTab === undefined && !isScreenSmOrSmaller) {
      navigateToAccountSettingsPage(AccountSettingsTabs.security);
    }
  }, [accountSettingsTab, isScreenSmOrSmaller, navigateToAccountSettingsPage]);

  // If accountSettingsTab is available, but not valid, show not found page
  // If accountSettingsTab is not available (undefined), then:
  // - If media is mobile, show the tab selection page
  // - If media is desktop, show the default tab
  if (
    accountSettingsTab &&
    !Object.values(AccountSettingsTabs).includes(accountSettingsTab)
  ) {
    return <NotFoundPage />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
      }}
    >
      {isScreenSmOrSmaller ? (
        <AccountSettingsMobileLayout
          tabs={accountSettingsTabs}
          selectedTabId={accountSettingsTab}
        />
      ) : (
        accountSettingsTab && (
          <MainPageLayout
            shouldHideOpenButton={true}
            shouldShowSidebar={false}
            shouldShowTopbarBorder={true}
            topLeftHeaderButton={<CloseAccountSettingsButton />}
            isAccountSettings={true}
          >
            <VerticalTabs
              tabs={accountSettingsTabs}
              tabSelectionHeader="General"
              selectedTabId={accountSettingsTab}
              isLoading={isFetchingLoggedInUser}
              trackingEventPageName={OpenTabEvents.openAccountSettingsTab}
              dataTestId="account-settings-tab"
            />
          </MainPageLayout>
        )
      )}
    </Box>
  );
}
