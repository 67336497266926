import { FilterMapId } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { Markup } from "@custom-types/project-markups-types";
import { MemberTypes } from "@custom-types/member-types";
import { getPrettyName, getUserInitials } from "@utils/user-utils";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { MarkupStatusIcon } from "@pages/project-details/project-markups/status/markup-status-icon";
import DateAdapter from "@date-io/luxon";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { useAppSelector } from "@store/store-helper";
import {
  markupsUniqueAssigneeSelector,
  markupsUniqueKeysSelector,
} from "@store/markups/markups-selector";
import { useMemo } from "react";
import { getMarkupStatusValue } from "@pages/project-details/project-markups/status/markup-status-utils";
import { currentUserSelector } from "@store/user/user-selector";
import { AnnotationStatus } from "@faro-lotv/ielement-types";
import { FaroStatusText } from "@faro-lotv/flat-ui";

/** Returns all the available filters for ProjectMarkup */
export function useProjectMarkupsFilterLists(): Partial<
  Record<ProjectMarkupsTableHeaders, FilterMapId<Markup>>
> {
  const markupsLabels = useAppSelector(markupsUniqueKeysSelector("labels"));
  const markupsAssignees = useAppSelector(markupsUniqueAssigneeSelector());
  const currentUser = useAppSelector(currentUserSelector);

  const allFilters = useMemo(() => {
    return {
      [ProjectMarkupsTableHeaders.status]: getMarkupsStatusFilterOptions(),
      [ProjectMarkupsTableHeaders.tags]:
        getMarkupsTagsFilterOptions(markupsLabels),
      [ProjectMarkupsTableHeaders.attachments]:
        getMarkupsAttachmentFilterOptions(),
      [ProjectMarkupsTableHeaders.assignee]: getMarkupsAssigneesFilterOptions(
        markupsAssignees,
        currentUser?.identity
      ),
      [ProjectMarkupsTableHeaders.dueDate]: getMarkupsDueDateFilterOptions(),
    };
  }, [
    currentUser?.identity,
    markupsAssignees,
    markupsLabels,
  ]);

  return allFilters;
}

function getMarkupsStatusFilterOptions(): FilterMapId<Markup> {
  const noStatusFilter: FilterMapId<Markup> = {
    unclassified: {
      id: AnnotationStatus.Unclassified,
      hasEntityPassedFilter: (markup) =>
        getMarkupStatusValue(markup.status?.value) ===
        AnnotationStatus.Unclassified,
      label: FaroStatusText.unclassified,
      icon: (
        <MarkupStatusIcon
          key={AnnotationStatus.Unclassified}
          status={AnnotationStatus.Unclassified}
          shouldHaveBorder={true}
        />
      ),
    },
  };

  const statusFilters: FilterMapId<Markup> = Object.values(
    AnnotationStatus
  ).reduce((acc, value) => {
    return {
      ...acc,
      [value]: {
        id: value,
        hasEntityPassedFilter: (markup) =>
          getMarkupStatusValue(markup.status?.value) === value,
        label: FaroStatusText[value],
        icon: (
          <MarkupStatusIcon
            key={value}
            status={value}
            shouldHaveBorder={true}
          />
        ),
      },
    };
  }, {} as FilterMapId<Markup>);

  return { ...noStatusFilter, ...statusFilters };
}

/** Returns all the filter options for markup tags */
function getMarkupsTagsFilterOptions(
  markupsLabels: NonNullable<Markup["labels"]>[]
): FilterMapId<Markup> {
  const untaggedFilter: FilterMapId<Markup> = {
    untagged: {
      id: "untagged",
      label: "Untagged",
      hasEntityPassedFilter: (markup) =>
        !markup.labels || markup.labels.length === 0,
    },
  };

  const tagFilters: FilterMapId<Markup> = markupsLabels
    .flat()
    .reduce((acc, label) => {
      return {
        ...acc,
        [label.id]: {
          id: label.id,
          label: label.name,
          hasEntityPassedFilter: (markup) => {
            const tags = Array.isArray(markup.labels)
              ? markup.labels.map((label) => label.id)
              : undefined;
            return tags?.includes(label.id) ?? false;
          },
        },
      };
    }, {} as FilterMapId<Markup>);

  return { ...untaggedFilter, ...tagFilters };
}

/** Returns all the filter options for markup attachments */
function getMarkupsAttachmentFilterOptions(): FilterMapId<Markup> {
  return {
    hasAttachment: {
      id: "hasAttachment",
      hasEntityPassedFilter: (markup) => markup.attachmentsCount > 0,
      label: "",
    },
  };
}

/** Returns all the filter options for markup assignee */
function getMarkupsAssigneesFilterOptions(
  markupsAssignees: MemberTypes[],
  currentUserId: string | undefined
): FilterMapId<Markup> {
  const noAssigneeFilter: FilterMapId<Markup> = {
    unassigned: {
      id: "unassigned",
      label: "Unassigned",
      hasEntityPassedFilter: (markup) =>
        markup.assignee?.values?.[0] === undefined,
    },
  };

  const assigneeFilters: FilterMapId<Markup> = markupsAssignees.reduce(
    (acc, assignee) => {
      return {
        ...acc,
        [assignee.identity]: {
          id: assignee.identity,
          label: getPrettyName(assignee),
          hasEntityPassedFilter: (markup) =>
            markup.assignee?.values?.[0] === assignee.identity,
          icon: (
            <SphereAvatar
              shouldUseSimplifiedAvatar={currentUserId !== assignee.identity}
              size={"small"}
              initials={getUserInitials(assignee) ?? ""}
              src={assignee.thumbnailUrl}
            />
          ),
        },
      };
    },
    {} as FilterMapId<Markup>
  );

  return { ...noAssigneeFilter, ...assigneeFilters };
}

/** Returns all the filter options for markup due date */
function getMarkupsDueDateFilterOptions(): FilterMapId<Markup> {
  const dateAdapter = new DateAdapter();
  const today = dateAdapter.date();
  const startOfToday = dateAdapter.startOfDay(today);
  const endOfToday = dateAdapter.endOfDay(today);

  /* eslint-disable @typescript-eslint/no-magic-numbers -- these numbers are requited for giving duration */
  return {
    beforeToday: {
      id: "beforeToday",
      label: "Before today",
      hasEntityPassedFilter: (markup) => {
        const dueDate = markup.dueDate?.value
          ? dateAdapter.date(markup.dueDate.value)?.valueOf()
          : undefined;
        return dueDate !== undefined && dueDate < startOfToday.valueOf();
      },
    },

    today: {
      id: "today",
      label: "Today",
      hasEntityPassedFilter: (markup) => {
        const dueDate = markup.dueDate?.value
          ? dateAdapter.date(markup.dueDate.value)?.valueOf()
          : undefined;
        return (
          dueDate !== undefined &&
          dueDate >= startOfToday.valueOf() &&
          dueDate < endOfToday.valueOf()
        );
      },
    },
    tomorrow: {
      id: "tomorrow",
      label: "Tomorrow",
      hasEntityPassedFilter: (markup) => {
        const dueDate = markup.dueDate?.value
          ? dateAdapter.date(markup.dueDate.value)?.valueOf()
          : undefined;
        return (
          dueDate !== undefined &&
          dueDate >= dateAdapter.addDays(startOfToday, 1).valueOf() &&
          dueDate < dateAdapter.addDays(startOfToday, 2).valueOf()
        );
      },
    },
    next7Days: {
      id: "next7Days",
      label: "Next 7 days",
      hasEntityPassedFilter: (markup) => {
        const dueDate = markup.dueDate?.value
          ? dateAdapter.date(markup.dueDate.value)?.valueOf()
          : undefined;
        return (
          dueDate !== undefined &&
          dueDate >= dateAdapter.addDays(startOfToday, 0).valueOf() &&
          dueDate < dateAdapter.addDays(startOfToday, 7).valueOf()
        );
      },
    },
    next30Days: {
      id: "next30Days",
      label: "Next 30 days",
      hasEntityPassedFilter: (markup) => {
        const dueDate = markup.dueDate?.value
          ? dateAdapter.date(markup.dueDate.value)?.valueOf()
          : undefined;
        return (
          dueDate !== undefined &&
          dueDate >= dateAdapter.addDays(startOfToday, 0).valueOf() &&
          dueDate < dateAdapter.addDays(startOfToday, 30).valueOf()
        );
      },
    },
    next90Days: {
      id: "next90Days",
      label: "Next 90 days",
      hasEntityPassedFilter: (markup) => {
        const dueDate = markup.dueDate?.value
          ? dateAdapter.date(markup.dueDate.value)?.valueOf()
          : undefined;
        return (
          dueDate !== undefined &&
          dueDate >= dateAdapter.addDays(startOfToday, 0).valueOf() &&
          dueDate < dateAdapter.addDays(startOfToday, 90).valueOf()
        );
      },
    },
    noDate: {
      id: "noDate",
      label: "No date",
      hasEntityPassedFilter: (markup) => {
        const dueDate = markup.dueDate?.value;
        return dueDate === undefined;
      },
    },
  };
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}
