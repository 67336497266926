import { PageSectionProps } from "@components/page-section/page-section";
import { Skeleton } from "@mui/material";
import { AccountProfilePicture } from "@pages/account-settings/sections/account-profile-picture";
import { CoreAPITypes } from "@stellar/api-logic";
import { getIdentityProviderDisplayName } from "@utils/user-utils";
import { AccountChangeEmailDialog } from "@pages/account-settings/sections/account-change-email-dialog";
import { AccountChangePassword } from "@pages/account-settings/sections/account-change-password/change-password";
import { AccountDeletion } from "@pages/account-settings/sections/account-deletion";
import { BaseUserProps } from "@custom-types/member-types";
import { AccountChangeName } from "@pages/account-settings/sections/account-change-name";
import { AccountReadOnlyText } from "@pages/account-settings/sections/account-read-only-text";

/** Content for the account and security tab of account settings page */
export function accountSettingsSecurityTab({
  user,
}: BaseUserProps): PageSectionProps[] {
  return [
    {
      title: "Public profile",
      subtitle: `Your profile photo and name appear when you log in to Sphere XG, FARO products and services.
         You can update your profile at any time and it will automatically update anywhere your profile 
         photo and name appear.`,
      items: [
        {
          label: "Profile picture",
          content: <AccountProfilePicture />,
          shouldAddRightPadding: true,
          customSkeleton: (
            <Skeleton
              variant="circular"
              sx={{ width: "54px", height: "54px" }}
            />
          ),
        },
        {
          label: "First name",
          content: <AccountChangeName field="firstName" />,
        },
        {
          label: "Last name",
          content: <AccountChangeName field="lastName" />,
        },
      ],
    },
    {
      title: "Log in",
      subtitle: `You are using email and a password to log in. 
        Changing this information changes how you'll sign in to FARO Sphere XG.`,
      isHidden: user.providerId !== CoreAPITypes.EUserJsonProviderId.email,
      items: [
        {
          label: "Email",
          content: <AccountChangeEmailDialog />,
          shouldHideTopBorder: true,
        },
        {
          label: "Current password",
          content: <AccountChangePassword email={user.mailAddress} />,
          shouldAddRightPadding: true,
        },
      ],
    },
    {
      title: "Single sign-on (SSO)",
      subtitle: `You are using Single Sign On (SSO) to connect with Sphere XG. 
        The SSO provider cannot be changed automatically, if you need to change it, please contact us.`,
      isHidden: user.providerId === CoreAPITypes.EUserJsonProviderId.email,
      items: [
        {
          label: "Identity provider",
          content: (
            <AccountReadOnlyText
              text={getIdentityProviderDisplayName(user)}
              shouldPreventTranslation={true}
            />
          ),
          shouldHideTopBorder: true,
        },
      ],
    },

    {
      title: "Delete account",
      subtitle:
        "This will delete your account. Your account will be permanently deleted from Sphere XG",
      items: [
        {
          label: "Delete account",
          content: <AccountDeletion />,
          shouldAlignToEnd: true,
          shouldAddRightPadding: true,
          shouldHideTopBorder: true,
        },
      ],
    },
  ];
}
