import { Markup, MarkupsKPIs } from "@custom-types/project-markups-types";
import {
  isIElementAttachmentsGroup,
  isIElementMarkupImage,
  isIElementMarkupWithTypeHint,
} from "@custom-types/i-elements-type-guards";
import {
  IElementDateTimeMarkupField,
  IElementDropDownMarkupField,
  IElementImg2d,
  IElementModel3D,
  IElementSection,
  IElementUserDirectoryMarkupField,
  isIElementAreaSection,
  isIElementMarkupAssignee,
  isIElementMarkupDueTime,
  isIElementMarkupState,
  isIElementModel3d,
  isIElementSection,
  IElementTypeHint,
  IElementAreaSection,
} from "@faro-lotv/ielement-types";
import {
  selectAllIElementsOfType,
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
} from "@faro-lotv/project-source";
import { RootState } from "@store/store-helper";
import {
  findSlideNodeAndSceneObject,
  isMarkupInProgress,
  isMarkupInReview,
  isMarkupOverdue,
  isMarkupResolved,
  isMarkupToDo,
  isMarkupUnclassified,
  sortMarkups,
} from "@utils/markups-utils";
import { slideNodesSelector } from "@store/slide-nodes/slide-nodes-selector";
import { getSceneObjectWebEditorUrl } from "@utils/web-editor-utils";
import { selectRootExternalId } from "@store/i-elements/i-elements-selectors";
import { getSphereViewerUrl } from "@utils/project-utils";
import { IElementMarkupWithTypeHint } from "@custom-types/i-elements-types";
import { selectedProjectIdSelector } from "@store/projects/projects-selector";

/**
 * @returns All Markup entities generated from the ielements available in the redux store.
 * @param state Redux store state.
 */
export function getMarkups(state: RootState): Markup[] {
  const markups = selectAllIElementsOfType(isIElementMarkupWithTypeHint)(state);
  const extendedMarkups: Markup[] = markups.map((markup) => getMarkup(state, markup));
  return sortMarkups(extendedMarkups);
}

/**
 * @returns A Markup entity from a given markup ielement.
 * @param state Redux store state.
 * @param markup Markup ielement.
 */
export function getMarkup(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): Markup {
  return {
    ...markup,
    assignee: getAssignee(state, markup),
    dueDate: getDueDate(state, markup),
    status: getStatus(state, markup),
    image: getImage(state, markup),
    model3d: getModel3d(state, markup),
    section: getSection(state, markup),
    areaSection: getAreaSection(state, markup),
    sphereViewerUrl: getMarkupSphereViewerUrl(state, markup),
    webEditorUrl: getMarkupWebEditorUrl(state, markup),
    attachmentsCount: getAttachmentsCount(state, markup),
  };
}

/** Gets the markup assignee element associated to the markup */
function getAssignee(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementUserDirectoryMarkupField | undefined {
  return selectChildDepthFirst(markup, isIElementMarkupAssignee)(state);
}

/** Gets the markup dueDate element associated to the markup */
function getDueDate(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementDateTimeMarkupField | undefined {
  return selectChildDepthFirst(markup, isIElementMarkupDueTime)(state);
}

/** Gets the markup status element associated to the markup */
function getStatus(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementDropDownMarkupField | undefined {
  return selectChildDepthFirst(markup, isIElementMarkupState)(state);
}

/** Gets the 2d image element associated to the markup */
function getImage(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementImg2d | undefined {
  return selectChildDepthFirst(markup, isIElementMarkupImage)(state);
}

/** Gets the Model3d element associated to the markup */
function getModel3d(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementModel3D | undefined {
  return selectAncestor(markup, isIElementModel3d)(state);
}

/** Gets the section element associated to the markup */
function getSection(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementSection | undefined {
  return selectAncestor(markup, isIElementSection)(state);
}

/** Gets the area section element associated to the markup */
function getAreaSection(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): IElementAreaSection | undefined {
  return selectAncestor(markup, isIElementAreaSection)(state);
}

/** Gets the WebEditor URL of the markup */
function getMarkupWebEditorUrl(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): string | undefined {
  // Find the slide node and the scene object associated to the markup
  const slideNodes = slideNodesSelector(state);
  const model3d = getModel3d(state, markup);

  const slideNodeAndSceneObject = findSlideNodeAndSceneObject(
    slideNodes,
    markup,
    model3d
  );
  const projectId = selectedProjectIdSelector(state);
  if (!slideNodeAndSceneObject || !projectId) {
    return;
  }

  return getSceneObjectWebEditorUrl({
    projectId,
    ...slideNodeAndSceneObject,
  });
}

/** Gets the Sphere Viewer URL of the markup */
function getMarkupSphereViewerUrl(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): string | undefined {
  const projectId = selectRootExternalId(state);
  const lookAtId = markup.id;

  if (!projectId) {
    return;
  }

  return getSphereViewerUrl({
    projectId,
    lookAtId,
  }).href;
}

/**
 * @returns the number of attachments of the provided markup
 * The amount of attachments is taken from the sum of children IDs of each the attachments group element.
 * @param state Redux store state
 * @param markup Markup element
 */
function getAttachmentsCount(
  state: RootState,
  markup: IElementMarkupWithTypeHint
): number {
  const attachmentsGroups = selectChildrenDepthFirst(markup, isIElementAttachmentsGroup)(state);
  return attachmentsGroups.reduce((acc, el) => acc + (el.childrenIds?.length ?? 0), 0);
}

/** Gets the markups KPIs */
export function getMarkupsKPIs(markups: Markup[]): MarkupsKPIs {
  const markupsSummary = {
    total: markups.length,
    toDo: 0,
    inProgress: 0,
    inReview: 0,
    resolved: 0,
    unclassified: 0,
    overdue: 0,
  };
  markups.forEach((markup) => {
    if (isMarkupToDo(markup)) {
      markupsSummary.toDo++;
    } else if (isMarkupInProgress(markup)) {
      markupsSummary.inProgress++;
    } else if (isMarkupInReview(markup)) {
      markupsSummary.inReview++;
    } else if (isMarkupResolved(markup)) {
      markupsSummary.resolved++;
    } else if (isMarkupUnclassified(markup)) {
      markupsSummary.unclassified++;
    }

    // We do not use else-if because there are cases that a markup can be in multiple states,
    // for example, it can be in review and overdue at the same time.
    if (isMarkupOverdue(markup)) {
      markupsSummary.overdue++;
    }
  });
  return markupsSummary;
}

/** 
 * Determines if a 3D model node should be deleted based on its children and type.
 */
export function shouldDeleteModel3D(
  model3d: IElementModel3D | undefined,
  elementId: string
): boolean {
  if (!model3d || model3d.typeHint !== IElementTypeHint.node){
    return false;
  }
  const otherChildren = model3d.childrenIds?.filter((id) => id !== elementId) || [];
  return otherChildren.length === 0;
}