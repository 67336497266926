import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { Grid, Stack, SvgIcon, Typography } from "@mui/material";
import CheckRectangular from "@assets/icons/new/check_rectangular_l18.svg?react";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_TITLE_FONT_SIZE } from "@styles/common-styles";

interface QuoteConfirmationDialogProps {
  /** Callback to close the dialog */
  onClose: () => void;
}

/**
 * Render a dialog to show the confirmation message after the user has requested a quote.
 */
export function QuoteConfirmationDialog({
  onClose,
}: QuoteConfirmationDialogProps): JSX.Element {
  return (
    <FaroDialog
      open={true}
      title=""
      closeText="Ok"
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      shouldForceShowCloseButton={true}
      customCloseButtonSx={{
        borderRadius: "4px",
        border: `1px solid ${sphereColors.blue600}`,
        background: sphereColors.blue500,
        color: sphereColors.pureWhite,
        "&:hover": {
          background: sphereColors.blue600,
        },
      }}
    >
      <Grid
        maxWidth="100%"
        alignItems="center"
        container
        flexDirection="column"
      >
        <Typography
          sx={{
            fontSize: "32px",
            textAlign: "center",
            width: "100%",
            whiteSpace: "pre-line",
          }}
        >
          Thank you,{"\n"}your request has been sent.
        </Typography>
        <Stack 
          sx={{ 
            alignItems: "center", 
            py: "52px", 
            width: "100%",
          }}
        >
          <SvgIcon
            inheritViewBox={true}
            component={CheckRectangular}
            sx={{
              width: "84px",
              height: "84px",
            }}
          />
        </Stack>
        <Typography
          sx={{
            fontSize: DEFAULT_TITLE_FONT_SIZE,
            textAlign: "center",
            width: "100%",
          }}
        >
          We'll provide the details within 1-2 business days.
        </Typography>
      </Grid>
    </FaroDialog>
  );
}
