import { Typography } from "@mui/material";
import { useAccountSettingsLayout } from "@pages/account-settings/account-settings-layout/account-settings-layout-utils";

interface Props {
  /** String to show */
  text: string;

  /** Whether to send the text to localize for translation or not */
  shouldPreventTranslation?: boolean;
}

/** Shows a static text that is not possible to change */
export function AccountReadOnlyText({
  text,
  shouldPreventTranslation = false,
}: Props): JSX.Element {
  const { accountSettingsFontSize, accountSettingsFontWeight } =
    useAccountSettingsLayout();

  return (
    <Typography
      sx={{
        fontSize: accountSettingsFontSize,
        fontWeight: accountSettingsFontWeight,
      }}
    >
      {shouldPreventTranslation ? <var>{text}</var> : text}
    </Typography>
  );
}
