import { useMediaQueryList } from "@hooks/use-media-query";
import {
  DEFAULT_BOLD_TEXT_FONT_WEIGHT,
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TEXT_FONT_WEIGHT,
} from "@styles/common-styles";

interface ReturnProps {
  /** The font size uses in account settings */
  accountSettingsFontSize: string;

  /** The font weight uses in account settings */
  accountSettingsFontWeight: number;
}

/**
 * Returns the common styling for account settings layout.
 * It has its own specific file as the design for account settings is different than other parts of the Dashboard
 */
export function useAccountSettingsLayout(): ReturnProps {
  const { isScreenSmOrSmaller } = useMediaQueryList();

  const accountSettingsFontSize = isScreenSmOrSmaller
    ? DEFAULT_INPUT_FONT_SIZE
    : "1.25rem";

  const accountSettingsFontWeight = isScreenSmOrSmaller
    ? DEFAULT_TEXT_FONT_WEIGHT
    : DEFAULT_BOLD_TEXT_FONT_WEIGHT;

  return {
    accountSettingsFontSize,
    accountSettingsFontWeight,
  };
}
