import { FaroButton } from "@components/common/faro-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { BaseProjectIdProps, ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import { getSphereViewerUrl, OPEN_OTHERS_TARGET_ATTRIBUTE, trackOpenProject } from "@utils/project-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";

export function OpenViewerButton({ projectId }: BaseProjectIdProps): JSX.Element {
  const { trackAsyncEvent } = useTrackEvent();

  const sphereViewerUrl = getSphereViewerUrl({ projectId }).href;

  return (
    <SphereTooltip title="View the published data.">
      <FaroButton
        to={sphereViewerUrl}
        target={OPEN_OTHERS_TARGET_ATTRIBUTE}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
          trackOpenProject({
            openFrom: "projectDetails-dataManagement",
            numberOfMembers: undefined,
            openTarget: ProjectLaunchTarget.sphereViewer,
            trackAsyncEvent,
          });
        }}
      >
        Open
      </FaroButton>
    </SphereTooltip>
  );
}
