import { FaroHelpCenterMenu } from "@components/header/faro-help-center-menu";
import { Searchbar } from "@components/header/searchbar";
import { ProgressOverviewMenu } from "@components/progress-overview/progress-overview-menu";
import { UserMenu } from "@components/header/user-menu";
// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { MainPageLayoutProps } from "@components/page-layout/main-page-layout";

type Props = Pick<
  MainPageLayoutProps,
  "shouldShowProgressOverviewMenu" | "shouldShowUserMenu"
>;

/** Content for the right side of layout header */
export function HeaderLayoutRight({
  shouldShowProgressOverviewMenu,
  shouldShowUserMenu,
}: Props): JSX.Element {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="right"
    >
      {/* Search bar */}
      <Searchbar />
      {/* Icons and buttons */}
      <Grid container alignItems="center" gap="1rem">
        <FaroHelpCenterMenu />

        {shouldShowProgressOverviewMenu && <ProgressOverviewMenu />}

        {shouldShowUserMenu && <UserMenu />}
      </Grid>
    </Grid>
  );
}
