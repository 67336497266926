import { HeaderLayoutLeftButton } from "@components/page-layout/header/header-layout-left-button";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { useCloseAccountSettings } from "@pages/account-settings/use-close-account-settings";

/** Button in the header to close the account settings page */
export function CloseAccountSettingsButton(): JSX.Element {
  const { onCloseButtonClick } = useCloseAccountSettings();

  return (
    <HeaderLayoutLeftButton
      tooltip="Close Account Settings"
      icon={CloseIcon}
      text="Close"
      onClickHandler={onCloseButtonClick}
    />
  );
}
