import { FaroButton } from "@components/common/faro-button";
import { FaroTextButton } from "@components/common/faro-text-button";
import { useMediaQueryList } from "@hooks/use-media-query";
import { useToast } from "@hooks/use-toast";
import { UserProfile } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useShowCookieManager, useIsCookieManagerInitialized } from "@faro-lotv/foreign-observers";

/** Allow user to manage the cookie manager in account settings */
export function AccountCookiesPreferences(): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { showToast } = useToast();
  const { isScreenSmOrSmaller } = useMediaQueryList();
  const isCookieManagerInitialized = useIsCookieManagerInitialized();
  const showCookieManager = useShowCookieManager();

  function showManageCookiesError(): void {
    showToast({
      type: "error",
      message: "Unknown error loading cookie manager.",
      description: "Please reload the page or try again later.",
    });
  }

  function onCookiePreferences(): void {
    trackEvent({
      name: UserProfile.manageCookies,
    });

    // If the cookie manager is not available, show an error message
    if (!isCookieManagerInitialized) {
      showManageCookiesError();
      return;
    }

    try {
      showCookieManager();
    } catch (error) {
      showManageCookiesError();
    }
  }

  if (isScreenSmOrSmaller) {
    return (
      <FaroButton onClick={onCookiePreferences} sx={{ width: "100%" }}>
        Open cookies preferences
      </FaroButton>
    );
  }

  return (
    <FaroTextButton onClick={onCookiePreferences}>
      Open cookies preferences
    </FaroTextButton>
  );
}
