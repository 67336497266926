import { LsClusterRaw, LsScanRaw } from "@api/stagingarea-api/stagingarea-api-types";
import {
  BaseCaptureTreeEntity,
  CaptureTreeRevision,
  ClusterEntity,
  EntityMaps,
  RootEntity,
  SCAN_ENTITY_TYPES,
  ScanEntity,
  ScanEntityType,
} from "@custom-types/capture-tree/capture-tree-types";
import { assert, GUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntity, CaptureTreeEntityType, RegistrationState,
  RevisionType, Transformation, CaptureApiClient, ProjectApi,
} from "@faro-lotv/service-wires";
import { getExternalScanId } from "@pages/project-details/project-data-management/data-management-utils";
import { UUID } from "@stellar/api-logic/dist/api/core-api/api-types";
import { assertValue } from "@utils/assert-utils";

/** Path of the RootEntity in the Capture Tree and in LsDataV2. */
export const ROOT_ENTITY_PATH = getEntityPath("", "root");

/**
 * Returns a name-path for an entity.
 * Used to identify LsDataV2 clusters in the Capture Tree, since they don't have an "externalId".
 * The JSON encoding is used to ensure that two different lists of names never return the same path
 * (consider e.g. ["root", "C1"] vs. ["root,C1"]).
 * @param parentPath Encoded name-path of the parent entity.
 * @param name Name of the entity.
 * @returns Encoded name-path of the entity.
 */
export function getEntityPath(parentPath: string, name: string): string {
  const encodedName = JSON.stringify(name);
  return parentPath ? `${parentPath},${encodedName}` : encodedName;
}

/**
 * Alternative version of getEntityPath that accepts an array of names.
 * @param names Ordered list of names: [root.name, ..., parentEntity.name, entity.name].
 * @returns Encoded name-path of the entity.
 */
export function getEntitiesPath(names: string[]): string {
  return names.map((name) => getEntityPath("", name)).join(",");
}

/**
 * @returns A new instance of an identity transformation for Capture Tree / ProjectAPI.
 *          This means you can safely modify any property of the returned object.
 */
export function getIdentityPose(): Transformation {
  return {
    pos: { x: 0, y: 0, z: 0 },
    rot: { x: 0, y: 0, z: 0, w: 1 },
  };
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a root.
 */
export function isRootEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is RootEntity<T> {
  return entity.type === CaptureTreeEntityType.root && entity.parentId === null;
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a cluster.
 */
export function isClusterEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is ClusterEntity<T> {
  return entity.type === CaptureTreeEntityType.cluster;
}

/**
 * @param entity The capture tree entity to check.
 * @returns Whether the entity is a scan.
 */
export function isScanEntity<T extends BaseCaptureTreeEntity>(
  entity: T
): entity is ScanEntity<T> {
  return isScanEntityType(entity.type);
}

export function isScanEntityType(type: CaptureTreeEntityType): type is ScanEntityType {
  const sType = type as ScanEntityType;
  return SCAN_ENTITY_TYPES[sType] !== undefined;
}

/**
 * @param entities The capture tree entities
 * @returns The root entity or undefined if not found
 */
export function getRootEntity<T extends BaseCaptureTreeEntity>(
  entities: T[]
): RootEntity<T> | undefined {
  return entities.find(isRootEntity);
}

/**
 * @param entities The capture tree entities
 * @returns The cluster entities
 */
export function getClusterEntities<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T>[] {
  return entities.filter(isClusterEntity);
}

export function getEntityMaps<T extends BaseCaptureTreeEntity>(
  entities: T[]
): EntityMaps<T> {
  const entityById: Record<GUID, T> = Object.fromEntries(
    entities.map((entity) => [entity.id, entity])
  );
  const pathById: Record<GUID, string> = {};
  const idByPath: Record<string, GUID> = {};
  const scanIdByUuid: Record<UUID, GUID> = {};

  // Sort the entities so we get a consistent order, since paths may be duplicated.
  const entitiesSorted = [...entities].sort(
    (a, b) => a.id.localeCompare(b.id, "en")
  );

  const rootEntity = getRootEntity(entities);
  if (!rootEntity) {
    assert(entities.length === 0, "RootEntity is required if > 0 entities are present");
    return { entityById, pathById, idByPath, scanIdByUuid };
  }
  pathById[rootEntity.id] = ROOT_ENTITY_PATH;

  // eslint-disable-next-line no-constant-condition -- We exit the loop using "return" once all entities have a path.
  while (true) {
    let isChanged = false;
    for (const entity of entitiesSorted) {
      assert(entity.id, "Entity ID is required");
      if (!entity.parentId || pathById[entity.id]) {
        continue;
      }
      const parentPath  = pathById[entity.parentId];
      if (parentPath) {
        pathById[entity.id] = getEntityPath(parentPath, entity.name);
        isChanged = true;
      }
    }
    if (!isChanged) {
      break;
    }
  }

  for (const [id, path] of Object.entries(pathById)) {
    idByPath[path] = id;
  }

  for (const entity of entitiesSorted) {
    const externalId = getExternalScanId(entity);
    if (externalId) {
      scanIdByUuid[externalId] = entity.id;
    }
  }

  return { entityById, pathById, idByPath, scanIdByUuid };
}

// ##### De-duplication of cluster paths ##### //

/** @returns "id" or "uuid" attribute. */
function getId(obj: LsClusterRaw | ClusterEntity | LsScanRaw | ScanEntity): GUID | UUID {
  return (obj as (ClusterEntity | ScanEntity)).id || (obj as unknown as (LsClusterRaw | LsScanRaw)).uuid;
}
/** @returns "parentId" or "parentUuid" attribute. */
function getParentId(obj: LsClusterRaw | ClusterEntity | LsScanRaw | ScanEntity):  GUID | UUID {
  return (obj as (ClusterEntity | ScanEntity)).parentId || (obj as unknown as (LsClusterRaw | LsScanRaw)).parentUuid;
}
/** @returns Key for the cluster that we're trying to make unique. */
function getKey(cluster: LsClusterRaw | ClusterEntity): string {
  return `${getParentId(cluster)}/${cluster.name}`;
}

/**
 * @see deduplicateClusterPaths
 * @param entities The whole Capture Tree.
 * @returns List of clusters to rename.
 */
export function deduplicateClusterPathsCaptureTree(entities: CaptureTreeEntity[]): ClusterEntity[] {
  return deduplicateClusterPaths(
    entities.filter(isClusterEntity),
    entities.filter(isScanEntity)
  );
}

/**
 * Make the names of clusters unique by appending a suffix to the duplicates.
 * Clusters with more direct children (especially scans) are preferred for keeping their original name.
 *
 * The implementation makes `parentId/name` unique, which is equivalent to making the name-path unique.
 * For the same list of clusters, no matter in which order, the function returns the same result.
 *
 * @param clusters List of clusters to deduplicate.
 * @param scans List of scans to determine the importance of each cluster.
 * @returns List of clusters to rename.
 */
export function deduplicateClusterPaths<
  ClusterType extends LsClusterRaw | ClusterEntity,
  ScanType extends LsScanRaw | ScanEntity,
>(
  clusters: ClusterType[],
  scans: ScanType[]
): ClusterType[] {
  const clustersByParentUuidAndName: Record<string, ClusterType[]> = {};
  for (const cluster of clusters) {
    const key = getKey(cluster);
    clustersByParentUuidAndName[key] = clustersByParentUuidAndName[key] || [];
    clustersByParentUuidAndName[key].push(cluster);
  }

  // Keep track of already used names.
  const usedKeys = new Set<string>();
  const duplicates: Record<string, ClusterType[]> = {};
  for (const key in clustersByParentUuidAndName) {
    const clusters = clustersByParentUuidAndName[key];
    if (clusters.length === 1) {
      usedKeys.add(key);
    } else {
      duplicates[key] = clusters;
    }
  }
  if (!Object.keys(duplicates).length) {
    return [];
  }

  // Clusters with more content (especially scans) should be preferred for keeping their original name.
  // Better would be to consider all ancestors, but that would be too complicated to implement.
  const WEIGHT_SCAN = 1000;
  const weights: Record<UUID, number> = {};
  const renamed: ClusterType[] = [];

  for (const key in duplicates) {
    const clustersKey = duplicates[key];
    for (const c of clustersKey) {
      weights[getId(c)] =
        WEIGHT_SCAN * scans.filter((s) => getParentId(s) === getId(c)).length +
        clusters.filter((c2) => getParentId(c2) === getId(c)).length;
    }
    // Sort by descending weight, then by ID to get a defined order.
    clustersKey.sort((a, b) => (weights[getId(b)] - weights[getId(a)]) || getId(a).localeCompare(getId(b)));

    const FIRST_UUID_SEGMENT = 8;
    for (const c of clustersKey) {
      const origName = c.name;
      let key = getKey(c);
      if (usedKeys.has(key)) {
        c.name = `${origName} (${getId(c).substring(0, FIRST_UUID_SEGMENT)})`;
        key = getKey(c);
        if (usedKeys.has(key)) {
          c.name = `${origName} (${getId(c)})`;
          key = getKey(c);
        }
        renamed.push(c);
      }
      usedKeys.add(key);
    }
  }

  return renamed;
}

// ########################################### //

/** @returns True if the provided revision is an open Draft Revision. */
export function isOpenDraftRevision(revision: CaptureTreeRevision): boolean {
  return revision.revisionType === RevisionType.draft &&
    revision.state !== RegistrationState.merged && revision.state !== RegistrationState.canceled;
}

/**
 * Renames the provided `scansToRename` in the capture tree in a dedicated revision.
 * On success, the revision is merged to the draft, otherwise it is canceled.
 *
 * @param scansToRename Scan entity/entities, with desired new `name`.
 */
export async function createRevisionToRenameScans(
  projectApiClient: ProjectApi, entities: CaptureTreeEntity[], scansToRename: ScanEntity[]
): Promise<void> {
  assert(scansToRename.length > 0);
  const rootId = assertValue(getRootEntity(entities)).id;
  const scanIds = new Set(scansToRename.map((scan) => scan.id));
  const entitiesMap = new Map(entities.map((entity) => [entity.id, entity]));

  // Find ancestors of the scans to rename, as required by Capture Tree API.
  const clusterIds = new Set<GUID>();
  for (const entity of scansToRename) {
    let { parentId } = entity;
    while (parentId && parentId !== rootId) {
      const parent = entitiesMap.get(parentId);
      clusterIds.add(parentId);
      parentId = parent?.parentId ?? null;
    }
  }
  const entityIds = [rootId, ...clusterIds, ...scanIds];

  const revision = await projectApiClient.createRegistrationRevision(
    [...entityIds], /* registrationEdgeIds */ [], CaptureApiClient.dashboard
  );
  const registrationRevisionId = revision.id;

  try {
    await projectApiClient.createOrUpdateRootEntityForRegistrationRevision({
      registrationRevisionId,
      requestBody: {
        id: rootId,
      },
    });

    if (clusterIds.size > 0) {
      await projectApiClient.createOrUpdateClusterEntitiesForRegistrationRevision({
        registrationRevisionId,
        requestBody: [...clusterIds].map((id) => ({ id })),
      });
    }

    await projectApiClient.createOrUpdateScanEntitiesForRegistrationRevision({
      registrationRevisionId,
      requestBody: scansToRename.map(({ id, name }) => ({ id, name })),
    });

    await projectApiClient.updateRegistrationRevision({
      registrationRevisionId,
      state: RegistrationState.registered,
    });

    await projectApiClient.applyRegistrationRevision(
      registrationRevisionId
    );
  } catch (error) {
    // Try to cancel the revision, ignoring errors, then throw original error.
    await projectApiClient.updateRegistrationRevision({
      registrationRevisionId,
      state: RegistrationState.canceled,
    }).catch(() => undefined);

    throw error;
  }
}
