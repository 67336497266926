import { FaroClickableIcon } from "@components/common/faro-clickable-icon";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import DownloadIcon from "@assets/icons/new/download_24px.svg?react";
import { Skeleton } from "@mui/material";
import { useToast } from "@hooks/use-toast";
import { ExportType, TableType } from "@utils/track-event/track-event-list";
import { projectsSelector } from "@store/projects/projects-selector";
import {
  allCreditSubscriptionsSelector,
  selectedSdbCompanyNameSelector,
} from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import {
  downloadPurchasesDataAsCSV,
  downloadTransactionsDataAsCSV,
} from "@utils/csv-data-export";
import { useDateTime } from "@hooks/use-date-time";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useMembersUtils } from "@hooks/use-member-utils";
import {
  creditTransactionsSelector,
  fetchingTransactionsFlagsSelector,
} from "@store/credit-transactions/credit-transactions-selector";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { fetchedMembersFlagsSelector } from "@store/members/members-selector";
import { FetchingStatus } from "@store/store-types";

interface Props {
  /** Whether the current toggleView is transactions */
  isTransactionsView: boolean;
}

/** Renders a CSV icon button to download the transactions or purchases list as a CSV file. */
export function CreditDownloadCsvIcon({
  isTransactionsView,
}: Props): JSX.Element {
  const { showToast } = useToast();
  const { formatDate } = useDateTime();
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const companyName = useAppSelector(selectedSdbCompanyNameSelector);
  const { companyMembers } = useMembersUtils();
  const transactions = useAppSelector(creditTransactionsSelector);
  const projects = useAppSelector(projectsSelector);

  const { status } = useAppSelector(fetchingTransactionsFlagsSelector);

  const { hasFetchedWorkspaceMembers } = useAppSelector(
    fetchedMembersFlagsSelector
  );

  const subscriptions = useAppSelector(allCreditSubscriptionsSelector);

  if (
    isTransactionsView &&
    (!hasFetchedWorkspaceMembers || status === FetchingStatus.pending)
  ) {
    return (
      <SphereTooltip title="Transactions are loading">
        <Skeleton
          variant="rectangular"
          sx={{ width: "20px", height: "20px" }}
        />
      </SphereTooltip>
    );
  }

  function exportDataAsCvs(): void {
    const dataType = isTransactionsView
      ? TableType.creditTransactions
      : TableType.creditPurchases;
    const entityCount = isTransactionsView
      ? transactions.length
      : subscriptions.length;

    trackEvent({
      name: ExportType.exportAsCsv,
      props: {
        dataType: dataType,
        numberOfEntities: entityCount,
        areEntitiesSelected: false,
      },
    });

    try {
      if (isTransactionsView) {
        downloadTransactionsDataAsCSV({
          fileName: `${companyName}-Transactions List.csv`,
          transactions,
          companyMembers,
          projects,
          formatDate,
        });
      } else {
        downloadPurchasesDataAsCSV({
          subscriptions,
          fileName: `${companyName}-Purchases List.csv`,
          formatDate,
        });
      }

      showToast({
        type: "success",
        message: `Successfully exported ${
          isTransactionsView ? "transactions" : "purchases"
        } as CSV.`,
      });
    } catch (error) {
      handleErrorWithToast({
        id: `creditDownlandCvs-${Date.now().toString()}`,
        title: `Couldn't export ${
          isTransactionsView ? "transactions" : "purchases"
        }. Please try again.`,
        error,
      });
    }
  }

  return (
    <SphereTooltip
      title={`Export ${isTransactionsView ? "transactions" : "purchases"}`}
    >
      <FaroClickableIcon
        component={DownloadIcon}
        onClick={exportDataAsCvs}
        iconSize="20px"
      />
    </SphereTooltip>
  );
}
