import { AccountSettingsDropdown } from "@pages/account-settings/account-settings-dropdown";
import { useLanguage } from "@hooks/use-language";

/**
 * A simple Language dropdown.
 * When opened the user can chose between the available languages
 * and select to which one the Dashboard should be translated to.
 */
export function AccountLanguageSelector(): JSX.Element {
  const { languages, onLanguageChange, currentLanguage } = useLanguage();

  const languageItems = languages.map((language) => {
    return {
      value: language.code,
      label: language.name,
    };
  });

  return (
    <AccountSettingsDropdown
      items={languageItems}
      selectedItem={{
        label: currentLanguage.name,
        value: currentLanguage.code,
      }}
      onClickHandler={onLanguageChange}
    />
  );
}
