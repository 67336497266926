import { CaptureApiClient, RegistrationState } from "@faro-lotv/service-wires";
import {
  PublishedData,
  PublishedElsData,
} from "@pages/project-details/project-data-management/published-data/published-data-types";

export const MOCK_PUBLISHED_ELS_DATA: PublishedElsData = {
  id: "34e9c835-3766-4f37-687c-08dcaa3830dc",
  projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
  state: RegistrationState.merged,
  createdByClient: CaptureApiClient.registrationBackend,
  reportUri: "someUri",
  createdAt: "2024-07-22T17:32:29.6363316+00:00",
  createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
  modifiedAt: "2024-07-24T07:01:34.1519557+00:00",
  modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
};

export const MOCK_PUBLISHED_DATA: PublishedData[] = [MOCK_PUBLISHED_ELS_DATA];
