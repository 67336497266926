import { APITypes } from "@stellar/api-logic";
import { MainRoutes } from "@router/route-params";
import { BaseProjectProps } from "@custom-types/project-types";
import { useAppSelector } from "@store/store-helper";
import { isBetaTestingEnabledOrNotProdSelector, isDevModeEnabledSelector } from "@store/app/app-selector";
import { selectedCompanyAvailableFeatureSelector } from "@store/sdb-company/sdb-company-selector";
import { isProjectArchiveSelector } from "@store/projects/projects-selector";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { PreservingNavLink } from "@components/common/preserve-navigation/preserving-nav-link";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";
import { colorConst, sphereColors } from "@styles/common-colors";

interface Props extends BaseProjectProps {
  /** Flag to show a loading state */
  isLoading?: boolean;
}

/**
 *  Button to open staging area of a project for data management
 */
export function DataManagementButton({
  project,
  isLoading,
}: Props): JSX.Element | null {
  const { trackEvent } = useTrackEvent();

  const isBetaTestingEnabled = useAppSelector(
    isBetaTestingEnabledOrNotProdSelector
  );
  const { canViewDataManagement } = useHasUserValidPermissionProjectLevel({
    project,
  });
  const isProjectArchived = useAppSelector(
    isProjectArchiveSelector(project.id)
  );
  const canViewDataManagementArea = useAppSelector(
    selectedCompanyAvailableFeatureSelector(APITypes.EUserSubscriptionRole.manageDataAccess)
  );

  const hasRegularAccess = (isBetaTestingEnabled || canViewDataManagementArea) && canViewDataManagement && !isProjectArchived;
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const isDevOnly = !hasRegularAccess && isDevModeEnabled;

  /**
   * Returning null if:
   * - Loading
   * - (No beta flag, or no permission, or project is archived) and not (dev mode).
   */
  if (
    isLoading ||
    (!hasRegularAccess && !isDevModeEnabled)
  ) {
    return null;
  }

  const sx = isDevOnly ? {
    color: colorConst.devFeature,
    borderColor: colorConst.devFeature,
    "&:hover": {
      color: colorConst.devFeature,
      borderColor: colorConst.devFeature,
      backgroundColor: sphereColors.gray100,
      filter: "brightness(90%)",
    },
  } : undefined;

  return (
    <PreservingNavLink to={`../${MainRoutes.dataManagement}`}>
      <FaroButtonContained
        sx={sx}
        onClick={() => {
          trackEvent({
            name: ProjectEvents.openDataManagement,
            props: {
              origin: "projectDetails",
            },
          });
        }}
      >
        Manage Data
      </FaroButtonContained>
    </PreservingNavLink>
  );
}
