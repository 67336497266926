import { ProjectArchivingState } from "@custom-types/project-types";

/** List all the main routes that user can navigate */
export enum MainRoutes {
  /** The root page of the app which will be redirected to app route */
  root = "/",

  /** The main page of the app */
  app = "/:companyId",

  /** The projects page of the app */
  projects = "projects",

  /** The members page of the app */
  members = "users",

  /** The groups page of the app */
  groups = "groups",

  /** The analytics page of the app */
  analytics = "analytics",

  /** The workspace settings page of the app */
  settings = "settings",

  /** The feedback page of the app */
  feedback = "feedback",

  /** The forbidding page due to lack of permissions of the app */
  forbidden = "forbidden",

  /** Add-ons page */
  addons = "add-ons",

  /** Data Management page */
  dataManagement = "data-management",

  /** The teams page */
  teams = "teams",

  /** Integrations page */
  integrations = "integrations",

  /** Account settings page */
  accountSettings = "account",
}

/** List all the tabs for a specific project */
export enum ProjectTabs {
  /** The overview tab of a project */
  overview = "details",

  /** The team tab of a project */
  members = "members",

  /** The data tab of a project */
  data = "data",

  /** The snapshots tab of a project */
  snapshots = "snapshots",

  /** The integrations tab of a project */
  integrations = "integrations",

  /** The Activity tab of a project */
  activity = "activity",

  /** The Settings tab of a project */
  settings = "settings",

  /** The annotations (markups included) tab of a project */
  annotations = "annotations",

  /** The Evaluations tab of a project */
  evaluation = "evaluation",
}

/** List all the tabs for a specific group */
export enum GroupTabs {
  /** The Managers tab of a group */
  managers = "members",

  /** The Projects tab of a group */
  projects = "projects",
}

/** List all the tabs for a specific member */
export enum MemberTabs {
  /** The overview tab of a member */
  overview = "profile",
}

/** List all the tabs for add-ons page */
export enum AddOnsTabs {
  /** the extensions tab from add-ons page */
  extensions = "extensions",
}

export enum UsersTabs {
  /** The general tab of users page */
  members = "members",

  /** The teams tab of settings page */
  teams = "teams",
}

export enum SettingsTabs {
  /** The general tab of settings page */
  general = "general",

  /** The subscription tab of settings page */
  subscription = "subscription",

  /** The access control tab of settings page */
  accessControl = "access-control",
}

/** List of tabs in account settings */
export enum AccountSettingsTabs {
  /** The account and security tab of account settings */
  security = "security",

  /** The preferences tab of account settings */
  preferences = "preferences",
}

/** List all the tabs for analytics page */
export enum AnalyticsTabs {
  /** The workspace tab of analytics page */
  workspace = "workspace",

  /** The projects tab of analytics page */
  projects = "projects",

  /** The members tab of analytics page */
  members = "members",

  /** The groups tab of analytics page */
  groups = "groups",
}

/** List all the tabs for team page */
export enum TeamTabs {
  /** The overview tab of a team */
  projects = "projects",

  /** The members tab of a team */
  members = "members",
}

/** All the params to use with Router */
export type RouteParams = {
  /** The id of the company */
  companyId: string;

  /** The id of the project */
  projectId: string;

  /** The condition of the project. Used to have a type safety Tabs */
  projectStatus: ProjectArchivingState;

  /** The selected tab of a project */
  projectTabs: ProjectTabs;

  /** The ID of the group */
  groupId: string;

  /** The selected tab of a group */
  groupTabs: GroupTabs;

  /** The ID of the member */
  memberId: string;

  /** The selected tab of a member */
  memberTabs: MemberTabs;

  /** The selected tab for analytics page */
  analyticsTabs: AnalyticsTabs;

  /** The selected tab for addons page */
  addOnsTab: AddOnsTabs;

  /** The selected tab for settings page */
  settingsTab: SettingsTabs;

  /** The selected tab for users page */
  usersTab: UsersTabs;

  /** The ID of a team */
  teamId: string;

  /** The selected tab of a team */
  teamTabs: TeamTabs;

  /** The selected tab of account settings */
  accountSettingsTab: AccountSettingsTabs;
};

/** All the query search params that can be appended to the route */
export enum QueryParams {
  /** Company roles. Used to notify user about entering a forbidden page */
  workspaceRoles = "workspace_roles",

  /** Project permission. Used to notify user about entering a forbidden page */
  projectPermissions = "project_permissions",

  /** Company subscriptions. Used to notify user about entering a forbidden page */
  companySubscriptions = "company_subscriptions",

  /**
   * Original visited url. Used to redirect to the original page after user was redirected
   * to a forbidden page and it is no longer the case.
   */
  origin = "origin",

  /** User identity. Used to identify which user was notified about entering a forbidden page */
  user = "user",

  /** The language query */
  lang = "lang",

  /** The id of a project */
  projectId = "project_id",

  /** The selected time frame */
  timeFrame = "time_frame",

  /** Enable all the feature flags */
  enableBeta = "enableBeta",

  /** Enable all the feature alpha */
  enableAlpha = "enableAlpha",

  /** Enable dev features */
  enableDev = "enableDev",

  /**
   * Hide migrated workspaces from Sphere Legacy.
   * TODO: Once https://faro01.atlassian.net/browse/ST-1652 is implemented we can remove this.
   */
  hideMigratedWorkspaces = "hideMigratedWorkspaces",

  /** The current data type displayed in the project data tab */
  type = "type",

  /** Origin URL from where the account settings page were opened */
  settingsOriginUrl = "settingsOriginUrl",
}
