import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import {
  SdbCompaniesAdapter,
  SdbCompanyState,
} from "@store/sdb-company/sdb-company-slice";
import {
  ProjectLaunchTarget,
  SdbCompany,
} from "@custom-types/sdb-company-types";
import { EntityId, createSelector } from "@reduxjs/toolkit";
import { processSubscriptionConstraints, SPHERE_LEGACY_MIGRATED_TAG } from "@utils/sdb-company-utils";
import { runtimeConfig } from "@src/runtime-config";
import { addNumberedEnv } from "@utils/url-utils";
import { Subscription } from "@custom-types/subscription-types";
import { getSubscription } from "@utils/subscription-utils";
import { CreditSubscription } from "@custom-types/credit-types";

interface ProjectLaunchTargetSelector {
  /** Defines the default open project target for the selected company. */
  defaultOpenProjectTarget: ProjectLaunchTarget;

  /** Defines whether the default application setting should be hidden */
  shouldShowDefaultAppSetting: boolean;

  /** Defines whether the open in webEditor/HoloBuilder button should be shown or not */
  shouldShowOpenInWebEditorBtn: boolean;
}

export interface CompanyCreditSubscriptions {
  /** The available credit subscriptions */
  availableSubscriptions: CreditSubscription[];
  
  /**
   *  The expired credit subscriptions
   *  This includes subscriptions that have no available credits or the subscription has expired.
   */
  expiredSubscriptions: CreditSubscription[];
}

/**
 * Returns the ID of the selected SdbCompany
 */
export const selectedSdbCompanyIdSelector: (state: RootState) => string | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.sdbCompany.selectedSdbCompanyId;
    }
  );

/**
 * Checks if the given id is the same as the currently selected sdbCompany
 */
export function isSdbCompanySelectedSelector(
  sdbCompanyId: string
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return selectedSdbCompanyIdSelector(state) === sdbCompanyId;
    }
  );
}

/**
 * Returns the current selected SdbCompany
 */
export const selectedSdbCompanySelector: (
  state: RootState
) => SdbCompany | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const sdbCompanyId = selectedSdbCompanyIdSelector(state);
    if (!sdbCompanyId) {
      return null;
    }
    // Find the specific sdb-company using the id.
    return (
      SdbCompaniesAdapter.getSelectors().selectById(
        state.sdbCompany,
        sdbCompanyId
      ) ?? null
    );
  }
);

/**
 * Checks if the selected company has the necessary features enabled to
 * display the 'Open in Holobuilder' button.
 * For now it is not shown to any company that was migrated from Sphere Legacy.
 * @param state - The current state.
 * @returns True if any of the required features are enabled, false otherwise.
 */
export const isOpenInHBButtonEnabledSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const hasOpenInWebEditorFeature = selectedCompanyAvailableFeatureSelector(
        APITypes.EUserSubscriptionRole.openInWebeditor)(state);

      // If the company has the open in web editor feature, we don't care about anything else and show it.
      if (hasOpenInWebEditorFeature) {
        return true;
      }

      // Sphere Legacy companies should not see the button.
      // In the future, once all companies are migrated to the new XG plans
      // we should remove this check and only check for the company features.
      if (selectedSdbCompanySelector(state)?.isSphereLegacyMigrated) {
        return false;
      }

      const hasSphereXGAddonPointCloud = selectedCompanyAvailableFeatureSelector(
        APITypes.EUserSubscriptionRole.sphereXGAddonPointCloud
      )(state);
      // If the company has the sphereXGAddonPointCloud feature, we don't show the button.
      // This is not a problem for companies on the Point Cloud + 360 plan because
      // the 360 plan has the open in web editor feature, so it will early return true.
      if (hasSphereXGAddonPointCloud) {
        return false;
      }

      // By default we show the button, this is often the case with companies with the minimal bundle,
      // and expired trials.
      return true;
    }
  );

/**
 * Returns the selected sdbCompany name
 */
export const selectedSdbCompanyNameSelector: (
  state: RootState
) => string | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return selectedSdbCompanySelector(state)?.name ?? null;
  }
);

/**
 * Returns all sdbCompany of the user
 */
export const sdbCompaniesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        SdbCompaniesAdapter.getSelectors()
          .selectAll(state.sdbCompany)
          // Filters out migrated workspaces if the flag is enabled.
          // Do not do this on filteredSdbCompaniesSelector, so that the switch workspaces menu
          // can benefit from this filter.
          .filter((company) => {
            if (
              state.ui.isHideMigratedWorkspacesEnabled &&
              company.tags.includes(SPHERE_LEGACY_MIGRATED_TAG)
            ) {
              return false;
            }
            return true;
          })
          .map((company) => {
            let url = company.url;
            if (
              company.type === CoreAPITypes.EWorkspaceType.company &&
              runtimeConfig.isNumberedEnv &&
              runtimeConfig.numberedEnv
            ) {
              url = addNumberedEnv(url, runtimeConfig.numberedEnv);
            }
            return {
              ...company,
              url,
            };
          })
      );
    }
  );

/**
 * Returns all sdbCompany of the user, filtered by the search text
 */
export const filteredSdbCompaniesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const search = state.ui.search.debouncedSearchText.toLowerCase();
      return sdbCompaniesSelector(state).filter((company) =>
        company.name.toLowerCase().includes(search)
      );
    }
  );

/**
 * Returns all Ids of sdbCompany of the user
 */
export const sdbCompanyIdsSelector: (state: RootState) => EntityId[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return SdbCompaniesAdapter.getSelectors().selectIds(state.sdbCompany);
    }
  );

/**
 * Returns all companies of the user.
 */
export const companiesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return sdbCompaniesSelector(state).filter(
        ({ type }) => type === CoreAPITypes.EWorkspaceType.company
      );
    }
  );

/**
 * Returns all admin workspaces of the user.
 */
export const adminWorkspacesSelector: (state: RootState) => SdbCompany[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return sdbCompaniesSelector(state).filter(
        ({ type }) => type === CoreAPITypes.EWorkspaceType.admin
      );
    }
  );

/**
 * Returns all company features for the selected company.
 */
export const selectedCompanyFeaturesSelector: (
  state: RootState
) => CoreAPITypes.IFeatureStateResponse[] | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.selectedCompanyFeatures;
  }
);

/**
 * Returns whether the selected company has the given feature enabled.
 *
 * @param featureIdentifier The identifier of the feature to check.
 * @returns True if the feature is enabled, false otherwise.
 */
export function selectedCompanyAvailableFeatureSelector(
  featureIdentifier: APITypes.EUserSubscriptionRole
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const features = selectedCompanyFeaturesSelector(state);
      return (features ?? []).some(
        (feature) =>
          feature.identifier === featureIdentifier &&
          feature.state === "enabled"
      );
    }
  );
}

/**
 * Returns the fetching properties of the sdb-company slice.
 */
export const fetchingSdbCompanyFlagsSelector: (
  state: RootState
) => SdbCompanyState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.fetching;
  }
);

/**
 * Returns true if any fetching regarding company-slice is true.
 */
export const isFetchingSdbCompanySelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const {
        isFetchingSelectedCompanyContext,
        isFetchingSelectedCompanyFeatures,
        isFetchingSdbCompanies,
        isFetchingCompanyBrandingSetting,
        isFetchingCompanyCommunicationSettings,
        isFetchingCompanySubscriptions,
      } = state.sdbCompany.fetching;

      return (
        isFetchingSelectedCompanyContext ||
        isFetchingSelectedCompanyFeatures ||
        isFetchingCompanyBrandingSetting ||
        isFetchingSdbCompanies ||
        isFetchingCompanyCommunicationSettings ||
        isFetchingCompanySubscriptions
      );
    }
  );

/**
 * Returns company context for the selected company.
 */
export const selectedCompanyContextSelector: (
  state: RootState
) => SphereDashboardAPITypes.CompanyContext | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.selectedCompanyContext;
  }
);

/**
 * Returns branding settings for the selected company.
 */
export const companyBrandingSettingsSelector: (
  state: RootState
) => APITypes.BrandingSettings | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.brandingSettings;
  }
);

/** Get company details by providing the companyId */
export function getCompanyByIdSelector(
  companyId: APITypes.CompanyId
): (state: RootState) => SdbCompany | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        SdbCompaniesAdapter.getSelectors().selectById(
          state.sdbCompany,
          companyId
        ) ?? null
      );
    }
  );
}

/**
 * Returns communication settings for the selected company.
 */
export const companyCommunicationSettingsSelector: (
  state: RootState
) => SphereDashboardAPITypes.CompanyCommunicationSettings | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.sdbCompany.communicationSettings;
    }
  );

export const companySettingsSelector: (
  state: RootState
) => Record<
  SphereDashboardAPITypes.CompanySetting["identifier"],
  SphereDashboardAPITypes.CompanySetting["value"]
> = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.sdbCompany.companySettings;
  }
);

/**
 * Returns the default open project target for the selected company, based on the company settings.
 * If the company settings are set, it will favour the company settings.
 * If the company settings are not set, it will favour the provided default target.
 * @param defaultTarget  default target if the company settings are not set.
 * @param isSphereViewerDefaultSetting  company setting for the default target. Null if not set.
 * @returns The application to open the projects in by default.
 */
export function getDefaultOpenProjectTarget(
  payload: {
    defaultTarget: ProjectLaunchTarget,
    isSphereViewerDefaultSetting: boolean | undefined | null,
  }
): ProjectLaunchTarget {
  // Returns defaultTarget if the user has not set any target application.
  if (payload.isSphereViewerDefaultSetting === undefined || payload.isSphereViewerDefaultSetting === null) {
    return payload.defaultTarget;
  }
  return payload.isSphereViewerDefaultSetting
    ? ProjectLaunchTarget.sphereViewer
    : ProjectLaunchTarget.webEditor;
}

/**
 *  Returns the default open project application for the selected company.
 */
export const defaultProjectLaunchTargetSelector: (
  state: RootState
) => ProjectLaunchTargetSelector = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const shouldShowOpenInWebEditorBtn = isOpenInHBButtonEnabledSelector(state);

    const isSphereViewerDefaultSetting =
      companyCommunicationSettingsSelector(state)?.sphereViewerIsDefault;

    const hasSphereXGAddonPointCloud = selectedCompanyAvailableFeatureSelector(
      APITypes.EUserSubscriptionRole.sphereXGAddonPointCloud
    )(state);
    const hasGlobalPointCloudWrite = selectedCompanyAvailableFeatureSelector(
      APITypes.EUserSubscriptionRole.globalPointCloudWrite
    )(state);
    const hasGlobalOrbisProcessing = selectedCompanyAvailableFeatureSelector(
      APITypes.EUserSubscriptionRole.globalOrbisProcessing
    )(state);

    // If the company does not show the open in HoloBuilder button,
    // The default open project target should be Sphere Viewer.
    // It should not let users to change the default application setting.
    if (!shouldShowOpenInWebEditorBtn) {
      return {
        defaultOpenProjectTarget: getDefaultOpenProjectTarget({
          defaultTarget: ProjectLaunchTarget.sphereViewer,
          isSphereViewerDefaultSetting,
        }),
        shouldShowDefaultAppSetting: false,
        shouldShowOpenInWebEditorBtn,
      };
    }

    // If the company has the open in HoloBuilder button enabled,
    // But can view point clouds, the default application should be Sphere Viewer.
    // But it should let the user to change this setting.
    if (hasSphereXGAddonPointCloud || hasGlobalPointCloudWrite || hasGlobalOrbisProcessing) {
      return {
        defaultOpenProjectTarget: getDefaultOpenProjectTarget({
          defaultTarget: ProjectLaunchTarget.sphereViewer,
          isSphereViewerDefaultSetting,
        }),
        shouldShowDefaultAppSetting: true,
        shouldShowOpenInWebEditorBtn,
      };
    }

    // If the company has the open in HoloBuilder button enabled,
    // And can not view point clouds, the default application should be Web Editor.
    // But it should let the user to change this setting.
    // Once we have feature parity and we want to promote Sphere Viewer instead of Web Editor,
    // The default value should be changed here.
    return {
      defaultOpenProjectTarget: getDefaultOpenProjectTarget({
        defaultTarget: ProjectLaunchTarget.webEditor,
        isSphereViewerDefaultSetting,
      }),
      shouldShowDefaultAppSetting: true,
      shouldShowOpenInWebEditorBtn,
    };
  }
);

/**
 * Returns subscription of the selected company
 */
export const subscriptionSelector: (
  state: RootState
) => Subscription | undefined = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const context = selectedCompanyContextSelector(state);
    if (context) {
      return getSubscription(context.featuresAvailable);
    }
  }
);

/**
 * Returns the subscriptions of the selected company
 */
export const selectedCompanySubscriptionsSelector = createSelector(
  (state: RootState) => state,
  (state: RootState): SphereDashboardAPITypes.IGetCompanySubscriptionResponse[] | null => {
    return state.sdbCompany.selectedCompanySubscriptions;
  }
);

/**
 * Returns all credit subscriptions of the selected company
 */
export const allCreditSubscriptionsSelector = createSelector(
  selectedCompanySubscriptionsSelector,
  (companySubscriptions): CreditSubscription[] => {
    if (!companySubscriptions) {
      return [];
    }

    const result = companySubscriptions.flatMap((subscription) =>
      processSubscriptionConstraints(subscription)
    );
    return result;
  }
);

/** 
 * Returns all available credit subscriptions of the selected company 
 */
export const availableCreditSubsSelector = createSelector(
  allCreditSubscriptionsSelector,
  (allCreditSubscriptions): CreditSubscription[] => {

    if (!allCreditSubscriptions) {
      return [];
    }

    const now = new Date();

    return allCreditSubscriptions.filter(
      (creditSub) =>
        creditSub.availableCredit > 0 && new Date(creditSub.expiresAt) > now
    );
  }
);

/** Returns the earliest expiring credit subscription */
export const getEarliestExpiringSubscriptionSelector = createSelector(
  availableCreditSubsSelector,
  (availableSubscriptions): CreditSubscription | undefined => {
    if (availableSubscriptions.length === 0) {
      return undefined;
    }
    return availableSubscriptions.reduce((earliest, current) =>
      earliest && earliest.expiresAt <= current.expiresAt ? earliest : current
    );
  }
);

/**
 * Returns the total credit balance of the company
 * This is the sum of all available credits of the subscriptions.
 */
export const companyCreditBalanceSelector = createSelector(
  availableCreditSubsSelector,
  (availableSubscriptions): number =>
    availableSubscriptions.reduce(
      (total, { availableCredit }) => total + availableCredit,
      0
    )
);
