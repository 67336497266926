import {
  PageSection,
  PageSectionProps,
} from "@components/page-section/page-section";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Grid, GridProps } from "@mui/material";

interface Props {
  /** The page section props */
  pageSectionProps: PageSectionProps[];

  /**
   * Whether the page is loading
   *
   * @default false
   */
  isLoading?: boolean;

  /** The data-test-ID used for integration testing */
  dataTestId?: string;

  /** Optional sx properties to update the Grid style. */
  customSx?: GridProps["sx"];
}

/** The base settings page component that handles very basic styling and UI for the given PageSectionProps */
export function BaseSettingsPage({
  pageSectionProps,
  isLoading = false,
  dataTestId,
  customSx,
}: Props): JSX.Element {
  const { isScreenXsAndSmall } = useMediaQueryList();
  const filteredPageSectionProps = pageSectionProps.filter(
    (pageSectionProps) => !pageSectionProps.isHidden
  );

  return (
    <Grid data-testid={dataTestId} sx={{ ...customSx }}>
      {filteredPageSectionProps.map((sectionProps, index) => (
        <PageSection
          key={`settings-section-wrapper-${index}`}
          isLoading={isLoading}
          isScreenXsAndSmall={isScreenXsAndSmall}
          isTopSection={index === 0}
          isLastSection={index === filteredPageSectionProps.length - 1}
          {...sectionProps}
        />
      ))}
    </Grid>
  );
}
