import { ProjectApi } from "@api/project-api/project-api";
import { fetchTasksByType } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { fetchAllCaptureTreeRevisions } from "@store/capture-tree/capture-tree-thunks";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { isMergeConflictError, RegistrationApiClient } from "@faro-lotv/service-wires";
import { delay } from "@utils/time-utils";
import { LogEventParams } from "@utils/track-event/use-track-event";
import { SnackbarKey } from "notistack";
import { AppDispatch } from "@store/store-helper";
import { ErrorHandlingFunction } from "@context-providers/error-boundary/error-handling-context";
import { ProgressApiClient } from "@api/progress-api/progress-api-client";
import { ShowToastProps } from "@hooks/use-toast";
import { GUID } from "@faro-lotv/foundation";
import { sentryCaptureError } from "@src/utils/sentry-utils";

interface RegistrationProps {
  trackEvent: (params: LogEventParams) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  showToast: (params: ShowToastProps) => SnackbarKey,
  dispatch: AppDispatch,
  progressApiClient: ProgressApiClient,
  projectApiClient: ProjectApi,
  registrationApiClient: RegistrationApiClient,
  handleErrorWithToast: ErrorHandlingFunction,
}

interface RegistrationPropsWithId extends RegistrationProps {
  registrationId: GUID,
}

interface FetchProps {
  dispatch: AppDispatch,
  progressApiClient: ProgressApiClient,
  projectApiClient: ProjectApi
}

/** Recommended delay between triggering registration and refreshing tasks, to make sure the new task is visible. */
export const DELAY_FOR_REGISTRATION_TASK = 1000;

/**
 * Triggers a multi cloud registration.
 * After the multi cloud registration has successfully started the cloud registration tasks
 * are fetched in order to show the registration progress to the user.
 */
export async function startRegistration({
  trackEvent,
  setIsLoading,
  showToast,
  dispatch,
  progressApiClient,
  projectApiClient,
  registrationApiClient,
  handleErrorWithToast,
}: RegistrationProps): Promise<void> {
  trackEvent({
    name: DataManagementEvents.register,
  });
  setIsLoading(true);

  try {
    await registrationApiClient.startCaptureTreeRegistration({
      // eslint-disable-next-line @typescript-eslint/naming-convention -- defined by the API
      autoPublish: true,
    });

    showToast({
      message: "Multi cloud registration started successfully.",
      description:
        "Use the Inspect button to view the registration result once finished.",
      type: "success",
    });

    // Fetch fresh data 1 second after the registration is triggered in order to show
    // registration progress to the users immediately.
    await delay(DELAY_FOR_REGISTRATION_TASK);
    // Non-essential feature; any error is reported silently. We don't need to await the result.
    void fetchTasksAndRevisions({ dispatch, progressApiClient, projectApiClient });
  } catch (error) {
    handleErrorWithToast({
      id: `startCaptureTreeRegistration-${Date.now().toString()}`,
      title: "Error starting a multi cloud registration",
      error,
    });
  }

  setIsLoading(false);
}

/**
 * Fetches the cloud registration tasks and registrations.
 * Is not critical if the fetch operation fails so don't show any error to the user.
 */
export async function fetchTasksAndRevisions({
  dispatch,
  progressApiClient,
  projectApiClient,
}: FetchProps): Promise<void> {
  try {
    const tasksPromise = dispatch(
      fetchTasksByType({
        progressApiClient,
        taskType: "CloudRegistration",
      })
    ).unwrap();
    const revisionsPromise = dispatch(
      fetchAllCaptureTreeRevisions({ projectApiClient })
    ).unwrap();
    await Promise.all([tasksPromise, revisionsPromise]);
  } catch (error) {
    sentryCaptureError({
      title: "RegistrationError: Failed to fetch revisions.",
      error,
    });
  }
}

/**
 * Handles the logic when the initial autopublish fails:
 * - Tries to apply the registration revision again.
 * - If a merge conflict occurs, restart the registration process.
 */
export async function publishOrReRegister({
  trackEvent,
  setIsLoading,
  showToast,
  dispatch,
  progressApiClient,
  projectApiClient,
  registrationApiClient,
  handleErrorWithToast,
  registrationId,
}: RegistrationPropsWithId): Promise<void> {
  try {
    await projectApiClient.applyRegistrationRevision(registrationId);
  } catch (error) {
    if (isMergeConflictError(error)) {
      await startRegistration({
        trackEvent,
        setIsLoading,
        showToast,
        dispatch,
        progressApiClient,
        projectApiClient,
        registrationApiClient,
        handleErrorWithToast,
      });
    } else {
      throw error;
    }
  }
}
