import { useAppSelector } from "@store/store-helper";
import {
  selectedProjectContextSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { useCallback, useMemo } from "react";
import { RequiredFeatureProjectLevelName } from "@utils/feature-control/project/project-feature-control-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  hasUserValidFeatureProjectLevel,
  isUserPermittedToFeatureProjectLevel,
} from "@utils/feature-control/project/project-feature-control";

interface Props {
  /** The internal name used in the UI to determine certain feature */
  roleName: RequiredFeatureProjectLevelName;

  /** The available features of the project. */
  featuresAvailable: SphereDashboardAPITypes.IFeature[];
}

export type ReturnProps = {
  [key in RequiredFeatureProjectLevelName]: boolean;
} & {
  /** Wrapper to check if certain features are enabled and permitted to use by the user */
  hasFeatureProjectLevel: ({ roleName, featuresAvailable }: Props) => boolean;
};

/** Checks whether a user has permission for an action on project level or not */
export function useHasValidFeatureProjectLevel({
  featuresAvailable = undefined,
}: Partial<Pick<Props, "featuresAvailable">> = {}): ReturnProps {
  const selectedProjectInStore = useAppSelector(selectedProjectSelector);
  const projectContext = useAppSelector(selectedProjectContextSelector);

  /** If user provided a featuresAvailable, then use that. Otherwise, use from selectedProject */
  const availableFeatures = useMemo(
    () =>
      featuresAvailable ??
      selectedProjectInStore?.featuresAvailable ??
      projectContext?.featuresAvailable,
    [
      featuresAvailable,
      projectContext?.featuresAvailable,
      selectedProjectInStore?.featuresAvailable,
    ]
  );

  const canArchiveProject = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canArchiveProject,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canUnarchiveProject = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canUnarchiveProject,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canView2dImagesFeature = useMemo(
    () =>
      isUserPermittedToFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canView2dImagesFeature,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canViewVideoModeFeature = useMemo(
    () =>
      isUserPermittedToFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canViewVideoModeFeature,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canViewFaceBlurringFeature = useMemo(
    () =>
      isUserPermittedToFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canViewFaceBlurringFeature,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canViewFaceBlurringV1Feature = useMemo(
    () =>
      isUserPermittedToFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canViewFaceBlurringV1Feature,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canViewSnapshot = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canViewSnapshot,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canCreateSnapshot = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canCreateSnapshot,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const hasFeatureProjectLevel = useCallback(
    ({ roleName, featuresAvailable }: Props) =>
      hasUserValidFeatureProjectLevel({
        roleName,
        featuresAvailable,
      }),
    []
  );

  const canDownloadProject = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canDownloadProject,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canManageProjectIntegrations = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canManageProjectIntegrations,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  const canViewPhotogrammetryFeature = useMemo(
    () =>
      hasUserValidFeatureProjectLevel({
        roleName: RequiredFeatureProjectLevelName.canViewPhotogrammetryFeature,
        featuresAvailable: availableFeatures,
      }),
    [availableFeatures]
  );

  return {
    canArchiveProject,
    canUnarchiveProject,
    canView2dImagesFeature,
    canViewVideoModeFeature,
    canViewFaceBlurringFeature,
    canViewFaceBlurringV1Feature,
    canViewSnapshot,
    canCreateSnapshot,
    hasFeatureProjectLevel,
    canDownloadProject,
    canManageProjectIntegrations,
    canViewPhotogrammetryFeature,
  };
}
