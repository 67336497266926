import { Box, SvgIcon } from "@mui/material";
import { FaroTextButton } from "@components/common/faro-text-button";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_TITLE_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { ElementType } from "react";
import { SphereTooltip } from "@components/common/sphere-tooltip";

export interface HeaderLeftButtonProps {
  /** onClick handler for the button */
  onClickHandler: () => void;

  /** Navigation address when clicking on the button. Use regular link to allow opening in new tab. */
  navigationTo?: string;

  /** The icon to be shown on the left side of button text */
  icon?: ElementType;

  /** Text of the button */
  text: string;

  /** The text of the tooltip */
  tooltip?: string;
}

/**
 * A custom button that can be used in the left side of the header
 * It has a tooltip and an icon on the left side of the text, together with appropriate borders
 */
export function HeaderLayoutLeftButton({
  onClickHandler,
  navigationTo,
  icon,
  text,
  tooltip,
}: HeaderLeftButtonProps): JSX.Element {
  return (
    <SphereTooltip title={tooltip}>
      <FaroTextButton
        sx={{
          height: "60px",
          ml: "-24px",
          pl: "24px",
          pr: "24px",
          borderRadius: "0px",
          color: sphereColors.gray800,
          borderRight: `1px solid ${sphereColors.gray200}`,
        }}
        to={navigationTo}
        onClick={onClickHandler}
        dataTestId="header-left-button"
      >
        {icon && (
          <SvgIcon inheritViewBox component={icon} sx={{ width: "16px" }} />
        )}
        <Box
          component="span"
          sx={{
            ml: "10px",
            fontSize: DEFAULT_TITLE_FONT_SIZE,
            ...withEllipsis,
          }}
        >
          {text}
        </Box>
      </FaroTextButton>
    </SphereTooltip>
  );
}
