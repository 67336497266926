import { APITypes } from "@stellar/api-logic";
import {
  RequiredFeatureProjectLevelName,
  RequiredFeaturesProjectLevel,
} from "@utils/feature-control/project/project-feature-control-types";

/**
 * Object that determines all different feature permission for users to get
 * access on the project level.
 */
export const requiredFeaturesProjectLevel: RequiredFeaturesProjectLevel<RequiredFeatureProjectLevelName> =
  {
    /** Whether the user can archive projects. */
    [RequiredFeatureProjectLevelName.canArchiveProject]: {
      projectSubscriptionRoles: [APITypes.EUserSubscriptionRole.projectArchive],
    },

    /** Whether the user can unarchive projects. */
    [RequiredFeatureProjectLevelName.canUnarchiveProject]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.projectUnarchive,
      ],
    },

    /** Whether the user can view 2d image feature. */
    [RequiredFeatureProjectLevelName.canView2dImagesFeature]: {
      projectSubscriptionRoles: [APITypes.EUserSubscriptionRole.ft_image_2d],
    },

    /** Whether the user can view video mode feature. */
    [RequiredFeatureProjectLevelName.canViewVideoModeFeature]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.globalVideoMode,
      ],
    },

    /** Whether the user can view face blurring feature. */
    [RequiredFeatureProjectLevelName.canViewFaceBlurringFeature]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.globalFaceBlurring,
      ],
    },

    /** Whether the user can view face blurring v1 feature. */
    [RequiredFeatureProjectLevelName.canViewFaceBlurringV1Feature]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.ft_face_blurring,
      ],
    },

    /** Whether the user can view snapshot feature. */
    [RequiredFeatureProjectLevelName.canViewSnapshot]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.globalSnapshotView,
      ],
    },

    /** Whether the user can create snapshot. */
    [RequiredFeatureProjectLevelName.canCreateSnapshot]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.globalSnapshotCreate,
      ],
    },

    /** Whether the user can download projects. */
    [RequiredFeatureProjectLevelName.canDownloadProject]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.projectDownload,
      ],
    },

    /** Whether the user can manage project integrations. */
    [RequiredFeatureProjectLevelName.canManageProjectIntegrations]: {
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.dashboardProjectIntegrations,
      ],
    },

    /** Whether the user can view photogrammetry setting. */
    [RequiredFeatureProjectLevelName.canViewPhotogrammetryFeature]: {
      projectSubscriptionRoles: [APITypes.EUserSubscriptionRole.photogrammetry],
    },
  };
