import { PageInfoBar } from "@components/common/page-info-bar";
import { useAppSelector } from "@store/store-helper";
import { selectedAnalyticsTimeFrameSelector } from "@store/analytics/analytics-selector";
import { TIME_FRAMES } from "@utils/time-utils";
import { FaroSelect } from "@components/common/faro-select/faro-select";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { QueryParams } from "@router/route-params";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { selectedSdbCompanyNameSelector } from "@store/sdb-company/sdb-company-selector";

/**
 * Shows the analytics bar with project and date range
 */
export function AnalyticsInfoBar(): JSX.Element {
  const selectedAnalyticsTimeFrame = useAppSelector(
    selectedAnalyticsTimeFrameSelector
  );
  const selectedSdbCompanyName = useAppSelector(selectedSdbCompanyNameSelector);
  const { setUrlParam } = useAppNavigation();

  return (
    <PageInfoBar
      items={[
        {
          content: (
            <FaroTextField
              label={<var>{selectedSdbCompanyName ?? ""}</var>}
              initialValue="Includes all projects from this workspace"
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          content: (
            <FaroSelect
              shouldShowFullWidth
              label="Activity History"
              variant="standard"
              size="medium"
              value={selectedAnalyticsTimeFrame}
              onChange={(value) => {
                setUrlParam({
                  key: QueryParams.timeFrame,
                  value: value.toString(),
                });
              }}
            >
              {TIME_FRAMES?.map((timeFrame, index) => (
                <FaroMenuItem
                  key={`menu-item-${index}`}
                  value={timeFrame.value}
                >
                  {timeFrame.label}
                </FaroMenuItem>
              ))}
            </FaroSelect>
          ),
        },
      ]}
    />
  );
}
