import { TabsWithRoutes } from "@components/common/tabs/tabs-with-routes";
import { AddOnsTabs } from "@router/route-params";
import { AddOnsExtensions } from "@pages/add-ons/extensions/add-ons-extensions";
import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { AddonsProvider } from "@context-providers/add-ons/add-ons-context";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { useAppSelector } from "@store/store-helper";
import { isBetaTestingEnabledSelector } from "@store/app/app-selector";
import { ForbiddenPage } from "@pages/forbidden-page";

/** Renders the contents in add-ons page */
export function AddOnsPage(): JSX.Element {
  const { addOnsTab } = useAppParams();
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);

  if (!isBetaTestingEnabled) {
    return <ForbiddenPage />;
  }

  if (
    addOnsTab === undefined ||
    !Object.values(AddOnsTabs).includes(addOnsTab)
  ) {
    return <NotFoundPage />;
  }

  return (
    <TabsWithRoutes
      selectedTab={addOnsTab}
      dataTestId="ADD_ONS_CONTAINER"
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openAddOnsTab}
      tabs={[
        {
          label: "Extensions",
          route: AddOnsTabs.extensions,
          content: () => (
            <AddonsProvider>
              <AddOnsExtensions />
            </AddonsProvider>
          ),
          loadingContent: <div />,
        },
      ]}
    />
  );
}
