import { CreditTransaction } from "@custom-types/credit-types";
import { createEntityAdapter, createSlice, EntityId } from "@reduxjs/toolkit";
import { CreditTransactionState } from "@store/credit-transactions/credit-transactions-slice-types";
import { FetchingStatus } from "@store/store-types";
import { fetchCreditTransactions } from "@store/credit-transactions/credit-transactions-thunk";

export const creditTransactionAdapter = createEntityAdapter<
  CreditTransaction,
  EntityId
>({ selectId: (creditTransaction) => creditTransaction.id });

export const initialState: CreditTransactionState = {
  ...creditTransactionAdapter.getInitialState(),
  fetching: {
    status: FetchingStatus.uninitialized,
  },
};

const creditTransactionSlice = createSlice({
  name: "creditTransactions",
  initialState,
  reducers: {
    resetCreditTransactionState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCreditTransactions.pending, (state) => {
        state.fetching.status = FetchingStatus.pending;
      })
      .addCase(fetchCreditTransactions.fulfilled, (state, action) => {
        // Skipping pagination for now
        creditTransactionAdapter.upsertMany(state, action.payload.data);
        state.fetching.status = FetchingStatus.succeeded;
      })
      .addCase(fetchCreditTransactions.rejected, (state, action) => {
        state.fetching.status = FetchingStatus.rejected;
      });
  },
});

export const { resetCreditTransactionState } = creditTransactionSlice.actions;

export const creditTransactionReducer = creditTransactionSlice.reducer;
