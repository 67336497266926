import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  AutodeskLegacyMap,
  IntegrationCompany,
  IntegrationProject,
  ProjectIntegrationId,
  ProjectIntegrationsMap,
} from "@services/integrations-service/integrations-service-types";
import {
  AutodeskHub,
  AutodeskProject,
  AutodeskResponse,
} from "@services/integrations-service/autodesk/autodesk-types";
import { BaseService } from "@services/integrations-service/base-service";
import { StatusCodes } from "http-status-codes";
import { isAutodeskLegacyProjectIntegration } from "@services/integrations-service/integrations-type-guards";

/** Service that provides methods to manage the Autodesk 3rd party integration */
export class AutodeskService extends BaseService {
  protected integrationId = SphereDashboardAPITypes.IntegrationId.autodesk;

  /**
   * @returns the IntegrationCompany entities of the user
   * @throws {Error} if it fails to get the entities
   */
  public async getIntegrationCompanies(): Promise<IntegrationCompany[]> {
    const { data } = await this.getAutodeskHubs();

    return data.map((autodeskHub) => ({
      integrationId: SphereDashboardAPITypes.IntegrationId.autodesk,
      id: autodeskHub.id,
      name: autodeskHub.attributes.name,
    }));
  }

  /**
   * @returns the IntegrationProject entities for a given integration company
   * @throws {Error} if it fails to get the entities
   * @param companyId ID of the company
   */
  public async getIntegrationProjects(
    companyId: string
  ): Promise<IntegrationProject[]> {
    const { data } = await this.getAutodeskProjects(companyId);

    const integrationProjects: IntegrationProject[] = data.map((project) => {
      const issuesBcfProjectId = this.getIssuesBcfProjectId(project);
      const rfisBcfProjectId = this.getRfisBcfProjectId(project);
      return {
        integrationId: SphereDashboardAPITypes.IntegrationId.autodesk,
        id: project.id,
        name: project.attributes.name,
        companyId,
        rfisBcfProjectId,
        issuesOrObservationsBcfProjectId: issuesBcfProjectId,
        type: project.attributes.extension.data.projectType,
      };
    });

    return integrationProjects;
  }

  /**
   * @returns The response of the Autodesk API to get the list of hubs of the user:
   * https://aps.autodesk.com/en/docs/data/v2/reference/http/hubs-GET/
   * @throws {Error} if it fails to fetch the hubs
   */
  private async getAutodeskHubs(): Promise<AutodeskResponse<AutodeskHub[]>> {
    return this.request({
      url: "hubs?filter[extension.type]=hubs:autodesk.bim360:Account",
      verb: "GET",
    });
  }

  /**
   * @returns The response of the Autodesk API to get the list of projects for a given hub:
   * https://aps.autodesk.com/en/docs/data/v2/reference/http/hubs-hub_id-projects-GET/
   * @throws {Error} if it fails to fetch the projects
   * @param hubId ID of the hub
   */
  private async getAutodeskProjects(hubId: string): Promise<AutodeskResponse<AutodeskProject[]>> {
    return this.request({
      url: `hubs/${hubId}/projects`,
      verb: "GET",
    });
  }

  /**
   * Handles errors originating from the CoreAPI proxy backend for requests issued to the Autodesk API.
   * - If the error status is UNAUTHORIZED, FORBIDDEN or CONFLICT it attempts to update the token.
   * @param error Error to handle
   * @throws {CoreAPITypes.IBaseResponse} if it can't handle the error.
   */
  protected async handleIntegrationError(error: CoreAPITypes.IBaseResponse): Promise<void> {
    switch (error.status) {
      case StatusCodes.UNAUTHORIZED:
      case StatusCodes.FORBIDDEN:
      case StatusCodes.CONFLICT:
        await this.handleTokenUpdate();
        break;
      default:
        throw error;
    }
  }

  /**
   * Updates the project connection data to the Sphere XG format.
   * @returns The project integrations map with the updated data format
   * @param data Project integrations map
   * @throws {Error} if it fails to fetch the project entity from the Autodesk API
   */
  public async updateDataFormat(data: AutodeskLegacyMap): Promise<ProjectIntegrationsMap> {
    const response = await this.getAutodeskProject(data.autodesk.hubId, data.autodesk.projectId);
    const project = response.data;

    const projectType = project.attributes.extension.data.projectType;
    const issuesBcfProjectId = this.getIssuesBcfProjectId(project);
    const rfisBcfProjectId = this.getRfisBcfProjectId(project);

    const accData: ProjectIntegrationsMap = {
      ...data,
      [ProjectIntegrationId.autodeskAccIssues] : {
        bcfProjectId: issuesBcfProjectId,
      },
      [ProjectIntegrationId.autodeskAccRfis] : {
        bcfProjectId: rfisBcfProjectId,
      },
    };

    const bim360Data: ProjectIntegrationsMap = {
      ...data,
      [ProjectIntegrationId.autodeskBim360Issues] : {
        bcfProjectId: issuesBcfProjectId,
      },
      [ProjectIntegrationId.autodeskBim360Rfis] : {
        bcfProjectId: rfisBcfProjectId,
      },
    };

    return projectType === "ACC" ? accData : bim360Data;
  }

  /**
   * @returns Whether the project connection data format is of type Legacy (HB).
   * The legacy Autodesk data format can be identified from the project integrations map when
   * the "autodesk field" is defined but the other 4 new Sphere XG Autodesk fields are undefined or null.
   * @param data Project integrations map
   */
  public isLegacyDataFormat(data: ProjectIntegrationsMap): data is AutodeskLegacyMap {
    return (
      isAutodeskLegacyProjectIntegration(data.autodesk) &&
      !data["autodesk-acc.issues"] &&
      !data["autodesk-acc.rfis"] &&
      !data["autodesk-bim360.issues"] &&
      !data["autodesk-bim360.rfis"]
    );
  }

  /**
   * @returns The bcfProjectId for Autodesk issues.
   * @param project Project entity
   */
  private getIssuesBcfProjectId(project: AutodeskProject): string {
    const containerId = project.relationships.issues.data.id;
    const hubId = project.relationships.hub.data.id;
    return `${containerId}~${project.id}~${hubId}`;
  }

  /**
   * @returns The bcfProjectId for Autodesk RFIs.
   * @param project Project entity
   */
  private getRfisBcfProjectId(project: AutodeskProject): string {
    const containerId = project.relationships.rfis.data.id;
    const hubId = project.relationships.hub.data.id;
    return `${containerId}~${project.id}~${hubId}`;
  }

  /**
   * @returns The response of the Autodesk API to get a single project:
   * https://aps.autodesk.com/en/docs/data/v2/reference/http/hubs-hub_id-projects-project_id-GET/
   * @throws {Error} if it fails to fetch the projects
   * @param hubId ID of the hub
   * @param projectId ID of the project
   */
  private async getAutodeskProject(
    hubId: string,
    projectId: string
  ): Promise<AutodeskResponse<AutodeskProject>> {
    return this.request({
      url: `hubs/${hubId}/projects/${projectId}`,
      verb: "GET",
    });
  }
}
