import { useCallback } from "react";
import { isGLS } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { getFileExtension } from "@utils/file-utils";

export enum DataManagementInputType {
  gls = "gls",
  photogrammetry = "photogrammetry",
  unknown = "unknown",
}

const ALLOWED_IMAGE_EXTENSIONS = ["jpg", "jpeg"];

/**
 * Checks if the given file is an image based on its extension.
 * @param fileName File name, including the file extension.
 * @returns True if the file is an image, false otherwise.
 */
function isImageFile(fileName: string): boolean {
  return ALLOWED_IMAGE_EXTENSIONS.includes(getFileExtension(fileName));
}

/**
 * Determines the type of data management input based on the selected files.
 * It checks for GLS files, or identifies if the files are suitable for photogrammetry.
 */
export function useDataManagementInputType(): (selectedFiles: FileList | File[]) => DataManagementInputType {
  return useCallback(
    (selectedFiles: FileList | File[]): DataManagementInputType => {
      if (!selectedFiles || selectedFiles.length === 0) {
        return DataManagementInputType.unknown;
      }
      /** Filter out hidden files e.g. .DS_Store*/
      const files = Array.from(selectedFiles).filter(
        (file) => !file.name.startsWith(".")
      );

      // Check if there is at least one GLS file
      const hasGlsFiles = files.some((file) => isGLS(file.name));
      if (hasGlsFiles) {
        return DataManagementInputType.gls;
      }

      // Check if files are images for photogrammetry
      const areAllImages = files.every((file) => isImageFile(file.name));
      if (areAllImages) {
        return DataManagementInputType.photogrammetry;
      }

      return DataManagementInputType.unknown;
    },
    []
  );
}
