import { SelectWorkspaceItem } from "@components/common/workspace/select-workspace-item";
import { MainPageLayout } from "@components/page-layout/main-page-layout";
import { Box, Divider, Grid } from "@mui/material";
import {
  fetchingSdbCompanyFlagsSelector,
  filteredSdbCompaniesSelector,
  sdbCompaniesSelector,
} from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { sortWorkspaces } from "@utils/sdb-company-utils";
import { NoWorkspace } from "@pages/workspace/no-workspace";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import BrowseDuoColorIcon from "@assets/icons/browse-duo-color.svg?react";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** The number of skeletons to show when there are no workspaces.  */
const NUMBER_OF_SKELETONS = 4;

/** Array of skeletons to show when there are no workspaces. */
const skeletonsArray = new Array(NUMBER_OF_SKELETONS)
  .fill(null)
  .map((_, index) => (
    <SelectWorkspaceItem
      key={`skeleton-${index}`}
      isEven={index % 2 === 0}
      // If no company is provided, it shows an skeleton by default
    />
  ));

export function SelectWorkspacePage(): JSX.Element {
  const sdbCompanies = useAppSelector(sdbCompaniesSelector);
  const filteredSdbCompanies = useAppSelector(filteredSdbCompaniesSelector);
  const { isFetchingSdbCompanies } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const { trackEvent } = useTrackEvent();

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        backgroundColor: sphereColors.gray50,
      }}
    >
      <MainPageLayout
        shouldShowSidebar={false}
        shouldShowLogo={true}
        shouldHideOpenButton={true}
        topbarBackgroundColor={sphereColors.gray50}
      >
        <Grid container sx={{ marginTop: "55px" }}>
          {filteredSdbCompanies.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                color: sphereColors.gray800,
                fontSize: "32px",
                fontWeight: "500",
                lineHeight: "44px",
                letterSpacing: "2.5",
                paddingBottom: "32px",
              }}
            >
              All Workspaces
              <Divider sx={{ marginTop: "13px" }} />
            </Grid>
          )}

          {filteredSdbCompanies.length > 0 &&
            filteredSdbCompanies
              .sort(sortWorkspaces)
              .map((sdbCompany, index) => (
                <SelectWorkspaceItem
                  key={sdbCompany.id}
                  sdbCompany={sdbCompany}
                  isEven={index % 2 === 0}
                  onWorkspaceClick={() => {
                    trackEvent({
                      name: WorkspaceEvents.selectWorkspace,
                      props: {
                        numberOfWorkspaces: filteredSdbCompanies.length,
                      },
                    });
                  }}
                />
              ))}

          {filteredSdbCompanies.length === 0 && skeletonsArray}

          {/* Page to show when user does not have access to any workspace */}
          {sdbCompanies.length === 0 && !isFetchingSdbCompanies && (
            <NoWorkspace />
          )}

          {/* Page to show when there are no workspaces matching the search criteria */}
          {sdbCompanies.length > 0 &&
            filteredSdbCompanies.length === 0 &&
            !isFetchingSdbCompanies && (
              <Box
                component="div"
                sx={{
                  width: "100%",
                }}
              >
                <EmptyPage
                  title="No results found"
                  subtitle="There are no workspaces that match this search."
                  icon={BrowseDuoColorIcon}
                />
              </Box>
            )}
        </Grid>
      </MainPageLayout>
    </Grid>
  );
}
