import { Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import {
  DEFAULT_BOLD_TEXT_FONT_WEIGHT,
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TEXT_FONT_SIZE,
} from "@styles/common-styles";
import { FaroTextButton } from "@components/common/faro-text-button";
import { BasicTabProps } from "@components/common/tabs/tabs-type";
import { BaseSettingsPage } from "@pages/settings/base-settings-page";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { getVerticalTabsStyles } from "@components/common/tabs/vertical-tabs-style";

interface Props<K extends string> {
  /** List of available tabs */
  tabs: Record<K, BasicTabProps<K>>;

  /** The header to show on the tab selection section. If not available, nothing is being shown */
  tabSelectionHeader?: string;

  /** Flag whether something is being load or not */
  isLoading: boolean;

  /** The selected tab */
  selectedTabId: K;

  /**
   * The name of the event to track opening tab events.
   * In the same page all opening of a tab is tracked with the same event name,
   * and the tab name is sent as a prop.
   */
  trackingEventPageName: OpenTabEvents;

  /** The data test id for the button */
  dataTestId: string;
}

/**
 * A general component which generates vertical tabs.
 * The MUI vertical tabs are not used because it is unnecessary complicated for our purpose
 */
export function VerticalTabs<K extends string>({
  tabs,
  tabSelectionHeader,
  isLoading,
  selectedTabId,
  trackingEventPageName,
  dataTestId,
}: Props<K>): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const selectedTab = tabs[selectedTabId];
  const { tabListSection, tabContentSection } = getVerticalTabsStyles();

  return (
    <Grid container>
      {/* The header for both tab label and content */}
      <Grid item container alignItems="flex-end" flexWrap="nowrap">
        {/* The header for tab label */}
        <Grid
          item
          sx={{
            borderBottom: `1px solid ${sphereColors.gray200}`,
            pb: "10px",
            mr: tabListSection.mr,
            ml: "0px",
            width: tabListSection.width,
            fontSize: DEFAULT_TEXT_FONT_SIZE,
            letterSpacing: "0.48px",
            textTransform: "uppercase",
            fontWeight: "700",
          }}
        >
          {tabSelectionHeader}
        </Grid>

        {/* The header for tab content */}
        <Grid
          item
          sx={{
            borderBottom: `1px solid ${sphereColors.gray200}`,
            pb: "10px",
            width: tabContentSection.width,
            mr: "0px",
            fontSize: "24px",
            fontWeight: DEFAULT_BOLD_TEXT_FONT_WEIGHT,
          }}
        >
          {selectedTab.label}
        </Grid>
      </Grid>

      <Grid item container sx={{ mt: "40px" }} flexWrap="nowrap">
        {/* The tab label */}
        <Grid
          item
          sx={{
            mr: tabListSection.mr,
            ml: "0px",
            width: tabListSection.width,
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            textTransform: "uppercase",
            fontWeight: "700",
          }}
        >
          {Object.values<BasicTabProps<K>>(tabs).map((tab, index) => (
            <FaroTextButton
              key={tab.label}
              sx={{
                mb: "24px",
                pl: "0px",
                color:
                  selectedTab.label === tab.label
                    ? sphereColors.blue500
                    : sphereColors.gray800,
                fontSize: DEFAULT_INPUT_FONT_SIZE,
                fontWeight: selectedTab.label === tab.label ? "bold" : "normal",
              }}
              shouldHideFocusingEffect={true}
              shouldHideHoveringEffect={true}
              to={`../${tab.routeId}`}
              onClick={() => {
                trackEvent({
                  name: trackingEventPageName,
                  props: {
                    tab: tab.routeId,
                  },
                });
              }}
            >
              {tab.label}
            </FaroTextButton>
          ))}
        </Grid>

        {/* The tab content */}
        <Grid
          item
          sx={{
            width: tabContentSection.width,
            mr: "0px",
          }}
        >
          <BaseSettingsPage
            pageSectionProps={selectedTab.content}
            isLoading={isLoading}
            dataTestId={dataTestId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
