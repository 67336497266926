import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { AutodeskProjectType } from "@services/integrations-service/autodesk/autodesk-types";

/** ID of each project integration */
export enum ProjectIntegrationId {
  procore = "procore",
  procoreObservations = "procore.observations",
  procoreRfis = "procore.rfis",
  autodesk = "autodesk",
  autodeskAccIssues = "autodesk-acc.issues",
  autodeskAccRfis = "autodesk-acc.rfis",
  autodeskBim360Issues = "autodesk-bim360.issues",
  autodeskBim360Rfis = "autodesk-bim360.rfis",
}

/** Data of a legacy (HoloBuilder) integration connected to a project */
interface LegacyProjectIntegration {
  /** The project name as defined by the integration */
  projectName: string;

  /** The project Id as defined by the integration */
  projectId: string;
}

/** Data of the legacy (HoloBuilder) Autodesk integration connected to a project */
export interface AutodeskLegacyProjectIntegration
  extends LegacyProjectIntegration {
  /** Id of the hub given by Autodesk */
  hubId: string;

  /** Autodesk integration ID */
  providerId: SphereDashboardAPITypes.IntegrationId.autodesk;
}

/** Data of the legacy (HoloBuilder) Procore integration connected to a project */
export interface ProcoreLegacyProjectIntegration
  extends LegacyProjectIntegration {
  /** Unique identifier of a company given by Procore */
  companyId: string;

  /** Procore integration ID */
  providerId: SphereDashboardAPITypes.IntegrationId.procore;
}

/** Data of an integration connected to a project */
export interface ProjectIntegration {
  /** ID of the BCF integration */
  bcfProjectId: string | null;
}

/** Integrations object of the ProjectApi root metadata */
export type ProjectIntegrationsMap = {
  /** Data of the legacy (HoloBuilder) Autodesk integration connected to a project */
  [ProjectIntegrationId.autodesk]?: AutodeskLegacyProjectIntegration | null;

  /** Data of the legacy (HoloBuilder) Procore integration connected to a project */
  [ProjectIntegrationId.procore]?: ProcoreLegacyProjectIntegration | null;

  /** Data of the ACC-issues integration connected to a project */
  [ProjectIntegrationId.autodeskAccIssues]?: ProjectIntegration | null;

  /** Data of the ACC-RFIs integration connected to a project */
  [ProjectIntegrationId.autodeskAccRfis]?: ProjectIntegration | null;

  /** Data of the Bim360-issues integration connected to a project */
  [ProjectIntegrationId.autodeskBim360Issues]?: ProjectIntegration | null;

  /** Data of the Bim360-RFIs integration connected to a project */
  [ProjectIntegrationId.autodeskBim360Rfis]?: ProjectIntegration | null;

  /** Data of the Procore-observations integration connected to a project */
  [ProjectIntegrationId.procoreObservations]?: ProjectIntegration | null;

  /** Data of the Procore-RFIs integration connected to a project */
  [ProjectIntegrationId.procoreRfis]?: ProjectIntegration | null;
};

/** Project integrations map where the Procore integration has the Legacy (HB) data format */
export interface ProcoreLegacyMap extends ProjectIntegrationsMap {
  [ProjectIntegrationId.procore]: ProcoreLegacyProjectIntegration;
  [ProjectIntegrationId.procoreObservations]?: null;
  [ProjectIntegrationId.procoreRfis]?: null;
}

/** Project integrations map where the Autodesk integration has the Legacy (HB) data format */
export interface AutodeskLegacyMap extends ProjectIntegrationsMap {
  [ProjectIntegrationId.autodesk]: AutodeskLegacyProjectIntegration;
  [ProjectIntegrationId.autodeskAccIssues]?: null;
  [ProjectIntegrationId.autodeskAccRfis]?: null;
  [ProjectIntegrationId.autodeskBim360Issues]?: null;
  [ProjectIntegrationId.autodeskBim360Rfis]?: null;
}

/** Legacy data map for each service */
export type ServiceLegacyMap =
  | ProcoreLegacyMap
  | AutodeskLegacyMap

/** Parsed message with the result of the integration authorization */
export interface AuthorizationMessage {
  /** Whether the connection was established. True if successful */
  // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by CoreAPI backend
  connectStatus: boolean;

  /** ID of the integration */
  providerId: SphereDashboardAPITypes.IntegrationId;

  /** Result status */
  status: "success" | "error";

  /** Human readable result message */
  message: string;
}

/** Base properties of an integration entity */
interface IntegrationEntityBase {
  /** ID of the integration */
  integrationId: SphereDashboardAPITypes.IntegrationId;

  /** ID of the entity */
  id: string;

  /** Name of the entity */
  name: string;
}

/** Company resource of an integration. For autodesk the equivalent of a company is a hub */
export type IntegrationCompany = IntegrationEntityBase;

/** Project resource of an integration */
export interface IntegrationProject extends IntegrationEntityBase {  
  /** ID of the company (or hub) that contains the project */
  companyId: string;

  /** "bcfProjectId" for RFIs */
  rfisBcfProjectId: string;

  /** "bcfProjectId" for Autodesk issues or Procore observations */
  issuesOrObservationsBcfProjectId: string;

  /** Project type. For non-autodesk projects it should be "none" */
  type: AutodeskProjectType | "none";
}

/** Error codes returned by the CoreAPI backend */
export type IntegrationErrorCode =
// Returned when a 3rd party integration failed to authenticate the user
| "authentication_error"
// Returned when the token of a 3rd party integration is invalid
| "invalid_token"
// Returned when the token of a 3rd party integration has invalid scopes
| "invalid_scope"
// Returned when the user has no access token for the integration
| "no_authorization_token";

/** Error object returned by the CoreAPI for failed proxy requests issued to an integration */
export interface ResponseError extends CoreAPITypes.IBaseResponse {
  /** Error message */
  message: string;

  /* eslint-disable @typescript-eslint/naming-convention -- name given by backend */
  /** Error code */
  error_v2: IntegrationErrorCode;
  /* eslint-enable @typescript-eslint/naming-convention */
}
