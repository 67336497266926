import { useMemo } from "react";
import { ProgressApiClient } from "@api/progress-api/progress-api-client";
import { runtimeConfig } from "@src/runtime-config";
import { useCachedTokenProvider } from "@api/use-cached-token-provider";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/**
 * Initializes the progress API client and returns a memoized instance
 */
export function useProgressApiClient({
  projectId,
}: BaseProjectIdProps): ProgressApiClient {
  const tokenProvider = useCachedTokenProvider({
    projectId,
  });

  return useMemo(
    () =>
      new ProgressApiClient(
        projectId.toString(),
        tokenProvider,
        runtimeConfig.urls.progressApiUrl
      ),
    [tokenProvider, projectId]
  );
}
