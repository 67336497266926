import { runtimeConfig } from "@src/runtime-config";
import Cookies, { CookieAttributes } from "js-cookie";

/**
 * Try our best to clear all browser-accessible cookies.
 * https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-remove-all-cookies
 */
export function tryToClearCookies(): void {
  // Unfortunately the browser doesn't allow to get the attributes of a cookie, but we need to
  // specify the same attributes for deleting it as when creating it.
  // Many non-essential cookies are set for the whole ".holobuilder.(com|eu)" domain, so we try that.
  for (const cookieName of Object.keys(Cookies.get())) {
    const attributes: CookieAttributes = {
      domain: `.holobuilder.${runtimeConfig.appRegion}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
      secure: false,
    };
    if (cookieName.startsWith("AMP_") || cookieName.startsWith("intercom-")) {
      attributes.sameSite = "Lax";
    } else if (cookieName.startsWith("_BEAMER_")) {
      attributes.sameSite = "None";
      attributes.secure = true;
    }

    // eslint-disable-next-line no-console -- Developer feature, useful information.
    console.log("Remove cookie", cookieName, attributes);
    Cookies.remove(cookieName, attributes);
  }
}
