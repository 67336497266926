import { useEffect } from "react";
import { runtimeConfig } from "@src/runtime-config";
import { IReactChildrenOnly } from "@custom-types/types";
import { HBCookieManagerProvider } from "@faro-lotv/foreign-observers";

const gtmId = runtimeConfig.features.gtm?.id;
const gtmAuth = runtimeConfig.features.gtm?.auth;
const gtmPreview = runtimeConfig.features.gtm?.preview;

/**
 * Loads both the Cookie Manager and Google Tag Manager
 */
export function CookieManagerLoader({
  children,
}: IReactChildrenOnly): JSX.Element {
  /**
   * This useEffect will load the Google Tag Manager script.
   * TODO: Import Intercom without the google tag manager in EU https://faro01.atlassian.net/browse/ST-2969
   */
  useEffect(() => {
    if (!gtmId || !gtmAuth || !gtmPreview) {
      return;
    }
    // eslint-disable-next-line max-len
    const gtmlHeadUrl = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;
    document.head.appendChild(document.createElement("script")).innerHTML =
      `(function () {
      window["dataLayer"] = window["dataLayer"] || [];
      window["dataLayer"].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = document.getElementsByTagName("script")[0];
      var j = document.createElement("script");
      j.async = true;
      j.src = "${gtmlHeadUrl}"
      f.parentNode.insertBefore(j, f);
      })();`;

      const gtmIframe = document.createElement("iframe");
      // eslint-disable-next-line max-len
      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;
      gtmIframe.height = "0";
      gtmIframe.width = "0";
      gtmIframe.style.display = "none";
      gtmIframe.style.visibility = "hidden";

      const gtmlNoScript = document.createElement("noscript");
      gtmlNoScript.appendChild(gtmIframe);
      document.body.appendChild(gtmlNoScript);
  }, []);

  return (
    // If the URL is undefined or empty the cookie manager will not be loaded
    <HBCookieManagerProvider
      cookieManagerUrl={runtimeConfig.urls.cookieManagerUrl}
      options={{
        /* eslint-disable @typescript-eslint/naming-convention -- Names from package */
        preferencesButton: false,
        preferencesButtonPosition: "bottom right",
        requireBabelPolyfill: true,
        requireJquery: true,
        requireBootstrap: true,
        triggerGoogleTagManager: true,
        delay: 300,
        /* eslint-enable @typescript-eslint/naming-convention */
      }}
    >
      {children}
    </HBCookieManagerProvider>
  );
}
