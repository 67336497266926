import { Box, LinearProgress, Stack, Step, StepLabel } from "@mui/material";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { DataManagementProgressBar } from "@pages/project-details/project-data-management/data-management-stepper/data-management-progress-bar";
import { StepState, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { InspectAndPublishButton } from "@pages/project-details/project-data-management/inspect-and-publish-button";
import { useAppSelector } from "@store/store-helper";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { FaroPopoverAction } from "@components/common/faro-popover-action";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { contactSupport } from "@utils/email-utils";
import { ContactSupportLocations } from "@custom-types/types";
import { assert } from "@faro-lotv/foundation";

interface Props extends BaseProjectIdProps {
  registerStepState: StepState;
  registrationId?: string;
  state: WorkflowState;
  progress: number;
}

export function RegisterStep({
  registerStepState,
  projectId,
  registrationId,
  state,
  progress,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const { navigateToInspectAndPublishTool } = useAppNavigation();

  // Define popover content based on the state.
  const title = state === "registerBadResult" ? "Registration Failed" : "System Error";
  const description = state === "registerBadResult" ?
  // eslint-disable-next-line max-len
    "The automatic registration of your scans was not successful. Please inspect the registration results and adjust the alignment manually." :
    "We encountered an unexpected error during the registration process. Please contact our support team for assistance.";
  const buttonText = state === "registerBadResult" ? "Inspect Registration" : "Contact Support";

  // See the comment in data-management-step-icon.tsx why we need custom code to display the correct icon.
  return (
    <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <Step
          active={registerStepState === "active"}
          completed={registerStepState === "done"}
        >
          <FaroPopoverAction
            title={title}
            description={description}
            actionTitle={buttonText}
            action={() => {
              if (state === "registerBadResult") {
                assert(registrationId, "Registration ID should be known at this point.");
                trackEvent({
                  name: DataManagementEvents.inspect,
                  props: {
                    projectId,
                    registrationId,
                  },
                });
                navigateToInspectAndPublishTool({
                  projectId: projectId,
                  registrationId: registrationId,
                });
              } else {
                // This block is only reached for a hard registration error.
                contactSupport({
                  location: ContactSupportLocations.dataManagementPage,
                  trackEvent,
                });
              }
            }}
            isEnabled={registerStepState === "error"}
          >
            <StepLabel StepIconComponent={DataManagementStepIcon} error={registerStepState === "error"}>
              Register
            </StepLabel>
          </FaroPopoverAction>
        </Step>
        {
          // Show animated progress bar when task is scheduled or just started without first progress value.
          (registerStepState === "active" && progress === 0) ?
          <LinearProgress
            variant="indeterminate"
            color="primary"
            sx={{
              "&.MuiLinearProgress-root": {
                flex: "1 1 auto",
                marginLeft: "10px",
                marginRight: "10px",
              },
            }}
          /> : <DataManagementProgressBar progress={progress} />
        }
      </Stack>
      { (!!registrationId && (registerStepState === "done" || isDevModeEnabled)) &&
        <Box sx={{
          width: "fit-content",
          marginLeft: "24px",
        }}>
          <InspectAndPublishButton
            projectId={projectId}
            registrationId={registrationId}
            isPublished={state === "published"}
            isDevMode={registerStepState !== "done"}
          />
        </Box>
      }
    </Stack>
  );
}
