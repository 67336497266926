import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { SelectRole } from "@components/role/select-role";
import { getDisabledRoles } from "@utils/workspace-role-utils";
import { SelectCompanyRoleProps } from "@components/role/select-role-utils";

/**
 *  Renders a dropdown to select company role 
 *  TODO: Refactor this component to handle only TableCell fields, as it is no longer used for non-TableCell fields
 *  https://faro01.atlassian.net/browse/ST-2993.
*/
export function SelectCompanyRole<
  T extends SphereDashboardAPITypes.IAssignmentCompanyRole
>({
  selectedRole,
  onChange,
  onResetSelection,
  isTableCell = false,
  placeholder,
  isRequired = true,
  shouldAllowAllRolesSelection = false,
}: SelectCompanyRoleProps<T>): JSX.Element {
  function onSelectRoleChange(role: CoreAPITypes.EUserCompanyRole): void {
    // TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
    onChange(role as T);
  }

  const roles: CoreAPITypes.EUserCompanyRole[] = [
    CoreAPITypes.EUserCompanyRole.companyExecutive,
    CoreAPITypes.EUserCompanyRole.companyViewer,
    CoreAPITypes.EUserCompanyRole.companyManager,
    CoreAPITypes.EUserCompanyRole.projectManager,
    CoreAPITypes.EUserCompanyRole.member,
  ];

  /**
   * Decides which roles to show disabled.
   * If shouldAllowAllRolesSelection is true, then no roles will be disabled.
   * Otherwise, it will show roles that are not assignable based on the selected role.
   * TODO: Type assertion will be fixed in https://faro01.atlassian.net/browse/ST-1902
   */
  const disabledRoles: SphereDashboardAPITypes.IAssignmentCompanyRole[] =
    shouldAllowAllRolesSelection || !selectedRole
    ? []
    : getDisabledRoles(selectedRole) as SphereDashboardAPITypes.IAssignmentCompanyRole[];

  return (
    <SelectRole
      label="Workspace role"
      roles={roles}
      disabledRoles={disabledRoles}
      placeholder={placeholder}
      isTableCell={isTableCell}
      selectedRole={selectedRole}
      onChange={onSelectRoleChange}
      onResetSelection={onResetSelection}
      link="workspaceRolesHelp"
      isRequired={isRequired}
    />
  );
}
