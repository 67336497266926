import { CLIENT_ID } from "@api/client-id";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { getCachedTokenProvider } from "@api/use-cached-token-provider";
import { runtimeConfig } from "@src/runtime-config";
import { useMemo } from "react";
import { PhotogrammetryApiClient } from "@api/photogrammetry-api/photogrammetry-api";

/**
 * Hook to create a Photogrammetry API client.
 * @param {BaseProjectIdProps} props - The properties including projectId.
 * @returns {PhotogrammetryApiClient} The Photogrammetry API client instance.
 */
export function usePhotogrammetryApiClient({
  projectId,
}: BaseProjectIdProps): PhotogrammetryApiClient {
  const tokenProvider = getCachedTokenProvider(projectId);

  // Make sure the client is only created once, unless projectId or token provider change.
  return useMemo(
    () =>
      new PhotogrammetryApiClient({
        baseUrl: runtimeConfig.urls.photogrammetryApiUrl,
        projectId: projectId.toString(),
        tokenProvider,
        clientId: CLIENT_ID,
      }),
    [projectId, tokenProvider]
  );
}
