import { DataDisplayActions } from "@components/common/data-display-actions";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { CreateProjectDialog } from "@pages/projects/create-project-dialog";
import { useAppSelector } from "@store/store-helper";
import { useMemo } from "react";
import { selectedCompanyAvailableFeatureSelector } from "@store/sdb-company/sdb-company-selector";
import { APITypes } from "@stellar/api-logic";

/** Returns the action buttons of the projects tab */
export function ProjectsTabActionButtons(): JSX.Element {
  const { canCreateProjects } = useHasUserValidRoleCompanyLevel();
  const hasCreateProjectsFeature = useAppSelector(
    selectedCompanyAvailableFeatureSelector(APITypes.EUserSubscriptionRole.projectCreate)
  );

  /** Flag whether the user has the permission to create projects and the subscription has the feature enabled */
  const shouldCreateProjects = useMemo(() => {
    return canCreateProjects && hasCreateProjectsFeature;
  }, [canCreateProjects, hasCreateProjectsFeature]);

  return (
    <>
      <DataDisplayActions subjectType="project" />
      {/* Create new project button */}
      {shouldCreateProjects && <CreateProjectDialog />}
    </>
  );
}
