/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Markup } from "@custom-types/project-markups-types";
import { IElementAttachment, IElementType } from "@faro-lotv/ielement-types";

export const MOCK_MARKUPS: Markup[] = [
  {
    templateId: "aa3a2b5b-4e2b-4338-4fd8-08dbfcbd2269",
    id: "ed08ebb7-b80d-4332-0d00-08dc3c2ce41e",
    typeHint: "Markup",
    type: IElementType.markup,
    name: "Viewer Markup test",
    descr: null,
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    createdAt: "2024-05-17T09:34:49.218+00:00",
    modifiedBy: "77082f04-a876-472b-8130-6f84d3c30a26",
    modifiedAt: "2024-08-08T10:56:25.664+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: [
      "d48084e3-b8ad-4ff8-c310-08dc3c3c130a",
      "6f2cc827-f116-4b4d-fc00-08dcb179a84d",
      "d461a082-9e8e-47c1-fc00-08dcb179a84d",
      "dcf1c07e-078a-46bc-fc00-08dcb179a84d",
      "e8781662-0245-4253-fc00-08dcb179a84d",
      "7edc4b3a-aa0e-4bb9-fc00-08dcb179a84d",
      "f1dd1fbd-cdf9-42b6-fc00-08dcb179a84d",
    ],
    children_Ids: [
      "d48084e3-b8ad-4ff8-c310-08dc3c3c130a",
      "6f2cc827-f116-4b4d-fc00-08dcb179a84d",
      "d461a082-9e8e-47c1-fc00-08dcb179a84d",
      "dcf1c07e-078a-46bc-fc00-08dcb179a84d",
      "e8781662-0245-4253-fc00-08dcb179a84d",
      "7edc4b3a-aa0e-4bb9-fc00-08dcb179a84d",
      "f1dd1fbd-cdf9-42b6-fc00-08dcb179a84d",
    ],
    parentId: "1c040b75-6827-4938-0d00-08dc3c2ce41e",
    parent_Id: "1c040b75-6827-4938-0d00-08dc3c2ce41e",
    rootId: "5771779b-be8b-4109-7622-08dbfcbce78e",
    root_Id: "5771779b-be8b-4109-7622-08dbfcbce78e",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-08-08T10:55:56.3471078",
    sphereViewerUrl:
      "https://viewer.dev.holobuilder.com/project/2b1e7a42-57fa-4fd4-ad60-f3ad650eb20a?lookAtId=ed08ebb7-b80d-4332-0d00-08dc3c2ce41e",
    webEditorUrl:
      "https://app.dev.holobuilder.com?p=2b1e7a42-57fa-4fd4-ad60-f3ad650eb20a&s=1706573759048&u=f3df85bc&d=2l7l&o=2304",
    attachmentsCount: 1,
    assignee: {
      values: ["bfdd5b71-2f87-4fd1-803e-4b299006a35e"],
      templateId: "a5bc4895-3f80-44b6-866a-08dc34d1ed09",
      id: "abba482f-7314-4d26-0f36-08dc3dd53884",
      typeHint: "MarkupAssigneeId",
      type: IElementType.userDirectoryMarkupField,
      name: "Assignee",
      descr: null,
      createdBy: "2b6a75f0-ba94-4527-83a2-55392060495f",
      createdAt: "2024-03-06T12:00:41.567+00:00",
      modifiedBy: "2b6a75f0-ba94-4527-83a2-55392060495f",
      modifiedAt: "2024-03-06T12:00:40.629+00:00",
      thumbnailUri: null,
      pose: null,
      childrenIds: null,
      children_Ids: null,
      parentId: "6e5e71e2-0ea2-44c1-0d34-08dc3dd53884",
      parent_Id: "6e5e71e2-0ea2-44c1-0d34-08dc3dd53884",
      rootId: "120bf7cb-b0f1-4489-990e-08dc34ce31f5",
      root_Id: "120bf7cb-b0f1-4489-990e-08dc34ce31f5",
      labels: [],
      lastModifiedInDb: null,
      metaDataMap: null,
    },
  },
  {
    templateId: "aa3a2b5b-4e2b-4338-4fd8-08dbfcbd2269",
    id: "5e073276-0abf-47bb-6eed-08dc34750722",
    typeHint: "Markup",
    type: IElementType.markup,
    name: "Replace stove",
    descr: null,
    createdBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    createdAt: "2024-07-01T12:46:56.306+00:00",
    modifiedBy: "77082f04-a876-472b-8130-6f84d3c30a26",
    modifiedAt: "2024-08-08T10:55:45.306+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: [
      "9481ef8a-c221-405f-e380-08dcb17a15cf",
      "4430de33-b73b-4913-0a80-08dcb17a15d0",
      "5bdfe4a0-c8bc-496f-0a80-08dcb17a15d0",
      "2012e3d0-d9a4-4be8-0a80-08dcb17a15d0",
      "e463c4c5-67f4-489e-0a80-08dcb17a15d0",
      "1511f931-25e4-461f-0a80-08dcb17a15d0",
      "2f77a925-71ad-4eaf-0a80-08dcb17a15d0",
      "6d7d0fdd-299e-4e06-a880-08dcb6dd8a39",
      "62d082d5-1960-462a-c480-08dcb6f836b6",
    ],
    children_Ids: [
      "9481ef8a-c221-405f-e380-08dcb17a15cf",
      "4430de33-b73b-4913-0a80-08dcb17a15d0",
      "5bdfe4a0-c8bc-496f-0a80-08dcb17a15d0",
      "2012e3d0-d9a4-4be8-0a80-08dcb17a15d0",
      "e463c4c5-67f4-489e-0a80-08dcb17a15d0",
      "1511f931-25e4-461f-0a80-08dcb17a15d0",
      "2f77a925-71ad-4eaf-0a80-08dcb17a15d0",
      "6d7d0fdd-299e-4e06-a880-08dcb6dd8a39",
      "62d082d5-1960-462a-c480-08dcb6f836b6",
    ],
    parentId: "38435198-e01c-45a6-9126-08dc34748651",
    parent_Id: "38435198-e01c-45a6-9126-08dc34748651",
    rootId: "5771779b-be8b-4109-7622-08dbfcbce78e",
    root_Id: "5771779b-be8b-4109-7622-08dbfcbce78e",
    labels: [],
    metaData: "{'authorName':'Osvaldo'}",
    metaDataMap: {
      authorName: "Osvaldo",
    },
    lastModifiedInDb: "2024-08-08T10:55:16.5339987",
    sphereViewerUrl:
      "https://viewer.dev.holobuilder.com/project/2b1e7a42-57fa-4fd4-ad60-f3ad650eb20a?lookAtId=5e073276-0abf-47bb-6eed-08dc34750722",
    webEditorUrl:
      "https://app.dev.holobuilder.com?p=2b1e7a42-57fa-4fd4-ad60-f3ad650eb20a&s=1702819231150&u=3EED2E52&d=2l1c&o=2304",
      attachmentsCount: 0,
  },
];

export const MOCK_MARKUP_ATTACHMENTS: IElementAttachment[] = [
  {
    md5Hash: "96ed8bfc94637238146e49c5857d07de",
    fileName: "test.jpg",
    fileSize: 85902,
    uri: "https://uploads.dev.holobuilder.com/company-67e9c632-cd66-4b1e-b592-2d03ff670283/project-2b1e7a42-57fa-4fd4-ad60-f3ad650eb20a%2FEYo76O69Mcr9uBHSD39KIVuAeR278orTbooN",
    id: "0a75ee94-4a69-49e8-2400-08dcb798bf91",
    typeHint: null,
    type: IElementType.attachment,
    name: "test.jpg",
    descr: null,
    createdBy: "77082f04-a876-472b-8130-6f84d3c30a26",
    createdAt: "2024-08-08T10:56:25.664+00:00",
    modifiedBy: "77082f04-a876-472b-8130-6f84d3c30a26",
    modifiedAt: "2024-08-08T10:56:25.664+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "6f2cc827-f116-4b4d-fc00-08dcb179a84d",
    parent_Id: "6f2cc827-f116-4b4d-fc00-08dcb179a84d",
    rootId: "5771779b-be8b-4109-7622-08dbfcbce78e",
    root_Id: "5771779b-be8b-4109-7622-08dbfcbce78e",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-08-08T10:55:56.3471078",
  },
];
