import { isIElementAttachmentsGroup } from "@custom-types/i-elements-type-guards";
import { IElementAttachmentsGroup } from "@custom-types/i-elements-types";
import {
  GUID,
  IElement,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectProjectLoadingState,
  selectRootIElement,
} from "@faro-lotv/project-source";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";

/**
 * @returns The externalId value of the selected project root IElement, which is the selected project ID
 */
export const selectRootExternalId: (state: RootState) => GUID | undefined =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const rootIElement = selectRootIElement(state);
      return rootIElement?.externalId;
    }
  );

/**
 * @returns True if the project loading state is `loading`, false otherwise
 */
export const selectIsProjectLoading: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const loadingState = selectProjectLoadingState(state);
      return loadingState === "loading";
    }
  );

/**
 * Selector to retrieve an attachment group. Attachment group is an iElement with typeHint "Attachments"
 * which is a children of markup iElement.
 *
 * @returns The attachment group of a markup if available
 */
export function markupAttachmentGroupSelector(
  iElement: IElement
): (state: RootState) => IElementAttachmentsGroup | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return selectChildDepthFirst(iElement, isIElementAttachmentsGroup)(state);
    }
  );
}
