import { getBreakpointName } from "@utils/ui-utils";
import { CSSProperties } from "react";

interface GetVerticalTabsStyleReturnProps {
  /** Style properties for the tab list section where the tab labels (name) are shown */
  tabListSection: {
    /** Width of the tab list section */
    width: CSSProperties["width"];

    /** Right margin of the tab list section */
    mr: CSSProperties["marginRight"];
  };

  /** Style properties for the tab content section */
  tabContentSection: {
    /** Width of the tab section */
    width: CSSProperties["width"];
  };
}

/** Styles for the vertical tabs that is changing based on breakpoints */
export function getVerticalTabsStyles(): GetVerticalTabsStyleReturnProps {
  const breakPointName = getBreakpointName(window.innerWidth);
  switch (breakPointName) {
    // Page xxxl Breakpoints: 2560px
    // Page xxl Breakpoints: 1920px
    case "xxxl":
    case "xxl":
      return {
        tabListSection: {
          width: "260px",
          mr: "90px",
        },
        tabContentSection: {
          width: "1330px",
        },
      };

    // Page Breakpoints: 1536px
    case "xl":
      return {
        tabListSection: {
          width: "200px",
          mr: "60px",
        },
        tabContentSection: {
          width: "1070px",
        },
      };

    // Page Breakpoints: 1200px
    case "lg":
      return {
        tabListSection: {
          width: "200px",
          mr: "60px",
        },
        tabContentSection: {
          width: "840px",
        },
      };

    // Page Breakpoints: 900px
    case "md":
      return {
        tabListSection: {
          width: "180px",
          mr: "20px",
        },
        tabContentSection: {
          width: "660px",
        },
      };

    // Page Breakpoints: 600px
    case "sm":
      return {
        tabListSection: {
          width: "150px",
          mr: "20px",
        },
        tabContentSection: {
          width: "400px",
        },
      };

    // Page Breakpoints: 0px
    // Vertical tabs are not shown in mobile view
    case "xs":
      return {
        tabListSection: {
          width: "0px",
          mr: "0px",
        },
        tabContentSection: {
          width: "0px",
        },
      };
  }
}
