import { FaroButtonContained } from "@components/common/faro-button-contained";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box } from "@mui/material";
import { colorConst, sphereColors } from "@styles/common-colors";

interface Props {
  onClick: () => void | Promise<void>;
  /** Flag if the button is only visible & enabled because of dev mode. */
  isDevMode?: boolean;
  /** Flag to disable the button. */
  isDisabled: boolean;
  /** The tooltip if disabled is set to true. */
  disabledTooltip?: string;
}

/** Button to import ELS scan data */
export function ImportDataButton({ onClick, isDevMode, isDisabled, disabledTooltip }: Props): JSX.Element {
  const tooltip = isDisabled ? disabledTooltip :
    <div>
      <div style={{ fontWeight: "bold", fontSize: "14px" }}>
        Upload from your computer
      </div>
      Currently, only Blink data is supported.
    </div>;

  const sx = isDevMode ? {
    backgroundColor: colorConst.devFeature,
    color: sphereColors.pureWhite,
    "&:hover": {
      backgroundColor: colorConst.devFeature,
      filter: "brightness(90%)",
    },
  } : undefined;

  return (
    <SphereTooltip
      dataTestId="sa-upload-data-tooltip"
      boxProps={{
        sx: {
          display: "inline-block",
        },
      }}
      title={tooltip}
    >
      <FaroButtonContained
        isDisabled={isDisabled}
        onClick={onClick}
        sx={sx}
      >
        <Box data-testid="sa-upload-data-button">
          Upload Data {isDevMode ? " (Dev)" : ""}
        </Box>
      </FaroButtonContained>
    </SphereTooltip>
  );
}
