import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { isIElementAreaSectionWithUrl } from "@custom-types/i-elements-type-guards";
import { Markup } from "@custom-types/project-markups-types";
import { GUID } from "@faro-lotv/foundation";
import { IElement, IElementAttachment, isIElementAttachment } from "@faro-lotv/ielement-types";
import { addIElements } from "@faro-lotv/project-source";
import { getMarkupByIdSelector } from "@store/markups/markups-selector";
import { setAll } from "@store/slide-nodes/slide-nodes-slice";
import { downloadSlideNodes } from "@store/slide-nodes/slide-nodes-slice-utils";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { cloneDeep, findIndex } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

/** Loading state of the annotation */
type LoadingState = "loading" | "fetched" | "loaded";

interface ReturnValue {
  /** Whether the required data to display the side panel is still loading */
  isLoading: boolean

  /** Callback to close the side panel */
  closeSidePanel: () => void;
}

/**
 * Handles closing the side panel.
 * Fetches and stores the data required to display the annotation side panel.
 */
export function useMarkupSidePanel(): ReturnValue {
  const {
    markups,
    updateMarkups,
    selectedMarkup,
    updateSelectedMarkup,
    isMarkupUpdateInProgress,
    projectId,
    activeTab,
    updateActiveTab,
    updateIsSidePanelOpen,
    updateAttachments,
  } = useMarkupContext();
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();
  const markup = useAppSelector(getMarkupByIdSelector(selectedMarkup?.id));
  const projectApiClient = useProjectApiClient({ projectId });
  const [isInit, setIsInit] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>("loading");

  /**
   * Callback function to close the side panel.
   * This function is used to close the markup side panel by setting the state to indicate that it is not open
   * and resetting the selected markup to `undefined`.
   */
  const closeSidePanel = useCallback(() => {
    // Restrict closing the sidebar if there is any update is ongoing
    if (isMarkupUpdateInProgress()) {
      return;
    }

    updateActiveTab("Info");
    updateIsSidePanelOpen(false);
    updateSelectedMarkup(undefined);
    setIsInit(false);
  }, [
    isMarkupUpdateInProgress,
    updateActiveTab,
    updateIsSidePanelOpen,
    updateSelectedMarkup]);

  // Updates markups context with the given annotation.
  const updateMarkupsContext = useCallback((markup: Markup): void => {
    const markupIndex = findIndex(markups, { id: markup.id });
    const newMarkups = cloneDeep(markups);
    newMarkups[markupIndex] = markup;
    updateMarkups(newMarkups);
    setLoadingState("loaded");
  }, [markups, updateMarkups]);

  // Fetches the annotation data and sets it in the redux store.
  const fetchAnnotation = useCallback(async (
    markupId: GUID
  ): Promise<void> => {
    try {
      const [ancestors, descendants] = await Promise.all([
        projectApiClient.getAllIElements({
          descendantIds: [markupId],
        }),
        projectApiClient.getAllIElements({
          ancestorIds: [markupId],
        }),
      ]);


      // Filter out attachments to reduce the amount of ielements set in the redux store.
      const children: IElement[] = [];
      const attachments: IElementAttachment[] = [];

      descendants.forEach((descendant) => {
        if (isIElementAttachment(descendant)) {
          attachments.push(descendant);
        } else  {
          children.push(descendant);
        }
      });

      const sortedAttachments = attachments
        .filter(isIElementAttachment)
        .sort(
          (attachmentA, attachmentB) =>
            new Date(attachmentB.createdAt).getTime() -
            new Date(attachmentA.createdAt).getTime()
        );

      const areaSections = ancestors.filter(isIElementAreaSectionWithUrl);
      const urls = areaSections.map((section) => section.metaDataMap.slideNodesUrl);
      const promises = urls.map((url) => downloadSlideNodes(url));
      const slideNodes = (await Promise.all(promises)).flat();

      dispatch(setAll(slideNodes));
      dispatch(addIElements([...ancestors, ...children]));
      updateAttachments(sortedAttachments);
      setLoadingState("fetched");
    } catch (error) {
      closeSidePanel();

      const title = activeTab === "Info"
        ? "Failed to open annotation details."
        : "Failed to open annoation attachments.";
      handleErrorWithToast({
        id: `fetchAnnotation-${Date.now().toString()}`,
        title,
        error,
      });
    }
  }, [activeTab, closeSidePanel, dispatch, handleErrorWithToast, projectApiClient, updateAttachments]);

  // Updates the markups context once the annotation data has been fetched and set in the redux store.
  useEffect(() => {
    if (loadingState === "fetched" && markup) {
      updateMarkupsContext(markup);
    }
  }, [loadingState, markup, updateMarkupsContext]);

  // Fetches the annotation data if the side panel is not yet initialized and there is a selected annotation.
  useEffect(() => {
    if (!isInit && selectedMarkup) {
      setLoadingState("loading");
      setIsInit(true);
      void fetchAnnotation(selectedMarkup.id);
    }
  }, [fetchAnnotation, isInit, selectedMarkup]);

  const isLoading: boolean = useMemo(() => {
    return loadingState !== "loaded";
  }, [loadingState]);

  return {
    isLoading,
    closeSidePanel,
  };
}
