import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { runtimeConfig } from "@src/runtime-config";
import { useMemo } from "react";
import { useCachedTokenProvider } from "@api/use-cached-token-provider";
import { RegistrationApiClient } from "@faro-lotv/service-wires";
import { CLIENT_ID } from "@api/client-id";

/**
 * Custom hook that initializes the Staging Area API client and returns an instance of it.
 * Because import-data may in theory have a `null` projectId, we currently allow it here,
 * and return `null` instead of a client in that case.
 */
export function useRegistrationApiClient({
  projectId,
}: BaseProjectIdProps): RegistrationApiClient {
  const tokenProvider = useCachedTokenProvider({
    projectId,
  });

  // Make sure the client is only created once, unless projectId or token provider change.
  return useMemo(
    () =>
      new RegistrationApiClient({
        baseUrl: runtimeConfig.urls.registrationApiUrl,
        projectId: projectId.toString(),
        tokenProvider,
        clientId: CLIENT_ID,
      }),
    [projectId, tokenProvider]
  );
}
