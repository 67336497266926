import { FaroTable } from "@components/common/faro-table/faro-table";
import { SdbBackgroundTask } from "@custom-types/sdb-background-tasks-types";
import { BackgroundTasksTableHeaders } from "@pages/project-details/project-bg-tasks/project-background-tasks-table-utils";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { resetTableState, setTableType } from "@store/table/table-slice";
import { TableType } from "@utils/track-event/track-event-list";
import { useEffect } from "react";
import { getProjectBackgroundTasksColumns } from "@pages/project-details/project-bg-tasks/project-background-tasks-columns";
import {
  backgroundTasksFetchingStatusSelector,
  sdbBackgroundTaskBeforePageSelector,
  totalCountSelector,
} from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { updateBackgroundTasks } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { LoadMoreButton } from "@components/common/button/load-more-button";
import { FetchingStatus } from "@store/store-types";
import { useDateTime } from "@hooks/use-date-time";

interface Props extends BaseProjectIdProps {
  /** List of background tasks */
  sdbBackgroundsTasks: SdbBackgroundTask[];

  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: BackgroundTasksTableHeaders[];
}

/**
 * List of the background tasks for the currently selected project
 */
export function ProjectBackgroundTasksTable({
  sdbBackgroundsTasks,
  isLoading,
  requiredColumns,
  projectId,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const progressApiClient = useProgressApiClient({
    projectId,
  });
  const projectApiClient = useProjectApiClient({ projectId });
  const beforePage = useAppSelector(sdbBackgroundTaskBeforePageSelector);
  const totalCount = useAppSelector(totalCountSelector);
  const backgroundTasksFetchingStatus = useAppSelector(
    backgroundTasksFetchingStatusSelector
  );
  const { formatDate } = useDateTime();

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    dispatch(setTableType(TableType.backgroundTasks));

    return () => {
      dispatch(resetTableState());
    };
  }, [dispatch]);

  return (
    <>
      <FaroTable
        entities={sdbBackgroundsTasks}
        uniqueIdKey="id"
        isLoading={isLoading}
        columns={requiredColumns.map(
          (requiredColumn) =>
            getProjectBackgroundTasksColumns(formatDate)[requiredColumn]
        )}
        rowHeight={54}
        shouldHideCheckbox={true}
        totalCount={totalCount ?? undefined}
        disabledNextPageTooltip="Please load more activities first"
      />

      <LoadMoreButton
        isLoading={backgroundTasksFetchingStatus === FetchingStatus.pending}
        // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
        onClick={async () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
          dispatch(
            updateBackgroundTasks({
              progressApiClient,
              projectApiClient,
              beforePage: `${beforePage}`,
            })
          );
        }}
        buttonText="Load more activities"
        shouldHideLoadMoreButton={
          sdbBackgroundsTasks.length === 0 ||
          sdbBackgroundsTasks.length === totalCount
        }
      />
    </>
  );
}
