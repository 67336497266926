import { useSearchParams } from "react-router-dom";
import { ErrorPageBase } from "@pages/error-page-base";
import { QueryParams } from "@router/route-params";
import { MemberRoleTypes, RoleDisplayNames } from "@custom-types/member-types";
import { useAppSelector } from "@store/store-helper";
import { SdbProject } from "@custom-types/project-types";
import { SdbCompany } from "@custom-types/sdb-company-types";
import { currentUserSelector } from "@store/user/user-selector";
import AccessDeniedIcon from "@assets/icons/new/access-denied_566px.svg";
import { useEffect } from "react";
import { ErrorEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface GetMissingRolesProps {
  /** Search parameters including the needed roles. */
  searchParams: URLSearchParams;

  /** Query parameter to look for roles in the url. */
  param: QueryParams;
}

/**
 * Gets the missing roles for the user extracted from the URL.
 *
 * @returns An object containing the decoded roles in a display format and a
 * simplified text to be shown to the user.
 */
export function getMissingRoles({
  searchParams,
  param,
}: GetMissingRolesProps): {
  roles: string[];
  text: string;
} {
  const rolesEncoded = searchParams.get(param);
  if (!rolesEncoded) {
    return {
      roles: [],
      text: "",
    };
  }

  // Whether it is a subscription or role
  const isSubscription = param === QueryParams.companySubscriptions;

  const rolesDecoded = decodeURIComponent(rolesEncoded);
  const roles = rolesDecoded
    .split("+")
    .map((role) =>
      isSubscription ? role : RoleDisplayNames[role as MemberRoleTypes]
    );
  let text: string = "";

  if (roles.length === 0) {
    text = "";
  } else if (roles.length === 1) {
    text = roles[0];
  } else if (roles.length === 2) {
    text = `${roles[0]} or ${roles[1]}`;
  } else {
    const firstRolesText = roles.slice(0, roles.length - 1).join(", ");
    const lastRoleText = roles[roles.length - 1];
    text = `${firstRolesText}, or ${lastRoleText}`;
  }
  return { roles, text };
}

interface GetMissingRolesTextProps extends GetMissingRolesProps {
  /** The selected company where the user is missing workspace roles. */
  selectedCompany?: SdbCompany | null;

  /** The selected project where the user is missing project roles. */
  selectedProject?: SdbProject | null;
}

/**
 * Gets the text to be shown in the page to indicate that some roles were missing
 *
 * @returns A simplified text in HTML, for example:
 * <>You need the workspace role "<strong>Enterprise Viewer</strong>" for workspace TestWorkspace.</>
 * <>You need one of the project roles "<strong>Project Admin or Owner</strong>"
 *   for project TestProject.</>
 */
export function getMissingRolesText({
  searchParams,
  param,
  selectedCompany,
  selectedProject,
}: GetMissingRolesTextProps): JSX.Element | null {
  const missingRoles = getMissingRoles({ searchParams, param });
  if (missingRoles.roles.length === 0) {
    return null;
  }

  // The display name of the entity the role belongs to.
  const roleName: "workspace" | "project" | "company" =
    // eslint-disable-next-line no-nested-ternary
    param === QueryParams.workspaceRoles
      ? "workspace"
      : param === QueryParams.projectPermissions
      ? "project"
      : "company";

  // The display role type in singular and plural form
  const roleType = {
    singular:
      param === QueryParams.companySubscriptions ? "subscription" : "role",
    plural:
      param === QueryParams.companySubscriptions ? "subscriptions" : "roles",
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment -- we need as default assignation.
  let extraInfo: JSX.Element = <></>;
  if (roleName === "project" && selectedProject) {
    extraInfo = (
      <>
        {" "}
        for project{" "}
        <i>
          <var>{selectedProject.name}</var>
        </i>
      </>
    );
  }
  if (roleName === "workspace" && selectedCompany) {
    extraInfo = (
      <>
        {" "}
        for workspace{" "}
        <i>
          <var>{selectedCompany.name}</var>
        </i>
      </>
    );
  }

  if (missingRoles.roles.length === 1) {
    return (
      <>
        You need the {roleName} {roleType.singular} "
        <strong>{missingRoles.text}</strong>"{extraInfo}.
      </>
    );
  }
  return (
    <>
      You need one of the {roleName} {roleType.plural} "
      <strong>{missingRoles.text}</strong>"{extraInfo}.
    </>
  );
}

/**
 * Returns a page when the user enter a forbidden section due to missing
 * roles, permissions, company features, etc.
 */
export function ForbiddenPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const currentUser = useAppSelector(currentUserSelector);
  const { trackAsyncEvent } = useTrackEvent();

  const origin = searchParams.get(QueryParams.origin);
  const userId = searchParams.get(QueryParams.user);
  const workspaceRoles = searchParams.get(QueryParams.workspaceRoles);
  const projectPermissions = searchParams.get(QueryParams.projectPermissions);
  const companySubscriptions = searchParams.get(
    QueryParams.companySubscriptions
  );

  // Track event every time user's access is denied and track the url plus missing roles
  useEffect(() => {
    /** Tracks the event when user opens a forbidden page */
    async function track(): Promise<void> {
      await trackAsyncEvent({
        name: ErrorEvents.openForbiddenPage,
        props: {
          url: origin ?? "",
          workspaceRoles: getMissingRoles({
            searchParams,
            param: QueryParams.workspaceRoles,
          }).roles.join(", "),
          projectPermissions: getMissingRoles({
            searchParams,
            param: QueryParams.projectPermissions,
          }).roles.join(", "),
          companySubscriptions: getMissingRoles({
            searchParams,
            param: QueryParams.companySubscriptions,
          }).roles.join(", "),
        },
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    track();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to track once or if the user changes
  }, []);

  // Checks if the user that was initially redirected to this page is the same
  // one or did it change. In the second case, we navigate to the original page.
  // This is useful when a user that didn't have permissions, logs in with a
  // different user that has permissions. If the page is redirected, we log the event.
  useEffect(() => {
    /** Redirects if the user changed to the original page and tracks this event. */
    async function redirectAndTrack(): Promise<void> {
      if (origin && currentUser && userId && currentUser.identity !== userId) {
        await trackAsyncEvent({
          name: ErrorEvents.openForbiddenPageDifferentUser,
          props: {
            url: origin ?? "",
            workspaceRoles: workspaceRoles ?? "",
            projectPermissions: projectPermissions ?? "",
            companySubscriptions: companySubscriptions ?? "",
          },
        });
        window.location.replace(origin);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    redirectAndTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to track once or if the user changes
  }, [currentUser]);

  return (
    <ErrorPageBase title="Access denied" iconImg={AccessDeniedIcon}>
      You don’t have permission to view this link, or the link may not be
      available. Request access from your administrator.
    </ErrorPageBase>
  );
}
