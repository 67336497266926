import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { runtimeConfig } from "@src/runtime-config";
import { getMailHref } from "@utils/email-utils";
import { useState } from "react";
import { getPrettyName } from "@utils/user-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { UserProfile } from "@utils/track-event/track-event-list";
import {
  DEFAULT_BOLD_TEXT_FONT_WEIGHT,
  DEFAULT_INPUT_FONT_SIZE,
} from "@styles/common-styles";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Button } from "@mui/material";
import { useUserContext } from "@pages/account-settings/user-provider";

/** Let user to request deleting their account */
export function AccountDeletion(): JSX.Element {
  const { user } = useUserContext();
  const [isAccountDeletionDialogOpen, setIsAccountDeletionDialogOpen] =
    useState<boolean>(false);

  const { trackEvent } = useTrackEvent();

  const body = encodeURIComponent(
    `I would like to request the deletion of my account associated with the email ${user.mailAddress}.\n` +
      "Please proceed with permanently removing my account and all related data as per your policies.\n" +
      "I understand that this action is irreversible and may take some time to complete.\n" +
      "Kindly confirm once the deletion process has been initiated or if any additional steps are required from my end.\n" +
      `Thank you for your assistance.\n\nBest regards,\n${getPrettyName(user)}`
  );

  function onDeleteAccountConfirm(): void {
    trackEvent({
      name: UserProfile.deleteAccount,
    });

    window.open(
      getMailHref({
        additionalEmails: [runtimeConfig.mailSupportAddress],
        subject: "Request deleting my account",
        body,
      })
    );

    setIsAccountDeletionDialogOpen(false);
  }

  return (
    <>
      <SphereTooltip title={"Delete account permanently"}>
        <Button
          color="error"
          onClick={() => setIsAccountDeletionDialogOpen(true)}
          disableFocusRipple={true}
          disableRipple={true}
          disableElevation={true}
          disableTouchRipple={true}
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            fontWeight: DEFAULT_BOLD_TEXT_FONT_WEIGHT,
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          Delete account
        </Button>
      </SphereTooltip>

      <FaroDialog
        title="Request account deletion"
        open={isAccountDeletionDialogOpen}
        onClose={() => setIsAccountDeletionDialogOpen(false)}
        onConfirm={onDeleteAccountConfirm}
        confirmButtonColor="red"
        confirmText="Request deletion"
      >
        Deleting your account is a permanent action. By confirming this request,
        our support team will initiate the deletion process.
      </FaroDialog>
    </>
  );
}
