import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  TEAM_DISPLAY_NAME,
  TEAMS_DISPLAY_NAME,
} from "@src/constants/team-constants";
import {
  APITypes,
  CoreAPITypes,
} from "@stellar/api-logic";
import { sphereColors } from "@styles/common-colors";
import { STACK_ERROR_SX } from "@styles/common-styles";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { SphereAvatar } from "@components/header/sphere-avatar";
import Team from "@assets/icons/new/team.svg?react";
import { TextField } from "@faro-lotv/flat-ui";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import { LabelWithHelp } from "@components/common/label-with-help";
import { Dispatch, useMemo } from "react";
import { AutoCompleteMemberOption } from "@components/common/members-autocomplete/members-autocomplete-types";
import {
  isNewEmailValid,
  MAX_INVITED_MEMBERS,
  validateInputChange,
  validateMembers,
} from "@pages/members/teams/teams-utils";
import { createMemberOption } from "@components/common/members-autocomplete/members-autocomplete-utils";
import { GroupAutoComplete } from "@components/common/group-autocomplete/group-autocomplete";
import { useCompanyMembers } from "@hooks/use-company-members";
import {
  MAX_DESCRIPTION_CHARACTERS,
  MAX_NAME_CHARACTERS,
} from "@pages/members/teams/create-team-dialog";
import {
  ActionCreateNewTeam,
  StateCreateNewTeam,
} from "@pages/members/teams/create-team-reducer";
import { isValidTeamName } from "@utils/team-utils";
import { CompanyRoleAutoComplete } from "@components/role/role-autocomplete/company-role-autocomplete";

interface Props {
  /** All the states for the create new team */
  state: StateCreateNewTeam;

  /** All the dispatch for the create new team */
  dispatchTeams: Dispatch<ActionCreateNewTeam>;
}

/** The content for the dialog create team */
export function CreateTeamDialogContent({
  state,
  dispatchTeams,
}: Props): JSX.Element {
  const companyMembers = useCompanyMembers();

  /** Stores the members of the company in the format required by the autocomplete */
  const memberOptions: AutoCompleteMemberOption[] = useMemo(() => {
    return companyMembers.map((member) => createMemberOption({ member }));
  }, [companyMembers]);

  /** Selected roles that needs to show group list when inviting them  */
  const shouldShowGroupList =
    state.selectedRole === CoreAPITypes.EUserCompanyRole.companyManager ||
    state.selectedRole === CoreAPITypes.EUserCompanyRole.projectManager;

  /**
   * Function to be called when the input value changes
   * It sets the valid emails to the addedEmails state and removes them from the input value
   */
  function onInputChange(value: string): void {
    const { message, isValid } = validateInputChange(
      memberOptions,
      companyMembers,
      value,
      TEAM_DISPLAY_NAME
    );

    dispatchTeams({ type: "SET_MESSAGE", message: message });
    dispatchTeams({
      type: "SET_IS_MEMBER_INPUT_VALID",
      isMemberInputValid: isValid,
    });
  }

  function handleMemberSelect(members: APITypes.UserIdentity[]): void {
    const { message, isValid } = validateMembers(
      members,
      TEAM_DISPLAY_NAME,
      MAX_INVITED_MEMBERS,
      memberIds
    );

    dispatchTeams({ type: "SET_MESSAGE", message: message });
    dispatchTeams({
      type: "SET_IS_MEMBER_INPUT_VALID",
      isMemberInputValid: isValid,
    });
    dispatchTeams({
      type: "SET_SELECTED_MEMBERS_IDS",
      selectedMembersIds: members,
    });
  }

  /**
   * Resets the group state when the selected role changes
  */
  function resetGroupsState(): void {
    dispatchTeams({ type: "SET_SELECTED_GROUP_ID", selectedGroupId: "" }); 
    dispatchTeams({ type: "SET_SELECTED_ROLE", selectedRole: undefined });
  }
 
  /** Stores the ids of the members of the company */
  const memberIds = useMemo(
    () =>
      companyMembers.map(
        (member) => member.id?.toLowerCase() ?? member.email.toLowerCase()
      ),
    [companyMembers]
  );

  return (
    <Grid maxWidth="100%" width="70vw" paddingX={"2px"}>
      <Grid container alignItems="center">
        <Grid item xs>
          <Box
            borderBottom={`1px solid ${sphereColors.gray200}`}
            width="100%"
          />
        </Grid>

        <Grid item>
          <Box mx={2}>
            <SphereAvatar
              icon={<Team width={"40px"} height={"40px"} />}
              size="x-large"
              backgroundColor={sphereColors.gray700}
            />
          </Box>
        </Grid>

        <Grid item xs>
          <Box
            borderBottom={`1px solid ${sphereColors.gray200}`}
            width="100%"
          />
        </Grid>
      </Grid>

      <Typography marginY={"32px"} fontSize={"14px"}>
        {capitalizeFirstLetter(TEAMS_DISPLAY_NAME)} allow you to organize
        individual members and assign roles to the entire {TEAM_DISPLAY_NAME} at
        once, saving time compared to assigning roles to each member
        individually.
      </Typography>

      {/* Team name */}
      <Stack sx={STACK_ERROR_SX}>
        <LabelWithHelp
          title={`${capitalizeFirstLetter(TEAM_DISPLAY_NAME)} name`}
          isRequired
        />
        <TextField
          maxCharacterCount={MAX_NAME_CHARACTERS}
          shouldShowCounter={true}
          onTextChanged={(name) => {
            dispatchTeams({ type: "SET_TEAM_NAME", teamName: name });
          }}
          text={state.teamName}
          fullWidth
          placeholder={`Enter the ${TEAM_DISPLAY_NAME} name...`}
          sx={{
            height: "38px",
            fontSize: "14px",
          }}
          autoFocus
          error={
            isValidTeamName(state.teamName)
              ? ""
              : `${capitalizeFirstLetter(
                  TEAM_DISPLAY_NAME
                )} name shouldn't contain comma(,) or semicolon(;)`
          }
        />
      </Stack>

      {/* Workspace role */}
      <Stack sx={STACK_ERROR_SX}>
        <CompanyRoleAutoComplete
          selectedRole={state.selectedRole}
          onChange={(role) =>
            dispatchTeams({
              type: "SET_SELECTED_ROLE",
              selectedRole: role,
            })
          }
          placeholder="Assign a workspace role to your team if needed"
          onResetSelection={resetGroupsState}
          shouldAllowAllRolesSelection={true}
        />
      </Stack>

      {/* Group */}
      {shouldShowGroupList && (
        <Stack sx={STACK_ERROR_SX}>
          <LabelWithHelp title="Group" isRequired />
          <GroupAutoComplete
            callbackOnGroupSelected={(groupId) =>
              dispatchTeams({
                type: "SET_SELECTED_GROUP_ID",
                selectedGroupId: groupId,
              })
            }
            selectedGroupId={state.selectedGroupId}
            placeholder="Please select a group..."
          />
        </Stack>
      )}

      {/* Team members */}
      <Stack sx={STACK_ERROR_SX}>
        <MembersAutocomplete
          options={memberOptions}
          handleChange={handleMemberSelect}
          onInputChange={onInputChange}
          placeHolder="Enter member name or email"
          message={state.message}
          validateNewOption={(email) => isNewEmailValid(email, companyMembers)}
          // If one of the members is preselected, they should be added to the group by default.
          initialValue={memberOptions.filter(
            (memberOption) => memberOption.isPreselected
          )}
          labelTitle="Members"
          isRequired={false}
        />
      </Stack>

      {/* Team description */}
      <LabelWithHelp title="Description" />
      <TextField
        maxCharacterCount={MAX_DESCRIPTION_CHARACTERS}
        shouldShowCounter={true}
        onTextChanged={(description) => {
          dispatchTeams({ type: "SET_DESCRIPTION", description: description });
        }}
        text={state.description}
        fullWidth
        placeholder={`Enter the ${TEAM_DISPLAY_NAME} description...`}
        multiline={true}
        rows={4}
        sx={{ fontSize: "14px" }}
      />
    </Grid>
  );
}
