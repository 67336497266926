import { SdbProject } from "@custom-types/project-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** A single description for permissions on the project level. */
interface RequiredPermissionProjectLevel {
  /**
   * Array filled with permissions where the user
   * should have one of them in order to have access.
   * If the array is empty, it means user has no required permission and has default access
   */
  permissions: SphereDashboardAPITypes.EProjectPermission[];
}

/**
 * Object that determines all different permission rules for users to get
 * access on the project level.
 */
export type RequiredPermissionsProjectLevel<PermissionNameT extends string> = {
  [key in PermissionNameT]: RequiredPermissionProjectLevel;
};

/**
 * Signature for the function hasUserValidPermissionProjectLevel.
 */
export interface HasUserValidPermissionProjectLevelProps<
  PermissionNameT extends string
> {
  /**
   * The internal name used in the UI to determine certain permissions,
   * e.g. "canChangeRole" is used to determine whether the user can change the role
   * teams tab in the project overview page.
   */
  permissionName: PermissionNameT;

  /**
   * The project to get the user's permission level.
   */
  selectedProject: SdbProject | null;

  /**
   * You can optionally change the default required permissions definition,
   * ! This is only used for unit tests,
   * therefore only use the default value.
   */
  defaultRequiredPermissions?: RequiredPermissionsProjectLevel<PermissionNameT>;
}

/** Names for all permissions within the project level. */
export enum RequiredPermissionProjectLevelName {
  /** Whether the user can change the role of a team member */
  canChangeRole = "canChangeRole",

  /** Whether the user can change the group and project manager of a project */
  canChangeProjectGroup = "canChangeProjectGroup",

  /** Whether the user can change the project access level of a project */
  canChangeProjectAccessLevel = "canChangeProjectAccessLevel",

  /** Whether the user can edit details from a project like name or description */
  canEditProjectDetails = "canEditProjectDetails",

  /** Whether the user can view the project cloud activity */
  canViewProjectCloudActivity = "canViewProjectCloudActivity",

  /**
   * Whether the user can view the members tab in project overview page
   * and view all users with access to the project
   */
  canViewAllProjectMembers = "canViewAllProjectMembers",

  /** Whether the user can view the project settings */
  canViewProjectSettings = "canViewProjectSettings",

  /** Whether the user can view the project activity */
  canInviteUsersToPrivateProject = "canInviteUsersToPrivateProject",

  /** Whether the user can view the project activity */
  canEditProjectSettings = "canEditProjectSettings",

  /** Whether the user can delete a member from a project */
  canDeleteMemberFromProject = "canDeleteMemberFromProject",

  /** Whether the user can delete a project */
  canDeleteProject = "canDeleteProject",

  /** Whether the user has permission to delete snapshots from a project */
  canDeleteSnapshots = "canDeleteSnapshots",

  /** Whether the user can view the markups (annotations) of a project */
  canViewProjectMarkups = "canViewProjectMarkups",

  /** Whether the user can view the markups (annotations) of a project. Project viewers are allowed */
  canViewProjectMarkupsAllowViewers = "canViewProjectMarkupsAllowViewers",

  /** Whether the user can view the project stats chart in the project overview page */
  canViewProjectsStats = "canViewProjectsStats",

  /** Whether the user can view the project data */
  canViewProjectData = "canViewProjectData",

  /** Whether the user can edit the markup details */
  canEditProjectMarkups = "canEditProjectMarkups",

  /** Whether the user can view data management */
  canViewDataManagement = "canViewDataManagement",

  /** Whether the user can view project integrations tab. */
  canViewProjectIntegrations = "canViewProjectIntegrations",
}
