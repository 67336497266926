import { BaseProjectsProps, ProjectArchivingState } from "@custom-types/project-types";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import { useMediaQueryList } from "@hooks/use-media-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { resetTableState, setInitialFetchingItems, setTableType } from "@store/table/table-slice";
import { ProjectEvents, TableType } from "@utils/track-event/track-event-list";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { ProjectsPageBulkActions } from "@pages/projects/projects-page-bulk-actions";
import { getProjectsColumns } from "@components/table/projects/projects-columns";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { ThemeProvider } from "@mui/material";
import { DEFAULT_HEADER_HEIGHT_IN_PX, theme } from "@components/common/faro-table/faro-table";
import { FaroTableToolbar } from "@components/common/faro-table/faro-table-toolbar";
import { DataGridPro, GridRowsProp } from "@mui/x-data-grid-pro";
import { listTypeSelector, nextPageCursorSelector } from "@store/projects/projects-selector";
import { selectedRowIdsSelector, updatingItemsSelector } from "@store/table/table-selector";
import { FaroCustomRow } from "@components/common/faro-table/faro-custom-row";
import { FaroCustomCheckbox } from "@components/common/faro-table/faro-custom-checkbox";
import UpArrow from "@assets/icons/new/arrow-up_10px.svg?react";
import DownArrow from "@assets/icons/new/arrow-down_10px.svg?react";
import { API_PROJECTS_PER_REQUEST } from "@store/projects/projects-slice-utils";
import { MIN_CHARACTERS_FOR_SEARCH } from "@utils/project-utils";
import { searchSelector } from "@store/ui/ui-selector";
import { ProjectsTableFooter } from "@components/table/projects/projects-table-footer";

export type ProjectTableContextType =
  | "active projects"
  | "archived projects"
  | "group projects";

interface Props extends BaseCompanyIdProps, BaseProjectsProps {
  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** Recognizes on which context the table is being used */
  contextType: ProjectTableContextType;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: ProjectHeaders[];

  /** The archiving state of the project */
  projectArchivingState: ProjectArchivingState;

  /** Callback when the load more button is clicked */
  loadMoreProjects: () => Promise<void>;
}

/**
 * Listing all the projects of a subject such as a company or a group in a table
 */
export function ProjectsTable({
  companyId,
  projects,
  isLoading,
  contextType,
  requiredColumns,
  projectArchivingState,
  loadMoreProjects,
}: Props): JSX.Element {
  const { navigateToProjectDetail } = useAppNavigation();
  const { canViewAllCompanyGroups } = useHasUserValidRoleCompanyLevel();
  const dispatch = useAppDispatch();
  const { isMedium, isScreenLgAndLarger } = useMediaQueryList();
  const { trackEvent } = useTrackEvent();
  const updatingItems = useAppSelector(updatingItemsSelector);
  const selectedRowIds = useAppSelector(selectedRowIdsSelector);
  const listType = useAppSelector(listTypeSelector(projectArchivingState));
  const nextPageCursor = useAppSelector(nextPageCursorSelector(contextType === "group projects" ? "group" : listType));
  const { debouncedSearchText } = useAppSelector(searchSelector);

  const [page, setPage] = useState<number>(0);

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    // As ProjectsTable is a component that is used in multiple places,
    // we need to make sure that the state is reset after contextType is changed
    dispatch(resetTableState());
    switch (contextType) {
      case "active projects":
        dispatch(setTableType(TableType.activeProjects));
        break;

      case "archived projects":
        dispatch(setTableType(TableType.archiveProjects));
        break;

      case "group projects":
        dispatch(setTableType(TableType.groupProjects));
        break;
    }

    return () => {
      dispatch(resetTableState());
    };
  }, [contextType, dispatch]);

  // Total projects count. It's set to unknown (-1) when there are still pages to fetch from the backend.
  const rowCount = useMemo(() => {
    return nextPageCursor ? -1 : projects.length;
  }, [nextPageCursor, projects.length]);

  // Last page. if there are still pages to fetch then it default to unknown (-1)
  const lastPage = useMemo(() => {
    if (rowCount === -1) {
      return -1;
    }

    const quotient = Math.floor(rowCount / API_PROJECTS_PER_REQUEST);
    const remainder = rowCount % API_PROJECTS_PER_REQUEST;

    if (remainder === 0) {
      return quotient -1;
    } else {
      return quotient;
    }
  }, [rowCount]);

  // Index of the first project of the current page
  const start = useMemo(() => {
    return page * API_PROJECTS_PER_REQUEST;
  }, [page]);

  // Projects page
  const paginatedProjects = useMemo(() => {
    const end = start + API_PROJECTS_PER_REQUEST;
    return projects.slice(start, end);
  }, [projects, start]);

  // Index of the last project of the current page
  const to = useMemo(() => {
    return start + paginatedProjects.length;
  }, [paginatedProjects.length, start]);

  // Our table and column requires to pass the projects as list of ID and entity objects
  const rows: GridRowsProp = useMemo(() => {
    return paginatedProjects.map((project) => {
      return {
        id: project.id,
        entity: project,
      };
    });
  }, [paginatedProjects]);

  const actionButtons = ProjectsPageBulkActions({
    companyId,
    projects,
    projectStatus: projectArchivingState,
    subjectType: contextType === "group projects" ? "group" : "workspace",
  });

  const columns = useMemo(() => {
    const columnsMap = getProjectsColumns({
      contextType,
      companyId,
      options: {
        isScreenLgOrLarger: isScreenLgAndLarger,
        isMedium,
      },
      permissions: {
        canViewAllCompanyGroups,
      },
      trackEvent,
    });

    return requiredColumns.map((header) => {
      return {
        ...columnsMap[header],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sortable: false,
      };
    });
  }, [canViewAllCompanyGroups, companyId, contextType, isMedium, isScreenLgAndLarger, requiredColumns, trackEvent]);

  const handlePageChange = useCallback((newPage: number) => {
    // Fetch the next page of projects if available
    if (newPage > page && nextPageCursor) {
      void loadMoreProjects();
    }

    setPage(newPage);
  }, [loadMoreProjects, nextPageCursor, page]);

  // Resets the current page to the first when the list type changes or
  // when searching for projects the search input changes.
  useEffect(() => {
    if (listType) {
      setPage(0);
    }

    if (listType === "searched" && debouncedSearchText.length >= MIN_CHARACTERS_FOR_SEARCH) {
      setPage(0);
    }
  }, [debouncedSearchText.length, listType]);

  return (
    <ThemeProvider theme={theme}>
      <FaroTableToolbar
        numberOfSelectedRows={updatingItems.length}
        numberOfRows={projects.length}
        actionButtons={actionButtons}
      />
      <DataGridPro
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        rows={rows}
        columns={columns}
        slots={{
          row: FaroCustomRow,
          baseCheckbox: FaroCustomCheckbox,
          columnSortedAscendingIcon: DownArrow,
          columnSortedDescendingIcon: UpArrow,
          footer: ProjectsTableFooter,
        }}
        slotProps={{
          footer: {
            from: start + 1,
            to,
            rowCount,
            page,
            lastPage,
            isLoading,
            handlePageChange,
          },
        }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        checkboxSelection={true}
        loading={isLoading}
        hideFooter={isLoading}
        columnHeaderHeight={DEFAULT_HEADER_HEIGHT_IN_PX}
        rowHeight={70}
        onRowSelectionModelChange={(newSelectedRowIds) => {
          dispatch(setInitialFetchingItems([...newSelectedRowIds]));
        }}
        onRowClick={(row) => {
          trackEvent({
            name: ProjectEvents.openProjectDetails,
            props: {
              eventLocation: contextType,
            },
          });
          navigateToProjectDetail({
            companyId,
            projectId: row.id,
          });
        }}
        rowSelectionModel={selectedRowIds}
        // Server side pagination attributes
        pageSizeOptions={[API_PROJECTS_PER_REQUEST]}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={{ page, pageSize: API_PROJECTS_PER_REQUEST}}
      />
    </ThemeProvider>
  );
}
