import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { runtimeConfig } from "@src/runtime-config";
import { ProjectApi } from "@api/project-api/project-api";
import { CLIENT_ID } from "@api/client-id";
import { getCachedTokenProvider } from "@api/use-cached-token-provider";

/**
 * Returns an instance of the project API client.
 *
 * @returns ProjectApi client instance
 */
export function getProjectApiClient({
  projectId,
}: BaseProjectIdProps): ProjectApi {
  const projectApiURL = new URL(runtimeConfig.urls.projectApiUrl);

  const tokenProvider = getCachedTokenProvider(projectId);

  return new ProjectApi({
    projectApi: projectApiURL,
    projectId: projectId.toString(),
    tokenProvider,
    clientId: CLIENT_ID,
  });
}
