

/**
 * Function to get the dimensions of an image
 * @param file - The image file
 * @returns A Promise with the image dimensions
 */
export async function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    const blobUrl = URL.createObjectURL(file);
    img.src = blobUrl;
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      URL.revokeObjectURL(blobUrl);
    };
    img.onerror = (error) => {
      reject(new Error(`Failed to load image: ${file.name}`));
      URL.revokeObjectURL(blobUrl);
    };
  });
}
