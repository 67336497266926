import { BasicTabProps } from "@components/common/tabs/tabs-type";
import { accountSettingsPreferencesTab } from "@pages/account-settings/account-settings-preferences-tab";
import { accountSettingsSecurityTab } from "@pages/account-settings/account-settings-security-tab";
import { AccountSettingsTabs, QueryParams } from "@router/route-params";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useUserContext } from "@pages/account-settings/user-provider";
import { generateAccountSettingsRoute } from "@router/router-helper";

interface UseAccountSettingsProps {
  /** The tabs used for account settings */
  accountSettingsTabs: Record<
    AccountSettingsTabs,
    BasicTabProps<AccountSettingsTabs>
  >;
}

/** The tabs used for account settings */
export function useAccountSettingsTabs(): UseAccountSettingsProps {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();

  // Accessing alpha flag from selector is not available at this state. This is because the store is not set up yet.
  const isAlphaFlagEnabled =
    searchParams.get(QueryParams.enableAlpha) === "true";

  const accountSettingsTabs: Record<
    AccountSettingsTabs,
    BasicTabProps<AccountSettingsTabs>
  > = useMemo(() => {
    return {
      security: {
        routeId: AccountSettingsTabs.security,
        label: "Account & Security",
        content: accountSettingsSecurityTab({
          user,
        }),
      },
      preferences: {
        routeId: AccountSettingsTabs.preferences,
        label: "Preferences",
        content: accountSettingsPreferencesTab({ isAlphaFlagEnabled }),
      },
    };
  }, [isAlphaFlagEnabled, user]);

  return {
    accountSettingsTabs,
  };
}

/**
 * Redirects to the account settings page.
 * It will redirect outside the SPA and pass the current pathname and query params
 * as  query param that the account settings page will read.
 * @param accountSettingsTab Tab to navigate to.
 */
export function redirectToAccountSettingsPage(
  accountSettingsTab: AccountSettingsTabs
): void {
    const route = generateAccountSettingsRoute(accountSettingsTab);
    const pathname = window.location.pathname;
    const search = window.location.search;
    const encodedRoute = encodeURIComponent(`${pathname}${search}`);
    const href = `${route}?${QueryParams.settingsOriginUrl}=${encodedRoute}`;
    window.location.href = href;
}
