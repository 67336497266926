import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { CaptureTreeRevision, CaptureTreeEntityWithRevisionStatus } from "@custom-types/capture-tree/capture-tree-types";
import { CaptureTreeEntity } from "@faro-lotv/service-wires";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseProjectApiClientProps } from "@store/store-types";

interface FetchCaptureTreeForOpenDraftRevisionProps extends BaseProjectApiClientProps {
  /** The ID of the open draft revision */
  openDraftRevisionId: string | null;
}

/** Fetches the list of capture tree entities for the current main/draft revision of the selected project */
export const fetchCaptureTree = createAsyncThunk<
  CaptureTreeEntity[],
  BaseProjectApiClientProps
>(
  "captureTree/fetchCaptureTree",
  async ({ projectApiClient }) => {
    try {
      return await projectApiClient.getCaptureTree();
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches the list of capture tree entities for the current draft revision of the selected project */
export const fetchCaptureTreeForOpenDraftRevision = createAsyncThunk<
  CaptureTreeEntityWithRevisionStatus[],
  FetchCaptureTreeForOpenDraftRevisionProps
>(
  "captureTree/fetchCaptureTreeForOpenDraftRevision",
  async ({ projectApiClient, openDraftRevisionId }) => {
    try {
      // If there is currently no open draft revision, we want to return an empty array.
      return openDraftRevisionId ?
        await projectApiClient.getCaptureTreeForRegistrationRevision(openDraftRevisionId) :
        [];
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches the list of all revisions of the selected project */
export const fetchAllCaptureTreeRevisions = createAsyncThunk<
  CaptureTreeRevision[],
  BaseProjectApiClientProps
>("captureTree/fetchAllCaptureTreeRevisions", async ({ projectApiClient }) => {
  try {
    // Revisions in the ProjectAPI are actually called "registration revisions",
    // but we alias them "capture tree revisions" in the Dashboard to avoid confusion.
    return await projectApiClient.getAllRegistrationRevisions();
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});
