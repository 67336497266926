import { PageSectionProps } from "@components/page-section/page-section";
import { runtimeConfig } from "@src/runtime-config";
import { AccountLanguageSelector } from "@pages/account-settings/sections/account-language-selector";
import { AccountUnitSelector } from "@pages/account-settings/sections/account-unit-selector";
import { AccountCookiesPreferences } from "@pages/account-settings/sections/account-cookies-preferences";

interface Props {
  /** Flag to determine if the alpha flag is enabled */
  isAlphaFlagEnabled: boolean;
}

/** Content for the preferences tab of account settings page */
export function accountSettingsPreferencesTab({
  isAlphaFlagEnabled,
}: Props): PageSectionProps[] {
  return [
    {
      title: "Language",
      subtitle:
        "Changes to your language will be reflected across Sphere XG applications.",
      items: [
        {
          label: "Language",
          content: <AccountLanguageSelector />,
        },
      ],
    },
    {
      title: "Units",
      subtitle: `Select your preferred units of measurement (e.g. metric or imperial) 
        for consistent display across all reports, charts, and project metrics.`,
      items: [
        {
          label: "Units",
          content: <AccountUnitSelector />,
          shouldHideTopBorder: true,
        },
      ],
      isHidden: !isAlphaFlagEnabled,
    },
    {
      title: "Cookie preferences",
      subtitle:
        "We use cookies & similar technologies to improve and personalize your user experience, " +
        "deliver adverts to your interests on third party platforms and analyze our web traffic.",
      // Cookies are not stored in EU therefore we don't show the cookie manager there
      isHidden: !runtimeConfig.urls.cookieManagerUrl,
      items: [
        {
          label: "Cookie policy",
          content: <AccountCookiesPreferences />,
          shouldAlignToEnd: true,
          shouldAddRightPadding: true,
          shouldHideTopBorder: true,
        },
      ],
    },
  ];
}
