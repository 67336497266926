import { FaroTable } from "@components/common/faro-table/faro-table";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { resetTableState, setTableType } from "@store/table/table-slice";
import {
  SidePanelEvents,
  TableType,
} from "@utils/track-event/track-event-list";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useEffect } from "react";
import { getProjectMarkupsColumns } from "@pages/project-details/project-markups/project-markups-columns";
import { sphereColors } from "@styles/common-colors";
import { updateSidebar } from "@store/ui/ui-slice";
import { sidebarSelector } from "@store/ui/ui-selector";
import { MarkupSidePanel } from "@pages/project-details/project-markups/sidepanel/markup-sidepanel";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useMembersUtils } from "@hooks/use-member-utils";
import { projectMarkupsFilterChips } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-chips";
import { useApplyFilters } from "@hooks/table-filters/use-apply-filters";
import { useProjectMarkupsFilterLists } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";
import { ProjectMarkupBulkActions } from "@pages/project-details/project-markups/project-markups-bulk-actions/project-markup-bulk-actions";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

interface Props extends BaseProjectIdProps {
  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: ProjectMarkupsTableHeaders[];
}

/**
 * List of the Project markups for the currently selected project
 */
export function ProjectMarkupsTable({
  isLoading,
  requiredColumns,
  projectId,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const { companyMembers, projectMembers } = useMembersUtils();

  const {
    markups,
    updateSelectedMarkup,
    selectedMarkup,
    isOpenMarkupSidePanel,
    updateIsSidePanelOpen,
    selectedMarkupId,
  } = useMarkupContext();

  const allTableFilters = useProjectMarkupsFilterLists();

  const filteredMarkups = useApplyFilters({
    entities: markups,
    allTableFilters,
    requiredColumns,
    quickFilterEntity: (markup) => markup.name,
  });

  const bulkActionButtons = ProjectMarkupBulkActions({
    markups: filteredMarkups,
    projectId,
  });

  const filterChips = projectMarkupsFilterChips();

  const { isFullSize } = useAppSelector(sidebarSelector);

  useEffect(() => {
    if (selectedMarkup) {
      const updatedMarkup = markups.find(
        (markup) => markup.id === selectedMarkup.id
      );

      if (updatedMarkup) {
        updateSelectedMarkup(updatedMarkup);
      }
    }
  }, [selectedMarkup, markups, updateSelectedMarkup]);

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    dispatch(setTableType(TableType.projectMarkups));

    return () => {
      dispatch(resetTableState());
    };
  }, [dispatch]);

  function onRowClicked(id: string | number): void {
    if (isFullSize) {
      dispatch(updateSidebar({ isFullSize: false }));
    }

    trackEvent({
      name: SidePanelEvents.openSidePanelMarkups,
    });

    const markup = markups.find((markup) => markup.id === id);

    if (markup) {
      updateSelectedMarkup(markup);
      updateIsSidePanelOpen(!isOpenMarkupSidePanel);
    }
  }

  return (
    <>
      <FaroTable
        entities={filteredMarkups}
        uniqueIdKey="id"
        isLoading={isLoading}
        columns={requiredColumns.map(
          (requiredColumn) =>
            getProjectMarkupsColumns({ projectMembers, companyMembers })[
              requiredColumn
            ]
        )}
        rowHeight={54}
        onRowClick={onRowClicked}
        bulkActionButtons={bulkActionButtons}
        filterChips={filterChips}
        rowClassName={(rowData) => {
          return rowData.id === selectedMarkupId ||
            selectedMarkup?.id === rowData.id
            ? "markups-selected-side-panel"
            : "";
        }}
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
          "& .markups-selected-side-panel": {
            background: sphereColors.gray200,
          },
          "& .MuiDataGrid-cell": {
            cursor: "pointer",
            // Padding should be 0 to align the bottom blue border on edit mode on the very bottom of the cell
            padding: "0",
          },
        }}
      />

      <MarkupSidePanel />
    </>
  );
}
