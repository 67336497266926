import { CREDITS_DISPLAY_NAME } from "@src/constants/credit-constants";
import { capitalizeFirstLetter } from "@utils/string-utils";

const CREDIT_HEADER = capitalizeFirstLetter(CREDITS_DISPLAY_NAME);

/** All the possible headers for transactions table. */
export const TransactionsHeaders = {
  /** The date of each transactions */
  date: "Date",
  
  /** User who used each of the transactions */
  user: "Users",
  
  /** Description of the transactions */
  description: "Description",

  /** Project from where the transactions created */
  project: "Project",

  /** Amount of credit information in each transactions */
  credits: CREDIT_HEADER,
} as const;

export type TransactionsHeadersType = typeof TransactionsHeaders[keyof typeof TransactionsHeaders];


/** All the possible headers for token(credit) history table. */
export const CreditPurchasesHistoryHeaders = {
   /** The name of the package based on the credit amount */
  package: "Package",

  /** The date when the package was purchased */
  purchasedDate: "Purchased date",

  /** The expiration date of the package */
  expirationDate: "Expiration date",

  /** The status of the package */
  status: "Status",

  /** The remaining credits in the package */
  creditsRemaining: `${CREDIT_HEADER} remaining`,
} as const;

export type CreditPurchasesHistoryHeadersType =
  typeof CreditPurchasesHistoryHeaders[keyof typeof CreditPurchasesHistoryHeaders];