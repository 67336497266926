import { Box } from "@mui/material";
import { HelpCenterMenu, LanguageCodes } from "@faro-lotv/flat-ui";
import { SendFeedbackDialog } from "@components/header/send-feedback-dialog";
import { useToast } from "@hooks/use-toast";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  AppBarEvents,
  FeedbackModalEvents,
} from "@utils/track-event/track-event-list";
import { useState } from "react";
import { useAppSelector } from "@store/store-helper";
import { loggedInUserSelector } from "@store/user/user-selector";
import { numUnreadMsgSelector } from "@store/ui/ui-selector";
import  { useIsCookieAccepted, CookieId, useIsCookieManagerInitialized } from "@faro-lotv/foreign-observers";
import { runtimeConfig } from "@src/runtime-config";

// If the cookie manager URL is set, we can show the Intercom chat.
// That does not mean that the user gave consent to use it, but we do show the button.
const shouldShowIntercom = !!runtimeConfig.urls.cookieManagerUrl;

export function FaroHelpCenterMenu(): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const loggedInUser = useAppSelector(loggedInUserSelector);
  const numUnreadMsg = useAppSelector(numUnreadMsgSelector);
  const { showToast } = useToast();
  const isCookieAccepted = useIsCookieAccepted();
  const isCookieManagerInitialized = useIsCookieManagerInitialized();
  const [shouldGiveFeedback, setsShouldGiveFeedback] = useState<boolean>(false);

  function openFeedbackDialog(): void {
    setsShouldGiveFeedback(true);
    trackEvent({ name: FeedbackModalEvents.openFeedbackDialog });
  }

  function onOpenChat(): void {
    if (!window.Intercom) {
      trackEvent({ name: AppBarEvents.openChatNotInitialized });
      showToast({
        message: "The chat plugin isn't initialized yet. " +
          "Please verify that Intercom is enabled in your cookie preferences.",
        type: "error",
      });
    } else if (!loggedInUser) {
      trackEvent({ name: AppBarEvents.openChatNotLoggedIn });
      showToast({
        message: "Only logged-in users can use the chat.",
        type: "error",
      });
    } else if (!isCookieManagerInitialized || !isCookieAccepted(CookieId.intercom)) {
      // This case seems to occur only when Intercom was still enabled at page load, and then disabled.
      trackEvent({ name: AppBarEvents.openChatMissingCookie });
      showToast({
        message: "You have to enable Intercom in your cookie preferences to use the chat.",
        type: "error",
      });
    } else {
      window.Intercom("show");
      trackEvent({ name: AppBarEvents.openChatSuccess });
    }
  }

  return (
    <>
      <Box component="div" marginLeft={"0.75rem"}>
        <HelpCenterMenu
          helpCenterLanguages={LanguageCodes.English}
          sendFeedback={openFeedbackDialog}
          onOpenHelpCenter={() => {
            trackEvent({ name: AppBarEvents.openHelpMenu });
          }}
          onCloseHelpCenter={() => {
            trackEvent({ name: AppBarEvents.closeHelpMenu });
          }}
          onOpenHelpLink={(itemName) => {
            trackEvent({
              name: AppBarEvents.openHelpMenuItem,
              props: {
                itemName,
              },
            });
          }}
          openChat={shouldShowIntercom ? onOpenChat : undefined}
          numUnreadMsg={numUnreadMsg}
        />
      </Box>

      <SendFeedbackDialog
        /**
         * TODO: https://faro01.atlassian.net/browse/ST-2140 use the correct language - hash commit where was removed
         * 97fdac6aed9358cfd4efeb06d1c6e7afabe961a8
         */
        shouldShowFeedbackDialog={shouldGiveFeedback}
        setShouldShowFeedbackDialog={setsShouldGiveFeedback}
      />
    </>
  );
}
