import {
  generateGroupDetailsRoute,
  generateProjectDetailsRoute,
  generateProjectsRoute,
  generateMemberProfileRoute,
  generateGroupsRoute,
  generateTeamDetailsRoute,
  getPreservedQueryParams,
  generateIntegrationsRoute,
  generateAccountSettingsRoute,
} from "@router/router-helper";
import { getInspectAndPublishToolUrl } from "@pages/project-details/project-data-management/data-management-utils";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { AccountSettingsTabs, QueryParams } from "@router/route-params";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { OPEN_PROJECT_TARGET_ATTRIBUTE } from "@utils/project-utils";
import {
  NavigateToGroupDetails,
  NavigateToInspectAndPublishTool,
  NavigateToIntegrationsPage,
  NavigateToMemberProfile,
  NavigateToProjectDetail,
  NavigateToProjects,
  NavigateToTeamDetails,
  NavigateWithPreservedParams,
  SetUrlParamProps,
  UseAppNavigation,
} from "@hooks/navigation/use-app-navigation-types";

/** A hook that gathers all the navigation and routing in the app */
export function useAppNavigation(): UseAppNavigation {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  /** Navigates to a new path while preserving the query parameters from the current URL */
  function navigateWithPreservedParams({
    path,
    state = "in-app-navigation",
  }: NavigateWithPreservedParams): void {
    const preservedSearch = getPreservedQueryParams(location.search);
    const cleanedPath = path.split("?")[0];
    navigate(
      {
        pathname: cleanedPath,
        search: preservedSearch,
      },
      { state }
    );
  }

  function navigateToRoot(): void {
    navigateWithPreservedParams({ path: "/" });
  }

  function navigateToProjects({
    companyId,
    projectStatus,
  }: NavigateToProjects): void {
    const path = generateProjectsRoute(companyId, projectStatus);
    navigateWithPreservedParams({ path });
  }

  function navigateToProjectDetail({
    companyId,
    projectId,
  }: NavigateToProjectDetail): void {
    const path = generateProjectDetailsRoute(companyId, projectId);
    navigateWithPreservedParams({ path });
  }

  function navigateToGroups({ companyId }: BaseCompanyIdProps): void {
    const path = generateGroupsRoute(companyId);
    navigateWithPreservedParams({ path });
  }

  function navigateToGroupDetails({
    companyId,
    groupId,
  }: NavigateToGroupDetails): void {
    const path = generateGroupDetailsRoute(companyId, groupId);
    navigateWithPreservedParams({ path });
  }

  function navigateToMemberProfile({
    companyId,
    memberId,
  }: NavigateToMemberProfile): void {
    const path = generateMemberProfileRoute(companyId, memberId);
    navigateWithPreservedParams({ path });
  }

  function navigateToTeamDetails({
    companyId,
    teamId,
  }: NavigateToTeamDetails): void {
    const path = generateTeamDetailsRoute(companyId, teamId);
    navigateWithPreservedParams({ path });
  }

  function navigateToInspectAndPublishTool({
    projectId,
    registrationId,
  }: NavigateToInspectAndPublishTool): void {
    const path = getInspectAndPublishToolUrl(projectId, registrationId);
    window.open(path, OPEN_PROJECT_TARGET_ATTRIBUTE);
  }

  function navigateToIntegrationsPage({
    companyId,
  }: NavigateToIntegrationsPage): void {
    const path = generateIntegrationsRoute(companyId);
    navigateWithPreservedParams({ path });
  }

  function navigateToAccountSettingsPage(
    accountSettingsTab: AccountSettingsTabs
  ): void {
    const path = generateAccountSettingsRoute(accountSettingsTab);
    navigateWithPreservedParams({
      path,
      state: "in-app-navigation",
    });
  }

  /** Sets a query parameter in the URL */
  function setUrlParam({ key, value }: SetUrlParamProps): void {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    if (value === null) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, value);
    }
    setSearchParams(newSearchParams);
  }

  /**
   * Returns the value of a query parameter from the URL
   */
  function getQueryParam(key: QueryParams): string | null {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(key);
  }

  return {
    navigateToRoot,
    navigateToProjects,
    navigateToProjectDetail,
    navigateToGroups,
    navigateToGroupDetails,
    navigateToMemberProfile,
    navigateToTeamDetails,
    navigateToInspectAndPublishTool,
    navigateToIntegrationsPage,
    navigateToAccountSettingsPage,
    setUrlParam,
    getQueryParam,
  };
}
