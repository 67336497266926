import {
  GridColDef,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { CreditPurchasesHistoryHeadersType, CreditPurchasesHistoryHeaders } from "@components/table/transactions/transactions-table-utils";
import {
  CreditSubscription,
  CreditSubscriptionStatus,
} from "@custom-types/credit-types";
import { sphereColors } from "@styles/common-colors";
import { Box } from "@mui/material";
import {
  getSubscriptionPackageName,
  getSubscriptionStatus,
} from "@utils/credit-transaction-utils";
import { FormatDate } from "@hooks/use-date-time";
import { FaroChip } from "@faro-lotv/flat-ui";
import { nounPluralize } from "@utils/data-display";
import { CREDIT_DISPLAY_NAME } from "@src/constants/credit-constants";

interface Props {
  /** The function to format date. */
  formatDate: FormatDate;
}

/** Return all the possible columns for the Credit Purchases History table */
export function getCreditPurchasesColumns({
  formatDate,
}: Props): Record<CreditPurchasesHistoryHeadersType, GridColDef> {
  return {
    [CreditPurchasesHistoryHeaders.package]: {
      field: CreditPurchasesHistoryHeaders.package,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditSubscription }>
      ) => {
        return <var>{getSubscriptionPackageName(params.row.entity)}</var>;
      },
      valueGetter: (_, row: { entity: CreditSubscription }) =>
        getSubscriptionPackageName(row.entity),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [CreditPurchasesHistoryHeaders.purchasedDate]: {
      field: CreditPurchasesHistoryHeaders.purchasedDate,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditSubscription }>
      ) => {
        return <var>{formatDate(params.row.entity.startDate)}</var>;
      },
      valueGetter: (_, row: { entity: CreditSubscription }) =>
        row.entity.startDate,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [CreditPurchasesHistoryHeaders.expirationDate]: {
      field: CreditPurchasesHistoryHeaders.expirationDate,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditSubscription }>
      ) => {
        return <var>{formatDate(params.row.entity.expiresAt)}</var>;
      },
      valueGetter: (_, row: { entity: CreditSubscription }) =>
        row.entity.expiresAt,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [CreditPurchasesHistoryHeaders.status]: {
      field: CreditPurchasesHistoryHeaders.status,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditSubscription }>
      ) => {
        const currentStatus: CreditSubscriptionStatus = getSubscriptionStatus(
          params.row.entity
        );
        const isUsedOrExpired =
          currentStatus === CreditSubscriptionStatus.used ||
          currentStatus === CreditSubscriptionStatus.expired;
        return (
          <FaroChip
            label={getSubscriptionStatus(params.row.entity)}
            size="medium"
            variant="outlined"
            color={isUsedOrExpired ? sphereColors.red600 : sphereColors.blue500}
            backgroundColor={
              isUsedOrExpired ? sphereColors.red50 : sphereColors.blue50
            }
            borderColor={
              isUsedOrExpired ? sphereColors.red100 : sphereColors.blue100
            }
          />
        );
      },
      valueGetter: (_, row: { entity: CreditSubscription }) =>
        getSubscriptionStatus(row.entity),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [CreditPurchasesHistoryHeaders.creditsRemaining]: {
      field: CreditPurchasesHistoryHeaders.creditsRemaining,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: CreditSubscription }>
      ) => {
        const hasExpiredSubHasCredits =
          params.row.entity.availableCredit > 0 &&
          getSubscriptionStatus(params.row.entity) ===
            CreditSubscriptionStatus.expired;
        return (
          <Box
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: hasExpiredSubHasCredits ? sphereColors.red600 : "inherit",
            }}
          >
            {nounPluralize({
              shouldShowCounter: true,
              counter: params.row.entity.availableCredit,
              word: CREDIT_DISPLAY_NAME,
            })}
          </Box>
        );
      },
      valueGetter: (_, row: { entity: CreditSubscription }) =>
        row.entity.availableCredit,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },
  };
}
