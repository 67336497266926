import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

export const MOCK_PROJECT_MEMBERS: SphereDashboardAPITypes.IProjectMemberBase[] =
  [
    {
      identity: "mockIdentity",
      id: "mockId",
      name: "mockName",
      email: "mockEmailr@faro.com",
      role: CoreAPITypes.EUserProjectRole.admin,
    },
  ];
