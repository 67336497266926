import { Box, Stack, Typography } from "@mui/material";
import { DemoTag } from "@src/components/common/demo-tag";
import { EmptyPage } from "@src/components/common/empty-page/empty-page";
import { sphereColors } from "@styles/common-colors";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
// Here we do not need it as a React component because will be used as src for an image
import defaultGroupThumbnail from "/src/assets/group-thumbnail_default.svg";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import GroupsDuoColorIcon from "@assets/icons/groups-duo-color.svg?react";
import { PageContainer } from "@components/common/page-container";
import { ExternalLinks } from "@components/external-links";
import { CreateGroup } from "@components/groups/create-group";
import { userMenuLink, withEllipsisTwoLines } from "@styles/common-styles";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { generateGroupDetailsRoute } from "@router/router-helper";
import { UserMenuEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props {
  /** The ID of the company */
  companyId: string;

  /** The demo group */
  demoGroup: SphereDashboardAPITypes.ICompanyGroup;
}

/** Show this specific empty page when workspace has only the demo group */
export function GroupsDemoEmptyPage({
  companyId,
  demoGroup,
}: Props): JSX.Element {
  const { navigateToGroupDetails } = useAppNavigation();
  const { trackEvent } = useTrackEvent();
  const { canCreateGroups } = useHasUserValidRoleCompanyLevel();

  /** Stores the link to open the details page of the demo group */
  const demoGroupLink = useMemo(() => {
    return (
      <Box
        component={Link}
        to={generateGroupDetailsRoute(companyId, demoGroup.id)}
        sx={{
          ...userMenuLink,
          color: sphereColors.blue500,
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "underline !important",
          },
        }}
        onClick={() => {
          trackEvent({
            name: UserMenuEvents.openGroupDetails,
            props: {
              origin: "Empty groups page",
              type: "demo",
              groupId: demoGroup.id,
            },
          });
        }}
      >
        demo
      </Box>
    );
  }, [companyId, demoGroup, trackEvent]);

  return (
    <PageContainer
      title="Groups Management"
      description={
        <Typography
          sx={{
            color: sphereColors.gray600,
            fontSize: "12px",
          }}
        >
          Create and manage groups in your workspace. Groups need to have at
          least one group manager. <ExternalLinks link={"managingGroupsHelp"} />
        </Typography>
      }
    >
      <Stack
        sx={{
          width: "100%",
          height: "70px",
          mt: "15px",
          py: "10px",
          px: "20px",
          background: sphereColors.gray50,
          "&:hover": {
            background: sphereColors.gray100,
            "& .actionButtons": { visibility: "visible" },
          },
          cursor: "pointer",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() =>
          navigateToGroupDetails({
            companyId,
            groupId: demoGroup.id,
          })
        }
      >
        <Stack direction="row">
          <Box
            component="img"
            src={demoGroup.thumbnailUrl ?? defaultGroupThumbnail}
            sx={{
              maxHeight: "50px",
              maxWidth: "70px",
              objectFit: "contain",
              mx: "20px",
            }}
            alt="Group thumbnail"
          />

          <Box component="div" paddingRight="5px">
            <Typography
              sx={{
                color: sphereColors.gray800,
                fontSize: "12px",
                fontWeight: "bold",
                mb: "7px",
              }}
            >
              Demo
            </Typography>
            <Typography
              sx={{
                color: sphereColors.gray600,
                fontSize: "12px",
                ...withEllipsisTwoLines,
              }}
            >
              Customise and explore our demo group
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <DemoTag sx={{ mr: "20px" }} />
        </Stack>
      </Stack>
      <EmptyPage
        title="You don't have a group yet"
        subtitle={
          canCreateGroups ? (
            <Box component="p">
              Explore the {demoGroupLink} to discover the Sphere XG features.
              Feel free to add your own group.
            </Box>
          ) : (
            <>
              You currently don't have any groups. To get started, explore and
              try out our {demoGroupLink}.
            </>
          )
        }
        icon={GroupsDuoColorIcon}
        button={canCreateGroups && <CreateGroup groups={[demoGroup]} />}
      />
    </PageContainer>
  );
}
