import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  LoadingSpinnerProvider,
  useLoadingSpinner,
} from "@context-providers/loading-spinner-provider";
import { useCallback, useEffect } from "react";
import { getLoggedInUser } from "@store/user/user-slice";
import { useCoreApiClient } from "@api/use-core-api-client";
import { mainTheme } from "@styles/themes";
import { LocalizeLoader } from "@loaders/localize-loader";
import { TrackEventInitializerLoader } from "@loaders/track-event-initializer-loader";
import { FileUploadContextProvider } from "@context-providers/file-upload/file-uploads-context";
import { AccountSettingsLayout } from "@pages/account-settings/account-settings-layout/account-settings-layout";
import { CoreAPIUtils } from "@stellar/api-logic";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { StatusCodes } from "http-status-codes";
import { UserProvider } from "@pages/account-settings/user-provider";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { loggedInUserSelector } from "@store/user/user-selector";
import { CookieManagerLoader } from "@hooks/cookie-manager-loader";

/** Content for the account settings page */
export function AccountSettingsPage(): JSX.Element | undefined {
  const { setLoadingSpinner, isLoadingSpinnerShowing } = useLoadingSpinner();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithPage } = useErrorContext();

  // currentUser cannot be used here. This is because to get the current user, we need to attach a company ID.
  // However, if user directly opens this page, no company information is available and to have one,
  // an additional api request should be done to get the company ids and another one to get the currentUser information.
  // To prevent this, we are using loggedInUser instead of currentUser. If the needed information to show this page
  // and its tabs are not available, we have to fetch it then.
  const loggedInUser = useAppSelector(loggedInUserSelector);

  // Fetches the logged in user through the redux store.
  const getUser = useCallback(async (): Promise<void> => {
    try {
      await dispatch(getLoggedInUser({ coreApiClient })).unwrap();
    } catch (error) {
      // If the error is because the user is not logged-in then do nothing.
      // The auth provider will take care of showing the login UI.
      if (
        CoreAPIUtils.isResponseError(error) &&
        (error.status === StatusCodes.FORBIDDEN ||
          error.status === StatusCodes.UNAUTHORIZED)
      ) {
        return;
      } else {
        handleErrorWithPage({
          id: `getLoggedInUser-${Date.now().toString()}`,
          title:
            "Failed to get the logged in user. Please reload the page to try again.",
          error: getErrorDisplayMarkup(error),
        });
      }
    } finally {
      if (isLoadingSpinnerShowing) {
        setLoadingSpinner(false);
      }
    }
  }, [coreApiClient, dispatch, handleErrorWithPage, isLoadingSpinnerShowing, setLoadingSpinner]);

  useEffect(() => {
    void getUser();
  }, [getUser]);

  if (!loggedInUser) {
    return;
  }

  return (
    <CookieManagerLoader>
      <UserProvider user={loggedInUser}>
        <LoadingSpinnerProvider>
          <TrackEventInitializerLoader>
            <CssBaseline />
            <ThemeProvider theme={mainTheme}>
              <LocalizeLoader>
                <FileUploadContextProvider>
                  <AccountSettingsLayout />
                </FileUploadContextProvider>
              </LocalizeLoader>
            </ThemeProvider>
          </TrackEventInitializerLoader>
        </LoadingSpinnerProvider>
      </UserProvider>
    </CookieManagerLoader>
  );
}
