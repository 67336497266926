import { useAppDispatch } from "@store/store-helper";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useState, useEffect } from "react";
import { alpha, Typography } from "@mui/material";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { FaroButton } from "@faro-lotv/flat-ui";
import { CreditViewMode } from "@custom-types/credit-types";
import { TransactionsInfoBar } from "@pages/settings/subscription-settings/credits/transactions-info-bar";
import { TransactionsTable } from "@components/table/transactions/transactions-table";
import { CreditPurchasesTable } from "@components/table/transactions/credit-purchases-table";
import { fetchCreditTransactions } from "@store/credit-transactions/credit-transactions-thunk";
import { CreditsDialogTopRightActions } from "@pages/settings/subscription-settings/credits/credits-dialog-top-right-actions";
import { sphereColors } from "@styles/common-colors";
import { useAppParams } from "@router/router-helper";
import { useActivityApiClient } from "@api/activity-api/use-activity-api-client";
import { resetCreditTransactionState } from "@store/credit-transactions/credit-transactions-slice";
import { useCoreApiClient } from "@api/use-core-api-client";
import { CREDIT_DISPLAY_NAME } from "@src/constants/credit-constants";
import { capitalizeFirstLetter } from "@utils/string-utils";

interface CreditsDialogProps {
  /** The label for the button that opens the dialog */
  buttonLabel: string;

  /** The initial view mode of the dialog, that is either transactions or purchases */
  initialViewMode: CreditViewMode;
}

/**
 * A dialog to show credit(token) transactions over time or credit(token) purchases history.
 * It contains a toggle to switch between the two views.
 * @param buttonLabel - The label for the button that opens the dialog.
 * @param initialViewMode - The initial view mode of the dialog.
 */
export function CreditsDialog({
  buttonLabel,
  initialViewMode,
}: CreditsDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { companyId } = useAppParams();
  const activityApiClient = useActivityApiClient({ companyId });
  const coreApiClient = useCoreApiClient();
  const { trackEvent } = useTrackEvent();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<CreditViewMode>(initialViewMode);
  const creditDisplayName = capitalizeFirstLetter(CREDIT_DISPLAY_NAME);

  useEffect(() => {
    if (isDialogOpen && viewMode === CreditViewMode.transaction) {
      void dispatch(
        fetchCreditTransactions({ activityApiClient, coreApiClient })
      );
    }
  }, [activityApiClient, coreApiClient, dispatch, isDialogOpen, viewMode]);

  /** Handle button clicks to open the dialog */
  function handleDialogOpen(): void {
    trackEvent({
      name:
        viewMode === CreditViewMode.transaction
          ? WorkspaceEvents.viewCreditsTransactions
          : WorkspaceEvents.viewCreditsPurchaseHistory,
      props: {
        origin: "subscription-settings-page",
      },
    });
    setViewMode(initialViewMode);
    setIsDialogOpen(true);
  }

  function onCreditsDialogClose(): void {
    setIsDialogOpen(false);

    // Clearing credit transaction state while closing credits dialog
    dispatch(resetCreditTransactionState());
  }

  return (
    <>
      <FaroButton
        variant="ghost"
        size="m"
        onClick={handleDialogOpen}
        sx={{
          padding: "8px 16px",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "18px",
          gap: "8px",
          "&:hover": {
            borderRadius: "4px",
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            backgroundColor: alpha(sphereColors.gray500, 0.15),
          },
        }}
      >
        {buttonLabel}
      </FaroButton>

      {isDialogOpen && (
        <FaroDialog
          title={
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "24px" }}
            >
              {viewMode === CreditViewMode.transaction
                ? `${creditDisplayName} transactions over time`
                : `${creditDisplayName} purchase history`}
            </Typography>
          }
          open={isDialogOpen}
          onClose={onCreditsDialogClose}
          shouldHideActions={true}
          fullWidth={true}
          maxWidth="lg"
          topRightActions={
            <CreditsDialogTopRightActions
              currentViewMode={viewMode}
              setViewMode={setViewMode}
            />
          }
          sx={{
            "& .MuiDialog-paper": {
              height: "100%",
              display: "flex",
              flexDirection: "column",
            },
            "& .MuiDialogContent-root": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "auto",
            },
          }}
        >
          <TransactionsInfoBar />
          {viewMode === CreditViewMode.transaction ? (
            <TransactionsTable />
          ) : (
            <CreditPurchasesTable />
          )}
        </FaroDialog>
      )}
    </>
  );
}
