import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { Typography, Stack } from "@mui/material";
import { useAppDispatch } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { useCallback, useState } from "react";
import { fetchRevisionsAndDraftEntities } from "@hooks/data-management/use-data-management";
import { summarizeRevisionChanges } from "@utils/capture-tree/capture-tree-changes";
import { Chip } from "@mui/material";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";

interface Props {
  /** Flag if the publish button is disabled. */
  isPublishDisabled: boolean,
}

export function UnpublishedChangesChip({ isPublishDisabled }: Props): JSX.Element {
  const { projectId } = useDataManagementContext();
  const { createDialog } = useDialog();
  const { handleErrorWithToast } = useErrorContext();
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showChanges = useCallback(async (): Promise<void> => {
    setIsLoading(true);

    /** Handle an error, returning `undefined`, to make the error handling a bit shorter. */
    function handleFetchError(error: unknown): undefined {
      setIsLoading(false);
      handleErrorWithToast({
        id: `onCancelDraft-fetch-${Date.now().toString()}`,
        title: "Failed to fetch the latest state of the project. Please try reloading the page.",
        error,
      });
    }

    const updateBeforeDialog = await fetchRevisionsAndDraftEntities(dispatch, projectApiClient)
      .catch(handleFetchError);
    if (!updateBeforeDialog) {
      // We came here from `handleFetchError`, which returns undefined.
      return;
    }

    const { openDraftEntities } = updateBeforeDialog;
    const changeSummary = summarizeRevisionChanges(openDraftEntities);
    const changes = changeSummary.textJsx.map((change, i) =>
      <li key={i}>{change}</li>
    );

    setIsLoading(false);

    await createDialog(
      {
        title: "Unpublished Changes",
        shouldHideActions: true,
        maxWidth: "sm",
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        fullWidth: true,
      },
      <Typography
        // <ul> is not allowed below <p>.
        component={"div"}
        sx={{
          fontSize: "14px",
        }}
      >
        { changes.length === 0 &&
          <div>No changes have been made yet.</div>
        }
        <ul>
          {changes}
        </ul>
        { changes.length > 0 && !isPublishDisabled &&
          <div style={{ marginTop: "50px" }}>
            Click on the "Publish" button to make all changes visible to other users.
          </div>
        }
      </Typography>
    );
  }, [
    createDialog, dispatch, handleErrorWithToast, projectApiClient, isPublishDisabled,
  ]);

  return (
    <Stack direction="row">
      <Chip
        label="Unpublished Changes"
        title="Click to see the changes that have not been published yet."
        // showChanges() already includes error handling, so we ignore the returned Promise.
        onClick={() => void showChanges()}
        disabled={isLoading}
        sx={{
          color: sphereColors.pureWhite,
          background: sphereColors.gray400,
          height: "25px",
          fontSize: "12px",
        }}
      />
      <FaroButtonSpinner
        shouldHide={!isLoading}
        loadingIndicatorColor={sphereColors.blue600}
        loadingTrackColor={sphereColors.gray100}
      />
    </Stack>
  );
}
