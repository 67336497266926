import { NavLink, NavLinkProps } from "react-router-dom";
import { usePreservedSearch } from "@hooks/use-preserved-search";
import { forwardRef } from "react";

interface PreservingNavLinkProps extends NavLinkProps {
  /** The path to navigate to */
  to: string;
}

/**
 * A custom NavLink component that preserves some query parameters when navigating to a new route.
 * @see PRESERVED_QUERY_PARAMS
 */
export const PreservingNavLink = forwardRef<
  HTMLDivElement,
  PreservingNavLinkProps
>(({ to, ...rest }, ref) => {
  const toWithSearch = usePreservedSearch(to);

  return <NavLink to={toWithSearch} {...rest} />;
});
