import { Box } from "@mui/material";
import { AccountSettingsMobileTabSelection } from "@pages/account-settings/account-settings-layout/mobile-layout/account-settings-mobile-tab-selection";
import { BasicTabProps } from "@components/common/tabs/tabs-type";
import { AccountSettingsMobileTabContents } from "@pages/account-settings/account-settings-layout/mobile-layout/account-settings-mobile-tab-contents";
import { AccountSettingsTabs } from "@router/route-params";

interface Props {
  /** The tabs for account settings */
  tabs: Record<AccountSettingsTabs, BasicTabProps<AccountSettingsTabs>>;

  /** The selected tab */
  selectedTabId: AccountSettingsTabs | undefined;
}

/** Layout for account settings in mobile view */
export function AccountSettingsMobileLayout({
  tabs,
  selectedTabId,
}: Props): JSX.Element {
  return (
    <Box component="div">
      {selectedTabId === undefined ? (
        <AccountSettingsMobileTabSelection tabs={tabs} />
      ) : (
        <AccountSettingsMobileTabContents
          tabs={tabs}
          selectedTabId={selectedTabId}
        />
      )}
    </Box>
  );
}
