import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { CreditViewMode } from "@custom-types/credit-types";
import { CreditDownloadCsvIcon } from "@pages/settings/subscription-settings/credits/credit-download-csv-icon";
import { FaroToggleView } from "@components/common/toggle/faro-toggle-view";

interface Props {
  /** The current view mode of the toggle */
  currentViewMode: CreditViewMode;

  /** Function to set the view mode */
  setViewMode: (newMode: CreditViewMode) => void;
}

/**
 * List of possible view options for the dialog.
 * It is used for the toggle view component.
 */
const VIEW_OPTIONS = [
  { label: "Transactions", value: CreditViewMode.transaction },
  { label: "Purchases", value: CreditViewMode.purchases },
];

/** Renders additional top right actions for the credits dialog */
export function CreditsDialogTopRightActions({
  currentViewMode,
  setViewMode,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();

  const isTransactionsView = currentViewMode === CreditViewMode.transaction;

  /** Handle the view mode change */
  function handleViewModeChange(newMode: CreditViewMode): void {
    trackEvent({
      name:
        newMode === CreditViewMode.transaction
          ? WorkspaceEvents.viewCreditsTransactions
          : WorkspaceEvents.viewCreditsPurchaseHistory,
      props: {
        origin: "credits-dialog",
      },
    });

    setViewMode(newMode);
  }

  return (
    <>
      <FaroToggleView
        options={VIEW_OPTIONS}
        onChange={handleViewModeChange}
        currentViewMode={currentViewMode}
      />
      <SphereActionDivider />
      <CreditDownloadCsvIcon isTransactionsView={isTransactionsView} />
      <SphereActionDivider />
    </>
  );
}
