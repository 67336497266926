import {
  HasFeatureProjectLevelProps,
  HasUserValidFeatureProjectLevelProps,
  RequiredFeatureProjectLevelName,
  RequiredFeaturesProjectLevel,
} from "@utils/feature-control/project/project-feature-control-types";
import { requiredFeaturesProjectLevel } from "@utils/feature-control/project/project-feature-control-helper";

/**
 * Checks whether a feature is enabled and permitted to use by the user within the project level.
 *
 * @returns True if the feature is enabled and permitted within project level.
 */
export function hasUserValidFeatureProjectLevel<
  RoleNameT extends string = RequiredFeatureProjectLevelName
>({
  roleName,
  featuresAvailable,
  defaultRequiredFeaturesProjectLevel = requiredFeaturesProjectLevel as RequiredFeaturesProjectLevel<RoleNameT>,
}: HasUserValidFeatureProjectLevelProps<RoleNameT>): boolean {
  // Checks if the user has the required features roles for the project
  if (
    defaultRequiredFeaturesProjectLevel[roleName].projectSubscriptionRoles &&
    hasFeatureProjectLevel({
      featuresAvailable: featuresAvailable ?? null,
      requiredProjectFeatures:
        defaultRequiredFeaturesProjectLevel[roleName].projectSubscriptionRoles,
    })
  ) {
    return true;
  }

  // If the user didn't match any of the rules above it means it
  // does not have permission.
  return false;
}

/**
 * Checks whether required features are available for the project level in featuresAvailable list.
 * This required "enabled" and "permitted" both property to be true
 * @returns True if the project has required subscription.
 */
function hasFeatureProjectLevel({
  featuresAvailable,
  requiredProjectFeatures,
}: HasFeatureProjectLevelProps): boolean {
  // Early return if there is no available features provided
  if (!featuresAvailable) {
    return false;
  }

  /**
   * Early return if there is no required project features
   * In this case there is no required features
   */
  if (!requiredProjectFeatures.length) {
    return true;
  }

  if (featuresAvailable && featuresAvailable.length > 0) {
    return requiredProjectFeatures.every((requiredProjectFeature) => {
      const feature = featuresAvailable.find(
        (feature) => feature.identifier === requiredProjectFeature
      );
      if (feature && feature.enabled && feature.permitted) {
        return true;
      }
      return false;
    });
  }

  // Returning false if no criteria met
  return false;
}

/**
 * Checks whether a user has permission to use a feature within the project level.
 * This required "permitted" property to be true
 *
 * @returns True if the user has a valid project level permission.
 */
export function isUserPermittedToFeatureProjectLevel<
  RoleNameT extends string = RequiredFeatureProjectLevelName
>({
  roleName,
  featuresAvailable,
  defaultRequiredFeaturesProjectLevel = requiredFeaturesProjectLevel as RequiredFeaturesProjectLevel<RoleNameT>,
}: HasUserValidFeatureProjectLevelProps<RoleNameT>): boolean {
  // Checks if the user has the required features roles for the project
  if (
    defaultRequiredFeaturesProjectLevel[roleName].projectSubscriptionRoles &&
    hasFeaturePermissionProjectLevel({
      featuresAvailable: featuresAvailable ?? null,
      requiredProjectFeatures:
        defaultRequiredFeaturesProjectLevel[roleName].projectSubscriptionRoles,
    })
  ) {
    return true;
  }

  // If the user didn't match any of the rules above it means it
  // does not have permission.
  return false;
}

/**
 * Checks whether required features are permitted to use in the project level.
 * This required "permitted" property to be true
 *
 * @returns True if the project has required subscription permitted.
 */
function hasFeaturePermissionProjectLevel({
  featuresAvailable,
  requiredProjectFeatures,
}: HasFeatureProjectLevelProps): boolean {
  // Early return if there is no available features provided
  if (!featuresAvailable) {
    return false;
  }

  /**
   * Early return if there is no required project features
   * In this case there is no required features
   */
  if (!requiredProjectFeatures.length) {
    return true;
  }

  if (featuresAvailable && featuresAvailable.length > 0) {
    return requiredProjectFeatures.every((requiredProjectFeature) => {
      const feature = featuresAvailable.find(
        (feature) => feature.identifier === requiredProjectFeature
      );
      if (feature && feature.permitted) {
        return true;
      }
      return false;
    });
  }

  // Returning false if no criteria met
  return false;
}
