// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { PropsWithChildren } from "react";
import { CONTENT_MAX_WIDTH, PAGE_X_MARGINS } from "@styles/common-styles";
import { Outlet } from "react-router-dom";
import { MainPageLayoutProps } from "@components/page-layout/main-page-layout";

type Props = PropsWithChildren &
  Pick<MainPageLayoutProps, "contentBackgroundColor">;

/** The layout of the body nof sphere dashboard */
export function BodyLayout({
  contentBackgroundColor,
  children,
}: Props): JSX.Element {
  return (
    <Grid
      container
      justifyContent="center"
      data-testid="CONTENT_WRAPPER"
      sx={{
        width: "100%",
        px: {
          xs: PAGE_X_MARGINS.xs,
          sm: PAGE_X_MARGINS.sm,
          md: PAGE_X_MARGINS.md,
          lg: PAGE_X_MARGINS.lg,
          xl: PAGE_X_MARGINS.xl,
        },
        pb: "30px",
        backgroundColor: contentBackgroundColor,
      }}
    >
      <Grid
        data-testid="CONTENT"
        sx={{
          width: "100%",
          maxWidth: {
            xs: CONTENT_MAX_WIDTH.xs,
            sm: CONTENT_MAX_WIDTH.sm,
            md: CONTENT_MAX_WIDTH.md,
            lg: CONTENT_MAX_WIDTH.lg,
            xl: CONTENT_MAX_WIDTH.xl,
          },
        }}
      >
        {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
        {children ? children : <Outlet />}
      </Grid>
    </Grid>
  );
}
