import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "@store/store-helper";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { FetchingStatus } from "@store/store-types";
import { MockedData } from "@test/mock-data";
import { APITypes } from "@stellar/api-logic";
import {
  FetchCreditTransactionThunkProps,
  PaginatedCreditTransactionResponse,
} from "@store/credit-transactions/credit-transactions-slice-types";
import { fetchProjectsByIds } from "@store/projects/projects-slice-thunk";

/** Fetches credit transactions from backend so they can be put into the store */
export const fetchCreditTransactions = createAsyncThunk<
  PaginatedCreditTransactionResponse,
  FetchCreditTransactionThunkProps,
  { state: RootState; dispatch: AppDispatch }
>(
  "creditTransactions/fetchCreditTransactions",
  async ({ activityApiClient, coreApiClient }, { getState, dispatch }) => {
    const {
      app: { isDevModeEnabled },
      projects: { ids: projectIds },
    } = getState();

    try {
      const response = await activityApiClient.fetchCreditTransaction();

      const projectIdsInStore = new Set(projectIds);

      /** Distinct array of project ids those need to fetch from backend */
      const projectIdsToFetch = new Set<APITypes.ProjectId>();
      response.data.forEach((transaction) => {
        const projectId = transaction.context.projectId;
        if (projectId && !projectIdsInStore.has(projectId)) {
          projectIdsToFetch.add(projectId);
        }
      });

      // Fetch projects that are not available in store
      if (projectIdsToFetch.size > 0) {
        void dispatch(
          fetchProjectsByIds({
            coreApiClient,
            projectIds: [...projectIdsToFetch],
          })
        );
      }

      if (isDevModeEnabled && response.data.length === 0) {
        // Return mocked data if dev mode is enabled and no transaction is available in the response
        return {
          data: MockedData.mockCreditTransactions,
          after: null,
          before: null,
          pageSize: 0,
          totalCount: 0,
        };
      }
      return response;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  },
  {
    condition: (_, api) => {
      const state = api.getState();

      // Avoid to fetch the credit transactions multiple times
      return (
        state.creditTransactions.fetching.status !== FetchingStatus.succeeded
      );
    },
  }
);
