import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCallback } from "react";
import {
  tableTypeSelector,
  updatingItemsSelector,
} from "@store/table/table-selector";
import {
  changeAllFetchingStatus,
  removeOneManually,
  removeOneAfterFetching,
  setBulkActionResults,
  updateOne,
} from "@store/table/table-slice";
import { isApiError } from "@custom-types/type-guards";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { setFetchingUpdatingProjects } from "@store/projects/projects-slice";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props<T> {
  /** The function to call for each project when confirming the bulk action */
  bulkActionCallback: (entity: T) => Promise<unknown>;

  /** Callback function triggers when all bulk action completed successfully */
  onSuccessfulComplete?(): void;

  /** The event name sent for tracking */
  trackingEvent: BulkActionEvents;

  /** The entities that are selected */
  selectedEntities: T[];

  /** A key from entity that is unique like ID and it should be in the type of number or string */
  uniqueIdKey: keyof T;
}

/** Returns a function that loops over the selected entities and apply the provided bulkActionCallback to each entity */
export function useBulkActionCallback<T>({
  bulkActionCallback,
  onSuccessfulComplete,
  trackingEvent,
  selectedEntities,
  uniqueIdKey,
}: Props<T>): { onBulkActionConfirmed(): Promise<void> } {
  const dispatch = useAppDispatch();
  const updatingItems = useAppSelector(updatingItemsSelector);
  const tableType = useAppSelector(tableTypeSelector);
  const { trackAsyncEvent } = useTrackEvent();

  const onBulkActionConfirmed = useCallback(async () => {
    if (!tableType) {
      return;
    }

    await trackAsyncEvent({
      name: trackingEvent,
      props: {
        dataType: tableType,
        numberOfEntities: selectedEntities.length,
      },
    });

    const bulkActionResults: {
      successIds: Array<string | number>;
      errorIds: Array<string | number>;
    } = {
      successIds: [],
      errorIds: [],
    };

    dispatch(
      changeAllFetchingStatus({ status: "in-queue", shouldClearMessage: true })
    );

    for (const entity of selectedEntities) {
      const entityId = entity[uniqueIdKey];
      // The entity ID should be a string or a number otherwise the ID are not accepted as correct type
      if (typeof entityId !== "string" && typeof entityId !== "number") {
        throw new Error(
          "entityId should be a string or a number to accept it as correct type."
        );
      }

      // Remove item if it is not allowed to be changed
      const updatingItem = updatingItems.find((item) => item.id === entityId);
      if (updatingItem?.status === "not-allowed") {
        dispatch(removeOneManually(updatingItem.id));
        continue;
      }

      dispatch(
        updateOne({
          id: entityId,
          changes: {
            status: "updating",
          },
        })
      );

      try {
        await bulkActionCallback(entity);

        dispatch(
          updateOne({
            id: entityId,
            changes: {
              status: "success",
            },
          })
        );

        bulkActionResults.successIds.push(entityId);
      } catch (error) {
        dispatch(
          updateOne({
            id: entityId,
            changes: {
              status: "error",
              message: isApiError(error) ? error.message : "Unknown error",
            },
          })
        );

        bulkActionResults.errorIds.push(entityId);
      }
    }

    dispatch(
      setBulkActionResults({
        numberOfSuccess: bulkActionResults.successIds.length,
        numberOfErrors: bulkActionResults.errorIds.length,
      })
    );

    // After all the fetching is done, make sure that the fetching property is false
    dispatch(setFetchingUpdatingProjects(false));

    bulkActionResults.successIds.forEach((id) => {
      dispatch(removeOneAfterFetching(id));
    });
    bulkActionResults.errorIds.forEach((id) => {
      dispatch(updateOne({ id, changes: { status: "idle" } }));
    });

    // Calling successful callback if there is no errors
    if (onSuccessfulComplete && bulkActionResults.errorIds.length === 0) {
      onSuccessfulComplete();
    }
  }, [
    trackingEvent,
    bulkActionCallback,
    dispatch,
    selectedEntities,
    tableType,
    trackAsyncEvent,
    uniqueIdKey,
    updatingItems,
    onSuccessfulComplete,
  ]);

  return { onBulkActionConfirmed };
}
