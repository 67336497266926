import { BackgroundTaskCategory } from "@custom-types/sdb-background-tasks-types";
import { CaptureApiClient, RegistrationState } from "@faro-lotv/service-wires";
import { RegisteredData } from "@pages/project-details/project-data-management/registered-data/registered-data-types";

export const MOCK_REGISTERED_DATA: RegisteredData[] = [
  {
    id: "4e7d5b09-5eb6-4549-6970-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.registered,
    createdByClient: null,
    reportUri: "someUri",
    createdAt: "2024-07-24T09:13:32.3691879+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T09:17:10.336987+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    task: undefined,
  },
  {
    id: "dea429f6-764f-4e81-668a-08dcaa383283",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.cloudRegistrationStarted,
    createdByClient: null,
    reportUri: null,
    createdAt: "2024-07-23T11:38:34.5510438+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-23T11:38:35.7525587+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    task: {
      id: "24ffb835-f131-4771-b0fd-17c9b15f97a5",
      createdAt: "2024-07-23T11:38:34.551Z",
      elementName: undefined,
      taskType: "CloudRegistration",
      taskCategory: BackgroundTaskCategory.pointCloud,
      state: "Started",
      progress: "50%",
      updatedAt: "2024-07-23T11:38:34.551Z",
      viewerDeepLink: undefined,
      context: {
        companyId: null,
        projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
        userId: "c53a3306-889b-42ae-8c5b-c5c305017ff7",
        correlationId: null,
        jobId: "multi-cloud-reg-17c9b15f97a5",
        elementId: "dea429f6-764f-4e81-668a-08dcaa383283",
      },
      status: {
        id: "b8e9ca30-8729-4a1c-ad5c-2979cc923565",
        type: "Progress",
        changedAt: "2024-07-23T11:38:34.551Z",
        devMessage: null,
        errorCode: null,
        result: {
          downloadUrl: "https://some-url.com/company-2d54y024-cc4a-4830-9450/project-3fa2-4800-b4d1-d0c/export.e57",
        },
        progress: {
          current: 50,
          total: 100,
        },
      },
    },
  },
  {
    id: "34e9c835-3766-4f37-687c-08dcaa3830dc",
    projectId: "d6aa0103-b823-4e9a-a745-5d069f488e0c",
    state: RegistrationState.merged,
    createdByClient: CaptureApiClient.registrationBackend,
    reportUri: "someUri",
    createdAt: "2024-07-22T17:32:29.6363316+00:00",
    createdBy: "8b4caaed-14d2-432b-8ff4-abf53d184364",
    modifiedAt: "2024-07-24T07:01:34.1519557+00:00",
    modifiedBy: "98e4cc79-779b-4f82-a490-6bd72d709b5e",
    task: undefined,
  },
];
