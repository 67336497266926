import { RootState } from "@store/store-helper";
import { AppState } from "@store/app/app-slice";
import { createSelector } from "@reduxjs/toolkit";
import { RuntimeUtils } from "@stellar/web-core";
import { runtimeConfig } from "@src/runtime-config";

/**
 * Returns whether the beta testing is enabled
 */
export const isAnalyticsModuleInitializedSelector: (
  state: RootState
) => AppState["isAnalyticsModuleInitialized"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.app.isAnalyticsModuleInitialized;
  }
);

/**
 * Returns whether the beta testing is enabled
 */
export const isBetaTestingEnabledSelector: (
  state: RootState
) => AppState["isBetaTestingEnabled"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.app.isBetaTestingEnabled;
  }
);

/**
 * Returns whether the alpha testing is enabled
 */
export const isAlphaTestingEnabledSelector: (
  state: RootState
) => AppState["isAlphaTestingEnabled"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.app.isAlphaTestingEnabled;
  }
);

/**
 * Returns whether the beta testing is enabled or the environments is not production.
 */
export const isBetaTestingEnabledOrNotProdSelector: (
  state: RootState
) => AppState["isBetaTestingEnabled"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const isProd = RuntimeUtils.isProductionEnvironment(runtimeConfig.appEnv);
    return state.app.isBetaTestingEnabled || !isProd;
  }
);

/**
 * Returns whether the dev mode is enabled, and the user is a Faro user
 */
export const isDevModeEnabledSelector: (
  state: RootState
) => AppState["isDevModeEnabled"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {    
    return state.app.isDevModeEnabled;
  }
);
