// Use Grid version 2, because it will be the stable version in the newer MUI versions
import Grid from "@mui/material/Unstable_Grid2";
import { Outlet } from "react-router-dom";
import { PropsWithChildren } from "react";
import { getAccountSettingsLayoutStyles } from "@pages/account-settings/account-settings-layout/account-settings-layout-styles";

/**
 * Describes the layout for the account settings page. Includes the header bar,
 * and the "Outlet", in which the router will inject every page content.
 */
export function AccountSettingsBodyLayout({
  children,
}: PropsWithChildren): JSX.Element {
  const { contentWrapper, content } = getAccountSettingsLayoutStyles();

  return (
    <Grid
      container
      justifyContent="center"
      data-testid="CONTENT_WRAPPER"
      sx={{
        width: "100%",
        px: contentWrapper.px,
        pt: contentWrapper.pt,
      }}
    >
      <Grid
        data-testid="CONTENT"
        sx={{
          width: "100%",
          maxWidth: content.maxWidth,
        }}
      >
        {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
        {children ? children : <Outlet />}
      </Grid>
    </Grid>
  );
}
