import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import {
  markupAssigneeComparator,
  markupStatusComparator,
  markupTagsComparator,
  ProjectMarkupsTableHeaders,
} from "@pages/project-details/project-markups/project-markups-table-utils";
import { Markup } from "@custom-types/project-markups-types";
import { MarkupNameAndDetails } from "@pages/project-details/project-markups/markup-name-and-details";
import { MarkupAssignee } from "@pages/project-details/project-markups/markup-assignee";
import { MarkupActions } from "@pages/project-details/project-markups/markup-actions";
import { MarkupDueDate } from "@pages/project-details/project-markups/due-date/markup-due-date";
import { MarkupStatusDropdown } from "@pages/project-details/project-markups/status/markup-status-dropdown";
import { MarkupTags } from "@pages/project-details/project-markups/markup-tags";
import { MarkupAttachments } from "@pages/project-details/project-markups/markup-attachments";
import { getMarkupStatusValue } from "@pages/project-details/project-markups/status/markup-status-utils";
import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/** Return all the possible columns for Project markups table */
export function getProjectMarkupsColumns({
  companyMembers,
  projectMembers,
}: Props): Record<ProjectMarkupsTableHeaders, GridColDef> {
  return {
    [ProjectMarkupsTableHeaders.name]: {
      field: ProjectMarkupsTableHeaders.name,
      minWidth: 200,
      flex: 1.25,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupNameAndDetails markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    [ProjectMarkupsTableHeaders.assignee]: {
      field: ProjectMarkupsTableHeaders.assignee,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupAssignee markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) =>
        row.entity.assignee?.values?.[0],

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        markupAssigneeComparator(cp1, cp2, companyMembers, projectMembers),
    },

    [ProjectMarkupsTableHeaders.dueDate]: {
      field: ProjectMarkupsTableHeaders.dueDate,
      minWidth: 140,
      flex: 0.5,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupDueDate markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) => {
        return row.entity.dueDate?.value
          ? new Date(row.entity.dueDate.value)
          : undefined;
      },

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    [ProjectMarkupsTableHeaders.status]: {
      field: ProjectMarkupsTableHeaders.status,
      minWidth: 140,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupStatusDropdown markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) =>
        getMarkupStatusValue(row.entity.status?.value),

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: markupStatusComparator,
    },

    [ProjectMarkupsTableHeaders.tags]: {
      field: ProjectMarkupsTableHeaders.tags,
      minWidth: 160,
      flex: 0.75,

      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupTags markup={params.row.entity} />;
      },

      valueGetter: (_, row: { entity: Markup }) => {
        if (row.entity.labels?.length) {
          return row.entity.labels.map((label) => label.id).join(", ");
        }

        // If no tags exist, we add null so that we can use isEmpty for filtering
        return "";
      },

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) => markupTagsComparator(cp1, cp2),
    },

    [ProjectMarkupsTableHeaders.attachments]: {
      field: ProjectMarkupsTableHeaders.attachments,
      headerAlign: "left",
      minWidth: 160,
      flex: 0.75,
      type: "number",
      align: "left",
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return <MarkupAttachments markup={params.row.entity} />;
      },
      valueGetter: (_, row: { entity: Markup }) =>
        row.entity.attachmentsCount,

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
    },

    [ProjectMarkupsTableHeaders.options]: {
      field: ProjectMarkupsTableHeaders.options,
      type: "actions",
      align: "right",
      width: 90,
      renderCell: (params: GridRenderCellParams<{ entity: Markup }>) => {
        return (
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <MarkupActions markup={params.row.entity} />
          </Box>
        );
      },
    },
  };
}
