import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroSwitch } from "@components/common/faro-switch";
import { useAppParams } from "@router/router-helper";
import { APITypes } from "@stellar/api-logic";
import {
  getProjectByIdSelector,
  isFeatureEnabledForProjectSelector,
  isSelectedProjectEditableSelector,
} from "@store/projects/projects-selector";
import { editProjectFeature } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useState } from "react";
import {
  FeatureSettingLayout,
  TOOLTIP_TEXT_CHECKED,
  TOOLTIP_TEXT_NOT_CHECKED,
} from "@pages/project-details/project-settings/feature-setting-layout";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { AvailableLinks } from "@components/external-links";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";

/** Renders video mode feature setting */
export function FeatureVideoMode({
  projectId,
}: BaseProjectIdProps): JSX.Element | null {
  const { companyId } = useAppParams();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const selectedProject = useAppSelector(getProjectByIdSelector(projectId));
  const isProjectEditable = useAppSelector(isSelectedProjectEditableSelector());

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Determines if the video mode feature is enabled for the selected project */
  const isVideoModeEnabled = useAppSelector(
    isFeatureEnabledForProjectSelector(
      projectId,
      APITypes.EUserSubscriptionRole.globalVideoMode
    )
  );
  const { canEditProjectSettings } = useHasUserValidPermissionProjectLevel({
    project: selectedProject,
  });

  /** Triggers when feature settings toggle button in clicked */
  async function onChangeFeatureSetting(
    shouldEnableImage2d: boolean
  ): Promise<void> {
    // Track event feature toggling
    trackEvent({
      name: ProjectEvents.adjustFeatureSettings,
      props: {
        featureName: APITypes.EUserSubscriptionRole.globalVideoMode,
        isFeatureEnabled: shouldEnableImage2d,
      },
    });

    if (companyId && selectedProject) {
      setIsLoading(true);

      const payload = {
        [APITypes.EProjectFeatureKey.globalVideoMode]: shouldEnableImage2d,
      };

      try {
        await dispatch(
          editProjectFeature({
            coreApiClient,
            companyId,
            selectedProject,
            payload,
          })
        );
      } catch (error) {
        handleErrorWithToast({
          id: `editVideoModeSetting-${Date.now().toString()}`,
          title: "Error changing video mode setting",
          error,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  const actionComponent = (
    <FaroSwitch
      disabled={!isProjectEditable || !canEditProjectSettings}
      checked={isVideoModeEnabled}
      isLoading={isLoading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onChange={(event, isChecked) => onChangeFeatureSetting(isChecked)}
      tooltipTextNotChecked={TOOLTIP_TEXT_NOT_CHECKED}
      tooltipTextChecked={TOOLTIP_TEXT_CHECKED}
      sx={{ cursor: !isProjectEditable ? "not-allowed" : "pointer" }}
    />
  );

  return (
    <FeatureSettingLayout
      featureName="VideoMode"
      featureDescription="Enables the use of VideoMode for this project."
      learnMoreUrl={AvailableLinks.videoModeHelp}
      actionComponent={actionComponent}
      shouldShowDivider
    />
  );
}
