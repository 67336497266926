import { FaroTextButton } from "@components/common/faro-text-button";
import { TableItem } from "@pages/project-details/project-data-management/data-management-types";

interface Props {
  /** The selected table items, which may include ones currently not eligible for removal. */
  selectedItems: TableItem[];
  /** Callback to set the scans the user selected for removal. */
  setScansToRemove: React.Dispatch<React.SetStateAction<TableItem[]>>;
  /** Callback to set whether the confirmation dialog for removing scans is open. */
  setIsRemoveScansDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/** Button to remove scans in the Staging Area in bulk. */
export function RemoveScansButton({
  selectedItems,
  setScansToRemove,
  setIsRemoveScansDialogOpen,
}: Props): JSX.Element {
  function onClick(): void {
    setScansToRemove(selectedItems);
    setIsRemoveScansDialogOpen(true);
  }

  return (
    <FaroTextButton
      onClick={onClick}
      tooltipText="Remove scans from project"
      sx={{ fontWeight: 600 }}
    >
      Remove Scans
    </FaroTextButton>
  );
}
