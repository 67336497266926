import { useCallback } from "react";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import {
  ElsScanFileUploadTaskContext,
  MultiUploadedFileResponse,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { useAddScansToRevisionAndMerge } from "@hooks/data-management/use-add-scans-to-revision-and-merge";
import { useHandleAllUploadsCanceled } from "@hooks/data-management/use-handle-all-uploads-canceled";
import { useHandleAllUploadsFailed } from "@hooks/data-management/use-handle-all-uploads-failed";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { COOKIES_ERROR_MESSAGE, CoreFileUploader } from "@utils/core-file-uploader";
import { useAppDispatch } from "@store/store-helper";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { fetchCaptureTreeData } from "@hooks/data-management/use-data-management";
import { delay } from "@utils/time-utils";
import { assert } from "@faro-lotv/foundation";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { EventProps } from "@faro-lotv/foreign-observers";

type ReturnFunction = (
  uploadedResponse: MultiUploadedFileResponse,
  context: ElsScanFileUploadTaskContext
) => Promise<void>;

/** Callback after all files were uploaded or canceled. */
export function useOnUploadComplete(project: SdbProject): ReturnFunction {
  const { trackEvent } = useTrackEvent();
  const handleAllUploadsFailed = useHandleAllUploadsFailed();
  const handleAllUploadsCanceled = useHandleAllUploadsCanceled();
  const addScansToRevisionAndMerge = useAddScansToRevisionAndMerge();
  const projectApiClient = useProjectApiClient({ projectId: project.id });
  const { uploadManager } = useFileUploadContext();
  const progressApiClient = useProgressApiClient({ projectId: project.id });
  const dispatch = useAppDispatch();

  return useCallback(
    async (
      uploadedResponse: MultiUploadedFileResponse,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      // The previous code used context.projectId for creating the ProjectAPI client.
      assert(context.projectId === project.id, "Project ID mismatch");

      const successfulUploads = uploadedResponse.successful;
      const failedUploads = uploadedResponse.failed;
      const canceledUploads = uploadedResponse.canceled;
      const uploadSizeMB = uploadedResponse.uploadSizeMB;
      const uploadTimeSecs = uploadedResponse.uploadTimeSecs;

      const propsToSend: EventProps = {
        successfulUploads: successfulUploads.length,
        failedUploads: failedUploads.length,
        canceledUploads: canceledUploads.length,
        uploadSizeMB,
        uploadTimeSecs,
        // When finishing the upload, the Promise is already resolved.
        uploadEndpoint: await CoreFileUploader.getUploadEndpoint().catch(() => ""),
        isSharedWorker: uploadManager.isSharedWorker(),
      };

      // For tiny uploads, the speed will be inaccurate, and therefore we better don't report it.
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (uploadSizeMB >= 20 && uploadedResponse.uploadSpeedMBps && uploadedResponse.uploadSpeedBucketMBps) {
        propsToSend.uploadSpeedMBps = uploadedResponse.uploadSpeedMBps;
        propsToSend.uploadSpeedBucketMBps = uploadedResponse.uploadSpeedBucketMBps;
      }

      const areCookiesToBlame = failedUploads.some((failedUpload) =>
        failedUpload.errorMessage === COOKIES_ERROR_MESSAGE
      );
      if (failedUploads.length > 0) {
        propsToSend.areCookiesToBlame = areCookiesToBlame;
        propsToSend.errorMessage = failedUploads[0].errorMessage;
      }

      trackEvent({
        name: DataManagementEvents.finishUpload,
        props: propsToSend,
      });

      // Handle case when there are zero successful uploads
      if (!successfulUploads.length) {
        // If there are failed uploads consider it an all-failed case
        if (failedUploads.length) {
          return handleAllUploadsFailed(
            projectApiClient, context.captureTreeRevisionId, areCookiesToBlame, failedUploads[0].errorMessage
          );
        }

        // If there are zero failed uploads and some canceled uploads consider it an all-canceled case
        if (!failedUploads.length && canceledUploads.length) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.captureTreeRevisionId
          );
        }
      }

      // Handle case when there were successful uploads.
      // The function will also check again if the revision was already canceled.
      if (successfulUploads.length) {
        const canceledImport = !(await addScansToRevisionAndMerge(
          successfulUploads,
          failedUploads,
          projectApiClient,
          context
        ));
        if (canceledImport) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.captureTreeRevisionId,
            true
          );
        }

        // Fetch the various objects to make the UI transition to the "processing" state earlier,
        // and not only when the next usePolling() happens.
        // Unfortunatelly, it seems that background tasks are not created immediately so we won't fetch
        // them if we don't wait a bit. 1-3s wasn't always enough so we wait 5s to be on the safe side.
        // We could add smarter polling but it's not worth the effort.
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 5 seconds
        await delay(5000);
        await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient)
          // Not important. The UI will update a bit later.
          .catch(() => undefined);
      }
    },
    [
      addScansToRevisionAndMerge,
      dispatch,
      handleAllUploadsCanceled,
      handleAllUploadsFailed,
      progressApiClient,
      project.id,
      projectApiClient,
      trackEvent,
      uploadManager,
    ]
  );
}
