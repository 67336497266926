import { getBreakpointName } from "@utils/ui-utils";
import { CSSProperties } from "react";

interface GetAccountSettingsStyleReturnProps {
  /** Style properties for the content wrapper of the body layout */
  contentWrapper: {
    /** The horizontal padding of the content wrapper */
    px: CSSProperties["paddingLeft"];
    /** The top padding of the content wrapper */
    pt: CSSProperties["paddingTop"];
  };

  /** Style properties for the content of the body layout */
  content: {
    /** The max width of the content */
    maxWidth: CSSProperties["maxWidth"];
  };
}

/** Styles for account settings layout that is changing based on breakpoints */
export function getAccountSettingsLayoutStyles(): GetAccountSettingsStyleReturnProps {
  const breakPointName = getBreakpointName(window.innerWidth);
  switch (breakPointName) {
    // Page xxxl Breakpoints: 2560px
    // Page xxl Breakpoints: 1920px
    case "xxxl":
    case "xxl":
      return {
        contentWrapper: {
          px: "120px",
          pt: "80px",
        },
        content: {
          maxWidth: "1680px",
        },
      };

    // Page Breakpoints: 1536px
    case "xl":
      return {
        contentWrapper: {
          px: "100px",
          pt: "60px",
        },
        content: {
          maxWidth: "1330px",
        },
      };

    // Page Breakpoints: 1200px
    case "lg":
      return {
        contentWrapper: {
          px: "50px",
          pt: "60px",
        },
        content: {
          maxWidth: "1100px",
        },
      };

    // Page Breakpoints: 900px
    case "md":
      return {
        contentWrapper: {
          px: "20px",
          pt: "60px",
        },
        content: {
          maxWidth: "860px",
        },
      };

    // Page Breakpoints: 600px
    // This is mobile view
    case "sm":
      return {
        contentWrapper: {
          px: "15px",
          pt: "60px",
        },
        content: {
          maxWidth: "568px",
        },
      };

    // Page Breakpoints: 0px
    case "xs":
      return {
        contentWrapper: {
          px: "0px",
          pt: "60px",
        },
        content: {
          maxWidth: "0px",
        },
      };
  }
}
