import { AppRoutes } from "@router/router-helper";
import { useMatch } from "react-router-dom";

/** Identifier for the route match */
export type RouteIdentifier =
  | "projects"
  | "projectDetails"
  | "members"
  | "groups"
  | "workspaces"
  | "teams"
  | "account"
  | "unknown";

/**
 * A hook that matches the current route (pathname) with the provided paths
 * and returns the identifier of the matched route.
 */
export function useRouteMatch(): RouteIdentifier {
  const matchProjectsRoute = useMatch(AppRoutes.ProjectsRoute);
  const matchProjectDetailsRoute = useMatch(AppRoutes.ProjectDetailsRoute);
  const matchMembersRoute = useMatch(AppRoutes.MembersRoute);
  const matchGroupsRoute = useMatch(AppRoutes.GroupsRoute);
  const matchWorkspacesRoute = useMatch(AppRoutes.WorkspaceSelectionRoute);
  const matchTeamsRoute = useMatch(AppRoutes.TeamRoute);
  const matchAccountRoute = useMatch(AppRoutes.AccountSettingsRoute);

  if (matchProjectsRoute) {
    return "projects";
  } else if (matchProjectDetailsRoute) {
    return "projectDetails";
  } else if (matchMembersRoute) {
    return "members";
  } else if (matchGroupsRoute) {
    return "groups";
  } else if (matchWorkspacesRoute) {
    return "workspaces";
  } else if (matchTeamsRoute) {
    return "teams";
  } else if (matchAccountRoute) {
    return "account";
  } else {
    return "unknown";
  }
}
