import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import {
  Breakpoint,
  IconButtonProps,
  SxProps,
  StackProps,
} from "@mui/material";
import { colorConst, sphereColors } from "@styles/common-colors";
import { CSSProperties } from "react";

/**
 * Type for more advanced CSS selectors,
 * for example "{ path:not([fill='none'])": { fill: "FFFFFF" } }
 */
type CssSelectors = {
  [selector: string]: Record<string, string>;
};

/** Default font size for titles */
export const DEFAULT_TITLE_FONT_SIZE = "16px";

/** Default font size for input elements like text fields and selects */
export const DEFAULT_INPUT_FONT_SIZE = "14px";

/** Default font size for normal text */
export const DEFAULT_TEXT_FONT_SIZE = "12px";

/** Default font weight for normal text */
export const DEFAULT_TEXT_FONT_WEIGHT = 400;

/** Default font weight for bold text */
export const DEFAULT_BOLD_TEXT_FONT_WEIGHT = 600;

/** Opacity in a 0-1 range to show when a element is disabled. */
export const DISABLED_OPACITY = 0.5;

/**
 * This breakpoint does not exist in MUI but we will use it as a new size,
 * since it is the most common size for our customers.
 */
export const XXL_BREAKPOINT = 1920;

/**
 * This breakpoint does not exist in MUI but we will use it as a new size,
 * since it is a size our customers use.
 */
export const XXXL_BREAKPOINT = 2560;

export const CommonStyles = {
  Borders: {
    gray200Divider: `1px solid ${sphereColors.gray200}`,
  },
};

/** Font family to be used to display content normal or bold */
export const DEFAULT_FONT_FAMILY: CSSProperties["fontFamily"] =
  // eslint-disable-next-line quotes -- Needed format with single quotes for "prettier"
  '"Open Sans", sans-serif';

/** Font family to be used to display content in italics. Marked as important to replace the default one */
export const DEFAULT_FONT_FAMILY_ITALIC: CSSProperties["fontFamily"] =
  "OpenSans-Italic !important";

/**
 * Defines the custom breakpoints for the dashboard.
 */
export const PAGE_BREAKPOINTS: { [key in Breakpoint]: number } = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

/** Extended breakpoints to include the new sizes */
export type BreakpointExtended = Breakpoint | "xxl" | "xxxl";

/**
 * Defines the custom breakpoints for the dashboard, including the new sizes.
 */
export const PAGE_BREAKPOINTS_EXTENDED: {
  [key in BreakpointExtended]: number;
} = {
  ...PAGE_BREAKPOINTS,
  xxl: XXL_BREAKPOINT,
  xxxl: XXXL_BREAKPOINT,
};

/**
 * Defines the margins in the X axis for the page for all page sizes.
 */
export const PAGE_X_MARGINS: { [key in Breakpoint]: string } = {
  xs: "20px",
  sm: "20px",
  md: "24px",
  lg: "24px",
  xl: "24px",
};

/**
 * Defines the maximum width of the content for all page sizes.
 * This applies only to the content below the app bar.
 */
export const CONTENT_MAX_WIDTH: { [key in Breakpoint]: string } = {
  xs: "500px",
  sm: "620px",
  md: "790px",
  lg: "1060px",
  xl: "1436px",
};

/** Custom styling for errors in stack components */
export const STACK_ERROR_SX: StackProps["sx"] = {
  marginBottom: SPACE_ELEMENTS_OF_MODAL,
  "& .Mui-error": {
    marginLeft: "2px",
  },
};

/**
 * Needed Css properties to wrap text and show an
 * ellipsis when overflowing text.
 */
export const withEllipsis: CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

/**
 * Needed Css properties to wrap text and show an
 * ellipsis when overflowing text.
 * It serves as base to break in multiple lines.
 * Use WebkitLineClamp to define the maximum lines ot be shown.
 */
const withEllipsisLines: CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  // !: Do not use ...withEllipsis
  // because we don't need whiteSpace: "nowrap" in order to
  // break the line in two rows.
  wordBreak: "break-word",
  lineHeight: "20px",
  // These properties allow to show an ellipsis on two lines
  // Unfortunately it is only allowed in webkit because there's
  // no native support from CSS for this, only for one line.
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
};

/**
 * Needed Css properties to wrap text and show an
 * ellipsis when overflowing text.
 * It breaks the lines and shows a maximum of the provided lines
 */
export function getEllipsisLines({ lines }: { lines: string }): CSSProperties {
  return {
    ...withEllipsisLines,
    WebkitLineClamp: lines,
  };
}

/** Css properties to show an ellipsis on two lines */
export const withEllipsisTwoLines = getEllipsisLines({ lines: "2" });

/** Css properties to show an ellipsis on three line */
export const withEllipsisThreeLines = getEllipsisLines({ lines: "3" });

/**
 * Needed Css properties for all links in the
 * user menu. They don't have an underline
 * on hover or active, and are colored to blue on hover.
 */
export const userMenuLink: SxProps = {
  textDecoration: "none",
  color: sphereColors.gray800,
  "&:hover": {
    color: sphereColors.blue500,
    cursor: "pointer",
  },
  "&::active": {
    color: sphereColors.gray800,
  },
  "&:hover,:active": {
    textDecoration: "none",
  },
};

export function getSvgColoredIconCss(color: string): CssSelectors {
  /* eslint-disable @typescript-eslint/naming-convention -- The namings are CSS selectors */
  return {
    "path:not([fill='none'])": { fill: color },
    "rect:not([fill='none'])": { fill: color },
    "circle:not([fill='none'])": { fill: color },
    "ellipse:not([fill='none'])": { fill: color },
    "line:not([fill='none'])": { fill: color },
    "polyline:not([fill='none'])": { fill: color },
    "polygon:not([fill='none'])": { fill: color },
  };
  /* eslint-enable */
}

/**
 * Sx properties to color to blue an svg icon on hover.
 */
export const colorOnHoverIcon: IconButtonProps["sx"] = {
  "&:hover": {
    ...getSvgColoredIconCss(sphereColors.blue500),
  },
};

/** Sx style for placeholders */
export const placeholderItalicSx: CSSProperties = {
  fontSize: "14px",
  fontFamily: DEFAULT_FONT_FAMILY_ITALIC,
  color: colorConst.placeholder,
  opacity: 1,
};

/** Sx style to have an italic placeholder in any input element  */
export const inputPlaceholderItalicSx: SxProps = {
  "& .MuiInputBase-input::placeholder": {
    ...placeholderItalicSx,
  },
};

/** Sx style to have the correct error color for a text field border */
const textfieldFieldsetSx: SxProps = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-error fieldset": {
      borderColor: sphereColors.red600,
    },
  },
};

/** Sx style to have the correct error color for a text field helper text */
const textfieldHelperTextSx: SxProps = {
  "& .MuiFormHelperText-root.Mui-error": {
    marginLeft: "0px",
    color: sphereColors.red600,
  },
};

/** Style override for text fields */
export const textfieldSx: SxProps = {
  ...inputPlaceholderItalicSx,
  ...textfieldFieldsetSx,
  ...textfieldHelperTextSx,
};

/** Default CSS style to apply when the button is disabled and it is hovered. */
export const hoverDisabledStyle: CSSProperties = {
  backgroundColor: "none",
  cursor: "default",
};

/** Default CSS style to apply when the button is disabled and it is clicked. */
export const clickDisabledStyle: CSSProperties = {
  backgroundColor: "none",
  cursor: "default",
};

/** Default gray border underneath the tabs */
export const DEFAULT_TAB_UNDERLINE: CSSProperties["boxShadow"] = `0 1px 0 0 ${sphereColors.gray200}`;

/** Do not show a box shadow */
export const TAB_WITH_NO_UNDERLINE: CSSProperties["boxShadow"] = "none";
