import {
  ErrorWithTitle,
  UiErrorType,
} from "@context-providers/error-boundary/error-types";
import {
  EntityAdapter,
  EntityId,
  createEntityAdapter,
  createSlice,
  isRejected,
} from "@reduxjs/toolkit";
import {
  fetchGroupDetails,
  fetchGroups,
  removeMemberFromGroup,
  updateGroupDetails,
  updateMemberRoleInGroup,
} from "@store/groups/groups-slice";
import {
  fetchCompanyMembers,
  fetchCompanyMemberDetails,
  removeMemberFromCompany,
  updateMemberRoleInWorkspace,
} from "@store/members/members-slice-thunk";
import {
  fetchProjects,
  fetchProjectDetails,
  removeMemberFromProject,
  updateProjectDetails,
  deleteProject,
  getProjectFeatures,
  fetchProjectSettings,
  updateMemberRoleInProjectSingle,
  updateProjectSettings,
} from "@store/projects/projects-slice-thunk";
import {
  fetchCompaniesAndWorkspaces,
  fetchCompanyBrandingSettings,
  fetchCompanyCommunicationSettings,
  fetchCompanyFeatures,
  fetchCompanySubscriptions,
  updateCompanyBrandingSettings,
  updateCompanyCommunicationSettings,
} from "@store/sdb-company/sdb-company-slice";
import { BaseEntityState } from "@store/store-types";
import {
  fetchCurrentUser,
  logoutUser,
  setFueFlags,
  getLoggedInUser,
} from "@store/user/user-slice";
import {
  compareErrorImportance,
  createErrorState,
} from "@store/errors/errors-slice-helper";
import {
  fetchProjectSnapshots,
  fetchSnapshotMembers,
  deleteProjectSnapshot,
  inviteMembersToSnapshot,
  removeMemberFromSnapshot,
} from "@store/snapshots/snapshots-slice";
import {
  fetchCompanyStats,
  fetchProjectStats,
} from "@store/analytics/analytics-slice";
import { fetchProjectIElements } from "@faro-lotv/project-source";
import { fetchCompanyMemberGroups } from "@store/company-member-groups/company-member-groups-thunk";
import {
  createTeam,
  deleteTeam,
  fetchTeamAsMember,
  fetchTeamDetails,
  fetchTeamMembers,
  fetchTeams,
  removeMembersFromTeam,
  updateTeamDetails,
} from "@store/teams/teams-slice-thunk";
import { fetchCreditTransactions } from "@store/credit-transactions/credit-transactions-thunk";

export interface BaseErrorState extends ErrorWithTitle {
  /** The UI to show the error to the user */
  uiError: UiErrorType;
}

/** Creates an entity adapter to store a map with all the errors. */
export const errorsAdapter: EntityAdapter<BaseErrorState, EntityId> =
  createEntityAdapter({
    sortComparer: (errorA, errorB) => compareErrorImportance(errorA, errorB),
    selectId: (error): EntityId => error.id,
  });

const initialState: BaseEntityState<BaseErrorState> = {
  ...errorsAdapter.getInitialState(),
};

/**
 * Slice to access state of errors
 */
const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    /**
     * Accepts a single error entity and adds or replaces it.
     *
     * @param state store state
     * @param entity Project to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setOne: errorsAdapter.setOne,
    /**
     * Accepts an array of error entities, and adds or replaces them.
     *
     * @param state store state
     * @param entity Projects to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setMany: errorsAdapter.setMany,
    /**
     * Removes all error entities from the store.
     *
     * @param state store state
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeAll: errorsAdapter.removeAll,
    /**
     * Accepts an array of error IDs, and removes each error entity with those IDs if they exist.
     *
     * @param state store state
     * @param entity Project Ids to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeMany: errorsAdapter.removeMany,
    /**
     * Accepts a single error IDs, and removes the error entity with that ID if it exists.
     *
     * @param state store state
     * @param entity Project Id to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeOne: errorsAdapter.removeOne,
    resetErrorsState: () => initialState,
  },

  extraReducers(builder) {
    builder.addMatcher(
      isRejected(
        updateProjectDetails,
        fetchProjectDetails,
        fetchProjectSettings,
        updateProjectSettings,
        fetchProjects,
        getProjectFeatures,
        removeMemberFromProject,
        fetchCompanyFeatures,
        fetchCompaniesAndWorkspaces,
        removeMemberFromCompany,
        updateMemberRoleInWorkspace,
        fetchCompanyMembers,
        fetchCompanyMemberDetails,
        fetchCurrentUser,
        setFueFlags,
        logoutUser,
        getLoggedInUser,
        fetchGroups,
        fetchGroupDetails,
        removeMemberFromGroup,
        updateMemberRoleInGroup,
        updateGroupDetails,
        fetchProjectSnapshots,
        deleteProject,
        fetchSnapshotMembers,
        deleteProjectSnapshot,
        inviteMembersToSnapshot,
        removeMemberFromSnapshot,
        fetchCompanyStats,
        fetchProjectStats,
        fetchCompanyBrandingSettings,
        updateCompanyBrandingSettings,
        fetchProjectIElements,
        fetchCompanyCommunicationSettings,
        updateCompanyCommunicationSettings,
        fetchCompanyMemberGroups,
        updateMemberRoleInProjectSingle,
        createTeam,
        fetchTeamDetails,
        fetchTeamAsMember,
        updateTeamDetails,
        fetchTeamMembers,
        removeMembersFromTeam,
        deleteTeam,
        fetchTeams,
        fetchCreditTransactions,
        fetchCompanySubscriptions
      ),
      (state, action) => {
        errorsAdapter.setOne(state, createErrorState(action));
      }
    );
  },
});

export const {
  setOne,
  setMany,
  removeAll,
  removeMany,
  removeOne,
  resetErrorsState,
} = errorsSlice.actions;

export const errorsReducer = errorsSlice.reducer;
