import { runtimeConfig } from "@src/runtime-config";
// eslint-disable-next-line no-restricted-imports -- The only place needed to initialize Analytics
import { Analytics as AnalyticsUtils, RuntimeUtils } from "@stellar/web-core";
import { selectedSdbCompanyIdSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { getBreakpointName } from "@utils/ui-utils";
import { PropsWithChildren, useEffect } from "react";
import { Analytics } from "@faro-lotv/foreign-observers";
import { serverRegion } from "@utils/env-utils";
import { setIsAnalyticsModuleInitialized } from "@store/app/app-slice";
import  { useIsCookieAccepted, CookieId } from "@faro-lotv/foreign-observers";

/**
 * Initializes Analytics module for tracking. Can be used like a context provider and should be used
 * where user and company are already available (i.e. inside the SdbCompanyLoader).
 * Currently we are using Amplitude analytics and this component initialize it.
 */
export function TrackEventInitializerLoader({
  children,
}: PropsWithChildren): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const selectedSdbCompanyId = useAppSelector(selectedSdbCompanyIdSelector);
  const isCookieAccepted = useIsCookieAccepted();

  const dispatch = useAppDispatch();

  // Initialize amplitude when user and company is defined
  useEffect(() => {
    async function initAmplitude(): Promise<void> {
      if (!currentUser || !selectedSdbCompanyId) {
        return;
      }

      if (
        RuntimeUtils.isProductionEnvironment(runtimeConfig.appEnv) &&
        AnalyticsUtils.shouldUserBeExcludedFromTracking(currentUser.email)
      ) {
        return;
      }

      await Analytics.init(runtimeConfig.features.amplitudeKey, {
        userId:
          // For EU assume no consent is given
          serverRegion !== "eu" &&
          isCookieAccepted(CookieId.amplitude)
            ? currentUser.id
            : undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Amplitude variable
        initConfigs: { defaultTracking: true },
      });

      Analytics.props = {
        defaultProps: {
          companyId: selectedSdbCompanyId,
          appVersion: runtimeConfig.appVersion,
          // For events we only care which breakpoint the user had when the event was triggered
          // For the exact screen size and orientation we track only on page load
          windowWidthBreakpoint: getBreakpointName(window.innerWidth),
          environment: runtimeConfig.environment,
          region: runtimeConfig.appRegion,
        },
      };

      dispatch(setIsAnalyticsModuleInitialized(true));
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    initAmplitude();
  }, [currentUser, dispatch, selectedSdbCompanyId, isCookieAccepted]);
  // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
  return <>{children}</>;
}
