import { CoreAPITypes } from "@stellar/api-logic";
import { getDisabledRoles } from "@utils/workspace-role-utils";
import { RoleAutocomplete } from "@components/role/role-autocomplete/role-autocomplete";
import { RoleAutocompleteProps } from "@components/role/select-role-utils";


/** Renders a dropdown to select company role */
export function CompanyRoleAutoComplete< T extends CoreAPITypes.EUserCompanyRole
>({
  selectedRole,
  onChange,
  onResetSelection,
  isRequired = false,
  placeholder = "Select a role...",
  shouldAllowAllRolesSelection = false,
}: RoleAutocompleteProps<T>): JSX.Element {

  const disabledRoles: CoreAPITypes.EUserCompanyRole[] =
  shouldAllowAllRolesSelection || !selectedRole
   ? []
   : getDisabledRoles(selectedRole);

  return (
    <RoleAutocomplete
      labelTitle="Workspace role"
      isRequired={isRequired}
      selectedRole={selectedRole}
      onChange={onChange}
      onResetSelection={onResetSelection}
      placeholder={placeholder}
      roles={Object.values(CoreAPITypes.EUserCompanyRole) as T[]}
      disabledRoles={disabledRoles as T[]}
      link="workspaceRolesHelp"
      dataTestId="workspace-role-selector"
    />
  );
}
