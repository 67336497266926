import { assert } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import {
  FileUploadParams,
  RemoveTaskFn,
  SharedWorkerRequestMessage,
  SharedWorkerResponseMessage,
  isSharedWorkerResponseMessage,
  StartUploadFn,
  UpdateTaskFn,
  UploadManagerInterface,
  FileUploadCallbacks,
  MINIMUM_CHUNK_SIZE,
  UpdateTaskProps,
  FailedResponseArg,
  ProgressResponseArg,
  CanceledResponseArg,
} from "@custom-types/upload-manager-types";
import { TASK_UPDATE_DEBOUNCE_DELAY } from "@context-providers/file-upload/upload-manager";
import { Required } from "@faro-lotv/foundation";
import { CoreFileUploader, UploadEndpoint } from "@utils/core-file-uploader";
import { UploadedFile } from "@custom-types/file-upload-types";

/**
 * Track if we had already connected an instance, to add appropriate logging.
 * When developing in StrictMode, two instances of the UploadManager(Worker) are created.
 * That's not an issue, but could be confusing.
 */
let hasWorkerConnected = false;

/**
 * This Mananger manages the connection to the upload worker, that actually handles the uploads.
 *
 * WARNING: this class should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUpload and useCancelUpload.
 */
export class UploadManagerWorker implements UploadManagerInterface {
  // Map to store the callbacks for each upload
  #callbacks = new Map<GUID, FileUploadCallbacks>();

  // SharedWorker instance to handle the uploads
  #worker: SharedWorker;

  // Tab ID to send heartbeat to the worker
  #tabId: string | undefined = undefined;

  // Map to store the updates for each upload
  #pendingTaskUpdates = new Map<GUID, Required<UpdateTaskProps, "progress">>();

  // True if the concurrency is manually set (Dev Mode).
  #isManualConcurrency = false;

  /**
   * @param startTaskInStore Function to start a background task in the store
   * @param updateTaskInStore Function to update the task in the store corresponding to the upload
   * @param removeTaskFromStore Function to remove a task from the store.
   */
  constructor(
    public startTaskInStore: StartUploadFn,
    public updateTaskInStore: UpdateTaskFn,
    public removeTaskFromStore: RemoveTaskFn
  ) {
    // Throws an error if SharedWorker is not supported by the browser or disallowed for some reason.
    // Create the SharedWorker in the beginning, to get an early-out if it's not supported.
    this.#worker = new SharedWorker(new URL("upload-worker", import.meta.url), { name: "XG-Upload-Worker", type: "module" });

    // Each tab only gets feedback for its own uploads, see upload-worker > onconnect().
    this.#worker.port.onmessage = (event: MessageEvent) => {
      const message: SharedWorkerResponseMessage = event.data;

      if (!isSharedWorkerResponseMessage(message)) {
        // eslint-disable-next-line no-console -- Should be visible for devs at least.
        console.warn("Invalid SharedWorkerResponseMessage received from UploadWorker:", event.data);
        return;
      }
      switch (message.responseType) {
        case "onConnectTab": {
          // Tab connected ... save the tabId for heartbeat
          this.#tabId = message.tabId;
          const extraLogs = hasWorkerConnected ? ["(connecting twice is expected for StrictMode)"] : [];
          // eslint-disable-next-line no-console -- Helpful for devs to see what's going on.
          console.log("Connected to UploadWorker with tabId", this.#tabId, ...extraLogs);
          hasWorkerConnected = true;
          break;
        }
        case "onProgress": {
          // Send heartbeat to the SharedWorker, so it knows that this tab is still active.
          this.#sendMessageToWorker({ requestType: "heartbeat", tabId: this.#tabId ?? "no-tab-id" });
          this.uploadUpdated(message.arg);
          break;
        }
        case "onComplete": {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- No need to await, error is handled internally.
          this.uploadCompleted(message.arg);
          break;
        }
        case "onCanceled": {
          this.onUploadCanceled(message.arg);
          break;
        }
        case "onError": {
          this.uploadFailed(message.arg);
          break;
        }
        case "onSetUploadEndpoint": {
          // Set the upload endpoint, so we can get it when tracking DataManagementEvents.finishUpload.
          // Otherwise the main window would make another Azure API call to determine it itself.
          const uploadEndpoint = message.uploadEndpoint;
          assert(uploadEndpoint === "frontdoor" || uploadEndpoint === "storage");
          CoreFileUploader.setUploadEndpoint(uploadEndpoint);
          break;
        }
        default: {
          message satisfies never;
        }
      }
    };

    // eslint-disable-next-line no-console -- It's not obvious for developers where the upload requests can be found.
    console.log(
      "UploadManagerWorker: SharedWorker initialized.\n" +
      "Debugging: Chrome: chrome://inspect/#workers | Firefox: about:debugging#/runtime/this-firefox"
    );
  }

  /**
   * Sends a message to the shared worker
   *
   * @param message The message to send to the worker
   */
  #sendMessageToWorker(message: SharedWorkerRequestMessage): void {
    this.#worker.port.postMessage(message);
  }

  /**
   * Used to remove all callbacks for a given upload
   *
   * @param id ID of the upload to remove
   */
  #removeCallbacks(id: GUID): void {
    this.#callbacks.delete(id);
    // Make sure to not apply any obsolete updates to the store.
    this.#pendingTaskUpdates.delete(id);
  }

  /**
   * Callback function triggered when an upload is completed
   * Marks upload task as succeeded
   * Calls the finalizer if it exists
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that completed
   * @param arg.downloadUrl URL containing the uploaded file at the remote location.
   */
  private async uploadCompleted(arg: UploadedFile): Promise<void> {
    const callbacks = this.#callbacks.get(arg.id);
    try {
      await callbacks?.finalizer?.(arg);
      this.updateTaskInStore(arg.id, { status: BackgroundTaskState.succeeded });
      callbacks?.onUploadCompleted?.(arg.id, arg.fileName, arg.fileSize, arg.fileType, arg.downloadUrl, arg.md5);

      this.#removeCallbacks(arg.id);
    } catch (error: unknown) {
      this.uploadFailed({ id: arg.id, fileName: arg.fileName, error: error as Error });
    }
  }

  /**
   * Callback function triggered when an upload was canceled
   * Marks upload task as aborted
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that completed
   * @param arg.fileName Name of the file that was canceled
   */
  private onUploadCanceled(arg: CanceledResponseArg): void {
    this.updateTaskInStore(arg.id, { status: BackgroundTaskState.aborted });

    const callbacks = this.#callbacks.get(arg.id);
    callbacks?.onUploadCanceled?.(arg.id, arg.fileName);

    this.#removeCallbacks(arg.id);
  }

  /**
   * Callback function triggered when an upload is failed
   * Marks upload task as failed
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.error Error thrown that made the upload to fail.
   */
  private uploadFailed(arg: FailedResponseArg): void {
    this.updateTaskInStore(arg.id, {
      status: BackgroundTaskState.failed,
      errorMessage: arg.error.message,
    });

    const callbacks = this.#callbacks.get(arg.id);
    callbacks?.onUploadFailed?.(arg.id, arg.fileName, arg.error);

    this.#removeCallbacks(arg.id);
  }

  /**
   * Callback function triggered when an upload got progress
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.progress Current progress of the given upload, from 0 to 100
   * @param arg.expectedEnd Expected end timestamp of this task
   */
  private uploadUpdated(arg: ProgressResponseArg): void {
    // If there were already pending updates, there is already a scheduled timeout.
    if (this.#pendingTaskUpdates.size === 0) {
      setTimeout(() => { this.bulkUpdateTasksInStore(); }, TASK_UPDATE_DEBOUNCE_DELAY);
    }
    this.#pendingTaskUpdates.set(arg.id, arg);
  }

  /** Apply several task updates in the store at once to avoid too many re-renderings. */
  private bulkUpdateTasksInStore(): void {
    for (const [id, props] of this.#pendingTaskUpdates) {
      this.updateTaskInStore(id, props);

      const callbacks = this.#callbacks.get(id);
      callbacks?.onUploadUpdated?.(id, props.progress);
    }
    this.#pendingTaskUpdates.clear();
  }

  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload({
    file,
    isSilent = false,
    coreApiClient,
    finalizer,
    onUploadCompleted,
    onUploadFailed,
    onUploadUpdated,
    onUploadCanceled,
    context,
  }: FileUploadParams): void {
    const id = crypto.randomUUID();

    this.#callbacks.set(id, {
      finalizer,
      onUploadCompleted,
      onUploadFailed,
      onUploadUpdated,
      onUploadCanceled,
    });

    this.startTaskInStore(id, file, isSilent, context);

    // Start the upload in the SharedWorker
    this.#sendMessageToWorker({ requestType: "startFileUpload", id, params: { file, context } });
  }

  /**
   * Cancels a file upload
   *
   * @param id The ID of the corresponding background task in the store
   * @param shouldRemoveTaskFromStore True to remove the task from store, primarily when all uploads are aborted.
   * @returns Whether the upload existed and was in progress, therefore canceled correctly.
   */
  cancelFileUpload(id: GUID, shouldRemoveTaskFromStore: boolean = false): boolean {
    if (shouldRemoveTaskFromStore) {
      this.removeTaskFromStore(id);
    }
    this.#sendMessageToWorker({ requestType: "cancelFileUpload", id });
    this.updateTaskInStore(id, { status: BackgroundTaskState.aborted });
    return true;
  }

  /**
   * Sets the maximum number of concurrent file uploads.
   * @param files Max concurrent files (integer >= 1).
   * @param chunks Max concurrent chunks per file (integer >= 1).
   * @param isManual True if manually setting the concurrency. This will prevent automatic adjustements.
   */
  setMaxConcurrentUploads(files: number, chunks: number, isManual: boolean): void {
    assert(
      Number.isInteger(files) && files >= 1,
      "The maximum number of concurrent file uploads must be an integer >= 1."
    );
    assert(
      Number.isInteger(chunks) && chunks >= 1,
      "The maximum number of concurrent chunk uploads per file must be an integer >= 1."
    );

    this.#sendMessageToWorker({ requestType: "setMaxConcurrentUploads", value: files });
    this.#sendMessageToWorker({ requestType: "setMaxConcurrentChunks", value: chunks });
    this.#isManualConcurrency = isManual;
  }

  /**
   * Automatically sets the maximum number of concurrent file uploads for the given files.
   * @param files Files to upload.
   */
  setMaxConcurrentUploadsAuto(files: File[]): void {
    if (!files.length || this.#isManualConcurrency) {
      return;
    }
    const avgSize = files.reduce((sum, file) => sum + file.size, 0) / files.length;
    this.#sendMessageToWorker({ requestType: "setMaxConcurrentUploadsAuto", value: avgSize });
  }

  /**
   * Set the chunk size for chunked upload in bytes.
   * @param value Integer > 1_048_576
   */
  setChunkSize(value: number): void {
    assert(
      Number.isInteger(value) && value >= MINIMUM_CHUNK_SIZE,
      `The chunk size must be an integer >= ${MINIMUM_CHUNK_SIZE}}.`
    );
    this.#sendMessageToWorker({ requestType: "setChunkSize", value });
  }

  /** Set the upload endpoint to use. */
  setUploadEndpoint(endpoint: UploadEndpoint): void {
    assert(endpoint === "auto" || endpoint === "frontdoor" || endpoint === "storage");
    this.#sendMessageToWorker({ requestType: "setUploadEndpoint", endpoint });
  }

  /**
   * This is a shared worker
   * @returns true
   */
  isSharedWorker(): boolean {
    return true;
  }
}
