import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { Box } from "@mui/material";
import { updateCurrentUserProfile } from "@store/user/user-slice";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useAppDispatch } from "@store/store-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { UserProfile } from "@utils/track-event/track-event-list";
import { useAccountSettingsLayout } from "@pages/account-settings/account-settings-layout/account-settings-layout-utils";
import { useUserContext } from "@pages/account-settings/user-provider";

interface Props {
  /** Whether the changes are made for first name or last name */
  field: "firstName" | "lastName";
}

/** Change the account name */
export function AccountChangeName({ field }: Props): JSX.Element {
  const { user } = useUserContext();
  const { accountSettingsFontSize } = useAccountSettingsLayout();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();

  async function onChangeDisplayName({
    newFirstName,
    newLastName,
  }: {
    newFirstName: string;
    newLastName: string;
  }): Promise<string> {
    trackEvent({
      name: UserProfile.updateProfile,
      props: { attribute: { field } },
    });

    try {
      await dispatch(
        updateCurrentUserProfile({
          coreApiClient,
          user: {
            ...user,
            // Make sure to trim the value to avoid empty spaces
            name: newFirstName.trim(),
            lastName: newLastName.trim(),
          },
        })
      );
    } catch (error) {
      handleErrorWithToast({
        id: `changeDisplayName-${Date.now().toString()}`,
        title: `Could not change ${field}`,
        error,
      });
    }
    return field === "firstName" ? newFirstName : newLastName;
  }

  return (
    <Box component="div" width="100%">
      <FaroTextField
        initialValue={field === "firstName" ? user.name : user.lastName}
        onConfirmed={(value) =>
          onChangeDisplayName({
            newFirstName: field === "firstName" ? value : user.name,
            newLastName: field === "firstName" ? user.lastName : value,
          })
        }
        isFullWidth={true}
        faroVariant="row"
        isReadOnly={false}
        minInputLength={1}
        fontSize={accountSettingsFontSize}
      />
    </Box>
  );
}
