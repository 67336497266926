import { EmptyPage } from "@src/components/common/empty-page/empty-page";
import BrowseDuoColorIcon from "@assets/icons/browse-duo-color.svg?react";
import MembersDuoColorIcon from "@assets/icons/members-duo-color.svg?react";
import { useAppSelector } from "@store/store-helper";
import { searchSelector } from "@store/ui/ui-selector";
import { ReactNode } from "react";

interface Props {
  /** Text to show as subtitle in this empty page */
  subtitle: string;

  /** Button to add in the empty page */
  button: ReactNode;
}

/** Empty page content for members page */
export function MembersEmptyPage({ subtitle, button }: Props): JSX.Element {
  const { debouncedSearchText } = useAppSelector(searchSelector);

  if (debouncedSearchText.length) {
    return (
      <EmptyPage
        title="No results found"
        subtitle="There are no members that match this search."
        icon={BrowseDuoColorIcon}
      />
    );
  } else {
    return (
      <EmptyPage
        title="You don't have any members yet"
        subtitle={subtitle}
        icon={MembersDuoColorIcon}
        button={button}
      />
    );
  }
}
