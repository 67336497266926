import { Component } from "react";
import {
  ErrorWithTitle,
  UiErrorType,
} from "@context-providers/error-boundary/error-types";
import { IReactChildrenOnly } from "@custom-types/types";
import { ErrorPage } from "@context-providers/error-boundary/error-page";
import { ErrorDialog } from "@context-providers/error-boundary/error-dialog";
import { ErrorToast } from "@context-providers/error-boundary/error-toast";
import { ErrorHandlingContext } from "@context-providers/error-boundary/error-handling-context";
import { sentryCaptureError } from "@src/utils/sentry-utils";

type State = {
  [key in UiErrorType]: ErrorWithTitle | null;
};

/**
 * Use to create a new ErrorBoundary and ErrorHandlingContext.
 * Needs to be a class component due to componentDidCatch not being available in functional components.
 */
// eslint-disable-next-line react/prefer-es6-class -- Error boundary is not available in functional component
export class ErrorBoundary extends Component<IReactChildrenOnly, State> {
  constructor(props: IReactChildrenOnly) {
    super(props);
    this.state = { page: null, dialog: null, toast: null };
  }

  /**
   * Catches all React-rendering errors within the children.
   * This life-cycle catch any unintentional/unexpected error that is not covered by use-error-handler
   */
  componentDidCatch(error: Error): void {
    this.handleErrorWithPage({
      id: `componentDidCatch-${Date.now().toString()}`,
      title: "An unexpected error occurred: ",
      error,
    });
  }

  /** Log error in logger */
  logError({ title, error }: ErrorWithTitle): void {
    sentryCaptureError({ error, title });
  }

  /** Handle the error by showing a page */
  handleErrorWithPage = ({ id, title, error }: ErrorWithTitle): void => {
    this.logError({ id, title, error });
    this.setState({
      page: {
        id,
        title,
        error,
      },
    });
  };

  /** Handle the error by showing a dialog */
  handleErrorWithDialog = ({ id, title, error }: ErrorWithTitle): void => {
    this.logError({ id, title, error });
    this.setState({
      dialog: {
        id,
        title,
        error,
      },
    });
  };

  /** Handle the error by showing a toast */
  handleErrorWithToast = ({ id, title, error }: ErrorWithTitle): void => {
    this.logError({ id, title, error });
    this.setState({
      toast: {
        id,
        title,
        error,
      },
    });
  };

  handleClosePageError = (): void => {
    this.setState({ page: null });
  };

  handleCloseDialogError = (): void => {
    this.setState({ dialog: null });
  };

  handleCloseToastError = (): void => {
    this.setState({ toast: null });
  };

  handleOnGoHome = (): void => {
    this.handleClosePageError();

    // React-router is not available in root ErrorBoundary
    window.location.href = "/";
  };

  render(): JSX.Element {
    if (this.state.page) {
      return (
        <ErrorPage
          error={this.state.page}
          onReload={this.handleClosePageError}
          onGoHome={this.handleOnGoHome}
        />
      );
    }

    return (
      <ErrorHandlingContext.Provider
        value={{
          handleErrorWithDialog: this.handleErrorWithDialog,
          handleErrorWithToast: this.handleErrorWithToast,
          handleErrorWithPage: this.handleErrorWithPage,
        }}
      >
        {this.props.children}
        {this.state.dialog && (
          <ErrorDialog
            error={this.state.dialog}
            onClose={this.handleCloseDialogError}
          />
        )}
        {this.state.toast && (
          <ErrorToast
            error={this.state.toast}
            onClose={this.handleCloseToastError}
          />
        )}
      </ErrorHandlingContext.Provider>
    );
  }
}
