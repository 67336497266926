import { CaptureTreeRevision, CaptureTreeEntityWithRevisionStatus } from "@custom-types/capture-tree/capture-tree-types";
import { CaptureApiClient, RegistrationState, RevisionStatus } from "@faro-lotv/service-wires";

/**
 * @param openDraftEntities The open draft entities, with their revision status (added, deleted, ...).
 * @param openDraftRevision The open draft revision.
 * @param revisions All revisions of the project.
 * @returns True if we need to update the project point cloud and map via registration worker.
 *          False if we can directly publish the project via `projectApiClient.publishDraftRevision()`.
 */
export function areProjectPointCloudAndMapOutdated(
  openDraftEntities: CaptureTreeEntityWithRevisionStatus[],
  openDraftRevision: CaptureTreeRevision,
  revisions: CaptureTreeRevision[]
): boolean {
  const hasDeletedEntity = openDraftEntities.some(
    (entity) => entity.status === RevisionStatus.deleted || entity.status === RevisionStatus.deletedExplicitly
  );
  if (!hasDeletedEntity) {
    return false;
  }

  // Implementation detail: Updating the map and project point cloud is done in the registration worker.
  // In the (unexpected) case that the latest revision is from the RegistrationBackend, probably no registration is necessary.
  // This would happen only if for some reason the registration succeeded, but the draft was not auto-published.
  const revisionsWhichContributedToOpenDraft = revisions.filter(
    (revision) =>
      revision.targetRevisionId && revision.targetRevisionId === openDraftRevision.id &&
      revision.state === RegistrationState.merged
  ).sort((a, b) => {
    // Sort from newest to oldest, to get latest merged revision:
    const aModifiedAt = new Date(a.modifiedAt || a.createdAt || 0).getTime();
    const bModifiedAt = new Date(b.modifiedAt || b.createdAt || 0).getTime();
    return bModifiedAt - aModifiedAt;
  });

  return revisionsWhichContributedToOpenDraft[0]?.createdByClient !== CaptureApiClient.registrationBackend;
}
