import {
  isBackgroundTaskProgressUpdate,
  isBackgroundTaskStateUpdate,
  isCadTaskType,
  isPointCloudTaskType,
  isVideoModeTaskType,
  isWSMigrationTaskType,
} from "@api/progress-api/progress-api-type-guards";
import { BackgroundTask } from "@api/progress-api/progress-api-types";
import {
  BackgroundTaskCategory,
  SdbBackgroundTask,
} from "@custom-types/sdb-background-tasks-types";
import { RootState } from "@store/store-helper";
import { selectAncestor, selectIElement } from "@faro-lotv/project-source";
import { VIDEO_MODE_TASK_TYPES } from "@api/progress-api/progress-api-constants";
import { selectedProjectIdSelector } from "@store/projects/projects-selector";
import { isIElementDataSetVideoWalk } from "@faro-lotv/ielement-types";
import { getSphereViewerUrl } from "@utils/project-utils";

/**
 * @returns a mapped array of SdbBackgroundTask object from an array BackgroundTask object
 */
export function generateSdbBackgroundTasks(
  backgroundTasks: BackgroundTask[]
): SdbBackgroundTask[] {
  return backgroundTasks.map((backgroundTask) => {
    return {
      id: backgroundTask.id,
      createdAt: backgroundTask.createdAt,
      taskType: backgroundTask.taskType,
      taskCategory: getBackgroundTaskCategory(backgroundTask),
      state: generateSdbBackgroundTaskStatus(backgroundTask),
      progress: generateSdbBackgroundTaskProgress(backgroundTask),
      updatedAt: backgroundTask.status ? backgroundTask.status.changedAt : null,
      message: getSdbBackgroundTaskMessage(backgroundTask),
      context: backgroundTask.context,
      status: backgroundTask.status,
    };
  });
}

/**
 * @returns the status state for tasks that are of status type "State" or
 * "Started" for tasks that are of status type "Progress" or null if there is not status
 */
function generateSdbBackgroundTaskStatus(
  backgroundTask: BackgroundTask
): SdbBackgroundTask["state"] {
  const status = backgroundTask.status;

  if (isBackgroundTaskStateUpdate(status)) {
    return status.state;
  }

  if (isBackgroundTaskProgressUpdate(status)) {
    if (status.progress) {
      const progressPercentage = getProgressPercentage(
        status.progress.current,
        status.progress.total
      );

      // Return `Pending` status for tasks where the progress value is zero.
      if (progressPercentage === 0) {
        return "Pending";
      }
    }

    return "Started";
  }

  return null;
}

/**
 * @returns A number [0-100] for tasks that have progress data or null if there is no progress data
 */
function generateSdbBackgroundTaskProgress(
  backgroundTask: BackgroundTask
): string {
  const status = backgroundTask.status;

  if (isBackgroundTaskStateUpdate(status)) {
    if (status.state === "Succeeded") {
      return "100%";
    }

    // Show "Calculating" when the task status is started but there is no progress data.
    if (status.state === "Started") {
      return "Calculating...";
    }
  }

  if (isBackgroundTaskProgressUpdate(status)) {
    if (!status.progress) {
      return "";
    }

    const progressPercentage = getProgressPercentage(
      status.progress.current,
      status.progress.total
    );

    // Some background tasks hang on `zero` progress for a long time, giving the user the impression that
    // the task is not running properly. It is better to not show the `zero` and show `Calculating` instead
    // until the value increases and it's greater than zero.
    if (progressPercentage === 0) {
      return "Calculating...";
    }

    return getDisplayProgress(progressPercentage);
  }
  return "";
}

/**
 * @returns A number representing the percentage of completed progress.
 */
export function getProgressPercentage(current: number, total: number): number {
  if (total === 0) {
    return 0;
  }

  return (current / total) * 100;
}

/**
 * @returns The progress value to display
 * Truncates the value to 2 decimals max.
 * @param percentage raw progress percentage.
 */
export function getDisplayProgress(percentage: number): string {
  const truncatedPercentage = Math.trunc(percentage * 100) / 100;
  return `${truncatedPercentage}%`;
}

export function getBackgroundTaskCategory(
  backgroundTask: BackgroundTask
): BackgroundTaskCategory | null {
  if (!backgroundTask.taskType) {
    return null;
  }

  if (isPointCloudTaskType(backgroundTask.taskType)) {
    return BackgroundTaskCategory.pointCloud;
  }

  if (isVideoModeTaskType(backgroundTask.taskType)) {
    return BackgroundTaskCategory.videoMode;
  }

  if (isCadTaskType(backgroundTask.taskType)) {
    return BackgroundTaskCategory.cad;
  }

  if (isWSMigrationTaskType(backgroundTask.taskType)) {
    return BackgroundTaskCategory.migration;
  }

  return null;
}

/**
 * @returns The devMessage property as message string
 */
export function getSdbBackgroundTaskMessage(
  backgroundTask: BackgroundTask
): SdbBackgroundTask["message"] {
  const status = backgroundTask.status;

  // Only get the devMessage for failed tasks
  if (isBackgroundTaskStateUpdate(status) && status.state === "Failed") {
    return status.devMessage ?? "Something went wrong";
  }
}

interface GetTasksContextProps {
  /** Array of backgrounds tasks */
  tasks: SdbBackgroundTask[];

  /** Store state */
  state: RootState;
}

/**
 * @returns The backgrounds tasks with additional context associated with its IElement
 *
 * @param tasks SDB backgrounds tasks
 * @param state Store state
 */
export function getTasksContext({
  tasks,
  state,
}: GetTasksContextProps): SdbBackgroundTask[] {
  const tasksWithContext: SdbBackgroundTask[] = [];

  tasks.forEach((task) => {
    let elementName: string | undefined = undefined;
    let viewerDeepLink: string | undefined = undefined;

    // Attempt to get the associated IElement Id from the task context elementId property
    // If not defined then attempt to get it from the correlationId property
    const elementId = task.context?.elementId ?? task.context?.correlationId;

    if (elementId) {
      const element = selectIElement(elementId)(state);

      if (element) {
        elementName = element.name;

        // Get Viewer deep link only for VideoMode tasks
        if (task.taskType === VIDEO_MODE_TASK_TYPES.VideoMode) {
          const projectId = selectedProjectIdSelector(state);
          const lookAtId = selectAncestor(
            element,
            isIElementDataSetVideoWalk
          )(state)?.id;

          if (projectId && lookAtId) {
            viewerDeepLink = getSphereViewerUrl({
              projectId,
              lookAtId,
            }).href;
          }
        }
      }
    }

    tasksWithContext.push({
      ...task,
      elementName,
      viewerDeepLink,
    });
  });

  return tasksWithContext;
}

/**
 * @returns An array of unique IElement IDs extracted from a list of backgrounds tasks
 *
 * @param tasks Array of background tasks
 */
export function getTasksIElementIds(tasks: BackgroundTask[]): string[] {
  const iElementIds: string[] = [];

  tasks.forEach((task) => {
    // Attempt to get the associated IElement Id from the task context elementId property
    // If not defined then attempt to get it from the correlationId property
    const iElementId = task.context?.elementId ?? task.context?.correlationId;

    if (iElementId && iElementIds.indexOf(iElementId) === -1) {
      iElementIds.push(iElementId);
    }
  });

  return iElementIds;
}

/**
 * @returns An array of sorted sdb background tasks:
 * - Tasks should be sorted by createdAt date, most recently created first.
 * - If it's the same createdAt date, then the task with the most recent
 *   updatedAt date should show first, if available.
 *
 * @param tasks Array of un-sorted sdb backgrounds tasks.
 */
export function sortSdbBackgroundTasks(
  tasks: SdbBackgroundTask[]
): SdbBackgroundTask[] {
  return tasks.sort((a, b) => {
    const aUpdatedAtRaw = a.updatedAt;
    const aCreatedAt = new Date(a.createdAt).getTime();

    const bUpdatedAtRaw = b.updatedAt;
    const bCreatedAt = new Date(b.createdAt).getTime();

    if (aCreatedAt !== bCreatedAt) {
      return bCreatedAt - aCreatedAt;
    }

    // If both tasks are created at the same time, try to sort by updatedAt
    if (aUpdatedAtRaw && bUpdatedAtRaw) {
      const aUpdatedAt = new Date(aUpdatedAtRaw).getTime();
      const bUpdatedAt = new Date(bUpdatedAtRaw).getTime();
      return bUpdatedAt - aUpdatedAt;
    } else if (aUpdatedAtRaw) {
      return -1;
    } else if (bUpdatedAtRaw) {
      return 1;
    }

    return 0;
  });
}

/**
 * @returns whether the task is ongoing
 * A task is considered to be ongoing if the status is created, pending, schedules or started.
 * If the status is succeeded, failed, aborted or the value is null then we consider it not ongoing.
 *
 * @param task SdbBackgroundTask entity
 */
export function isSdbBackgroundTaskOngoing(task: SdbBackgroundTask): boolean {
  return (
    task.state === "Created" ||
    task.state === "Pending" ||
    task.state === "Scheduled" ||
    task.state === "Started"
  );
}
