import { Box, Dialog, Grid, Typography } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { AttachmentItemActions } from "@pages/project-details/project-markups/sidepanel/attachment/attachment-item-actions";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { IElementAttachment } from "@faro-lotv/ielement-types";
import { addTransparency } from "@utils/ui-utils";
import { CommonStyles, withEllipsis } from "@styles/common-styles";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { ReactSetStateFunction } from "@custom-types/types";

interface Props {
  /** The attachment item to display */
  attachment: IElementAttachment;

  /** Function to toggle the preview dialog visibility */
  setIsPreviewDialogOpen: (value: boolean) => void;

  /** Function to update the downloading state of the attachment */
  setIsDownloading: (value: boolean) => void;

  /** Function to set the selected attachments */
  setSelectedAttachments: ReactSetStateFunction<Set<string>>;
}

/** Size in pixels for each attachment action */
const ACTION_BTN_SIZE = "24px";

/** Size in pixels for each attachment action icon */
const ACTION_ICON_SIZE = "24px";

/**
 * This component renders a full-screen dialog to preview an attachment. It displays the attachment's image,
 * name, and provides actions for downloading or deleting the attachment.
 */
export function FaroImagePreview({
  attachment,
  setIsPreviewDialogOpen,
  setIsDownloading,
  setSelectedAttachments,
}: Props): JSX.Element {
  return (
    <Dialog
      open={true}
      fullScreen
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
      // Added this to prevent the "preview attachment" tracking event from being triggered by clicks within the dialog.
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        data-testid="preview-attachment-dialog-content"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "70px",
            alignItems: "center",
            boxSizing: "border-box",
            borderBottom: CommonStyles.Borders.gray200Divider,
            backgroundColor: sphereColors.pureWhite,
            zIndex: 1,
            position: "relative",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Grid item xs zeroMinWidth>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <FaroIconButton
                  dataTestId="preview-dialog-close-icon"
                  component={CloseIcon}
                  buttonSize={ACTION_BTN_SIZE}
                  iconSize={ACTION_ICON_SIZE}
                  iconButtonProps={{
                    sx: {
                      padding: "20px",
                    },
                  }}
                  onClick={(e) => {
                    setIsPreviewDialogOpen(false);
                    e.stopPropagation();
                  }}
                />
                <Typography
                  sx={{
                    marginLeft: "10px",
                    fontWeight: "700",
                    ...withEllipsis,
                  }}
                  title={attachment.name}
                >
                  {attachment.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
              >
                <AttachmentItemActions
                  attachment={attachment}
                  setIsDownloading={setIsDownloading}
                  eventSource="preview attachment"
                  buttonSize={ACTION_BTN_SIZE}
                  iconSize={ACTION_ICON_SIZE}
                  iconBtnProps={{
                    sx: {
                      padding: "20px",
                    },
                  }}
                  setSelectedAttachments={setSelectedAttachments}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            padding: { xs: "16px", md: "24px" },
            backgroundColor: addTransparency({
              color: sphereColors.gray800,
              alpha: EDecimalToHex.hundredFortyFour,
            }),
            backdropFilter: "blur(15px)",
            overflow: "auto",
          }}
        >
          <Box
            component="img"
            src={attachment.uri}
            alt="attachment annotation"
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              width: "auto",
              height: "auto",
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
