import { ProjectMarkupsFilterComponents } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { ProjectMarkupsStatusFilter } from "@pages/project-details/project-markups/project-markups-filters/project-markups-status-filter";
import { ProjectMarkupsAttachmentFilter } from "@pages/project-details/project-markups/project-markups-filters/project-markups-attachment-filter";
import { ProjectMarkupsTagsFilter } from "@pages/project-details/project-markups/project-markups-filters/project-markups-tags-filter";
import { ProjectMarkupsAssigneeFilter } from "@pages/project-details/project-markups/project-markups-filters/project-markups-assignee-filter";
import { ProjectMarkupsDueDateFilter } from "@pages/project-details/project-markups/project-markups-filters/project-markups-due-date-filter";

/** All the filter chips applied to project markups table */
export function projectMarkupsFilterChips(): ProjectMarkupsFilterComponents {
  return {
    [ProjectMarkupsTableHeaders.status]: <ProjectMarkupsStatusFilter />,
    [ProjectMarkupsTableHeaders.assignee]: <ProjectMarkupsAssigneeFilter />,
    [ProjectMarkupsTableHeaders.dueDate]: <ProjectMarkupsDueDateFilter />,
    [ProjectMarkupsTableHeaders.tags]: <ProjectMarkupsTagsFilter />,
    [ProjectMarkupsTableHeaders.attachments]: (
      <ProjectMarkupsAttachmentFilter />
    ),
  };
}
