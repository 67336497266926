import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CaptureTreeState } from "@store/capture-tree/capture-tree-slice-types";
import { FetchingStatus } from "@store/store-types";
import {
  fetchAllCaptureTreeRevisions,
  fetchCaptureTree,
  fetchCaptureTreeForOpenDraftRevision,
} from "@store/capture-tree/capture-tree-thunks";
import { clearStore } from "@faro-lotv/project-source";
import { CaptureTreeEntity } from "@faro-lotv/service-wires";
import { CaptureTreeRevision, CaptureTreeEntityWithRevisionStatus } from "@custom-types/capture-tree/capture-tree-types";

/** Pre-built reducers for the capture tree entities `normalized state` */
export const captureTreeAdapter: EntityAdapter<CaptureTreeEntity, EntityId> =
  createEntityAdapter({
    selectId: (captureTreeEntity): EntityId => captureTreeEntity.id,
  });

export const captureTreeForOpenDraftRevisionAdapter: EntityAdapter<CaptureTreeEntityWithRevisionStatus, EntityId> =
  createEntityAdapter({
    selectId: (captureTreeEntityRevision): EntityId => captureTreeEntityRevision.id,
  });

/** Pre-built reducers for the revisions `normalized state` */
export const allCaptureTreeRevisionsAdapter: EntityAdapter<CaptureTreeRevision, EntityId> =
  createEntityAdapter({
    selectId: (revision): EntityId => revision.id,
  });

/** Initial state (readonly) of the capture tree store */
export const initialState: CaptureTreeState = {
  captureTree: captureTreeAdapter.getInitialState(),
  captureTreeForOpenDraftRevision: captureTreeForOpenDraftRevisionAdapter.getInitialState(),
  allCaptureTreeRevisions: allCaptureTreeRevisionsAdapter.getInitialState(),
  fetchingStatus: {
    captureTree: FetchingStatus.uninitialized,
    captureTreeForOpenDraftRevision: FetchingStatus.uninitialized,
    allCaptureTreeRevisions: FetchingStatus.uninitialized,
    hasFetchCaptureTreeDataError: false,
  },
  hasFetched: {
    hasFetchedCaptureTree: false,
    hasFetchedCaptureTreeForOpenDraftRevision: false,
    hasFetchedAllCaptureTreeRevisions: false,
  },
};

/** Capture tree slice */
const captureTreeSlice = createSlice({
  name: "captureTree",
  initialState,
  reducers: {
    /** Sets the capture tree entities for main/draft revision in the store */
    setCaptureTree(
      state,
      action: PayloadAction<CaptureTreeEntity[]>
    ) {
      captureTreeAdapter.setAll(
        state.captureTree,
        action.payload
      );
    },
    /** Sets the capture tree entities for draft revision in the store */
    setCaptureTreeForOpenDraftRevision(
      state,
      action: PayloadAction<CaptureTreeEntityWithRevisionStatus[]>
    ) {
      captureTreeAdapter.setAll(
        state.captureTreeForOpenDraftRevision,
        action.payload
      );
    },

    /** Sets the revisions in the store */
    setAllCaptureTreeRevisions(
      state,
      action: PayloadAction<CaptureTreeRevision[]>
    ) {
      allCaptureTreeRevisionsAdapter.setAll(
        state.allCaptureTreeRevisions,
        action.payload
      );
    },

    /** Sets the error for fetchCaptureTreeData() */
    setFetchCaptureTreeDataError: (state, action: PayloadAction<boolean>) => {
      state.fetchingStatus.hasFetchCaptureTreeDataError = action.payload;
    },

    /** Resets the capture tree store */
    resetCaptureTreeState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCaptureTree.pending, (state) => {
        state.fetchingStatus.captureTree = FetchingStatus.pending;
      })
      .addCase(fetchCaptureTree.fulfilled, (state, action) => {
        // TF-1907: Replace all the capture tree entities in the store with the new ones. Using "setMany"
        // is wrong here, because it doesn't remove the entities that are not present in the new list.
        captureTreeAdapter.setAll(
          state.captureTree,
          action.payload
        );
        state.fetchingStatus.captureTree = FetchingStatus.succeeded;
        if (!state.hasFetched.hasFetchedCaptureTree) {
          state.hasFetched.hasFetchedCaptureTree = true;
        }
      })
      .addCase(fetchCaptureTree.rejected, (state) => {
        state.fetchingStatus.captureTree = FetchingStatus.rejected;
      })

      .addCase(fetchCaptureTreeForOpenDraftRevision.pending, (state) => {
        state.fetchingStatus.captureTreeForOpenDraftRevision = FetchingStatus.pending;
      })
      .addCase(fetchCaptureTreeForOpenDraftRevision.fulfilled, (state, action) => {
        // TF-1907: Replace all the capture tree entities in the store with the new ones. Using "setMany"
        // is wrong here, because it doesn't remove the entities that are not present in the new list.
        captureTreeForOpenDraftRevisionAdapter.setAll(
          state.captureTreeForOpenDraftRevision,
          action.payload
        );
        state.fetchingStatus.captureTreeForOpenDraftRevision = FetchingStatus.succeeded;
        if (!state.hasFetched.hasFetchedCaptureTreeForOpenDraftRevision) {
          state.hasFetched.hasFetchedCaptureTreeForOpenDraftRevision = true;
        }
      })
      .addCase(fetchCaptureTreeForOpenDraftRevision.rejected, (state) => {
        state.fetchingStatus.captureTreeForOpenDraftRevision = FetchingStatus.rejected;
      })

      .addCase(fetchAllCaptureTreeRevisions.pending, (state) => {
        state.fetchingStatus.allCaptureTreeRevisions = FetchingStatus.pending;
      })
      .addCase(fetchAllCaptureTreeRevisions.fulfilled, (state, action) => {
        allCaptureTreeRevisionsAdapter.setAll(
          state.allCaptureTreeRevisions,
          action.payload
        );
        state.fetchingStatus.allCaptureTreeRevisions = FetchingStatus.succeeded;
        if (!state.hasFetched.hasFetchedAllCaptureTreeRevisions) {
          state.hasFetched.hasFetchedAllCaptureTreeRevisions = true;
        }
      })
      .addCase(fetchAllCaptureTreeRevisions.rejected, (state) => {
        state.fetchingStatus.allCaptureTreeRevisions = FetchingStatus.rejected;
      })

      /** Resets the capture tree store if it "listens" to the "clearStore" action */
      .addCase(clearStore, () => initialState);
  },
});

export const {
  setCaptureTree,
  setCaptureTreeForOpenDraftRevision,
  setAllCaptureTreeRevisions,
  resetCaptureTreeState,
  setFetchCaptureTreeDataError,
} = captureTreeSlice.actions;

export const captureTreeReducer = captureTreeSlice.reducer;
