import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Box, Grid } from "@mui/material";
import { companyCreditBalanceSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { useMemo } from "react";

/** Common style for the info bar items */
const infoBarItemStyle = {
  bgcolor: sphereColors.gray50,
  padding: "20px",
  height: {
    xs: "80px",
    sm: "90px",
    md: "100px",
  },
};

/** Renders the info bar for transactions dialog */
export function TransactionsInfoBar(): JSX.Element {
  const creditBalance = useAppSelector(companyCreditBalanceSelector);
  const { isScreenSmOrSmaller, isScreenMdAndSmaller, isScreenMdAndLarger } =
    useMediaQueryList();

  const valueFontSize = useMemo(() => {
    if (isScreenSmOrSmaller) {
      return "18px";
    }

    if (isScreenMdAndSmaller) {
      return "24px";
    }

    if (isScreenMdAndLarger) {
      return "32px";
    }
  }, [isScreenMdAndLarger, isScreenMdAndSmaller, isScreenSmOrSmaller]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Box sx={infoBarItemStyle}>
          <FaroTextField
            label="Balance"
            initialValue={creditBalance}
            faroVariant="main"
            fontSize={valueFontSize}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Box sx={infoBarItemStyle}>
          <FaroTextField
            label="Period"
            initialValue="All time"
            faroVariant="main"
            fontSize={valueFontSize}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
