import {
  MemberTypes,
  EMemberStatus,
  BaseMemberProps,
} from "@custom-types/member-types";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { sphereColors } from "@styles/common-colors";
import { capitalizeFirstLetter, removeExtraSpaces } from "@utils/string-utils";
import EmailIcon from "@assets/icons/new/email_32px.svg?react";
import {
  ICON_LARGE_STYLE,
  ICON_SMALL_STYLE,
} from "@components/header/sphere-avatar";
import { getMailToLink } from "@utils/email-utils";
import { PartialNull } from "@custom-types/types";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";
import { isTeam } from "@utils/team-utils";
import { isUserDetails } from "@custom-types/type-guards";

/**
 * Gets the user full name in a pretty format.
 * The first letter of the first and last name will be capitalized.
 * If there are no names, the email will be used as name.
 *
 * @param user The user to gets its name.
 * @returns The pretty name. E.g. John Doe
 */
export function getPrettyName(
  user?:
    | Partial<MemberTypes>
    | SphereDashboardAPITypes.IGroupMemberDetails
    | SphereDashboardAPITypes.IUserDetails
    | null
): string {
  if (!user) {
    return "";
  }

  if (!isUserDetails(user) && user.name) {
    // If the user includes the 'name' attribute, it should already be formatted nicely.
    return user.name;
  }

  const firstName = isUserDetails(user) ? user.name : user.firstName;
  const emailAddress = isUserDetails(user) ? user.mailAddress : user.email;

  let fullName = capitalizeFirstLetter(firstName ?? "");

  if (fullName.length && user.lastName?.length) {
    // Only add space if the first and last name were available.
    fullName += " ";
  }

  fullName += capitalizeFirstLetter(user.lastName ?? "");

  if (!fullName.length) {
    // If the name could not be extracted from first and last name, use email as default.
    fullName = emailAddress ?? "";
  }

  return fullName;
}

/**
 * Gets the user's full name in a pretty format combined into one single string separated by comma.
 *
 * @param user The users to gets its names.
 * @returns The pretty names. E.g. "John Doe, Foo Bar"
 */
export function getPrettyNames(members: Partial<MemberTypes>[]): string {
  return members.map((member) => getPrettyName(member)).join(", ");
}

/**
 * Gets the initials for a user.
 * If both first name and last name are available, it will use the first letter of each.
 * If only either the first or last name is available, it will use the first two letters.
 * If none of them are available, the first two letter of the email will be used.
 *
 * @param user User to get its initials from.
 *
 * @returns The two letters of the user name in uppercase.
 */
export function getUserInitials(user?: Partial<MemberTypes> | null): string {
  let initials: string = "";

  const maxDigits = 2;

  if (!user) {
    return "";
  }

  let firstName: string;
  let lastName: string;
  let name: string;
  let email: string;

  if (isUserDetails(user)) {
    firstName = user.name ?? "";
    lastName = user.lastName ?? "";
    name = "";
    email = user.mailAddress;
  } else {
    firstName = user.firstName ?? "";
    lastName = user.lastName ?? "";
    name = user.name ?? "";
    email = user.email ?? "";
  }

  if (firstName && lastName) {
    initials = `${firstName[0]}${lastName[0]}`;
  } else if (firstName && !lastName) {
    initials = firstName.substring(0, maxDigits);
  } else if (!firstName && lastName) {
    initials = lastName.substring(0, maxDigits);
  } else if (!firstName && !lastName && name) {
    // When fetching the logged in user, the response includes first and last name properties.
    // However, when fetching the users list, only a combined name property is included.
    // Therefore we try to infer the first and last name splitting the name separated by a space.
    const split = removeExtraSpaces(name).split(" ");
    if (split.length >= 2) {
      initials = (split[0][0] ?? "") + (split[1][0] ?? "");
    } else {
      initials = name.substring(0, maxDigits);
    }
  } else if (email) {
    initials = email.substring(0, maxDigits);
  }
  return initials.toLocaleUpperCase();
}

/**
 * Returns true if the identity is equal with its ID
 * The identity of a pending member is equal to the email
 */
export function isMemberActive(
  member: MemberTypes | SphereDashboardAPITypes.IUserAsManager
): boolean {
  return member.identity === member.id;
}

/** Returns readable text for the member status */
export function getMemberStatus({ member }: BaseMemberProps): EMemberStatus {
  if (isMemberActive(member)) {
    return EMemberStatus.active;
  }
  return EMemberStatus.pending;
}

/**
 * Gets the display name to show in the tooltip for a single user.
 * If the user name and email are the same, a link to the email will be shown.
 * Otherwise the user's name + a link to the user's email will be shown.
 *
 * @param member The member to get its name and email from.
 * @returns HTML element containing the name and/or email link.
 */
export function getUserTooltipDisplayName({
  member,
}: PartialNull<BaseMemberProps>): JSX.Element {
  if (!member) {
    return <div />;
  }
  const name = getPrettyName(member);
  const isNameSameAsEmail =
    name.toLowerCase() === (member.email ?? "").toLowerCase();

  if (isTeam(member) && isNameSameAsEmail) {
    return <div />;
  }

  const key = `member-${member.identity}`;
  const emailHtml = getMailToLink(member.email, {
    color: sphereColors.pureWhite,
  });
  const displayName = isNameSameAsEmail ? emailHtml : name;

  return (
    <div className={NO_TRANSLATE_CLASS} key={key}>
      {displayName}
      {!isTeam(member) && !isNameSameAsEmail && (
        <i>
          {"<"}
          {emailHtml}
          {">"}
        </i>
      )}
    </div>
  );
}

/**
 * Get's the icon to be used in the user avatar for a user if applies.
 * Only users that didn't accept the invitation should see the email icon.
 */
export function getPendingUserIcon(
  member: MemberTypes | SphereDashboardAPITypes.IUserAsManager | null,
  size: "large" | "small" = "small"
): JSX.Element | undefined {
  if (!member || isMemberActive(member)) {
    return undefined;
  }

  return (
    <EmailIcon style={size === "small" ? ICON_SMALL_STYLE : ICON_LARGE_STYLE} />
  );
}

/**
 * This function goes around the limitation that the backend sometimes returns the
 * name separated as first and last name, and sometimes as a single string.
 * If both are combined in a single string, then it will try to separate them,
 * assuming that the last word is the last name and the rest is the first name.
 * If both are available, it will return them as they are.
 */
export function getMemberNameSplit({ member }: PartialNull<BaseMemberProps>): {
  firstName: string;
  lastName: string;
} {
  if (!member) {
    return { firstName: "", lastName: "" };
  }

  if (member.firstName && member.lastName) {
    return {
      firstName: member.firstName,
      lastName: member.lastName,
    };
  }
  let firstName: string;
  let lastName: string;

  const split = removeExtraSpaces(member.name).split(" ");
  if (member.firstName) {
    firstName = member.firstName;
  } else {
    // If the name has only one word, then we take it as the first name.
    firstName =
      split.length === 1
        ? split[0] ?? ""
        : split.slice(0, split.length - 1).join(" ");
  }

  if (member.lastName) {
    lastName = member.lastName;
  } else {
    // If the name has only one word, then we take the last name as empty.
    lastName =
      split.length === 1
        ? ""
        : split.slice(split.length - 1, split.length).join(" ");
  }

  return { firstName, lastName };
}

/**
 * Gets the display name for the different identity providers.
 */
export function getIdentityProviderDisplayName(
  member?: SphereDashboardAPITypes.IUserDetails | null
): string {
  if (!member) {
    return "";
  }

  switch (member.providerId) {
    case CoreAPITypes.EUserJsonProviderId.email:
      return "Email";
    case CoreAPITypes.EUserJsonProviderId.google:
      return "Google";
    case CoreAPITypes.EUserJsonProviderId.apple:
      return "Apple";
    case CoreAPITypes.EUserJsonProviderId.facebook:
      return "Facebook";
    case CoreAPITypes.EUserJsonProviderId.autodesk:
      return "Autodesk";
    case CoreAPITypes.EUserJsonProviderId.plangrid:
      return "PlanGrid";
    case CoreAPITypes.EUserJsonProviderId.bluebeam:
      return "Bluebeam";
    case CoreAPITypes.EUserJsonProviderId.procore:
      return "Procore";
    case CoreAPITypes.EUserJsonProviderId.faroSphere:
      return "FARO";
    case CoreAPITypes.EUserJsonProviderId.azuread:
      return "Azure Active Directory";
    case CoreAPITypes.EUserJsonProviderId.serviceAccount:
    case CoreAPITypes.EUserJsonProviderId.genericOAuth:
      return "Custom SSO provider";
    default:
      return "Unknown";
  }
}

/** Gets the permission for Group Managers to create groups. */
export const createGroupsPermission: SphereDashboardAPITypes.ICompanyPermission =
  {
    name: APITypes.UserPermissionName.CREATE_GROUP,
    // eslint-disable-next-line @typescript-eslint/naming-convention -- Used is backend
    allowed: true,
  };

/** Returns true if the email params is an faro or holobuilder email */
export function isInternalUser(userEmail: string): boolean {
  return (
    userEmail.endsWith("@faro.com") ||
    userEmail.endsWith("@holobuilder.com") ||
    userEmail.endsWith("@faroeurope.com")
  );
}

/**
 * Returns the user's identity.
 * Prefers the user ID if available; otherwise, falls back to the email.
 */
export function resolveUserIdentity(
  userId?: APITypes.UserId | undefined | null,
  userEmail?: string | null
): string | undefined | null {
  return userId ?? userEmail;
}
