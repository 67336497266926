import { UserInfo } from "@components/user-info/user-info";
import { Box, Grid, Stack } from "@mui/material";
import { MemberProfileSkeleton } from "@components/common/skeleton-loading/member-profile-skeleton";
import { BaseMemberProps } from "@custom-types/member-types";
import { useMemberGroups } from "@hooks/use-member-groups";
import { NotFoundPage } from "@pages/not-found-page";
import { isCompanyMemberDetails } from "@custom-types/type-guards";
import { MemberGroupsTable } from "@components/common/member/member-groups-table";
import { MemberProfileEmptyPage } from "@components/common/empty-page/member-profile-empty-page";
import { PartialNull } from "@custom-types/types";
import { FetchingStatus } from "@store/store-types";
import { TeamDetailsInfoBar } from "@pages/members/teams/team-details/team-details-info-bar";
import { useAppSelector } from "@store/store-helper";
import { fetchingTeamsFlagsSelector } from "@store/teams/teams-selector";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { MemberProfileInfoBar } from "@components/member-profile/member-profile-info-bar";

interface Props extends PartialNull<BaseMemberProps> {
  /** Flag to indicate if the content of the member is loading */
  isLoading: boolean;

  /** Validate if we are opening a team overview or a member overview */
  subjectType: "team" | "member";
}

/**
 * Page to show the overview of the profile of the selected user.
 */
export function MemberOverviewPage({
  member,
  isLoading,
  subjectType,
}: Props): JSX.Element {
  const { groups, companyMemberGroupsStatus } = useMemberGroups();
  const { isFetchingTeamMembers } = useAppSelector(fetchingTeamsFlagsSelector);

  const isFetching =
    isLoading || companyMemberGroupsStatus === FetchingStatus.pending;

  if (isFetching || !groups) {
    return <MemberProfileSkeleton />;
  }

  // Show not found page if fetching member or groups failed
  // or if the member entity is not of type ICompanyMemberDetails
  if (
    !member ||
    !isCompanyMemberDetails(member) ||
    companyMemberGroupsStatus !== FetchingStatus.succeeded
  ) {
    return <NotFoundPage />;
  }

  let mainContent;

  if (groups.length === 0) {
    switch (subjectType) {
      case "member":
        mainContent = <MemberProfileEmptyPage />;
        break;
      case "team":
        mainContent = (
          <EmptyPage
            title="No access granted"
            subtitle={`This ${TEAM_DISPLAY_NAME} hasn't been assigned to any groups or projects within the workspace`}
            icon={NoDataIcon}
          />
        );
        break;
      default:
        mainContent = (
          <EmptyPage
            title="Unexpected subject type"
            subtitle="Please contact support to resolve this issue."
            icon={NoDataIcon}
          />
        );
        break;
    }
  } else {
    mainContent = <MemberGroupsTable groups={groups} member={member} />;
  }

  return (
    <Grid
      data-testid={
        subjectType === "member"
          ? "MEMBER_PROFILE_OVERVIEW_CONTAINER"
          : "TEAM_PROFILE_OVERVIEW_CONTAINER"
      }
    >
      {subjectType === "member" ? (
        <MemberProfileInfoBar role={member.role} />
      ) : (
        <TeamDetailsInfoBar
          isLoading={isFetchingTeamMembers === FetchingStatus.pending}
        />
      )}

      {/* Container for the projects, groups and user info component */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          width: "100%",
          gap: subjectType === "member" ? "20px" : 0,
        }}
      >
        {/* Container for the projects and groups */}
        <Box
          component="div"
          marginTop={"14px"}
          sx={{
            width: {
              md: subjectType === "member" ? "calc(100% - 360px)" : "100%",
              sx: "100%",
            },
          }}
        >
          {mainContent}
        </Box>

        {/* Container for the user info */}
        {subjectType === "member" && (
          <Stack marginTop="14px" alignItems="center">
            <UserInfo member={member} />
          </Stack>
        )}
      </Box>
    </Grid>
  );
}
