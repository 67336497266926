import { Box, Stack, SvgIcon } from "@mui/material";
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import PublishedSvg from "@assets/icons/new/checkmark-circle_24px.svg?react";
import UnpublishedSvg from "@assets/icons/new/thick-circle_24px.svg?react";
import StartSvg from "@assets/icons/new/info-circle_24px.svg?react";
import ErrorSvg from "@assets/icons/new/exclamation-mark-circle_24px.svg?react";
import GenericCloseSvg from "@assets/icons/generic-close_l.svg?react";
import { MemberTypes } from "@custom-types/member-types";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FormatDate } from "@hooks/use-date-time";
import { TableHeaders, TableItem, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { canTableItemBeRemoved, getDeviceType } from "@pages/project-details/project-data-management/data-management-utils";
import { EditableScanName } from "@pages/project-details/project-data-management/editable-scan-name";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { getMemberNameById } from "@utils/member-utils";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];
  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
  /** Function to format date */
  formatDate: FormatDate;
  /** Workflow state. */
  state: WorkflowState;
  /** Callback to set the scans the user selected for removal. */
  setScansToRemove: React.Dispatch<React.SetStateAction<TableItem[]>>;
  /** Callback to set whether the confirmation dialog for removing scans is open. */
  setIsRemoveScansDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** Whether the screen width is small or narrower. */
  isScreenXsAndSmall: boolean;
  /** Whether the screen width is medium or narrower. */
  isScreenMdAndSmaller: boolean;
}

/**
 * Reduce the tooltip delay since it isn't obvious that the icons have a tooltip and we want to offer faster
 * feedback to the user this way.
 */
const ENTER_DELAY = 100;

/**
 * Returns an object with the Staging Area table columns:
 * - Each property key is the column name
 * - Each property value is a GridColDef object of the MUI data grid
 */
export function getDataManagementTableColumns({
  companyMembers,
  projectMembers,
  formatDate,
  state,
  setScansToRemove,
  setIsRemoveScansDialogOpen,
  isScreenXsAndSmall,
  isScreenMdAndSmaller,
}: Props): Record<string, GridColDef> {
  /** Handler for a click on the removal button for a single scan. */
  function onRemoveScan(item: TableItem): void {
    setScansToRemove([item]);
    setIsRemoveScansDialogOpen(true);
  }

  const colName: GridColDef = {
    field: TableHeaders.name,
    minWidth: 180,
    flex: 1.25,
    renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
      const name = params.row.entity.name;
      const additionalName = params.row.entity.additionalName;
      const tooltip =
        <Stack direction="column" spacing={0}>
          <Box component="var">{name}</Box>
          { additionalName &&
            <Box component="var" sx={{ color: sphereColors.gray400 }}>
              {additionalName}
            </Box>
          }
        </Stack>;

      return (
        // We can't use FaroTableTextCell due to the desired extended tooltip.
        <SphereTooltip
          component="var"
          title={tooltip}
          boxProps={{
            sx: {
              ...withEllipsis,
              color: sphereColors.gray800,
              fontWeight: "bold",
              width: "100%",
            },
          }}
          >
          <EditableScanName
            entity={params.row.entity}
            isEditable={params.row.entity.state !== "uploading"}
          />
        </SphereTooltip>
      );
    },
    valueGetter: (_, row: { entity: TableItem }) => row.entity.name,
    // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
    sortable: true,
    sortComparator: (v1, v2, cp1, cp2) =>
      faroTableComparator<string>(
        v1,
        v2,
        cp1,
        cp2,
        gridStringOrNumberComparator
      ),
  };

  const colCreatedBy: GridColDef = {
    field: TableHeaders.createdBy,
    minWidth: 180,
    flex: 1,
    renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
      return (
        <FaroTableMemberCell
          memberId={params.row.entity.createdBy}
          companyMembers={companyMembers}
          projectMembers={projectMembers}
        />
      );
    },
    valueGetter: (_, row: { entity: TableItem }) =>
      getMemberNameById({
        memberId: row.entity.createdBy,
        companyMembers,
        projectMembers,
      }),
    // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
    sortable: true,
    sortComparator: (v1, v2, cp1, cp2) =>
      faroTableComparator<string | undefined>(
        v1,
        v2,
        cp1,
        cp2,
        gridStringOrNumberComparator
      ),
  };

  const colCreatedAt: GridColDef = {
    field: TableHeaders.createdAt,
    minWidth: 160,
    flex: 0.75,
    type: "date",
    renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
      let date = formatDate(
        params.row.entity.createdAt,
        DateTime.DATETIME_MED
      );
      if (date === "") {
        date = "Unknown";
      }
      return <FaroTableTextCell text={date} />;
    },
    valueGetter: (_, row: { entity: TableItem }) =>
      new Date(row.entity.createdAt),

    // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
    sortable: true,
    sortComparator: (v1, v2, cp1, cp2) =>
      faroTableComparator<Date | undefined>(
        v1,
        v2,
        cp1,
        cp2,
        gridDateComparator
      ),
  };

  const colDeviceType: GridColDef = {
    field: TableHeaders.deviceType,
    minWidth: 100,
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
      const deviceType = getDeviceType(params.row.entity.deviceType);
      return <FaroTableTextCell text={deviceType} />;
    },
    valueGetter: (_, row: { entity: TableItem }) =>
      getDeviceType(row.entity.deviceType),
    // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
    sortable: true,
    sortComparator: (v1, v2, cp1, cp2) =>
      faroTableComparator<string>(
        v1,
        v2,
        cp1,
        cp2,
        gridStringOrNumberComparator
      ),
  };

  const colStatus: GridColDef = {
    field: TableHeaders.status,
    minWidth: 50,
    maxWidth: 100,
    flex: 0.25,
    // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
    sortable: true,
    renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
      switch (params.row.entity.state) {
        case "uploading":
          return (
            <SphereTooltip
              title="The scan is currently getting uploaded. Please wait."
              enterDelay={ENTER_DELAY}
              boxProps={{ sx: { display: "flex" } }}
            >
              <FaroButtonSpinner loadingTrackColor={sphereColors.gray100} size="24px" marginLeft="0px" />
            </SphereTooltip>
          );
        case "uploaded":
          return (
            <SphereTooltip
              title="The scan has been uploaded."
              enterDelay={ENTER_DELAY}
              boxProps={{ sx: { display: "flex" } }}
            >
              <SvgIcon inheritViewBox component={UnpublishedSvg} sx={{ color: sphereColors.blue500 }} />
            </SphereTooltip>
          );
        case "processing":
          return (
            <SphereTooltip
              title="The scan is currently getting processed. Please wait."
              enterDelay={ENTER_DELAY}
              boxProps={{ sx: { display: "flex" } }}
            >
              <FaroButtonSpinner loadingTrackColor={sphereColors.gray100} size="24px" marginLeft="0px" />
            </SphereTooltip>
          );
        case "unpublished":
          return (
            <SphereTooltip
              title="The project containing the scan has not been published yet."
              enterDelay={ENTER_DELAY}
              boxProps={{ sx: { display: "flex" } }}
            >
              <SvgIcon inheritViewBox component={UnpublishedSvg} sx={{ color: sphereColors.blue500 }} />
            </SphereTooltip>
          );
        case "published":
          return (
            <SphereTooltip
              title="The project containing the scan has been published."
              enterDelay={ENTER_DELAY}
              boxProps={{ sx: { display: "flex" } }}
            >
              <SvgIcon inheritViewBox component={PublishedSvg} sx={{ color: sphereColors.blue500 }} />
            </SphereTooltip>
          );
        case "error":
          return (
            <SphereTooltip
              title="An error has occurred!"
              enterDelay={ENTER_DELAY}
              boxProps={{ sx: { display: "flex" } }}
            >
              <SvgIcon inheritViewBox component={ErrorSvg} sx={{ color: sphereColors.red500 }} />
            </SphereTooltip>
          );
        case "start":
        default:
          return (
            <SphereTooltip
              title="The scan will be uploaded and processed automatically. Please wait."
              enterDelay={ENTER_DELAY}
              boxProps={{ sx: { display: "flex" } }}
            >
              <SvgIcon inheritViewBox component={StartSvg} sx={{ color: sphereColors.gray500 }} />
            </SphereTooltip>
          );
      }
    },
  };

  const colActions: GridColDef = {
    field: TableHeaders.actions,
    type: "actions",
    align: "right",
    minWidth: 50,
    maxWidth: 50,
    flex: 0.25,
    renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
      const isInProgress = state === "uploading" || state === "processing" || state === "registering" || state === "publishing";
      const isDisabled = isInProgress || !canTableItemBeRemoved(params.row.entity);
      const tooltip = isDisabled ?
        "The scan can't be removed due to the current state. Please try again later." :
        "Remove scan from project";

      return (
        <SphereTooltip
          title={tooltip}
          enterDelay={ENTER_DELAY}
          dataTestId="sa-remove-single-scan"
          boxProps={{
            className: SHOW_ON_HOVER_CLASS,
            sx: {
              textAlign: "right",
              // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
              visibility: "hidden",
            },
          }}
        >
          <FaroIconButton
            isDisabled={isDisabled}
            aria-label="more"
            aria-haspopup="true"
            component={GenericCloseSvg}
            onClick={() => onRemoveScan(params.row.entity)}
          />
        </SphereTooltip>
      );
    },
  };

  // Keep in sync with equivalent code part in data-management-table.tsx.
  if (isScreenXsAndSmall) {
    return {
      [TableHeaders.name]: colName,
      [TableHeaders.deviceType]: colDeviceType,
      [TableHeaders.status]: colStatus,
      [TableHeaders.actions]: colActions,
    };
  } else if (isScreenMdAndSmaller) {
    return {
      [TableHeaders.name]: colName,
      [TableHeaders.createdBy]: colCreatedBy,
      [TableHeaders.deviceType]: colDeviceType,
      [TableHeaders.status]: colStatus,
      [TableHeaders.actions]: colActions,
    };
  } else {
    return {
      [TableHeaders.name]: colName,
      [TableHeaders.createdBy]: colCreatedBy,
      [TableHeaders.createdAt]: colCreatedAt,
      [TableHeaders.deviceType]: colDeviceType,
      [TableHeaders.status]: colStatus,
      [TableHeaders.actions]: colActions,
    };
  }
}
