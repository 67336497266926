import { CreditTransaction } from "@custom-types/credit-types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { creditTransactionAdapter } from "@store/credit-transactions/credit-transactions-slice";
import { CreditTransactionState } from "@store/credit-transactions/credit-transactions-slice-types";

/** Returns all the credit transactions */
export const creditTransactionsSelector: (
  state: RootState
) => CreditTransaction[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const creditTransactions = creditTransactionAdapter
      .getSelectors()
      .selectAll(state.creditTransactions);

    return creditTransactions;
  }
);

/**
 * Returns the fetching properties of the credit transactions slice.
 */
export const fetchingTransactionsFlagsSelector: (
  state: RootState
) => CreditTransactionState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.creditTransactions.fetching;
  }
);
