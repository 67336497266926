import GenericRight from "@assets/icons/generic-right_l.svg?react";
import { CompanyAvatar } from "@components/common/company-avatar";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import {
  fetchingSdbCompanyFlagsSelector,
  selectedSdbCompanySelector,
} from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { sidebarSelector } from "@store/ui/ui-selector";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import {
  SidebarEvents,
  SidebarTabs,
} from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";

interface Props {
  /** Whether the user has access to many sdb companies or not */
  hasManySdbCompanies: boolean;

  /** Is the SdbCompany menu open or not */
  isOpen: boolean;

  /** Callback function when user clicks on a sidebar item */
  handleClick(event: React.MouseEvent<HTMLElement>): void;
}

// TODO: This component should not handle logics related to SdbCompanyMenu like isOpen or handleClick
// This should be fixed as part of: https://faro01.atlassian.net/browse/HBD-323
/**
 * Renders the item related to sdbCompanies (company/workspace) in the sidebar
 */
export function SdbCompanySidebarItem({
  hasManySdbCompanies,
  isOpen,
  handleClick,
}: Props): JSX.Element {
  const { isFullSize } = useAppSelector(sidebarSelector);
  const selectedCompany = useAppSelector(selectedSdbCompanySelector);
  const { trackEvent } = useTrackEvent();
  const { isFetchingSdbCompanies } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );

  if (!selectedCompany && isFetchingSdbCompanies) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: "100%",
          height: "48px",
          backgroundColor: sphereColors.gray200,
        }}
      />
    );
  }

  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        marginLeft: "3px",
        boxShadow: isOpen ? `-3px 0 0 0 ${sphereColors.blue500}` : "none",
        backgroundColor: isOpen ? sphereColors.pureWhite : sphereColors.gray50,
        pointerEvents: hasManySdbCompanies ? "all" : "none",
        paddingTop: "9px",
      }}
      data-testid="SIDEBAR_WORKSPACE_MAIN_BUTTON"
      aria-controls={isOpen ? "workspaces-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? "true" : undefined}
      onClick={(ev) => {
        trackEvent({
          name: SidebarEvents.openTab,
          props: { tab: SidebarTabs.workspace },
        });
        handleClick(ev);
      }}
    >
      <ListItemButton
        selected={false}
        disabled={!hasManySdbCompanies}
        disableRipple
        sx={{
          minHeight: 48,
          justifyContent: "initial",
          px: 2.5,
          pl: 2.25,
          backgroundColor: isOpen
            ? sphereColors.pureWhite
            : sphereColors.gray50,
          "&.Mui-disabled": {
            // Even when the item is disabled do not show it opaque because it just means
            // that the menu cannot be opened because the user only has access to one sdbCompany.
            opacity: 1,
            pointerEvents: "none",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "24px",
            width: "24px",
            fill: sphereColors.blue500,
          }}
        >
          <CompanyAvatar
            name={selectedCompany?.name ?? ""}
            color={sphereColors.blue500}
            size="large"
          />
        </ListItemIcon>
        {isFullSize && (
          <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
              <ListItemText
                className={NO_TRANSLATE_CLASS}
                primary={selectedCompany?.name ?? ""}
                sx={{
                  opacity: 1,
                  fontSize: "14px",
                  "& span": {
                    ...withEllipsis,
                    width: hasManySdbCompanies ? "135px" : "145px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
            {/* Only show right icon when the menu can be opened because the user has access to multiple workspaces */}
            {hasManySdbCompanies && (
              <Box
                component="div"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <GenericRight style={{ height: "16px", width: "16px" }} />
              </Box>
            )}
          </Box>
        )}
      </ListItemButton>
    </ListItem>
  );
}
