import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { FaroTextMenuButton } from "@components/common/faro-text-menu-button";
import { Box, FormControl, Grid } from "@mui/material";
import { colorConst, EDecimalToHex, sphereColors } from "@styles/common-colors";
import {
  DEFAULT_BOLD_TEXT_FONT_WEIGHT,
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TEXT_FONT_SIZE,
  DEFAULT_TEXT_FONT_WEIGHT,
} from "@styles/common-styles";
import { addTransparency } from "@utils/ui-utils";
import GenericCheck from "@assets/icons/generic-check_l.svg?react";
import { useMediaQueryList } from "@hooks/use-media-query";
import { useAccountSettingsLayout } from "@pages/account-settings/account-settings-layout/account-settings-layout-utils";

export interface IItemProps<T = string> {
  /** The value representing the item */
  value: T;

  /** The label that is shown for the item */
  label: string;

  /** Any description shown  */
  description?: string;
}

interface Props<T = string> {
  /** If true it will show the description in the title as well */
  shouldShowDescriptionInTitle?: boolean;

  /** List or items shown in dropdown */
  items: IItemProps<T>[];

  /** The selected item */
  selectedItem: IItemProps<T>;

  /** On click handler for when selecting an item */
  onClickHandler: (event: T) => Promise<void> | void;
}

/** A dropdown to show for account settings */
export function AccountSettingsDropdown({
  shouldShowDescriptionInTitle = false,
  items,
  selectedItem,
  onClickHandler,
}: Props): JSX.Element {
  const { isHoverSupported, isScreenSmOrSmaller } = useMediaQueryList();
  const { accountSettingsFontSize, accountSettingsFontWeight } =
    useAccountSettingsLayout();

  const ButtonTitle = (
    <Grid container justifyContent="space-between" alignItems="center">
      <Box
        sx={{
          fontSize: accountSettingsFontSize,
          fontWeight: accountSettingsFontWeight,
        }}
      >
        {selectedItem.label}
      </Box>
      {shouldShowDescriptionInTitle && !isScreenSmOrSmaller && (
        <Box
          sx={{
            fontSize: DEFAULT_TEXT_FONT_SIZE,
            fontWeight: DEFAULT_TEXT_FONT_WEIGHT,
          }}
        >
          {selectedItem.description}
        </Box>
      )}
    </Grid>
  );

  return (
    <FormControl
      sx={{
        width: "100%",
      }}
    >
      <FaroTextMenuButton
        buttonTitle={ButtonTitle}
        isMenuWidthEqualToButton={true}
        buttonSx={(isMenuOpen) => {
          return {
            color: isMenuOpen ? sphereColors.blue500 : sphereColors.gray800,
            width: "100%",
            textOverflow: "ellipsis",
            justifyContent: "space-between",
            "& .MuiButton-icon": {
              "& svg": { width: "16px", height: "16px" },
            },

            // Not focused
            "&:not(&:focus)": {
              borderBottom: isHoverSupported
                ? "none"
                : `1px solid ${sphereColors.gray200}`,

              "& .MuiButton-icon": {
                visibility: isHoverSupported ? "hidden" : "visible",
              },

              // Hover style. Show underline and icon
              "&:hover": {
                backgroundColor: addTransparency({
                  color: sphereColors.gray500,
                  alpha: EDecimalToHex.thirtyEight,
                }),
                color: sphereColors.blue500,

                "& .MuiButton-icon": {
                  visibility: "visible",
                },
              },
            },

            // Focused
            "&:focus": {
              borderBottom: `2px solid ${sphereColors.blue500}`,
              color: sphereColors.blue500,
            },
          };
        }}
      >
        {items.map((item) => {
          const isSelectedItem = item.value === selectedItem.value;
          return (
            <FaroMenuItem
              data-my-value={item.value}
              key={item.value}
              value={item.value}
              onClick={(event) => {
                if (event.currentTarget.dataset.myValue) {
                  void onClickHandler(event.currentTarget.dataset.myValue);
                }
              }}
              sx={{
                display: "block",
                padding: "12px",
                mb: "2px",
                whiteSpace: "normal",
                "&:hover": {
                  backgroundColor: addTransparency({
                    color: sphereColors.gray500,
                    alpha: EDecimalToHex.twentySix,
                  }),

                  // Set menu item label color to blue500 on hover
                  "& .item-label": {
                    color: sphereColors.blue500,
                  },
                },
                "&.MuiButtonBase-root": {
                  backgroundColor: isSelectedItem
                    ? addTransparency({
                        color: sphereColors.gray500,
                        alpha: EDecimalToHex.thirtyEight,
                      })
                    : "transparent",

                  "&:hover": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.thirtyEight,
                    }),
                  },

                  // Overrides default style for ".Mui-focusVisible" class
                  "&.Mui-focusVisible": {
                    backgroundColor: addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.thirtyEight,
                    }),
                  },
                },
              }}
            >
              {/* Title and icon */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "9px",
                }}
              >
                <Box
                  className="item-label"
                  sx={{
                    fontSize: DEFAULT_INPUT_FONT_SIZE,
                    fontWeight: DEFAULT_BOLD_TEXT_FONT_WEIGHT,
                    color: isSelectedItem
                      ? sphereColors.blue500
                      : colorConst.normalFont,
                  }}
                >
                  {item.label}
                </Box>

                {isSelectedItem && (
                  <GenericCheck
                    width="21px"
                    height="21px"
                    fill={sphereColors.blue500}
                  />
                )}
              </Box>

              {/* Description */}
              <Box
                sx={{
                  fontSize: DEFAULT_TEXT_FONT_SIZE,
                  fontWeight: DEFAULT_TEXT_FONT_WEIGHT,
                  color: isSelectedItem
                    ? sphereColors.blue400
                    : sphereColors.gray600,
                }}
              >
                {item.description}
              </Box>
            </FaroMenuItem>
          );
        })}
      </FaroTextMenuButton>
    </FormControl>
  );
}
