import { SdbTeam } from "@custom-types/teams-types";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";

export const MOCK_TEAMS: SphereDashboardAPITypes.ITeam[] = [
  {
    id: "1",
    name: "Team 1",
    identity: "1",
    email: "test-ug-1",
    isManaged: true,
    memberCount: 1,
    createdAt: "2023-09-17T12:00:00Z",
    modifiedAt: "2023-09-17T12:00:00Z",
    sampleMembers: [
      {
        userResponse: {
          id: "user1",
          identity: "user1",
          lastName: "lastName",
          firstName: "firstName",
          email: "mockEmail@faro.com",
        },
        addedAt: "2023-09-17T12:00:00Z",
      },
    ],
  },
];

export const MOCK_TEAMS_WITH_ROLES: SdbTeam[] = [
  {
    id: "1",
    name: "Team 1",
    identity: "1",
    email: "test-ug-1",
    isManaged: true,
    memberCount: 1,
    createdAt: "2023-09-17T12:00:00Z",
    modifiedAt: "2023-09-17T12:00:00Z",
    role: CoreAPITypes.EUserCompanyRole.member,
    sampleMembers: [
      {
        userResponse: {
          id: "user1",
          identity: "user1",
          lastName: "lastName",
          firstName: "firstName",
          email: "mockEmail@faro.com",
        },
        addedAt: "2023-09-17T12:00:00Z",
      },
    ],
  },
];


/** The groups that the team belongs to */
export const MOCK_TEAM_GROUPS: SphereDashboardAPITypes.CompanyMemberGroup[] = [
  {
    id: "5321fc4a-f701-4362-a46a-b2f1b375781e",
    createdAt: 1700220018410,
    name: "Osvaldo Picazo Group",
    imageUrl: "",
    thumbnailUrl: "",
  },
  {
    id: "484b581e-bf67-4b59-b4f4-f0859a4bed8c",
    createdAt: 1689174188011,
    name: "Demo",
  },
];

/** Returns the team as member type */
export const MOCK_TEAM_AS_MEMBER: SphereDashboardAPITypes.ICompanyMemberDetails =
  {
    identity: "1234",
    id: "1234",
    name: "A very nice team",
    email: "no-reply.a-very-nice-team@faro.com",
    projectRoles: [
      {
        id: "cc3522c9-a6ea-4b98-b040-e7e2eda410b1",
        name: "Allstate Arena",
        archivingState: APITypes.ArchivingState.UNARCHIVED,
        role: CoreAPITypes.EUserProjectRole.admin,
      },
      {
        id: "748775e4-8475-4c3a-9d73-f2d10f6a0daa",
        name: "Archived Project",
        archivingState: APITypes.ArchivingState.ARCHIVED,
        role: CoreAPITypes.EUserProjectRole.editor,
      },
    ],
    role: CoreAPITypes.EUserCompanyRole.companyViewer,
    permissions: [],
  };
