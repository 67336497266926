import { UserProfile } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "@pages/account-settings/user-provider";

/** Custom hook returning the account settings close handler */
export function useCloseAccountSettings(): { onCloseButtonClick(): void } {
  const { trackEvent } = useTrackEvent();
  const navigate = useNavigate();
  const { navigateToRoot } = useAppNavigation();
  const { originUrl } = useUserContext();

  /**
   * Clicking on close will navigate to the origin URL from where the user opened the account settings page.
   * If the original URL is not available then clicking on close will navigate to the root route.
   */
  function onCloseButtonClick(): void {
    trackEvent({
      name: UserProfile.closeAccount,
    });

    if (originUrl) {
      navigate(originUrl);
    } else {
      navigateToRoot();
    }
  }

  return { onCloseButtonClick };
}
