import { useCallback } from "react";
import { ProjectApi } from "@api/project-api/project-api";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useToast } from "@hooks/use-toast";
import { GUID } from "@faro-lotv/foundation";
import { sentryCaptureError } from "@src/utils/sentry-utils";
import { Box, Stack } from "@mui/material";
import { COOKIES_ERROR_MESSAGE } from "@utils/core-file-uploader";
import { tryToClearCookies } from "@utils/cookie-utils";
import { useSnackbar } from "notistack";
import { runtimeConfig } from "@src/runtime-config";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { ButtonEvents } from "@utils/track-event/track-event-list";

type ReturnFunction = (
  projectApiClient: ProjectApi,
  captureTreeRevisionId: GUID,
  areCookiesToBlame: boolean,
  errorDetails: string,
) => Promise<void>;

/** Handles the case when all uploads failed. */
export function useHandleAllUploadsFailed(): ReturnFunction {
  const { showToast } = useToast();
  const { closeSnackbar } = useSnackbar();
  const { trackEvent } = useTrackEvent();
  const cancelRevision = useCancelRevision();

  return useCallback(
    async (
      projectApiClient: ProjectApi,
      captureTreeRevisionId: GUID,
      areCookiesToBlame: boolean,
      errorDetails: string
    ): Promise<void> => {
      const msg = "All Blink scan imports failed.";
      const domain = `holobuilder.${runtimeConfig.appRegion}`;

      const snackBarId = showToast({
        message: (
          <Stack direction="column" spacing={0}>
            <Box>{msg}</Box>
            {areCookiesToBlame ? (
              // The cookies error is unlikely if some uploads succeeded, but likely if all uploads failed.
              <Box sx={{ mt: "16px", mb: "16px" }}>
                {COOKIES_ERROR_MESSAGE}<br/>
                Afterwards, you can try to upload the Blink folder again.
              </Box>
            ) : (
              // Keep message in sync with FailedUploadsToastContent.
              <>
                <Box>You can try to upload the Blink folder again.</Box>
                {!!errorDetails && (
                  <Box sx={{ mt: "16px" }}>{errorDetails}</Box>
                )}
              </>
            )}
          </Stack>
        ),
        type: "error",
        actionButton: areCookiesToBlame ? {
          title: <span>Clear Cookies for <var style={{textTransform: "none"}}>"{domain}"</var></span>,
          onClick: () => {
            const oldSize = document.cookie.length;
            tryToClearCookies();
            const newSize = document.cookie.length;
            const kb = 1024;
            const kbCleared = ((oldSize - newSize) / kb).toFixed(1);

            closeSnackbar(snackBarId);
            showToast({
              message: (
                <Box>
                  Cookies cleared (<var>{kbCleared}</var> kB).<br/><br/>
                  If the error occurs again, please try clearing cookies for <var>"{domain}"</var> in
                  your browser settings instead.
                </Box>
              ),
              type: "info",
              shouldAutoHide: false,
            });

            trackEvent({
              name: ButtonEvents.clearCookies,
              props: { oldSize, newSize, source: "handleAllUploadsFailed" },
            });
          },
        } : undefined,
      });

      sentryCaptureError({
        error: msg,
        title: "UploadError: All Blink scan imports failed",
        data: { captureTreeRevisionId, areCookiesToBlame, errorDetails },
      });

      await cancelRevision(projectApiClient, captureTreeRevisionId);
    },
    [cancelRevision, closeSnackbar, showToast, trackEvent]
  );
}
