import { GUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntity,
  // eslint-disable-next-line no-restricted-imports -- import CaptureTreeEntityRevision from @faro-lotv/service-wires only here
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
  CreateOrUpdateScanEntityParams,
  // eslint-disable-next-line no-restricted-imports -- import RegistrationRevision from @faro-lotv/service-wires only here
  RegistrationRevision,
} from "@faro-lotv/service-wires";
import { UUID } from "@stellar/api-logic/dist/api/core-api/api-types";

/** Base capture tree entity */
export type BaseCaptureTreeEntity =
  | CaptureTreeEntity
  | CaptureTreeEntityRevision;

/** A capture tree entity of type root */
export type RootEntity<T extends BaseCaptureTreeEntity = CaptureTreeEntity> = T & {
  type: CaptureTreeEntityType.root;
};

/** A capture tree entity of type cluster */
export type ClusterEntity<T extends BaseCaptureTreeEntity = CaptureTreeEntity> = T & {
  type: CaptureTreeEntityType.cluster;
};

/** Possible types of a capture tree scan entity */
export type ScanEntityType =
  | CaptureTreeEntityType.elsScan
  | CaptureTreeEntityType.focusScan
  | CaptureTreeEntityType.orbisScan
  | CaptureTreeEntityType.orbisFlashScan
  | CaptureTreeEntityType.pCloudUploadScan
  | CaptureTreeEntityType.siteScapeScan
  | CaptureTreeEntityType.structuredScan
  | CaptureTreeEntityType.unstructuredScan;

/** List of possible types of a capture tree scan entity */
export const SCAN_ENTITY_TYPES: { [key in ScanEntityType]: key } = {
  [CaptureTreeEntityType.focusScan]: CaptureTreeEntityType.focusScan,
  [CaptureTreeEntityType.orbisScan]: CaptureTreeEntityType.orbisScan,
  [CaptureTreeEntityType.orbisFlashScan]: CaptureTreeEntityType.orbisFlashScan,
  [CaptureTreeEntityType.pCloudUploadScan]: CaptureTreeEntityType.pCloudUploadScan,
  [CaptureTreeEntityType.elsScan]: CaptureTreeEntityType.elsScan,
  [CaptureTreeEntityType.siteScapeScan]: CaptureTreeEntityType.siteScapeScan,
  [CaptureTreeEntityType.structuredScan]: CaptureTreeEntityType.structuredScan,
  [CaptureTreeEntityType.unstructuredScan]: CaptureTreeEntityType.unstructuredScan,
};

/** A capture tree entity of type scan */
export type ScanEntity<T extends BaseCaptureTreeEntity = CaptureTreeEntity> = T & {
  type: ScanEntityType;
  // The `pointClouds´ attribute is mandatory for all ScanEntity types (but not for RootEntity or ClusterEntity).
  pointClouds: NonNullable<Required<T["pointClouds"]>>;
};

/** Stricter version of CreateOrUpdateScanEntityParams for creation, so we need less type assertions. */
export interface CreateScanEntityParams extends CreateOrUpdateScanEntityParams {
  id: GUID;
  pointClouds: NonNullable<Required<CreateOrUpdateScanEntityParams["pointClouds"]>>;
}

/** Various maps to efficiently find clusters and scans by their name-path, id or externalId. */
export interface EntityMaps<T extends BaseCaptureTreeEntity = CaptureTreeEntity> {
  /** Map from "id" to Capture Tree entity. */
  entityById: Record<GUID, T>;
  /** Map from name-path (e.g. `"root","Cluster","SubCluster"`) to Capture Tree entity "id". */
  pathById: Record<GUID, string>;
  /** Inverse of pathById. */
  idByPath: Record<string, GUID>;
  /** Map from "externalId" to Capture Tree "id". */
  scanIdByUuid: Record<UUID, GUID>;
}

/** Aliases for the original misleading names to make devs' lives less confusing. */
export type CaptureTreeRevision = RegistrationRevision;
export type CaptureTreeEntityWithRevisionStatus = CaptureTreeEntityRevision;
