import {
  CanceledUploadFile,
  UploadedFile,
  UploadFailedFile,
} from "@custom-types/file-upload-types";
import {
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  CreateScanEntitiesParams,
} from "@faro-lotv/service-wires";
import { getIdentityPose } from "@utils/capture-tree/capture-tree-utils";

export const MOCK_UPLOADED_ELS_SCAN_FILES: UploadedFile[] = [
  {
    id: "b07750ce-66b8-4579-bbc4-649c0f88d589",
    fileName: "dataset_01.gls",
    fileSize: 1179205632,
    fileType: "gls",
    downloadUrl: "someUrl_b07750ce-66b8-4579-bbc4-649c0f88d589",
    md5: "339074c4fa49730bca285a40299d74ff",
  },
  {
    id: "037dfe18-519b-4a96-8aa8-a5f834c82f98",
    fileName: "dataset_02.gls",
    fileSize: 79205632,
    fileType: "gls",
    downloadUrl: "someUrl_037dfe18-519b-4a96-8aa8-a5f834c82f98",
    md5: "be040a708cd0c7b42c86b283a6eebaf8",
  },
  {
    id: "521b7667-3fca-494b-8267-e8244ebfd21e",
    fileName: "dataset_03.gls",
    fileSize: 9205632,
    fileType: "gls",
    downloadUrl: "someUrl_521b7667-3fca-494b-8267-e8244ebfd21e",
    md5: "85be3a2e015bdd863a06b3f12e0a3dd1",
  },
];

export const MOCK_UPLOADED_ELS_SCAN_FILES_PARAMS: CreateScanEntitiesParams["requestBody"] =
  [
    {
      createdAt: "1970-01-01T00:00:00.000Z",
      parentId: "fb7e31ca-4a47-4123-beb4-c5957b6a71c4",
      type: CaptureTreeEntityType.elsScan,
      name: MOCK_UPLOADED_ELS_SCAN_FILES[0].fileName,
      pose: getIdentityPose(),
      pointClouds: [
        {
          createdAt: "1970-01-01T00:00:00.000Z",
          externalId: "e3ccd201-f408-4f37-8e86-d3e9d1ca3d62",
          type: CaptureTreePointCloudType.elsRaw,
          uri: MOCK_UPLOADED_ELS_SCAN_FILES[0].downloadUrl,
          md5Hash: MOCK_UPLOADED_ELS_SCAN_FILES[0].md5,
          fileSize: MOCK_UPLOADED_ELS_SCAN_FILES[0].fileSize,
          fileName: MOCK_UPLOADED_ELS_SCAN_FILES[0].fileName,
        },
      ],
    },
    {
      createdAt: "1970-01-01T00:00:00.000Z",
      parentId: "fb7e31ca-4a47-4123-beb4-c5957b6a71c4",
      type: CaptureTreeEntityType.elsScan,
      name: MOCK_UPLOADED_ELS_SCAN_FILES[1].fileName,
      pose: getIdentityPose(),
      pointClouds: [
        {
          createdAt: "1970-01-01T00:00:00.000Z",
          externalId: "e3ccd201-f408-4f37-8e86-d3e9d1ca3d62",
          type: CaptureTreePointCloudType.elsRaw,
          uri: MOCK_UPLOADED_ELS_SCAN_FILES[1].downloadUrl,
          md5Hash: MOCK_UPLOADED_ELS_SCAN_FILES[1].md5,
          fileSize: MOCK_UPLOADED_ELS_SCAN_FILES[1].fileSize,
          fileName: MOCK_UPLOADED_ELS_SCAN_FILES[1].fileName,
        },
      ],
    },
    {
      createdAt: "1970-01-01T00:00:00.000Z",
      parentId: "fb7e31ca-4a47-4123-beb4-c5957b6a71c4",
      type: CaptureTreeEntityType.elsScan,
      name: MOCK_UPLOADED_ELS_SCAN_FILES[2].fileName,
      pose: getIdentityPose(),
      pointClouds: [
        {
          createdAt: "1970-01-01T00:00:00.000Z",
          externalId: "e3ccd201-f408-4f37-8e86-d3e9d1ca3d62",
          type: CaptureTreePointCloudType.elsRaw,
          uri: MOCK_UPLOADED_ELS_SCAN_FILES[2].downloadUrl,
          md5Hash: MOCK_UPLOADED_ELS_SCAN_FILES[2].md5,
          fileSize: MOCK_UPLOADED_ELS_SCAN_FILES[2].fileSize,
          fileName: MOCK_UPLOADED_ELS_SCAN_FILES[2].fileName,
        },
      ],
    },
  ];

export const MOCK_FAILED_UPLOADED_ELS_SCAN_FILES: UploadFailedFile[] = [
  {
    id: "00fe6ac8-988f-455a-b51b-b51659083f0c",
    fileName: "dataset_04.gls",
    errorMessage: "Some error",
  },
  {
    id: "9d392d0a-e43d-4245-bb91-d18411dbdac2",
    fileName: "dataset_05.gls",
    errorMessage: "Unknown error",
  },
  {
    id: "5974c4cf-0d14-4f91-aa8a-cfc4344533fb",
    fileName: "dataset_06.gls",
    errorMessage: "",
  },
];

export const MOCK_CANCELED_UPLOADED_ELS_SCAN_FILES: CanceledUploadFile[] = [
  {
    id: "00fe6ac8-988f-455a-b51b-b51659083f0c",
    fileName: "dataset_07.gls",
  },
  {
    id: "9d392d0a-e43d-4245-bb91-d18411dbdac2",
    fileName: "dataset_08.gls",
  },
  {
    id: "5974c4cf-0d14-4f91-aa8a-cfc4344533fb",
    fileName: "dataset_09.gls",
  },
];
