import {
  sendAuthenticatedRequest,
  SendAuthenticatedRequestParams,
  Token,
} from "@faro-lotv/service-wires";
import {
  PhotogrammetryTaskPayload,
  PhotogrammetryApiClientConfig,
} from "@src/api/photogrammetry-api/photogrammetry-api-types";

/**
 * Client for the Photogrammetry API:
 * https://dev.azure.com/faro-connect/Photogrammetry/_git/Pre-Alignment.SphereXG.trigger.function
 */
export class PhotogrammetryApiClient {
  protected baseUrl: string;
  protected clientId: string;
  protected tokenProvider: () => Promise<Token>;
  protected projectId: string;

  /**
   * @param {PhotogrammetryApiClientConfig} config - The configuration for the API client.
   */
  constructor({
    baseUrl,
    clientId,
    tokenProvider,
    projectId,
  }: PhotogrammetryApiClientConfig) {
    this.baseUrl = baseUrl;
    this.clientId = clientId;
    this.tokenProvider = tokenProvider;
    this.projectId = projectId;
  }

  /**
   * Triggers photogrammetry processing for a dataset.
   * @param {PhotogrammetryTaskPayload} payload - The request payload.
   * @returns {Promise<Response>} The response from the photogrammetry trigger endpoint.
   */
  public async triggerPhotogrammetryTask(
    payload: PhotogrammetryTaskPayload
  ): Promise<Response> {
    const requestParams: SendAuthenticatedRequestParams = {
      baseUrl: this.baseUrl,
      path: "api/photogrammetry-trigger",
      tokenProvider: this.tokenProvider,
      requestBody: JSON.stringify(payload),
      httpMethod: "POST",
    };
    return await sendAuthenticatedRequest(requestParams);
  }

  public getBaseUrl(): string {
    return this.baseUrl;
  }

  public getClientId(): string {
    return this.clientId;
  }

  public getProjectId(): string {
    return this.projectId;
  }
}
