import { SphereAvatar } from "@components/header/sphere-avatar";
import { getPrettyName, getUserInitials } from "@utils/user-utils";
import { Box, Grid, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import {
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TEXT_FONT_SIZE,
  withEllipsisTwoLines,
} from "@styles/common-styles";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { useCloseAccountSettings } from "@pages/account-settings/use-close-account-settings";
import { BasicTabProps } from "@components/common/tabs/tabs-type";
import { SphereDivider } from "@components/common/sphere-divider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroTextButton } from "@components/common/faro-text-button";
import { AccountSettingsTabs } from "@router/route-params";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { UserMenuEvents } from "@utils/track-event/track-event-list";
import { useAuthContext } from "@context-providers/auth/auth-context";
import { useUserContext } from "@pages/account-settings/user-provider";

interface Props {
  /** List of available tabs */
  tabs: Record<AccountSettingsTabs, BasicTabProps<AccountSettingsTabs>>;
}

/** The mobile layout for account settings tab selection */
export function AccountSettingsMobileTabSelection({
  tabs,
}: Props): JSX.Element {
  const { user } = useUserContext();
  const { onCloseButtonClick } = useCloseAccountSettings();
  const { trackEvent } = useTrackEvent();
  const { logout } = useAuthContext();

  return (
    <>
      {/* Header for the tab selection */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="nowrap"
        sx={{ px: "15px", mt: "45px" }}
      >
        <Grid container flexWrap="nowrap" alignItems="center">
          <SphereAvatar
            src={user.profileImageUrl}
            initials={getUserInitials(user)}
            icon={undefined}
            shouldUseSimplifiedAvatar={false}
            shouldHideWhiteRim={true}
            size="large"
          />
          <Grid item container direction="column" sx={{ ml: "20px" }}>
            <Typography
              sx={{
                color: sphereColors.gray800,
                fontSize: DEFAULT_INPUT_FONT_SIZE,
                ...withEllipsisTwoLines,
              }}
            >
              <var>{getPrettyName(user)}</var>
            </Typography>
            <Typography
              sx={{
                color: sphereColors.gray600,
                fontSize: DEFAULT_TEXT_FONT_SIZE,
                ...withEllipsisTwoLines,
              }}
            >
              <var>{user.mailAddress}</var>
            </Typography>
          </Grid>
        </Grid>
        <FaroIconButton
          component={CloseIcon}
          onClick={onCloseButtonClick}
          iconButtonProps={{
            // Adjust alignment of the close icon in small screen
            sx: {
              width: "2rem",
              height: "2rem",
            },
          }}
        />
      </Grid>

      {/* Body for the tab selection */}
      <Box sx={{ mt: "40px", mx: "15px" }}>
        {Object.values(tabs).map((tab, index) => (
          <FaroTextButton
            key={index}
            sx={{
              color: sphereColors.gray800,
              fontSize: DEFAULT_INPUT_FONT_SIZE,
              p: "8px",
              mb: "8px",
              width: "100%",
              justifyContent: "space-between",
            }}
            endIcon={<ChevronRightIcon />}
            to={tab.routeId}
            onClick={() => {
              trackEvent({
                name: OpenTabEvents.openAccountSettingsTab,
                props: {
                  tab: tab.routeId,
                },
              });
            }}
          >
            {tab.label}
          </FaroTextButton>
        ))}
        <SphereDivider sx={{ borderColor: sphereColors.gray200, my: "10px" }} />
        <FaroTextButton
          sx={{
            color: sphereColors.gray800,
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            width: "100%",
            justifyContent: "flex-start",
            p: "8px",
            mt: "4px",
          }}
          onClick={() => {
            trackEvent({
              name: UserMenuEvents.logOut,
              props: {
                from: "account-settings",
              },
            });
            logout();
          }}
        >
          Log out
        </FaroTextButton>
      </Box>
    </>
  );
}
