import { sphereColors } from "@styles/common-colors";
import { FaroToggleButton } from "@components/common/toggle/faro-toggle-button";
import { alpha, Box, SxProps, Theme } from "@mui/material";
import { FaroButtonSizes } from "@faro-lotv/flat-ui";


export interface ToggleViewOption<T> {
  /** The label to display for this option */
  label: string;

  /** The value associated with this option */
  value: T;
}

interface ToggleViewProps<T> {
  /** The options to display in the toggle view */
  options: ToggleViewOption<T>[];

  /** The function to call when the view mode changes */
  onChange: (value: T) => void;

  /** The current view mode */
  currentViewMode: T;

  /** Additional styles to apply to the toggle view */
  sx?: SxProps<Theme>;

  /**
   *  The size of the toggle buttons
   * @default "s"
   * accepted values: "s", "m", "l"
   */
  size?: FaroButtonSizes;

  /** Test ID for testing purposes */
  testId?: string;
 
  /**
   * Optional function to generate a unique key for each option.
   * Useful when dealing with complex objects as values.
   * @param value - The value of the option
   * @returns A string or number to be used as the React key
   * @default String(value)
   * @example
   * getOptionKey: (value) => value.id // e.g. for objects with an id field
   */
  getOptionKey?: (value: T) => string | number;

}

/**
 * A generic toggle view component that allows switching between different options.
 * @template T - The type of values for the toggle options
 */
export function FaroToggleView<T>({
  options,
  onChange,
  currentViewMode,
  getOptionKey = String,
  sx,
  size = FaroButtonSizes.s,
  testId = "faro-toggle-view",

}: ToggleViewProps<T>): JSX.Element | undefined {
  if (options.length === 0) {
    return undefined;
  }

  return (
    <Box
      data-testid={testId}
      sx={{
        display: "flex",
        alignItems: "center",
        border: `1px solid ${sphereColors.gray50}`,
        borderRadius: "2px",
        height: "36px",
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        background: alpha(sphereColors.gray500, 0.1),
        width: "100%",
        overflow: "hidden",
        ...sx,
      }}
    >
      {options.map((option) => (
        <FaroToggleButton
          key={getOptionKey(option.value)}
          isSelected={currentViewMode === option.value}
          onClick={() => onChange(option.value)}
          size={size}
          testId={`${testId}-option-${option.value}`}
        >
          {option.label}
        </FaroToggleButton>
      ))}
    </Box>
  );
}
