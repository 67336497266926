import { Box, Typography } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";
import {
  getMarkupAssigneeMember,
  getMarkupAssigneeName,
} from "@pages/project-details/project-markups/markup-assignee-utils";
import { CoreAPITypes } from "@stellar/api-logic";
import { useMemo, useState, MouseEvent, memo } from "react";
import UserNew from "@assets/icons/new/user-new_18px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { sphereColors } from "@styles/common-colors";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { currentUserSelector } from "@store/user/user-selector";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { getUserInitials, getPendingUserIcon } from "@utils/user-utils";
import { useAppSelector } from "@store/store-helper";
import { MarkupAssigneeEditable } from "@pages/project-details/project-markups/editable/markup-assignee-editable";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { useMembersUtils } from "@hooks/use-member-utils";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { BaseMarkupColumnProps } from "@custom-types/project-markups-types";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { cloneDeep, findIndex } from "lodash";

interface Props extends BaseMarkupColumnProps {
  /** If true removes the white rim between the avatar and the blue wrapper when shouldUseSimplifiedAvatar is false */
  shouldHideWhiteRim?: boolean;
}

/** Used to control the visibility on hover */
const MARKUP_ASSIGNEE_EDIT_ICON_CLASS = "assignee_edit";

/**
 * Component responsible for displaying and managing the assignee information
 * for an annotation markup within a project.
 *
 * This component renders the assignee's avatar, name, and edit/delete functionality
 * if the current user has appropriate permissions. It also provides an editable popover
 * for modifying the assignee details upon user interaction.
 *
 * @param {Props} props - The props for the MarkupAssigneeComponent.
 * @returns {JSX.Element} A JSX element representing the MarkupAssigneeComponent.
 */
function MarkupAssigneeComponent({
  markup,
  shouldHideWhiteRim = true,
  isSidePanelOpen = false,
}: Props): JSX.Element {
  const {
    projectId,
    markups,
    updateSelectedMarkupId,
    hasPermissionToEditMarkup,
    setIsMarkupUpdating,
    updateMarkups,
  } = useMarkupContext();
  const { removeMember } = useProjectMarkupUpdate({ projectId });

  const { companyMembers, projectMembers } = useMembersUtils();

  const currentUser = useAppSelector(currentUserSelector);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSpinnerLoading, setIsSpinnerLoading] = useState<boolean>(false);

  /** Filter the members to remove all the members viewers for the dropdown */
  const filterMembers = useMemo(
    () =>
      projectMembers.filter(
        (projectMember) =>
          projectMember.role !== CoreAPITypes.EUserProjectRole.viewer
      ),
    [projectMembers]
  );

  const assigneeMember = useMemo(() => {
    return getMarkupAssigneeMember({
      assignee: markup.assignee,
      companyMembers,
      projectMembers: filterMembers,
    });
  }, [markup, companyMembers, filterMembers]);

  const isCurrentUser = assigneeMember?.identity === currentUser?.identity;

  const markupAssignName = useMemo(
    () =>
      getMarkupAssigneeName({
        assignee: markup.assignee,
        companyMembers,
        projectMembers: filterMembers,
      }),
    [markup, companyMembers, filterMembers]
  );

  const assigneeName = useMemo(() => {
    return hasPermissionToEditMarkup
      ? markupAssignName ?? (
          <Box sx={{ pl: isSidePanelOpen ? "3px" : 0 }}>
            <UserNew />
          </Box>
        )
      : markupAssignName || "-";
  }, [hasPermissionToEditMarkup, isSidePanelOpen, markupAssignName]);

  /**
   * Handles the removal of a member assigned to an annotation markup.
   *
   * @param event The mouse event triggered by the button click.
   * @returns A promise that resolves once the member removal process is completed.
   */
  async function handleRemoveMember(
    event: MouseEvent<HTMLButtonElement>
  ): Promise<void> {
    event.stopPropagation();

    if (markup.assignee?.id) {
      setIsSpinnerLoading(true);
      setIsMarkupUpdating(true);

      await removeMember(markup.assignee.id);

      const newMarkups = cloneDeep(markups);
      const markupIndex = findIndex(newMarkups, { id: markup.id });

      newMarkups[markupIndex].assignee = undefined;

      updateMarkups(newMarkups);

      setIsSpinnerLoading(false);
      setIsMarkupUpdating(false);
    }
  }

  /**
   * Handles closing behavior when clicking away from the component.
   * Resets the anchor element and updates the selected markup to undefined.
   */
  function closeAssigneeDropdown(): void {
    updateSelectedMarkupId(undefined);
    setAnchorEl(null);
  }

  /**
   * Handles the event when opening the assignee dropdown menu.
   * Stops the event propagation to prevent unintended interactions
   *
   * @param event The mouse event triggering the dropdown menu opening.
   */
  function openAssigneeDropdown(event: MouseEvent<HTMLDivElement>): void {
    if (hasPermissionToEditMarkup) {
      event.stopPropagation();

      updateSelectedMarkupId(markup.id);
      setAnchorEl(event.currentTarget);
    }
  }

  return (
    <>
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
          px: isSidePanelOpen ? 0 : "8px",
          gap: "10px",
          ...(hasPermissionToEditMarkup && {
            cursor: "pointer",
            borderBottom: anchorEl
              ? `2px solid ${sphereColors.blue400}`
              : "1px solid transparent",
            background: anchorEl ? "white" : "",
            "&:hover": {
              [`& .${MARKUP_ASSIGNEE_EDIT_ICON_CLASS}`]: {
                visibility: anchorEl ? "hidden" : "visible",
              },
              borderBottom: !anchorEl
                ? `1px solid ${sphereColors.gray800}`
                : "",
            },
          }),
        }}
        onClick={openAssigneeDropdown}
      >
        {assigneeMember && (
          <SphereAvatar
            initials={getUserInitials(assigneeMember)}
            src={assigneeMember?.thumbnailUrl}
            icon={getPendingUserIcon(assigneeMember)}
            shouldUseSimplifiedAvatar={!isCurrentUser}
            shouldHideWhiteRim={shouldHideWhiteRim}
            size={isSidePanelOpen ? "x-small" : "small"}
          />
        )}

        <Typography
          component="var"
          sx={{
            ...withEllipsis,
            fontSize: "12px",
          }}
        >
          {assigneeName}
        </Typography>

        <Box sx={{ marginLeft: "auto" }}>
          {isSpinnerLoading && <FaroButtonSpinner />}

          {assigneeMember && hasPermissionToEditMarkup && !isSpinnerLoading && (
            <FaroIconButton
              component={CloseIcon}
              buttonSize="24px"
              iconSize="16px"
              color={sphereColors.gray400}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
              onClick={handleRemoveMember}
              iconButtonProps={{
                className: MARKUP_ASSIGNEE_EDIT_ICON_CLASS,
                sx: { visibility: "hidden" },
              }}
            />
          )}
        </Box>
      </Box>

      {anchorEl && hasPermissionToEditMarkup && (
        <MarkupAssigneeEditable
          anchorEl={anchorEl}
          closeAssigneeDropdown={closeAssigneeDropdown}
          setIsSpinnerLoading={setIsSpinnerLoading}
          projectMembers={filterMembers}
          markup={markup}
          assigneeMember={assigneeMember}
        />
      )}
    </>
  );
}

export const MarkupAssignee = memo(MarkupAssigneeComponent);
