import { CoreAPITypes } from "@stellar/api-logic";
import { RoleAutocomplete } from "@components/role/role-autocomplete/role-autocomplete";
import { RoleAutocompleteProps } from "@components/role/select-role-utils";


/** Renders a dropdown to select project role */
export function ProjectRoleAutoComplete< T extends CoreAPITypes.EUserProjectRole
>({
  selectedRole,
  onChange,
  onResetSelection,
  placeholder = "Select a role...",
  disabledRoles = [],
  isRequired,
}: RoleAutocompleteProps<T>): JSX.Element {

  return (
    <RoleAutocomplete
      labelTitle= "Project role"
      isRequired={isRequired}
      selectedRole={selectedRole}
      onChange={onChange}
      onResetSelection={onResetSelection}
      placeholder={placeholder}
      roles={Object.values(CoreAPITypes.EUserProjectRole) as T[]}
      disabledRoles={([CoreAPITypes.EUserProjectRole.owner].concat(
        disabledRoles
      ) as T[])}
      link="projectRolesHelp"
      dataTestId="project-role-selector"
    />
  );
}