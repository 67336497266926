import { createContext, useContext } from "react";
import { ErrorWithTitle } from "@context-providers/error-boundary/error-types";

/**
 * Generic interface of a function handling errors with a title.
 * Error-handling includes logging and displaying the error to the user, unless it can be resolved automatically.
 */
export type ErrorHandlingFunction = ({
  id,
  title,
  error,
}: ErrorWithTitle) => void;

export interface ErrorHandlingContextProps {
  /** Use to show a error dialog */
  handleErrorWithDialog: ErrorHandlingFunction;

  /** Use to show a toast with an error message */
  handleErrorWithToast: ErrorHandlingFunction;

  /** Use to unmount all components within the closest error boundary and show an error page instead */
  handleErrorWithPage: ErrorHandlingFunction;
}

export const ErrorHandlingContext =
  createContext<ErrorHandlingContextProps | null>(null);

/**
 * Hook to get access to error-handling functions.
 * Must be used within an ErrorBoundary.
 */
export function useErrorContext(): ErrorHandlingContextProps {
  const context = useContext(ErrorHandlingContext);

  if (!context) {
    throw new Error(
      "useErrorContext() has to be used within an ErrorHandlingContext."
    );
  }

  return context;
}
