import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { Alert } from "@faro-lotv/flat-ui";
import { Box, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { useCallback, useState } from "react";
import UploadSvg from "@assets/icons/new/upload-2_50px.svg?react";
import { FileUploadTaskContext, UploadedFileResponse } from "@custom-types/file-upload-types";
import { ImportDataButton } from "@pages/project-details/project-data-management/import-data/import-data-button";
import {
  ALLOWED_EXTENSIONS_ALL,
  MAX_FILE_SIZE_IN_MB,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { CancelImportButton } from "@pages/project-details/project-data-management/import-data/cancel-import-button";
import { useOnSelectFiles } from "@hooks/data-management/use-on-select-any-files";
import { haveFileNamesValidLength } from "@hooks/upload-tasks/upload-tasks-utils";
import { UploadErrorToastType, useUploadErrorToast } from "@hooks/data-management/use-upload-error-toast";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { SdbProject } from "@custom-types/project-types";
import { APITypes } from "@stellar/api-logic";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { useAppSelector } from "@store/store-helper";
import { WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { hasAnyDraftChangesSelector } from "@store/capture-tree/capture-tree-selectors";

interface Props {
  project: SdbProject;
  /** A map of the externalIds of the uploaded ELS scans, as returned by getUploadedIdsMap. */
  uploadedIdsMap: { [key: APITypes.UUID]: boolean },
  /** Flag whether the upload dialog is open. */
  isUploadDialogOpen: boolean;
  /** Setter for showing or hiding the upload dialog. */
  setIsUploadDialogOpen(isUploadDialogOpen: boolean): void;
  /** Flag to show the ImportDataButton. */
  isUploadBtnVisible: boolean;
  /** Flag to disable the ImportDataButton. */
  isUploadBtnDisabled: boolean;
  /** The tooltip of the ImportDataButton if isUploadBtnDisabled is set to true. */
  uploadBtnDisabledTooltip?: string;
  /** Workflow state of the project */
  workflowState: WorkflowState;
  /** Flag if an empty page is currently shown. */
  isEmpty: boolean;
}

/** Renders a button and the dialog to import ELS scan data. */
export function ImportData({
  project,
  uploadedIdsMap,
  isUploadDialogOpen,
  setIsUploadDialogOpen,
  isUploadBtnVisible,
  isUploadBtnDisabled,
  uploadBtnDisabledTooltip,
  workflowState,
  isEmpty,
}: Props): JSX.Element {
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const hasAnyDraftChanges = useAppSelector(hasAnyDraftChangesSelector);
  const uploadErrorToast = useUploadErrorToast();
  const onSelectFiles = useOnSelectFiles(project);
  // Flag if we're inside the onSelectFiles method while getting the necessary data.
  // In that case, a spinner is shown instead of the dropzone's normal captions.
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeDialog = useCallback((): void => {
    setIsUploadDialogOpen(false);
  }, [setIsUploadDialogOpen]);

  const onUploadComplete = useCallback(
    (uploadedResponse: UploadedFileResponse, context: FileUploadTaskContext): void => {
      // Nothing. Unused dummy function.
    }, []
  );

  /**
   * Event handler when files were selected via drag & drop or the file explorer.
   * Since we want to override large parts of the upload process, we don't use the _confirmCallback parameter,
   * and don't hand control back to the SphereDropzone.
   */
  const onSelectFilesInternal = useCallback(
    async(files: FileList | File[], _confirmCallback: () => void): Promise<void> => {
      try {
        // Show loading spinner while collecting file info.
        setIsLoading(true);

        // Validate file/folder names. If they're too long it can lead to an error or unexpected behaviour.
        const areFileNamesValid = haveFileNamesValidLength(files);
        if (!areFileNamesValid) {
          uploadErrorToast(UploadErrorToastType.tooLongFilename);
          return;
        }

        const isSuccessful = await onSelectFiles(files, uploadedIdsMap);
        if (!isSuccessful) {
          return;
        }

        closeDialog();
      } finally {
        setIsLoading(false);
      }
    }, [closeDialog, onSelectFiles, uploadErrorToast, uploadedIdsMap]
  );

  const isImportBtnDev = isDevModeEnabled && (!isUploadBtnVisible || isUploadBtnDisabled);

  return (
    <>
      {!isEmpty && !isImportBtnDev && isUploadBtnVisible && (
        <ImportDataButton
          isDisabled={isUploadBtnDisabled}
          disabledTooltip={uploadBtnDisabledTooltip}
          onClick={() => setIsUploadDialogOpen(true)}
        />
      )}
      {!isEmpty && isImportBtnDev && (
        <ImportDataButton
          isDisabled={false}
          isDevMode={true}
          onClick={() => setIsUploadDialogOpen(true)}
        />
      )}
      {(!isUploadBtnVisible || hasAnyDraftChanges) && (
        <CancelImportButton
          workflowState={workflowState}
        />
      )}
      <FaroDialog
        title="Upload Data"
        shouldHideActions={true}
        open={isUploadDialogOpen}
        onClose={closeDialog}
      >
        <Grid maxWidth="100%" width="70vw">
          <Alert
            title='Please upload the folder with raw Blink data (.gls) which contains a file called "index-v2".'
            variant="info"
            sx={{
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
              backgroundColor: sphereColors.blue100,
            }}
          />

          {/* Spinner while collecting file info */}
          {isLoading &&
            <Box data-testid="sa-upload-data-spinner" sx={{
                display: "flex",
                justifyContent: "center",
                height: "250px",
                border: `2px dashed ${sphereColors.gray300}`,
            }}>
              <FaroButtonSpinner
                loadingTrackColor={sphereColors.gray50 }
                size="80px"
                marginLeft="0px"
              />
            </Box>
          }

          {!isLoading &&
            <SphereDropzone
              instruction="Drag & drop"
              maxFileSize={MAX_FILE_SIZE_IN_MB}
              shouldShowSupportedFormats={false}
              shouldShowSizeLimit={false}
              shouldAllowMultiUpload={false}
              shouldAllowFolderUpload={true}
              shouldAllowFiles={false}
              avatar={
                <SphereAvatar
                  icon={<UploadSvg />}
                  size="x-large"
                  shouldHideWhiteRim
                  iconColor={sphereColors.gray600}
                  backgroundColor={sphereColors.gray100}
                />
              }
              allowedExtensions={ALLOWED_EXTENSIONS_ALL}
              isLoading={false}
              setIsLoading={() => undefined}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises -- We don't need to await.
              onUploadComplete={onUploadComplete}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises -- We don't need to await.
              onSelectFiles={onSelectFilesInternal}
            />
          }
        </Grid>
      </FaroDialog>
    </>
  );
}
