/* eslint-disable @typescript-eslint/naming-convention -- names given by backend*/
import { IElement, LabelType } from "@faro-lotv/ielement-types";

export const MOCK_IELEMENTS: IElement[] = [
  {
    id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    typeHint: "SlideContainer",
    type: "ProjectRoot",
    name: "Project-a063bca3-7e7a-4c50-9709-65a0bb16df83",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T09:30:18.8949079+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-06-26T12:04:54.3838178+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: [
      "914ec791-5aba-4202-3ed1-08dc57ae8189",
      "4ae68a52-dd11-4a1c-4e0a-08dc57ae8189",
      "cddbdfdf-c4de-41f4-4e4f-08dc57ae8189",
    ],
    children_Ids: [
      "914ec791-5aba-4202-3ed1-08dc57ae8189",
      "4ae68a52-dd11-4a1c-4e0a-08dc57ae8189",
      "cddbdfdf-c4de-41f4-4e4f-08dc57ae8189",
    ],
    parentId: null,
    parent_Id: null,
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [
      {
        id: "8fa50a88-9044-4fe0-5f00-08dc8ada494f",
        name: "PublishedRegistration",
        createdAt: "2024-06-12T12:22:11.72115+00:00",
        createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
        resourceId: "a063bca3-7e7a-4c50-9709-65a0bb16df83",
        labelType: LabelType.MergedPointCloud,
        color: null,
        lastModifiedInDb: "2024-06-12T12:22:13.4653722",
      },
    ],
    metaDataMap: {
      isInitial: true,
      slideJsonUrl: "someUrl",
    },
    lastModifiedInDb: "2024-06-26T12:04:55.472557",
  },
  {
    id: "15192347-3432-499a-a780-08dc57ae84af",
    typeHint: "DataSetGeoSlam",
    type: "Section",
    name: "2024-01-31_12-46-49",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T09:30:24.179671+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-06-12T10:13:28.176905+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: [
      "eeaa63ee-63db-488d-a880-08dc57ae84af",
      "adcebf6d-9c9f-4a49-5f80-08dc57b16af6",
      "47bb5dac-4836-4b54-5600-08dc57b5745b",
      "bb3f9804-5af6-4037-f380-08dc57b792c5",
      "d18cac4c-c81e-40c6-f200-08dc57b793a9",
      "9b29e1cf-c3d1-4108-b8d0-08dcf981a331",
      "3c7bc5cc-8e01-4726-b0f3-08dc57b92d75",
      "2974a81e-1b11-48c8-5780-08dc57b9b3d6",
      "b7847edc-d20c-4e3c-d880-08dc57b9baae",
      "47abf0e2-33e3-42fb-ae00-08dc596de27f",
      "4a5c6693-9637-4b9b-4700-08dc8a35f5e9",
      "58d5a88a-d315-49bc-b3fb-08dc8ada4826",
      "481ce936-47b3-434a-b5be-08dc8fa6056e",
    ],
    children_Ids: [
      "eeaa63ee-63db-488d-a880-08dc57ae84af",
      "adcebf6d-9c9f-4a49-5f80-08dc57b16af6",
      "47bb5dac-4836-4b54-5600-08dc57b5745b",
      "bb3f9804-5af6-4037-f380-08dc57b792c5",
      "d18cac4c-c81e-40c6-f200-08dc57b793a9",
      "9b29e1cf-c3d1-4108-b8d0-08dcf981a331",
      "3c7bc5cc-8e01-4726-b0f3-08dc57b92d75",
      "2974a81e-1b11-48c8-5780-08dc57b9b3d6",
      "b7847edc-d20c-4e3c-d880-08dc57b9baae",
      "47abf0e2-33e3-42fb-ae00-08dc596de27f",
      "4a5c6693-9637-4b9b-4700-08dc8a35f5e9",
      "58d5a88a-d315-49bc-b3fb-08dc8ada4826",
      "481ce936-47b3-434a-b5be-08dc8fa6056e",
    ],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaDataMap: {
      captureTreeMigration: {
        OriginalAreaId: "1147cf1f-8b36-4b35-5d00-08dc57ae83c5",
        OriginalAreaPose: null,
        OriginalDataSessionPose: {
          Pos: {
            X: 10.528915036774464,
            Y: 0.07031464706223273,
            Z: -0.2306071419883093,
          },
          Scale: {
            X: 1.0,
            Y: 1.0,
            Z: 1.0,
          },
          Rot: {
            X: -5.906067094232787e-5,
            Y: 0.9998562538914801,
            Z: -0.0032309848224471817,
            W: 0.016644182257186203,
          },
          Gps: null,
          IsWorldRot: false,
          IsWorldScale: null,
          IsWorldPose: null,
        },
        OriginalDataSetPose: null,
      },
    },
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "26bafc4b-7482-4d20-4c00-08dc57ae855e",
    typeHint: "DataSetGeoSlam",
    type: "Section",
    name: "2024-01-31_13-24-28",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T09:30:25.324242+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-06-12T10:13:28.467489+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: [
      "109d7424-239e-457e-4d00-08dc57ae855e",
      "acbb6d1f-7b1e-4aab-c280-08dc57b16e0a",
      "c8f272d1-244b-4b06-2a00-08dc57b56713",
      "28f0b4d9-6748-4936-1100-08dc57b7c0e1",
      "5081e1ac-3e2f-4ab0-b480-08dc57b7c1e1",
      "47e3e2a6-c19b-4832-04e9-08dc57b96040",
      "54e51064-9627-4d86-6700-08dc57ba1fbf",
      "ed21eed9-cf1a-4c42-f100-08dc57ba2713",
      "fd2809fc-69f2-4d3f-f980-08dc596de2b2",
      "ef5334d6-83cd-4d83-f880-08dc65d367ff",
      "59252d8c-c7a1-4d69-ea6b-08dc8ada48ac",
      "e6547274-9fe9-4bb1-4538-08dc8fa605fe",
      "7b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    children_Ids: [
      "109d7424-239e-457e-4d00-08dc57ae855e",
      "acbb6d1f-7b1e-4aab-c280-08dc57b16e0a",
      "c8f272d1-244b-4b06-2a00-08dc57b56713",
      "28f0b4d9-6748-4936-1100-08dc57b7c0e1",
      "5081e1ac-3e2f-4ab0-b480-08dc57b7c1e1",
      "47e3e2a6-c19b-4832-04e9-08dc57b96040",
      "54e51064-9627-4d86-6700-08dc57ba1fbf",
      "ed21eed9-cf1a-4c42-f100-08dc57ba2713",
      "fd2809fc-69f2-4d3f-f980-08dc596de2b2",
      "ef5334d6-83cd-4d83-f880-08dc65d367ff",
      "59252d8c-c7a1-4d69-ea6b-08dc8ada48ac",
      "e6547274-9fe9-4bb1-4538-08dc8fa605fe",
      "7b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaDataMap: {
      captureTreeMigration: {
        OriginalAreaId: "1147cf1f-8b36-4b35-5d00-08dc57ae83c5",
        OriginalAreaPose: null,
        OriginalDataSessionPose: {
          Pos: {
            X: -39.817357958537315,
            Y: -0.3711477055357047,
            Z: -18.947597518590673,
          },
          Scale: {
            X: 1.0,
            Y: 1.0,
            Z: 1.0,
          },
          Rot: {
            X: -0.002975975522628104,
            Y: 0.8966299980650949,
            Z: -0.003732807443130695,
            W: -0.44275484897183154,
          },
          Gps: null,
          IsWorldRot: false,
          IsWorldScale: null,
          IsWorldPose: null,
        },
        OriginalDataSetPose: null,
      },
    },
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "e1e87e98-ee92-4da7-6600-08dc57ae85e5",
    typeHint: "DataSetGeoSlam",
    type: "Section",
    name: "2024-01-31_12-59-29",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T09:30:26.209649+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-06-12T08:21:43.903384+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: [
      "76c62067-95e8-4e9c-6700-08dc57ae85e5",
      "ccd53168-070a-45da-e300-08dc57b1e01d",
      "05db029b-cb54-4c11-9800-08dc57b7127c",
      "cf3becac-8d49-4c5e-da80-08dc57baa878",
      "58ea9d5b-9957-48f6-fe00-08dc57baa9d9",
      "f3698058-5df0-4238-d3cb-08dc57bc6f9f",
      "7232e067-47c4-4408-7500-08dc57bea90b",
      "cec6a5d4-3461-4d2a-4f00-08dc57beb33c",
      "a0673de8-16d7-46b2-9800-08dc65d36837",
      "c3495c7a-c8ad-4805-db00-08dc8a35f62f",
      "61a77673-44ed-4ab7-eccd-08dc8ada48ac",
      "5b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    children_Ids: [
      "76c62067-95e8-4e9c-6700-08dc57ae85e5",
      "ccd53168-070a-45da-e300-08dc57b1e01d",
      "05db029b-cb54-4c11-9800-08dc57b7127c",
      "cf3becac-8d49-4c5e-da80-08dc57baa878",
      "58ea9d5b-9957-48f6-fe00-08dc57baa9d9",
      "f3698058-5df0-4238-d3cb-08dc57bc6f9f",
      "7232e067-47c4-4408-7500-08dc57bea90b",
      "cec6a5d4-3461-4d2a-4f00-08dc57beb33c",
      "a0673de8-16d7-46b2-9800-08dc65d36837",
      "c3495c7a-c8ad-4805-db00-08dc8a35f62f",
      "61a77673-44ed-4ab7-eccd-08dc8ada48ac",
      "5b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaDataMap: {
      captureTreeMigration: {
        OriginalAreaId: "1147cf1f-8b36-4b35-5d00-08dc57ae83c5",
        OriginalAreaPose: null,
        OriginalDataSessionPose: {
          Pos: {
            X: -3.469446951953614e-18,
            Y: 0.0,
            Z: -1.3877787807814457e-17,
          },
          Scale: {
            X: 1.0,
            Y: 1.0,
            Z: 1.0,
          },
          Rot: {
            X: 0.0,
            Y: 0.0,
            Z: 0.0,
            W: 1.0,
          },
          Gps: null,
          IsWorldRot: false,
          IsWorldScale: null,
          IsWorldPose: null,
        },
        OriginalDataSetPose: null,
      },
    },
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "dfb345f3-c463-4550-5c00-08dc8ada43c5",
    typeHint: "DataSetGeoSlam",
    type: "Section",
    name: "Merged PC",
    descr: null,
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    createdAt: "2024-06-12T12:11:44.278+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-06-12T12:46:58.7625434+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: [
      "c6342941-11f6-473b-5d00-08dc8ada43c5",
      "08e9064b-22d2-4516-ab9d-08dc8addbfa7",
      "3b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    children_Ids: [
      "c6342941-11f6-473b-5d00-08dc8ada43c5",
      "08e9064b-22d2-4516-ab9d-08dc8addbfa7",
      "3b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [
      {
        id: "8fa50a88-9044-4fe0-5f00-08dc8ada494f",
        name: "PublishedRegistration",
        createdAt: "2024-06-12T12:22:11.72115+00:00",
        createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
        resourceId: "a063bca3-7e7a-4c50-9709-65a0bb16df83",
        labelType: LabelType.MergedPointCloud,
        color: null,
        lastModifiedInDb: "2024-06-12T12:22:13.4653722",
      },
    ],
    metaDataMap: {
      captureTreeMigration: {
        OriginalAreaId: "1147cf1f-8b36-4b35-5d00-08dc57ae83c5",
        OriginalAreaPose: null,
        OriginalDataSessionPose: {
          Pos: {
            X: 0.0,
            Y: 0.0,
            Z: 0.0,
          },
          Scale: {
            X: 1.0,
            Y: 1.0,
            Z: 1.0,
          },
          Rot: {
            X: 0.0,
            Y: 0.0,
            Z: 0.0,
            W: 1.0,
          },
          Gps: null,
          IsWorldRot: false,
          IsWorldScale: true,
          IsWorldPose: null,
        },
        OriginalDataSetPose: null,
      },
    },
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "6519a4bc-1e92-44c4-3e80-08dc8fa601e9",
    typeHint: "DataSetGeoSlam",
    type: "Section",
    name: "test_bilal",
    descr: null,
    createdBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    createdAt: "2024-06-18T14:33:34.189+00:00",
    modifiedBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    modifiedAt: "2024-06-18T15:32:09.1607197+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: [
      "95027b40-ace5-497c-4000-08dc8fa601e9",
      "6fc6fc9e-b6e3-4615-c225-08dc8fabd130",
      "1b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    children_Ids: [
      "95027b40-ace5-497c-4000-08dc8fa601e9",
      "6fc6fc9e-b6e3-4615-c225-08dc8fabd130",
      "1b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    ],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [
      {
        id: "8fa50a88-9044-4fe0-5f00-08dc8ada494f",
        name: "PublishedRegistration",
        createdAt: "2024-06-12T12:22:11.72115+00:00",
        createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
        resourceId: "a063bca3-7e7a-4c50-9709-65a0bb16df83",
        labelType: LabelType.MergedPointCloud,
        color: null,
        lastModifiedInDb: "2024-06-12T12:22:13.4653722",
      },
    ],
    metaDataMap: {
      captureTreeMigration: {
        OriginalAreaId: "1147cf1f-8b36-4b35-5d00-08dc57ae83c5",
        OriginalAreaPose: null,
        OriginalDataSessionPose: {
          Pos: {
            X: 0.0,
            Y: 0.0,
            Z: 0.0,
          },
          Scale: {
            X: 1.0,
            Y: 1.0,
            Z: 1.0,
          },
          Rot: {
            X: 0.0,
            Y: 0.0,
            Z: 0.0,
            W: 1.0,
          },
          Gps: null,
          IsWorldRot: false,
          IsWorldScale: true,
          IsWorldPose: null,
        },
        OriginalDataSetPose: null,
      },
    },
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "a5a0dfd2-46cd-4a07-990f-560c53f00d7c",
    typeHint: "ElsScan",
    type: "Section",
    name: "dataset_01",
    descr: null,
    createdBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    createdAt: "2024-06-18T14:33:34.189+00:00",
    modifiedBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    modifiedAt: "2024-06-18T15:32:09.1607197+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: ["70be314d-c1fc-4e46-b639-a6097d5b7e86"],
    children_Ids: ["70be314d-c1fc-4e46-b639-a6097d5b7e86"],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "4513f3e2-41cc-4d2a-8ab8-89b39dbb9119",
    typeHint: "ElsScan",
    type: "Section",
    name: "dataset_02",
    descr: null,
    createdBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    createdAt: "2024-06-18T14:33:34.189+00:00",
    modifiedBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    modifiedAt: "2024-06-18T15:32:09.1607197+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: ["9c83c896-c960-43d4-a3c7-5f0a61897a8f"],
    children_Ids: ["9c83c896-c960-43d4-a3c7-5f0a61897a8f"],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "0f2e9e19-abf0-4665-8078-52c7921e7647",
    typeHint: "ElsScan",
    type: "Section",
    name: "dataset_03",
    descr: null,
    createdBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    createdAt: "2024-06-18T14:33:34.189+00:00",
    modifiedBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    modifiedAt: "2024-06-18T15:32:09.1607197+00:00",
    thumbnailUri: null,
    pose: {
      pos: null,
      scale: null,
      rot: null,
      gps: null,
      isWorldRot: false,
      isWorldScale: true,
      isWorldPose: undefined,
    },
    childrenIds: ["cf019492-5788-4bca-af6f-7b1faeb778eb"],
    children_Ids: ["cf019492-5788-4bca-af6f-7b1faeb778eb"],
    parentId: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    parent_Id: "0fd07f00-8b6e-46ba-4ea3-08dc57ae8189",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "9b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    typeHint: null,
    type: "PointCloudE57",
    name: "stuttgart-office_1.e57",
    descr: null,
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    createdAt: "2024-10-31T07:57:16.381819+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-10-31T07:57:16.3818191+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "15192347-3432-499a-a780-08dc57ae84af",
    parent_Id: "15192347-3432-499a-a780-08dc57ae84af",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-10-31T07:57:20.1304873",
  },
  {
    id: "3c7bc5cc-8e01-4726-b0f3-08dc57b92d75",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T10:46:42.3032062+00:00",
    modifiedBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    modifiedAt: "2024-04-08T10:46:42.3032114+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "15192347-3432-499a-a780-08dc57ae84af",
    parent_Id: "15192347-3432-499a-a780-08dc57ae84af",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "bdc723b2-7e43-4653-e4c4-08dc57ba4132",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T10:54:24.9167516+00:00",
    modifiedBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    modifiedAt: "2024-04-08T10:54:24.9167555+00:00",
    thumbnailUri: null,
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.7071067690849304,
        y: 0.0,
        z: 0.0,
        w: 0.7071067690849304,
      },
      gps: null,
      isWorldRot: false,
      isWorldScale: undefined,
      isWorldPose: undefined,
    },
    childrenIds: null,
    children_Ids: null,
    parentId: "b7847edc-d20c-4e3c-d880-08dc57b9baae",
    parent_Id: "b7847edc-d20c-4e3c-d880-08dc57b9baae",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "7b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    typeHint: null,
    type: "PointCloudE57",
    name: "stuttgart-office_1.e57",
    descr: null,
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    createdAt: "2024-10-31T07:57:16.381819+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-10-31T07:57:16.3818191+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "26bafc4b-7482-4d20-4c00-08dc57ae855e",
    parent_Id: "26bafc4b-7482-4d20-4c00-08dc57ae855e",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-10-31T07:57:20.1304873",
  },
  {
    id: "47e3e2a6-c19b-4832-04e9-08dc57b96040",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T10:48:07.5149392+00:00",
    modifiedBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    modifiedAt: "2024-04-08T10:48:07.5149426+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "26bafc4b-7482-4d20-4c00-08dc57ae855e",
    parent_Id: "26bafc4b-7482-4d20-4c00-08dc57ae855e",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "8d5c6330-770c-441f-d125-08dc57baa924",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T10:57:19.3071635+00:00",
    modifiedBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    modifiedAt: "2024-04-08T10:57:19.3071673+00:00",
    thumbnailUri: null,
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.7071067690849304,
        y: 0.0,
        z: 0.0,
        w: 0.7071067690849304,
      },
      gps: null,
      isWorldRot: false,
      isWorldScale: undefined,
      isWorldPose: undefined,
    },
    childrenIds: null,
    children_Ids: null,
    parentId: "ed21eed9-cf1a-4c42-f100-08dc57ba2713",
    parent_Id: "ed21eed9-cf1a-4c42-f100-08dc57ba2713",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "5b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    typeHint: null,
    type: "PointCloudE57",
    name: "stuttgart-office_1.e57",
    descr: null,
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    createdAt: "2024-10-31T07:57:16.381819+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-10-31T07:57:16.3818191+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "e1e87e98-ee92-4da7-6600-08dc57ae85e5",
    parent_Id: "e1e87e98-ee92-4da7-6600-08dc57ae85e5",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-10-31T07:57:20.1304873",
  },
  {
    id: "f3698058-5df0-4238-d3cb-08dc57bc6f9f",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T11:10:01.7989241+00:00",
    modifiedBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    modifiedAt: "2024-04-08T11:10:01.7989286+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "e1e87e98-ee92-4da7-6600-08dc57ae85e5",
    parent_Id: "e1e87e98-ee92-4da7-6600-08dc57ae85e5",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "e3d33919-7021-4d81-b77d-08dc57bf71ca",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    createdAt: "2024-04-08T11:31:33.9256769+00:00",
    modifiedBy: "6d0009cb-18fc-482d-b04f-57f4c08b9be5",
    modifiedAt: "2024-04-08T11:31:33.9256808+00:00",
    thumbnailUri: null,
    pose: {
      pos: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      scale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      rot: {
        x: 0.7071067690849304,
        y: 0.0,
        z: 0.0,
        w: 0.7071067690849304,
      },
      gps: null,
      isWorldRot: false,
      isWorldScale: undefined,
      isWorldPose: undefined,
    },
    childrenIds: null,
    children_Ids: null,
    parentId: "cec6a5d4-3461-4d2a-4f00-08dc57beb33c",
    parent_Id: "cec6a5d4-3461-4d2a-4f00-08dc57beb33c",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "3b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    typeHint: null,
    type: "PointCloudE57",
    name: "stuttgart-office_1.e57",
    descr: null,
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    createdAt: "2024-10-31T07:57:16.381819+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-10-31T07:57:16.3818191+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "dfb345f3-c463-4550-5c00-08dc8ada43c5",
    parent_Id: "dfb345f3-c463-4550-5c00-08dc8ada43c5",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-10-31T07:57:20.1304873",
  },
  {
    id: "08e9064b-22d2-4516-ab9d-08dc8addbfa7",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    createdAt: "2024-06-12T12:46:58.7618936+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-06-12T12:46:58.7618985+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "dfb345f3-c463-4550-5c00-08dc8ada43c5",
    parent_Id: "dfb345f3-c463-4550-5c00-08dc8ada43c5",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "1b29e1cf-c3d1-4108-b8d0-08dcf981a331",
    typeHint: null,
    type: "PointCloudE57",
    name: "stuttgart-office_1.e57",
    descr: null,
    createdBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    createdAt: "2024-10-31T07:57:16.381819+00:00",
    modifiedBy: "49ecc9e8-548b-4910-a08b-f86c720bf254",
    modifiedAt: "2024-10-31T07:57:16.3818191+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "6519a4bc-1e92-44c4-3e80-08dc8fa601e9",
    parent_Id: "6519a4bc-1e92-44c4-3e80-08dc8fa601e9",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-10-31T07:57:20.1304873",
  },
  {
    id: "6fc6fc9e-b6e3-4615-c225-08dc8fabd130",
    typeHint: null,
    type: "PointCloudStream",
    name: "PointCloudStream",
    descr: null,
    createdBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    createdAt: "2024-06-18T15:32:09.1596504+00:00",
    modifiedBy: "cb7476e1-0ba4-41aa-981b-d034eb17a812",
    modifiedAt: "2024-06-18T15:32:09.1597341+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "6519a4bc-1e92-44c4-3e80-08dc8fa601e9",
    parent_Id: "6519a4bc-1e92-44c4-3e80-08dc8fa601e9",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-06-28T12:30:09.0290362",
  },
  {
    id: "70be314d-c1fc-4e46-b639-a6097d5b7e86",
    typeHint: null,
    type: "PointCloudElsRaw",
    name: "Scan-24-04-12-14-06-04.gls",
    descr: null,
    createdBy: "e10b8414-cec6-43c6-93f7-df4cca8a147f",
    createdAt: "2024-07-25T13:43:24.1998034+00:00",
    modifiedBy: "e10b8414-cec6-43c6-93f7-df4cca8a147f",
    modifiedAt: "2024-07-25T13:43:24.1998034+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "bf521f64-9928-4c82-333c-08dcacafc0df",
    parent_Id: "bf521f64-9928-4c82-333c-08dcacafc0df",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-07-25T13:43:25.9056811",
  },
  {
    id: "9c83c896-c960-43d4-a3c7-5f0a61897a8f",
    typeHint: null,
    type: "PointCloudElsRaw",
    name: "Scan-24-04-12-14-06-04.gls",
    descr: null,
    createdBy: "e10b8414-cec6-43c6-93f7-df4cca8a147f",
    createdAt: "2024-07-25T13:43:24.1998034+00:00",
    modifiedBy: "e10b8414-cec6-43c6-93f7-df4cca8a147f",
    modifiedAt: "2024-07-25T13:43:24.1998034+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "4513f3e2-41cc-4d2a-8ab8-89b39dbb9119",
    parent_Id: "4513f3e2-41cc-4d2a-8ab8-89b39dbb9119",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-07-25T13:43:25.9056811",
  },
  {
    id: "cf019492-5788-4bca-af6f-7b1faeb778eb",
    typeHint: null,
    type: "PointCloudElsRaw",
    name: "Scan-24-04-12-14-06-04.gls",
    descr: null,
    createdBy: "e10b8414-cec6-43c6-93f7-df4cca8a147f",
    createdAt: "2024-07-25T13:43:24.1998034+00:00",
    modifiedBy: "e10b8414-cec6-43c6-93f7-df4cca8a147f",
    modifiedAt: "2024-07-25T13:43:24.1998034+00:00",
    thumbnailUri: null,
    pose: null,
    childrenIds: null,
    children_Ids: null,
    parentId: "0f2e9e19-abf0-4665-8078-52c7921e7647",
    parent_Id: "0f2e9e19-abf0-4665-8078-52c7921e7647",
    rootId: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    root_Id: "29c354af-c63b-4a4d-c147-08dc57ad7707",
    labels: [],
    metaData: null,
    metaDataMap: {},
    lastModifiedInDb: "2024-07-25T13:43:25.9056811",
  },
];
