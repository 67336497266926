import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { FaroTable } from "@components/common/faro-table/faro-table";
import {
  creditTransactionsSelector,
  fetchingTransactionsFlagsSelector,
} from "@store/credit-transactions/credit-transactions-selector";
import { useAppSelector } from "@store/store-helper";
import { TransactionsHeaders } from "@components/table/transactions/transactions-table-utils";
import { getTransactionsColumns } from "@components/table/transactions/transactions-columns";
import { useDateTime } from "@hooks/use-date-time";
import { useMembersUtils } from "@hooks/use-member-utils";
import { Box } from "@mui/material";
import { projectsMapByIdSelector } from "@store/projects/projects-selector";
import { useMemo } from "react";
import { getMembersMapById } from "@utils/member-utils";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import { FetchingStatus } from "@store/store-types";

/** List of required columns in the transactions table */
const requiredColumns = [
  TransactionsHeaders.date,
  TransactionsHeaders.user,
  TransactionsHeaders.description,
  TransactionsHeaders.project,
  TransactionsHeaders.credits,
];

/** Renders the table for transactions */
export function TransactionsTable(): JSX.Element {
  const transactions = useAppSelector(creditTransactionsSelector);
  const { status } = useAppSelector(fetchingTransactionsFlagsSelector);
  const { formatDate } = useDateTime();
  const projectsMap = useAppSelector(projectsMapByIdSelector);

  const { companyMembers } = useMembersUtils();
  const membersMap = getMembersMapById(companyMembers);

  const transactionColumns = useMemo(() => {
    return requiredColumns.map(
      (requiredColumn) =>
        getTransactionsColumns({
          formatDate,
          membersMap,
          projectsMap,
        })[requiredColumn]
    );
  }, [formatDate, membersMap, projectsMap]);

  if (transactions.length === 0 && status === FetchingStatus.succeeded) {
    return (
      <EmptyPage
        title="No transactions to show"
        subtitle="It looks like there are no transactions during this period."
        icon={NoDataIcon}
      />
    );
  }

  return (
    <Box
      sx={{
        marginTop: SPACE_ELEMENTS_OF_MODAL,
        overflow: "auto",
      }}
    >
      <FaroTable
        entities={transactions}
        uniqueIdKey={"id"}
        isLoading={status === FetchingStatus.pending}
        shouldHideCheckbox={true}
        columns={transactionColumns}
        rowHeight={70}
      />
    </Box>
  );
}
